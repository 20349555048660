import { FeatureIconName } from 'libs/icons';

import { FirstlineDataHeader } from './FirstlineDataHeader';
import { useFirstlineHeaderI18n } from './useFirstlineHeaderI18n';
import { FirstlineDataProperty } from '../FirstlineDataProperty';

export function FitlineClubMemberHeader(props: {
  readonly iconOnly?: boolean;
}) {
  const t = useFirstlineHeaderI18n();

  return (
    <FirstlineDataHeader
      icon={FeatureIconName.FitlineClubMember}
      text={t(FirstlineDataProperty.FitlineClubMember)}
      onlyIcon={props.iconOnly}
    />
  );
}
