import { TPII } from './TPII';
import { usePII } from './usePII';

interface IPIIPros {
  readonly value: string | undefined | null;
  readonly type: TPII;
}

export function PII(props: IPIIPros) {
  const { pii } = usePII();

  return <>{pii(props.value, props.type)}</>;
}
