import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useStepI18n } from 'features/shared-baskets/hooks/useStepI18n';
import { useMemo } from 'react';

import { WizardSectionWrapper } from '../WizardSectionWrapper';
import { BasketSummary } from './BasketSummary';

export function SummaryStep() {
  const { id: activeStep, steps } = useNewBasketContext();
  const tStep = useStepI18n();

  const { completed, active, step, title } = useMemo(() => {
    const step = steps[2];
    const completed = [steps[3]].includes(activeStep);
    const active = activeStep === step;
    const title = tStep(step, completed) ?? '';

    return {
      step,
      completed,
      active,
      title
    };
  }, [activeStep, steps, tStep]);

  return (
    <WizardSectionWrapper
      step={step}
      index={3}
      completed={completed}
      title={title}
      active={active}
    >
      <BasketSummary />
    </WizardSectionWrapper>
  );
}
