import { useQuery } from '@tanstack/react-query';
import { getEvent } from 'services';

const EVENTS_QUERY_KEY = 'pmi.web.office__events_list';
const TTL = 900000; // 15 minutes in milliseconds

export function useEvent(eventId: string) {
  const query = useQuery({
    queryKey: [EVENTS_QUERY_KEY, eventId],
    queryFn: () => getEvent(eventId),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
