export function Folder() {
  return (
    <svg
      height="24"
      width="24"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M1,13V40a4,4,0,0,0,4,4H43a4,4,0,0,0,4-4V13Z" fill="#6cc4f5" />
        <path
          d="M47,14H1V6A2,2,0,0,1,3,4H13l4,4H43a4,4,0,0,1,4,4Z"
          fill="#2594d0"
        />
      </g>
    </svg>
  );
}
