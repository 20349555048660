import { FC } from 'react';

export const CarOutlineIcon: FC<{ readonly size: number }> = ({ size }) => {
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className="flex-shrink-0 flex-grow-0"
    >
      <g fill="currentColor" stroke="currentColor" strokeWidth="1">
        <polyline
          fill="none"
          points="4.5,12.5 4.5,14.5 0.5,14.5 0.5,12.5 "
          stroke="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          fill="none"
          points="15.5,12.5 15.5,14.5 11.5,14.5 11.5,12.5 "
          stroke="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          height="5"
          width="15"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="0.5"
          y="7.5"
        />
        <circle cx="3" cy="10" fill="currentColor" r="1" stroke="none" />
        <circle cx="13" cy="10" fill="currentColor" r="1" stroke="none" />
        <path
          d="M0.5,7.5l1.772-5.316 C2.408,1.775,2.79,1.5,3.221,1.5h9.558c0.43,0,0.813,0.275,0.949,0.684L15.5,7.5"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
