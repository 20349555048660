import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { Icon, IconName } from 'libs/icons';
import { useEffect, useRef } from 'react';

const MAX_QUANTITY_PER_ITEM = 99;
const MIN_QUANTITY_PER_ITEM = 0;

export function QuantityController({
  articleNumber,
  small = false
}: {
  readonly articleNumber: string;
  readonly small?: boolean;
}) {
  const { articles, dispatchStepEvent } = useNewBasketContext();

  const quantityInputRef = useRef<HTMLInputElement>(null);
  const quantitySelected = articles?.get(articleNumber) ?? 0;

  useEffect(() => {
    if (quantityInputRef.current) {
      quantityInputRef.current.value = `${quantitySelected}`;
    }
  }, [quantitySelected]);

  return (
    <div
      className={`grid grid-cols-3 ${
        small ? 'max-w-[120px] h-[24px]' : 'max-w-[200px] h-[40px]'
      }`}
    >
      <button
        title={undefined}
        className={
          quantitySelected
            ? 'flex items-center justify-center border border-silver rounded-l-full border-r-transparent'
            : 'invisible'
        }
        disabled={quantitySelected <= 0}
        onClick={() => {
          if (quantitySelected > 0) {
            dispatchStepEvent({
              type: 'increment-article-quantity',
              value: [articleNumber, -1]
            });
          }
        }}
      >
        {quantitySelected > 1 ? (
          <Icon name={IconName.MinusSign} size={small ? 16 : 20} />
        ) : (
          <Icon name={IconName.TrashCan} size={small ? 16 : 20} />
        )}
      </button>
      <input
        title={undefined}
        ref={quantityInputRef}
        inputMode="numeric"
        className={`text-center outline-none ${
          quantitySelected ? 'border border-silver block' : 'invisible'
        }`}
        defaultValue={quantitySelected}
        min={MIN_QUANTITY_PER_ITEM}
        max={MAX_QUANTITY_PER_ITEM}
        onBlur={event => {
          if (!event.target.value) {
            dispatchStepEvent({
              type: 'set-article-quantity',
              value: [articleNumber, 0]
            });
          }
        }}
        onChange={event => {
          const rawQuantityValue = event.target.value;
          let quantity = Number(rawQuantityValue);

          // Ignore coerse values
          if (quantity === 0 && rawQuantityValue !== `${quantity}`) {
            return;
          }

          // Ignore non numeric inputs
          if (Number.isNaN(quantity)) {
            return;
          }

          // Ignore fractional numbers
          if (!Number.isInteger(quantity)) {
            return;
          }

          // Ignore negative values
          if (quantity < MIN_QUANTITY_PER_ITEM) {
            quantity = MIN_QUANTITY_PER_ITEM;
          }

          // Ignore numbers above MAX
          if (quantity > MAX_QUANTITY_PER_ITEM) {
            quantity = MAX_QUANTITY_PER_ITEM;
          }

          dispatchStepEvent({
            type: 'set-article-quantity',
            value: [articleNumber, quantity]
          });
        }}
      />
      <button
        className={`flex items-center justify-center border border-silver ${
          quantitySelected > 0
            ? 'rounded-r-full border-l-transparent'
            : 'rounded-full w-[40px]'
        }`}
        disabled={quantitySelected >= MAX_QUANTITY_PER_ITEM}
        title={undefined}
        onClick={() => {
          dispatchStepEvent({
            type: 'increment-article-quantity',
            value: [articleNumber, 1]
          });
        }}
      >
        {quantitySelected > 0 ? (
          <Icon name={IconName.PlusSign} size={small ? 16 : 20} />
        ) : (
          <Icon name={IconName.BasketAdd} size={small ? 16 : 20} />
        )}
      </button>
    </div>
  );
}
