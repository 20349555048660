import { PrimaryButton } from '@pmi.web/react-common';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useTranslation } from 'libs/translations';

export function ContinueButton() {
  const { articles, dispatchStepEvent } = useNewBasketContext();
  const { t } = useTranslation();

  return (
    <PrimaryButton
      disabled={articles.size === 0}
      onClick={() => {
        if (articles.size > 0) {
          dispatchStepEvent({
            type: 'confirm-articles'
          });
        }
      }}
    >
      {t('Continue')}
    </PrimaryButton>
  );
}
