import { PayquickerLink } from 'types';

export function getPayQuickerExternalLink(region: string | undefined) {
  switch (region?.toLocaleLowerCase()) {
    case 'america':
      return `${process.env.REACT_APP_PAYQUICKER_US}`;
    case 'asia':
      return `${process.env.REACT_APP_PAYQUICKER_ASIA}`;
    case 'italy':
      return `${process.env.REACT_APP_PAYQUICKER_ITALY}`;
    case 'greatbritain':
      return `${process.env.REACT_APP_PAYQUICKER_GREATBRITAIN}`;
    case 'europe':
    default:
      return `${process.env.REACT_APP_PAYQUICKER_EUROPE}`;
  }
}

export function buildPayquickerResouceLink(
  link: PayquickerLink,
  language: string | undefined,
  region: string | undefined,
  country: string
) {
  const baseLink = process.env.REACT_APP_PMPAYPLUS_CDN;

  const [languageOrFallback, regionorFallback] = getSupportedLanguage(
    language,
    region
  );

  if (
    region?.toLowerCase() === 'asia' &&
    [PayquickerLink.Benefits, PayquickerLink.Verification].includes(link)
  ) {
    return undefined;
  }

  if (
    ['america', 'greatbritain'].includes(region?.toLowerCase() ?? '') &&
    link === PayquickerLink.Verification
  ) {
    return undefined;
  }

  if (
    link === PayquickerLink.Fees &&
    ['ad', 'cl', 'il', 'kh', 'om', 'za', 'ma', 'ci'].includes(country)
  ) {
    /**
     * > Do not show the fees for users coming from these countries
     * Andorra
     * Chile
     * Israel
     * Cambodgia
     * Oman
     * South Africa
     * Morroco
     * Ivory coast
     */
    return undefined;
  }

  let filename = '';
  switch (link) {
    case PayquickerLink.Benefits:
      filename = 'PMI_PQ_Account_Holder_Benefit_Sheet.pdf';
      break;
    case PayquickerLink.FAQs:
      return 'https://payquicker.com/faqs/'; // hardcoded to PayQuicker's FAQ
    case PayquickerLink.Fees:
      filename = 'PMI_PQ_Fees.pdf';
      break;
    case PayquickerLink.Verification:
      filename = 'PMI_PQ_Account_Identity_Verification.pdf';
      break;
    case PayquickerLink.Introduction:
      filename = 'PMI_PQ_Payout_Training_Guide.pdf';
      break;
    case PayquickerLink.Support:
      return 'mailto:support@payquicker.com';
  }

  return `${baseLink}/payquicker/${regionorFallback}/${languageOrFallback}/${filename}`;
}

const supportedLanguages = {
  Europe: ['en', 'es', 'it', 'fr', 'de', 'zh', 'pl'], // first item is the fallback for the entire region
  Asia: ['en', 'es', 'it', 'fr', 'de', 'zh'],
  America: ['en', 'es', 'it', 'fr', 'de', 'zh'],
  Italy: ['it', 'en', 'es', 'fr', 'de', 'zh'],
  GreatBritain: ['en', 'es', 'it', 'fr', 'de', 'zh']
};

function getSupportedLanguage(
  language: string | undefined,
  region: string | undefined
) {
  const regionOrFallback = getSupportedRegion(region);
  const regionLanguages = supportedLanguages[regionOrFallback];

  let languageOrFallback = regionLanguages.find(
    x => x == language?.toLowerCase()
  );
  if (!languageOrFallback) {
    languageOrFallback = regionLanguages[0]; // use the fallback language for the region
  }

  return [languageOrFallback, regionOrFallback];
}

function getSupportedRegion(region: string | undefined) {
  switch (region) {
    case 'Europe':
    case 'America':
    case 'Italy':
    case 'GreatBritain':
    case 'Asia':
      return region;
    default:
      return 'Europe';
  }
}
