import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createBasket } from 'services';

import { BASKETS_QUERY_KEY } from './BasketQueryConfig';

export function useCreateBasket() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: createBasket,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BASKETS_QUERY_KEY] });
    }
  });

  return {
    createBasket: mutation.mutate,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    error: mutation.error ?? undefined,
    data: mutation.data
  };
}
