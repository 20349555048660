import { parsePaymentInstrumentMaskedInfo } from 'services/PaymentsServiceHelper';
import { PaymentModule } from 'types';
import { IOrderPayment } from 'types/orders';

import { IOrderPaymentApi } from '../types';

export const mapOrderPayment = (payment: IOrderPaymentApi): IOrderPayment => {
  const {
    amount,
    currencyCode,
    customerId,
    instrumentId,
    intentId,
    methodType,
    orderId,
    maskedInstrumentRef
  } = payment;

  return {
    amount,
    currencyCode,
    customerId,
    instrumentId,
    intentId,
    methodType: methodType as PaymentModule,
    orderId,
    instrumentReference: parsePaymentInstrumentMaskedInfo(
      methodType as PaymentModule,
      maskedInstrumentRef
    )
  };
};
