import { Timestamp } from '@pmi.libs/utils-common';
import _ from 'lodash';
import { IPromocode, PromocodeStatus } from 'types/promocodes';

import { IPromocodeApi } from './types/IPromocodeApi';

export function parsePromocode(
  apiPromocode: IPromocodeApi[]
): IPromocode | null {
  if (_.isEmpty(apiPromocode)) {
    return null;
  }

  const [promocode] = apiPromocode;

  return {
    ...promocode,
    status: promocode.status.toLowerCase() as PromocodeStatus,
    createdOn: Timestamp.createOrThrow(promocode.createdOn)
  };
}
