import { UnorderedList } from 'components/lists';
import { usePII } from 'features/pii';
import { FeatureIcon, FeatureIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { IUserCarBonusData } from 'types';
import { formatCurrencyLocalized } from 'utils';

export function WidgetCarBonusDetails(props: IUserCarBonusData) {
  const { activeLanguage } = useTranslation();
  const { pii } = usePII();

  if (
    _.isUndefined(props.carBonusCurrency) ||
    _.isUndefined(props.carBonusHistory) ||
    _.isUndefined(props.carBonusTotal)
  ) {
    throw new Error('Car bonus is missing and cannot be rendered');
  }

  return (
    <section>
      <div className="text-center">
        <h1 className="font-app font-bold">
          {pii(
            formatCurrencyLocalized(
              activeLanguage,
              props.carBonusTotal,
              props.carBonusCurrency
            ),
            'currency'
          )}
        </h1>
      </div>
      <div className="mt-lg">
        <UnorderedList
          items={props.carBonusHistory.map(item => {
            return {
              content: item.date.toLocaleString(activeLanguage),
              leftNode: (
                <span
                  className={`w-[24px] h-[24px] flex-shrink-0 rounded-full flex items-center justify-center ${
                    item.volume > 0 ? 'bg-success' : 'bg-silver'
                  }`}
                >
                  <FeatureIcon
                    name={FeatureIconName.CarBonus}
                    size={16}
                    className="text-white"
                  />
                </span>
              ),
              rightNode: formatCurrencyLocalized(
                activeLanguage,
                item.volume,
                props.carBonusCurrency as string
              )
            };
          })}
        />
      </div>
    </section>
  );
}
