import { HelperModal } from 'components/dialogs';
import { useBoolean } from 'hooks';
import { FeatureIcon, FeatureIconName } from 'libs/icons';

interface IFirstlineDataHeaderProps {
  readonly text: string;
  readonly icon: FeatureIconName;
  readonly onlyIcon?: boolean;
}

export function FirstlineDataHeader({
  text,
  icon,
  onlyIcon = false
}: IFirstlineDataHeaderProps) {
  const [
    isOpenHelperModal,
    { setFalse: closeHelperModal, setTrue: openHelperModal }
  ] = useBoolean(false);

  const cursorClass = onlyIcon ? 'cursor-help' : 'cursor-auto';
  const iconSize = onlyIcon ? 14 : 12;
  const iconMarginClass = onlyIcon ? '' : 'mr-xxs';

  return (
    <p
      className={`${cursorClass} pmi-caption uppercase overflow-hidden whitespace-nowrap text-ellipsis`}
      title={onlyIcon ? text : undefined}
      onClick={() => onlyIcon && openHelperModal()}
    >
      <FeatureIcon
        name={icon}
        size={iconSize}
        className={`inline-block ${iconMarginClass}`}
      />

      {!onlyIcon && <span>{text}</span>}

      {onlyIcon && isOpenHelperModal && (
        <HelperModal onDismiss={closeHelperModal}>
          <div className="flex flex-row items-center justify-center flex-wrap gap-xs">
            <FeatureIcon
              name={icon}
              size={iconSize}
              className={`inline-block ${iconMarginClass}`}
            />
            <p>{text}</p>
          </div>
        </HelperModal>
      )}
    </p>
  );
}
