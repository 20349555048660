import { ErrorHandler } from 'components/errors/ErrorHandler';
import { LoadingSpinner } from 'components/spinners';
import { Order, useOrder } from 'features/account/orders';
import { useParams } from 'react-router-dom';

export function AccountOrderRoute() {
  const { orderId } = useParams();
  const { data: order, isLoading, isError, error } = useOrder(orderId ?? '');

  if (isLoading) {
    return (
      <div className="flex justify-center p-md">
        <LoadingSpinner smallSize />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center">
        <ErrorHandler err={error} />
      </div>
    );
  }

  if (!order) {
    return null;
  }

  return <Order {...order} />;
}
