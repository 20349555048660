import type { FC } from 'react';

export const GroupsFillIcon: FC<{ readonly size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
    className="flex-shrink-0 flex-grow-0"
  >
    <g fill="currentColor">
      <path
        d="M8,6L8,6C6.3,6,5,4.7,5,3v0c0-1.7,1.3-3,3-3h0c1.7,0,3,1.3,3,3v0C11,4.7,9.7,6,8,6z"
        fill="currentColor"
      />
      <path d="M2,5L2,5C0.9,5,0,4.1,0,3v0c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2v0C4,4.1,3.1,5,2,5z" />
      <path d="M14,5L14,5c1.1,0,2-0.9,2-2v0c0-1.1-0.9-2-2-2h0c-1.1,0-2,0.9-2,2v0C12,4.1,12.9,5,14,5z" />
      <polygon
        fill="currentColor"
        points="10,16 6,16 6,12 4,10 4,7 12,7 12,10 10,12 "
      />
      <polygon points="3,10.4 3,6 0,6 0,9 1,10 1,14 4,14 4,11.4 " />
      <polygon points="13,10.4 13,6 16,6 16,9 15,10 15,14 12,14 12,11.4 " />
    </g>
  </svg>
);
