import { ScrollLock } from 'components/ScrollLock';
import { useKeydownWindowEvent } from 'hooks';
import { useTranslation } from 'libs/translations';
import { ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import { FaXmark } from 'react-icons/fa6';

import './BottomSheetContainer.scss';
import { Portal } from './Portal';

interface IBottomSheetContainerProps {
  readonly children: ReactNode;
  readonly header?: ReactNode;
  readonly maxWidth?: number;
  readonly open: boolean;
  readonly onDismiss: () => void;
}

export function BottomSheetContainer({
  children,
  ...props
}: IBottomSheetContainerProps) {
  if (!props.open) {
    return null;
  }

  return (
    <BottomSheetContainerContent {...props}>
      {children}
    </BottomSheetContainerContent>
  );
}

function BottomSheetContainerContent(props: IBottomSheetContainerProps) {
  useKeydownWindowEvent(keyboardEvent => {
    if (keyboardEvent.key.toLowerCase() === 'escape') {
      props.onDismiss();
    }
  });

  const { t } = useTranslation();

  return (
    <Portal>
      <ScrollLock>
        <div className="modal">
          <div
            className="modal-overlay"
            style={
              props.maxWidth ? { maxWidth: `${props.maxWidth}px` } : undefined
            }
          >
            <div className="modal-content-wrapper">
              <div className="modal-header-container min-h-[44px] md:min-h-[52px]">
                {props.header}
                <button
                  className="modal-close-btn"
                  onClick={props.onDismiss}
                  title={t('Close')}
                >
                  <FaXmark size={20} />
                </button>
              </div>
              <div className="modal-close-btn-container"></div>
              <div className="modal-content-container">{props.children}</div>
            </div>
          </div>
        </div>
      </ScrollLock>
    </Portal>
  );
}
