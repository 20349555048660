import { Dialog, Transition } from '@headlessui/react';
import { useGlobalConfigsContext } from 'contexts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useState, Fragment, ReactNode, useEffect } from 'react';

interface IModalProps {
  readonly triggerNode?: ReactNode;
  readonly children: ReactNode;
  readonly onOpen?: () => void;
  readonly onClose?: () => void;
  readonly closeButtonLabel?: string;
  readonly open?: boolean;
  readonly hideCloseBtn?: boolean;
  readonly noPadding?: boolean;
  readonly noMaxWidth?: boolean;
  readonly noBg?: boolean;
}

export function Modal(props: IModalProps) {
  const { t } = useTranslation();
  const {
    app: {
      office: { enableAnimations }
    }
  } = useGlobalConfigsContext();
  const [isOpen, setIsOpen] = useState(
    !_.isUndefined(props.triggerNode) ? false : props.open ?? false
  );

  const closeModal = () => {
    setIsOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const openModal = () => {
    setIsOpen(true);
    if (props.onOpen) {
      props.onOpen();
    }
  };

  useEffect(() => {
    if (props.open === true && !isOpen) {
      openModal();
    }

    if (props.open === false && isOpen) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <>
      {props.triggerNode && (
        <button onClick={openModal} className="block w-fit">
          {props.triggerNode}
        </button>
      )}

      {enableAnimations ? (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 pmi-glass-black" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className={`${props.noPadding ? '' : 'px-md py-sm'} ${
                      props.noMaxWidth ? '' : 'w-full max-w-lg'
                    } ${
                      props.noBg ? '' : 'bg-white shadow-md'
                    } transform text-left align-middle transition-all rounded-md`}
                  >
                    {props.children}
                    {!props.hideCloseBtn && (
                      <div
                        className={`w-full text-center ${
                          props.noPadding ? 'mb-sm' : ''
                        }`}
                      >
                        <button
                          onClick={closeModal}
                          className="rounded-full px-md py-sm bg-primary text-white"
                        >
                          {props.closeButtonLabel ?? t('Close')}
                        </button>
                      </div>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      ) : (
        <Dialog
          as="div"
          className="relative z-40"
          onClose={closeModal}
          open={isOpen}
        >
          <div className="fixed inset-0 pmi-glass-black" />

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Dialog.Panel
                className={`${props.noPadding ? '' : 'px-md py-sm'} ${
                  props.noMaxWidth ? '' : 'w-full max-w-lg'
                } ${
                  props.noBg ? '' : 'bg-white shadow-md'
                } transform text-left align-middle transition-all rounded-md`}
              >
                {props.children}
                {!props.hideCloseBtn && (
                  <div
                    className={`w-full text-center ${
                      props.noPadding ? 'mb-sm' : ''
                    }`}
                  >
                    <button
                      onClick={closeModal}
                      className="rounded-full px-md py-sm bg-primary text-white"
                    >
                      {props.closeButtonLabel ?? t('Close')}
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
