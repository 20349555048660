import { Month } from '@pmi.libs/utils-common';
import { useQuery } from '@tanstack/react-query';
import { fetchBusinessData } from 'services';

interface IBusinessDataHookOptions {
  readonly userId: string;
  readonly historicalMonths?: number;
  // If undefined, use the current month
  readonly month?: Month;
}

const MIN_HISTORICAL_MONTHS = 1;
const MAX_HISTORICAL_MONTHS = 12;
const BUSINESS_DATA_QUERY_KEY = 'pmi.web.office__businessdata';
const TTL = 1800000; // 30 minutes in milliseconds

export function useBusinessData(options: IBusinessDataHookOptions) {
  const period = options.month ?? Month.today();
  const historicalMonths = Math.min(
    options.historicalMonths ?? MIN_HISTORICAL_MONTHS,
    MAX_HISTORICAL_MONTHS
  );

  const query = useQuery({
    queryKey: [
      BUSINESS_DATA_QUERY_KEY,
      options.userId,
      historicalMonths,
      period.getTime()
    ],
    queryFn: () => fetchBusinessData(options.userId, historicalMonths, period),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
