import { BottomSheetContainer } from 'components/modals';
import { useBoolean } from 'hooks';
import { ReactNode, useEffect } from 'react';

interface IContentContainerProps {
  readonly children: ReactNode;
  readonly title?: string;
  readonly icon?: ReactNode;
  readonly open: boolean;
  readonly maxWidth?: number;
  readonly onDismiss: () => void;
}

/**
 * This component is a wrapper that decides when the content should be rendered
 * inside a Modal or a BoomtSheetContainer depending on the screen
 * size.
 */
export function ContentContainer(props: IContentContainerProps) {
  const [isOpen, { setFalse: close, setTrue: open }] = useBoolean(props.open);

  useEffect(() => {
    if (props.open) {
      open();
    } else {
      close();
    }
  }, [close, open, props.open]);

  return (
    <BottomSheetContainer
      maxWidth={props.maxWidth}
      open={isOpen}
      onDismiss={() => {
        close();
        props.onDismiss();
      }}
      header={
        <h2 className="flex flex-row gap-xs flex-nowrap line-clamp-1 text-ellipsis items-center justify-center pt-md md:pt-lg font-bold">
          {props.icon}
          {props.title}
        </h2>
      }
    >
      {props.children}
    </BottomSheetContainer>
  );
}
