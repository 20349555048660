import { trackEvent } from 'libs/telemetry';
import { triggerErrorToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import { ReactNode, useState } from 'react';
import { getPMOLink } from 'services';
import { openWindow } from 'utils';

export function PMOLink(props: { readonly children: ReactNode }) {
  const { t } = useTranslation();

  const [disabled, setDisabled] = useState<boolean>(false);

  return (
    <button
      disabled={disabled}
      type="button"
      className={'w-full cursor-pointer'}
      title={t('Structure analyzer')}
      onClick={async event => {
        event.preventDefault();
        setDisabled(true);

        trackEvent({
          name: 'ExternalLinkClick_PMO'
        });

        try {
          openWindow('pmi_web_office___pmo', getPMOLink());
        } catch (err) {
          console.error('Failed to redirect to PMO', err);
          triggerErrorToast(
            t(
              'Something went wrong. The structure analyser could not be opened.'
            )
          );
        } finally {
          setDisabled(false);
        }
      }}
    >
      {props.children}
    </button>
  );
}
