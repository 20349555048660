import { useGlobalConfigsContext } from 'contexts';
import { useNavigate } from 'react-router-dom';
import { CommitmentStatus, ICommitment } from 'types';

import { ActiveCommitmentCard } from './ActiveCommitmentCard';
import { ExpiredCommitmentCard } from './ExpiredCommitmentCard';
import { FulfilledCommitmentCard } from './FulfilledCommitmentCard';
import { PaymentRequiredCommitmentCard } from './PaymentRequiredCommitmentCard';
import { UnfulfilledCommitmentCard } from './UnfulfilledCommitmentCard';
import { useCommitmentStatus } from '../../hooks/useCommitmentStatus';

export function CommitmentListItem(commitment: ICommitment) {
  const navigate = useNavigate();
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();

  const commitmentStatus = useCommitmentStatus(commitment);

  return (
    <li className="bg-white shadow-md rounded-md">
      <button
        onClick={() => void navigate(commitment.id)}
        className={`text-left w-full p-sm grid grid-cols-[36px_1fr_20px] gap-sm items-center border border-transparent ${
          supportsHover
            ? 'hover:bg-secondary hover:text-primary hover:rounded-md hover:border-active transition-all'
            : ''
        }`}
      >
        {commitmentStatus === CommitmentStatus.Active && (
          <ActiveCommitmentCard {...commitment} />
        )}
        {commitmentStatus === CommitmentStatus.Unfulfilled && (
          <UnfulfilledCommitmentCard {...commitment} />
        )}
        {commitmentStatus === CommitmentStatus.Fulfilled && (
          <FulfilledCommitmentCard {...commitment} />
        )}
        {commitmentStatus === CommitmentStatus.PaymentRequired && (
          <PaymentRequiredCommitmentCard {...commitment} />
        )}
        {commitmentStatus === CommitmentStatus.Expired && (
          <ExpiredCommitmentCard {...commitment} />
        )}
      </button>
    </li>
  );
}
