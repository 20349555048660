import { useTranslation } from 'libs/translations';
import { TBusinessMetricProgressType } from 'types';

export function useProgressTypeI18n() {
  const { t } = useTranslation();

  const translateProgressType = (progressType: TBusinessMetricProgressType) => {
    switch (progressType) {
      case 'check-assurance':
        return t('Check Assurance');
      case 'six-level-volume':
        return t('6-Level Sales');
      case 'team-qualifications':
        return t('My Team');
      case 'own-autoship':
        return t('Autoships');
      case 'own-volume':
        return t('Personal Sales');
      case 'autoship+own-volume':
        return t('Autoships and Personal Sales');
      case 'firstline-autoship':
        return t('Autoships in the firstline');
      case 'firstline-quickstarter':
        return t('Firstline Quickstarters');
      case 'group-volume':
        return t('Group Sales');
      case 'teampartner-registration':
        return t('Team Partner registrations');
      case 'structure-volume':
        return t('Structure Sales');
      case 'own-pool-package':
        return t('Personal Pool Package');
      case 'pool-volume':
        return t('Pool Sales');
      case 'first-qualification':
        return t('First Qualification');
      default:
        return progressType;
    }
  };

  return translateProgressType;
}
