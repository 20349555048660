import { Disclosure, Transition } from '@headlessui/react';
import { Month } from '@pmi.libs/utils-common';
import { useGlobalConfigsContext } from 'contexts';
import { Icon, IconName } from 'libs/icons';
import { trackEvent } from 'libs/telemetry';
import { useMemo, useState } from 'react';
import { IUserProfile } from 'types';

import { AutoshipsColumn } from './columns/AutoshipsColumns';
import { CheckAssuranceColumn } from './columns/CheckAssuranceColumn';
import { FitlineClubMemberColumn } from './columns/FitlineClubMemberColumn';
import {
  CurrentQualificationColumn,
  HighesthQualificationColumn,
  PreviousMonthQualificationColumn
} from './columns/QualificationColumns';
import {
  PersonalSalesColumn,
  SixLevelSalesColumn,
  StructureSalesColumn
} from './columns/SalesColumns';
import { TeampartnerColumn } from './columns/TeamparterColumn';
import { FirstlineDataProperty } from './FirstlineDataProperty';
import { IFirstlineDataViewerProps } from './FirstlineDataViewer';
import { AutoshipsHeader } from './headers/AutoshipHeaders';
import { CheckAssuranceHeader } from './headers/CheckAssuranceHeader';
import { FitlineClubMemberHeader } from './headers/FitlineClubMemberHeader';
import {
  CurrentQualificationHeader,
  HighestQualificationHeader,
  PreviousQualificationHeader
} from './headers/QualificationHeaders';
import {
  PersonalSalesHeader,
  SixLevelSalesHeader,
  StructureSalesHeader
} from './headers/SalesHeaders';
import { useFirstlineHeaderI18n } from './headers/useFirstlineHeaderI18n';

export function FirstlineDataViewerMobile(props: IFirstlineDataViewerProps) {
  const [mainProperty, setMainProperty] = useState<FirstlineDataProperty>(
    FirstlineDataProperty.StructureSales
  );

  return (
    <>
      <SelectablePropertyHeader
        defaultValue={mainProperty}
        selectable={!props.isReadonly}
        onChange={setMainProperty}
      />
      <ul>
        {props.data.map(obj => {
          return (
            <li key={`fstl__${obj.userId}`} className="even:bg-[#f6f6f7] p-xs">
              <FristlineUser
                user={obj}
                mainProperty={mainProperty}
                period={props.period}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
}

function FristlineUser(props: {
  readonly user: IUserProfile;
  readonly mainProperty: FirstlineDataProperty;
  readonly period: Month;
}) {
  const {
    app: {
      office: { enableAnimations }
    }
  } = useGlobalConfigsContext();

  const disclosureContent = (
    <div className="grid grid-cols-2 gap-2">
      {!props.user.isDiscountCustomer && props.user.isTeampartner && (
        <>
          <span className="text-silver">
            <CheckAssuranceHeader />
          </span>
          <span className="text-right">
            <CheckAssuranceColumn userProfile={props.user} />
          </span>
          <span className="text-silver">
            <CurrentQualificationHeader />
          </span>
          <span className="text-right">
            <CurrentQualificationColumn userProfile={props.user} />
          </span>
          <span className="text-silver">
            <PreviousQualificationHeader />
          </span>
          <span className="text-right">
            <PreviousMonthQualificationColumn userProfile={props.user} />
          </span>
          <span className="text-silver">
            <HighestQualificationHeader />
          </span>
          <span className="text-right">
            <HighesthQualificationColumn userProfile={props.user} />
          </span>
          <span className="text-silver">
            <FitlineClubMemberHeader />
          </span>
          <span className="text-right">
            <FitlineClubMemberColumn userProfile={props.user} />
          </span>
        </>
      )}
      <span className="text-silver">
        <AutoshipsHeader />
      </span>
      <span className="text-right">
        <AutoshipsColumn userProfile={props.user} />
      </span>
      <span className="text-silver">
        <PersonalSalesHeader />
      </span>
      <span className="text-right">
        <PersonalSalesColumn userProfile={props.user} />
      </span>
      <span className="text-silver">
        <SixLevelSalesHeader />
      </span>
      <span className="text-right">
        <SixLevelSalesColumn userProfile={props.user} />
      </span>
      <span className="text-silver">
        <StructureSalesHeader />
      </span>
      <span className="text-right">
        <StructureSalesColumn userProfile={props.user} />
      </span>
    </div>
  );

  const disclosureContent2 = (
    <ul>
      {!props.user.isDiscountCustomer && props.user.isTeampartner && (
        <>
          <li className="flex flex-row flex-wrap justify-between min-h-[32px]">
            <span className="text-silver">
              <CheckAssuranceHeader />
            </span>
            <CheckAssuranceColumn userProfile={props.user} />
          </li>
          <li className="flex flex-row flex-wrap justify-between min-h-[32px]">
            <span className="text-silver">
              <CurrentQualificationHeader />
            </span>
            <CurrentQualificationColumn userProfile={props.user} />
          </li>
          <li className="flex flex-row flex-wrap justify-between min-h-[32px]">
            <span className="text-silver">
              <PreviousQualificationHeader />
            </span>
            <PreviousMonthQualificationColumn userProfile={props.user} />
          </li>
          <li className="flex flex-row flex-wrap justify-between min-h-[32px]">
            <span className="text-silver">
              <HighestQualificationHeader />
            </span>
            <HighesthQualificationColumn userProfile={props.user} />
          </li>
          <li className="flex flex-row flex-wrap justify-between min-h-[32px]">
            <span className="text-silver">
              <FitlineClubMemberHeader />
            </span>
            <FitlineClubMemberColumn userProfile={props.user} />
          </li>
        </>
      )}

      <li className="flex flex-row flex-wrap justify-between min-h-[32px]">
        <span className="text-silver">
          <AutoshipsHeader />
        </span>
        <AutoshipsColumn userProfile={props.user} />
      </li>
      <li className="flex flex-row flex-wrap justify-between min-h-[32px]">
        <span className="text-silver">
          <PersonalSalesHeader />
        </span>
        <PersonalSalesColumn userProfile={props.user} />
      </li>
      <li className="flex flex-row flex-wrap justify-between min-h-[32px]">
        <span className="text-silver">
          <SixLevelSalesHeader />
        </span>
        <SixLevelSalesColumn userProfile={props.user} />
      </li>
      <li className="flex flex-row flex-wrap justify-between min-h-[32px]">
        <span className="text-silver">
          <StructureSalesHeader />
        </span>
        <StructureSalesColumn userProfile={props.user} />
      </li>
    </ul>
  );

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex flex-row items-center justify-between w-full">
            <TeampartnerColumn userProfile={props.user} />
            <div className="flex flex-row items-center">
              <SelectablePropertyValue
                property={props.mainProperty}
                user={props.user}
              />
              <span className="p-xs">
                <Icon
                  name={open ? IconName.ChevronUp : IconName.ChevronDown}
                  size={16}
                  className="text-primary"
                />
              </span>
            </div>
          </Disclosure.Button>
          {enableAnimations ? (
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel>
                <div className="my-sm">{disclosureContent2}</div>
              </Disclosure.Panel>
            </Transition>
          ) : (
            <Disclosure.Panel>
              <div className="my-sm">{disclosureContent}</div>
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  );
}

function SelectablePropertyHeader(props: {
  readonly defaultValue: FirstlineDataProperty;
  readonly selectable: boolean;
  readonly onChange: (value: FirstlineDataProperty) => void;
}) {
  const t = useFirstlineHeaderI18n();

  const header = useMemo(() => {
    switch (props.defaultValue) {
      case FirstlineDataProperty.CheckAssurance:
        return <CheckAssuranceHeader />;
      case FirstlineDataProperty.CurrentQualification:
        return <CurrentQualificationHeader />;
      case FirstlineDataProperty.PreviousQualification:
        return <PreviousQualificationHeader />;
      case FirstlineDataProperty.HighestQualification:
        return <HighestQualificationHeader />;
      case FirstlineDataProperty.PersonalSales:
        return <PersonalSalesHeader />;
      case FirstlineDataProperty.SixLevelSales:
        return <SixLevelSalesHeader />;
      case FirstlineDataProperty.StructureSales:
        return <StructureSalesHeader />;
      case FirstlineDataProperty.Autoships:
        return <AutoshipsHeader />;
      case FirstlineDataProperty.FitlineClubMember:
        return <FitlineClubMemberHeader />;
    }
  }, [props.defaultValue]);

  if (props.selectable) {
    return (
      <div className="w-full flex justify-end my-sm">
        <div className="relative w-fit">
          <div className="border border-primary text-primary pr-md px-xs py-xxs after:content-[''] after:border-[5px] after:border-transparent after:border-t-primary after:absolute after:top-1/2 after:right-2 after:-translate-y-0.5">
            {header}
          </div>
          <select
            className="absolute inset-0 appearance-none cursor-pointer opacity-0 w-full"
            title="Select property to display"
            onChange={event => {
              trackEvent({
                name: 'FirstlineMobileHeaderChange',
                properties: {
                  oldColumnHeader: props.defaultValue,
                  newColumnHeader: event.target.value
                }
              });
              props.onChange(event.target.value as FirstlineDataProperty);
            }}
            defaultValue={props.defaultValue}
          >
            {Object.values(FirstlineDataProperty)
              .sort()
              .map(opt => (
                <option value={opt} key={`fltp__${opt}`}>
                  {t(opt)}
                </option>
              ))}
          </select>
        </div>
      </div>
    );
  }

  return <div className="flex justify-end mr-xs text-silver">{header}</div>;
}

function SelectablePropertyValue(props: {
  readonly property: FirstlineDataProperty;
  readonly user: IUserProfile;
}) {
  switch (props.property) {
    case FirstlineDataProperty.CheckAssurance:
      return <CheckAssuranceColumn userProfile={props.user} />;
    case FirstlineDataProperty.CurrentQualification:
      return <CurrentQualificationColumn userProfile={props.user} />;
    case FirstlineDataProperty.PreviousQualification:
      return <PreviousMonthQualificationColumn userProfile={props.user} />;
    case FirstlineDataProperty.HighestQualification:
      return <HighesthQualificationColumn userProfile={props.user} />;
    case FirstlineDataProperty.PersonalSales:
      return <PersonalSalesColumn userProfile={props.user} />;
    case FirstlineDataProperty.SixLevelSales:
      return <SixLevelSalesColumn userProfile={props.user} />;
    case FirstlineDataProperty.StructureSales:
      return <StructureSalesColumn userProfile={props.user} />;
    case FirstlineDataProperty.Autoships:
      return <AutoshipsColumn userProfile={props.user} />;
    case FirstlineDataProperty.FitlineClubMember:
      return <FitlineClubMemberColumn userProfile={props.user} />;
  }
}
