import _ from 'lodash';

import { IValidationError } from './ValidationError';

export class ApiError extends Error {
  private _status: number;
  private _errors?: IValidationError;

  constructor(
    message: string,
    response: Response,
    traceId?: string,
    errors?: IValidationError
  ) {
    const msg = `${response.status} ${response.statusText}.${
      message !== '' ? ` ${message}.` : ''
    }`;

    if (traceId !== undefined) {
      super(`${msg}\n Trace ID:${traceId}`);
    } else {
      super(msg);
    }
    this._errors = errors;

    this.name = 'ApiError';
    this.cause = JSON.stringify({
      url: response.url,
      status: response.status,
      statusText: response.statusText,
      ok: response.ok,
      type: response.type
    });
    this._status = response.status;
  }

  get status() {
    return this._status;
  }

  get errors() {
    return this._errors;
  }
}

export function isApiError(obj: unknown): obj is ApiError {
  return (
    _.isObject(obj) && _.has(obj, 'name') && _.get(obj, 'name') === 'ApiError'
  );
}
