import { HelperModal } from 'components/dialogs';
import { useBoolean } from 'hooks';
import { Icon, IconName } from 'libs/icons';
import _ from 'lodash';
import { ReactNode, useMemo, isValidElement, ReactElement } from 'react';

interface IBadgeProps {
  readonly title?: string;
  readonly icon?: IconName | ReactElement;
  readonly iconFill?: boolean;
  readonly textColorClass?: string;
  readonly backgroundColorClass?: string;
  readonly tooltipDetailsContent?: ReactNode;
  readonly hideDetails?: boolean;
}

export function Badge(props: IBadgeProps) {
  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

  const hasTooltipDetails = useMemo(() => {
    return !_.isUndefined(props.tooltipDetailsContent);
  }, [props.tooltipDetailsContent]);

  const showDetails = () => {
    if (!hasTooltipDetails) {
      return;
    }

    open();
  };

  return (
    <>
      <div
        style={{
          color: props.textColorClass ?? '#3d3d41',
          backgroundColor: props.backgroundColorClass ?? '#eeeeef'
        }}
        className={`${
          !_.isUndefined(hasTooltipDetails) && !props.hideDetails
            ? 'cursor-help'
            : ''
        } inline-flex items-center text-[10px] leading-[10px] font-bold px-[6px] py-[3px] rounded-full uppercase shadow space-x-[4px] h-fit w-fit whitespace-nowrap select-none`}
        onClick={
          !_.isUndefined(hasTooltipDetails) && !props.hideDetails
            ? event => {
                event.preventDefault();
                event.stopPropagation();
                showDetails();
              }
            : undefined
        }
      >
        {!_.isUndefined(props.icon) &&
          (isValidElement(props.icon) ? (
            <span className="inline-block">{props.icon}</span>
          ) : (
            <Icon
              fill={props.iconFill}
              name={props.icon as IconName}
              size={10}
              className="inline-block"
            />
          ))}
        {!_.isUndefined(props.title) && <span>{props.title}</span>}
      </div>
      {isOpen && (
        <HelperModal onDismiss={close}>
          {props.tooltipDetailsContent}
        </HelperModal>
      )}
    </>
  );
}
