import { useQuery } from '@tanstack/react-query';
import { IUserSearchParamsV2, searchUsers } from 'services';

const SEARCH_RESULTS_QUERY_KEY = 'pmi.web.office__search_results';
const TTL = 900000; // 15 minutes in milliseconds

export function useUsersSearch(
  pageNumber: number,
  pageSize: number,
  searchParams: IUserSearchParamsV2
) {
  const query = useQuery({
    queryKey: [SEARCH_RESULTS_QUERY_KEY, searchParams, pageNumber],
    queryFn: () => searchUsers(searchParams, pageNumber, pageSize),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
