import { Icon, IconName } from 'libs/icons';

import { Toast, ToastContent } from './Toast';

interface ISuccessToastProps {
  readonly description: string;
}

export function SuccessToast(props: ISuccessToastProps) {
  return (
    <Toast>
      <Icon name={IconName.CheckMark} className="text-success" size={16} />
      <ToastContent>
        <p className={'whitespace-pre-wrap text-success'}>
          {props.description}
        </p>
      </ToastContent>
    </Toast>
  );
}
