import { Switch } from '@headlessui/react';
import { useGlobalConfigsContext } from 'contexts';

interface ISwitchButtonProps {
  readonly title?: string;
  readonly isOn: boolean;
  readonly onClick: (nextState: boolean) => void;
  readonly disabled?: boolean;
}

export function SwitchButton(props: ISwitchButtonProps) {
  const {
    app: {
      office: { enableAnimations }
    }
  } = useGlobalConfigsContext();

  const backgroundColor =
    props.isOn && props.disabled
      ? 'var(--activeDisableColor)'
      : props.isOn
      ? 'var(--activeColor)'
      : 'var(--disableColor)';

  return (
    <Switch.Group as="div" className="flex flex-row items-center">
      <Switch.Label className="cursor-pointer pr-xs select-none">
        {props.title}
      </Switch.Label>
      <Switch
        disabled={props.disabled}
        checked={props.isOn}
        onChange={props.onClick}
        style={{
          backgroundColor
        }}
        className="h-[24px] w-[48px] cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-non disabled:cursor-not-allowed"
      >
        <span
          aria-hidden="true"
          className={`${
            props.isOn ? 'translate-x-[26px]' : 'translate-x-[2px]'
          } ${
            enableAnimations
              ? 'transform transition duration-200 ease-in-out'
              : ''
          } pointer-events-none block h-[20px] w-[20px] rounded-full bg-white shadow-lg ring-0`}
        />
      </Switch>
    </Switch.Group>
  );
}
