import type { FC } from 'react';

export const ArrowUpIcon: FC<{ readonly size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
    className="flex-shrink-0 flex-grow-0"
  >
    <g fill="currentColor">
      <path
        d="M8.393.191a.518.518,0,0,0-.786,0l-5.5,7A.5.5,0,0,0,2.5,8H5v3a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V8h2.5a.5.5,0,0,0,.393-.809Z"
        fill="currentColor"
      />
      <path d="M10,14H6a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Z" />
    </g>
  </svg>
);
