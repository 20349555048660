import { useFilterPeriod } from 'features/analytics/hooks/useFilterPeriod';
import { useTranslation } from 'libs/translations';
import { useFormContext } from 'react-hook-form';
// eslint-disable-next-line no-restricted-imports
import { Trans } from 'react-i18next';

export function ActiveSponsorshipFilter() {
  const { register } = useFormContext();
  const { t, activeLanguage } = useTranslation();
  const currentPeriod = useFilterPeriod();

  return (
    <fieldset>
      <legend>
        <Trans
          i18nKey={'analytics.active-sponsor-filter-title'}
          values={{
            period: currentPeriod.toLocaleString(activeLanguage)
          }}
          components={{ bold: <strong /> }}
          defaults="Active sponsor <bold>{{period}}</bold>?"
        />
      </legend>
      <label className="label-radio">
        <input type="radio" {...register('withSponsorActivity')} value={''} />
        <span>{t('All')}</span>
      </label>
      <label className="label-radio">
        <input
          type="radio"
          {...register('withSponsorActivity')}
          value={'true'}
        />
        <span>{t('Active Sponsors')}</span>
      </label>
      <label className="label-radio">
        <input
          type="radio"
          {...register('withSponsorActivity')}
          value={'false'}
        />
        <span>{t('No')}</span>
      </label>
    </fieldset>
  );
}
