/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  randomName,
  randomUserId,
  randomCountryCode
} from 'libs/random-generator';

export function piiName<T>(name: T): string {
  return randomName(name?.toString() ?? '');
}

export function piiUserId<T>(id: T): string {
  return randomUserId();
}

export function piiCountryCode<T>(countryCode: T): string {
  return randomCountryCode(countryCode?.toString() ?? '');
}
