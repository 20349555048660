import { useFilterPeriod } from 'features/analytics/hooks/useFilterPeriod';
import { useTranslation } from 'libs/translations';
import { useFormContext } from 'react-hook-form';
// eslint-disable-next-line no-restricted-imports
import { Trans } from 'react-i18next';

export function NewRegistrationsFilter() {
  const { register } = useFormContext();
  const { t, activeLanguage } = useTranslation();
  const currentPeriod = useFilterPeriod();

  return (
    <fieldset>
      <legend>
        <Trans
          i18nKey={'analytics.new-registrations-filter-title'}
          values={{
            period: currentPeriod.toLocaleString(activeLanguage)
          }}
          components={{ bold: <strong /> }}
          defaults="Registered in <bold>{{period}}</bold>?"
        />
      </legend>
      <label className="label-radio">
        <input type="radio" {...register('newRegistrations')} value={''} />
        <span>{t('All')}</span>
      </label>
      <label className="label-radio">
        <input type="radio" {...register('newRegistrations')} value={'true'} />
        <span>{t('New')}</span>
      </label>
      <label className="label-radio">
        <input type="radio" {...register('newRegistrations')} value={'false'} />
        <span>{t('Existing')}</span>
      </label>
    </fieldset>
  );
}
