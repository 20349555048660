import { FC } from 'react';

export const BarChartIcon: FC<{ readonly size: number }> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className="flex-shrink-0 flex-grow-0"
    >
      <g fill="currentColor" stroke="currentColor" strokeWidth="1">
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="5.5"
          x2="5.5"
          y1="14.5"
          y2="5.5"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="8.5"
          x2="8.5"
          y1="14.5"
          y2="8.5"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="14.5"
          x2="14.5"
          y1="14.5"
          y2="8.5"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="11.5"
          x2="11.5"
          y1="14.5"
          y2="5.5"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="2.5"
          x2="2.5"
          y1="14.5"
          y2="2.5"
        />
      </g>
    </svg>
  );
};
