import { FeatureIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';

import { FirstlineDataHeader } from './FirstlineDataHeader';
import { useFirstlineHeaderI18n } from './useFirstlineHeaderI18n';
import { FirstlineDataProperty } from '../FirstlineDataProperty';

export function CurrentQualificationHeader(props: {
  readonly iconOnly?: boolean;
}) {
  const t = useFirstlineHeaderI18n();

  return (
    <FirstlineDataHeader
      icon={FeatureIconName.Qualification}
      text={t(FirstlineDataProperty.CurrentQualification)}
      onlyIcon={props.iconOnly}
    />
  );
}

export function PreviousQualificationHeader(props: {
  readonly iconOnly?: boolean;
}) {
  const t = useFirstlineHeaderI18n();

  return (
    <FirstlineDataHeader
      icon={FeatureIconName.PreviousQualification}
      text={t(FirstlineDataProperty.PreviousQualification)}
      onlyIcon={props.iconOnly}
    />
  );
}

export function NextQualificationHeader() {
  const { t } = useTranslation();

  return (
    <FirstlineDataHeader
      icon={FeatureIconName.NextQualification}
      text={t('Next qualification')}
    />
  );
}

export function HighestQualificationHeader(props: {
  readonly iconOnly?: boolean;
}) {
  const t = useFirstlineHeaderI18n();

  return (
    <FirstlineDataHeader
      icon={FeatureIconName.Rank}
      text={t(FirstlineDataProperty.HighestQualification)}
      onlyIcon={props.iconOnly}
    />
  );
}
