import { PrimaryLinkRedirect } from 'components/buttons';
import { HelperModal } from 'components/dialogs';
import { LoadingSpinner } from 'components/spinners';
import {
  InvoiceItem,
  InvoiceSectionDivider,
  Invoice,
  InvoiceSection
} from 'features/invoices';
import { PII, usePII } from 'features/pii';
import { useCommitmentStatus } from 'features/virtual-warehouse/hooks/useCommitmentStatus';
import { useBoolean } from 'hooks';
import { IconName } from 'libs/icons';
import { Icon } from 'libs/icons/svg-icons/Icon';
import { trackEvent, trackException } from 'libs/telemetry';
import { triggerErrorToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import { useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { AiOutlinePercentage } from 'react-icons/ai';
// eslint-disable-next-line no-restricted-imports
import {
  IoBagCheckOutline,
  IoBarcodeSharp,
  IoCalendarOutline,
  IoTimeOutline,
  IoPricetagsOutline
} from 'react-icons/io5';
import { CommitmentStatus, ICommitment } from 'types';

import './Commitment.scss';
import { CommitmentAuthorizationDetails } from './CommitmentAuthorizationDetails';
import { CommitmentBillingAddress } from './CommitmentBillingAddress';
import { CommitmentCosts } from './CommitmentCosts';
import { CommitmentDeliveryAddress } from './CommitmentDeliveryAddress';
import { CommitmentOrders } from './CommitmentOrders';
import { CommitmentProducts } from './CommitmentProducts';
import { useCommimentOrders } from '../../hooks/useCommitmentOrders';
import { useFlushCommitment } from '../../hooks/useFlushCommitment';

export function Commitment(props: ICommitment) {
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();
  const { flushCommiment, isPending, isSuccess, isError, error } =
    useFlushCommitment();
  const [
    isFlushConfirmationDialogOpen,
    {
      setFalse: closeFlushConfirmationDialog,
      setTrue: openFlushConfirmationDialog
    }
  ] = useBoolean(false);

  const commitmentStatus = useCommitmentStatus(props);
  const commitmentOrders = useCommimentOrders(props);

  const { totalCommitedQuantity, totalFulfilledQuantity } = useMemo(() => {
    const { totalCommitedQuantity, totalFulfilledQuantity } =
      props.progress.reduce(
        (acc, value) => {
          return {
            totalCommitedQuantity:
              acc.totalCommitedQuantity + value.commitedQuantity,
            totalFulfilledQuantity:
              acc.totalFulfilledQuantity + value.fulfilledQuantity
          };
        },
        { totalCommitedQuantity: 0, totalFulfilledQuantity: 0 }
      );

    return {
      totalCommitedQuantity,
      totalFulfilledQuantity
    };
  }, [props]);

  const redirectLink = useMemo(() => {
    if (!props.fulfillmentBasketId) {
      return;
    }

    const link = `${process.env.REACT_APP_MINI_SHOP_BASE_URL}/vcart/${props.fulfillmentBasketId}`;

    trackEvent({
      name: 'VirtualWarehouse_CommitmentFlush_RediredLinkReady',
      properties: {
        redirectLink: link
      }
    });

    return link;
  }, [props.fulfillmentBasketId]);

  useEffect(() => {
    if (isError) {
      trackException(error, 'Commitment.tsx', 'Failed to flush commitment');
      triggerErrorToast(
        t(
          'Failed to claim remaining products. Try again later or contact the support team.'
        )
      );
    }
  }, [isError, error, t]);

  return (
    <>
      <Invoice>
        {commitmentStatus !== CommitmentStatus.Fulfilled && (
          <InvoiceItem
            icon={<AiOutlinePercentage size={16} />}
            title={t('Progress')}
            value={
              <p className="mt-xs">
                {t(
                  'You sold {{totalFulfilledQuantity}} of {{totalCommitedQuantity}} products.',
                  {
                    totalFulfilledQuantity: pii(
                      totalFulfilledQuantity,
                      'number'
                    ),
                    totalCommitedQuantity: pii(totalCommitedQuantity, 'number')
                  }
                )}
              </p>
            }
          />
        )}

        <InvoiceItem
          title={t('Products')}
          icon={<IoPricetagsOutline size={16} />}
          value={
            <>
              <CommitmentProducts {...props} />
              <InvoiceSectionDivider />
              <CommitmentCosts {...props} />
            </>
          }
        />

        {commitmentOrders.length > 0 && (
          <InvoiceItem
            icon={<Icon name={IconName.Receipt} size={16} />}
            title={t('Related Orders')}
            value={<CommitmentOrders orderIds={commitmentOrders} />}
          />
        )}

        <InvoiceSection>
          {[CommitmentStatus.Active, CommitmentStatus.PaymentRequired].includes(
            props.status
          ) && (
            <InvoiceItem
              icon={<IoTimeOutline size={16} />}
              title={t('Expires on')}
              value={
                <PII
                  value={props.expiresOn.toLocaleDateTimeString(
                    activeLanguage,
                    'medium'
                  )}
                  type={'fixed-date'}
                />
              }
            />
          )}

          <InvoiceItem
            icon={<IoCalendarOutline size={16} />}
            title={t('Created on')}
            value={
              <PII
                value={props.createdOn.toLocaleDateTimeString(
                  activeLanguage,
                  'medium'
                )}
                type={'fixed-date'}
              />
            }
          />

          <InvoiceItem
            icon={<IoBarcodeSharp size={16} />}
            title={t('Reference')}
            value={<PII value={props.id} type="ids" />}
          />

          <CommitmentDeliveryAddress {...props} />
          <CommitmentBillingAddress {...props} />
          <CommitmentAuthorizationDetails {...props} />
        </InvoiceSection>

        {commitmentStatus === CommitmentStatus.Active && (
          <div className="flex justify-end">
            <button
              disabled={isPending}
              onClick={() => {
                trackEvent({
                  name: 'VirtualWarehouse_CommitmentFlush_Open'
                });
                openFlushConfirmationDialog();
              }}
              className="commitment-action"
            >
              <IoBagCheckOutline size={14} />
              {t('Claim remaining products')}
              {isPending && <LoadingSpinner tinySize />}
            </button>
          </div>
        )}
      </Invoice>

      {isFlushConfirmationDialogOpen && (
        <HelperModal
          onDismiss={() => {
            trackEvent({
              name: 'VirtualWarehouse_CommitmentFlush_Dismiss'
            });
            closeFlushConfirmationDialog();
          }}
          showCloseButton={false}
        >
          <h1>{t('Claim remaining products')}</h1>
          {!redirectLink && (
            <>
              <p>
                {t(
                  'Are you sure you want to claim all remaining products in your commitment? If you claim them, you must pay for the remaining products, which will be shipped to you. This action will fulfill your commitment obligations.'
                )}
              </p>
              <div className="flex flex-row flex-wrap items-center justify-center gap-md">
                <button
                  disabled={isPending}
                  className="px-sm py-xs rounded-md transition-all hover:bg-lightGray"
                  onClick={() => {
                    trackEvent({
                      name: 'VirtualWarehouse_CommitmentFlush_Dismiss'
                    });
                    closeFlushConfirmationDialog();
                  }}
                >
                  {t('No, go back')}
                </button>

                <button
                  disabled={isPending}
                  className="flex flex-row gap-sm items-center w-fit text-primary px-sm py-xs rounded-md transition-all hover:bg-primary hover:text-white"
                  onClick={() => {
                    if (isPending) {
                      return;
                    }

                    trackEvent({
                      name: 'VirtualWarehouse_CommitmentFlush_Confirm'
                    });

                    flushCommiment(props.id);
                  }}
                >
                  <IoBagCheckOutline size={14} />
                  {t('Yes, claim products')}
                </button>
              </div>
            </>
          )}

          {(isPending || (isSuccess && !redirectLink)) && (
            <div className="inset-0 absolute pmi-glass-white rounded-md flex items-center justify-center">
              <LoadingSpinner smallSize />
            </div>
          )}

          {isSuccess && redirectLink && (
            <>
              <p>
                {t(
                  'Almost there! To claim the remaining products, please proceed to the Shop and confirm all details related to delivery and payment.'
                )}
              </p>
              <PrimaryLinkRedirect
                redirectUrl={redirectLink}
                title={t('Continue to the Shop')}
                onClick={() => {
                  trackEvent({
                    name: 'VirtualWarehouse_CommitmentFlush_PaymentRedirect'
                  });
                  closeFlushConfirmationDialog();
                }}
              />
            </>
          )}
        </HelperModal>
      )}
    </>
  );
}
