import { LoadingSpinner } from 'components/spinners';
import { Disclaimer } from 'components/text';
import { RouteIcon, RouteIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';

import { InventoryItem } from './InventoryItem';
import { useCommitmentsInventory } from '../../hooks';
import {
  AddProductsButton,
  AddProductsNowLink
} from '../add-products-button/AddProductsButton';

export function InventoryList() {
  const { t, activeLanguage } = useTranslation();
  const {
    data: products,
    isLoading,
    error
  } = useCommitmentsInventory(activeLanguage);

  return (
    <div>
      {!_.isUndefined(error) && (
        <Disclaimer
          message={t(
            'Something went wrong. If the problem persists, please contact our support team.'
          )}
          type="error"
        />
      )}

      {isLoading && (
        <div className="w-full flex items-center justify-center">
          <LoadingSpinner smallSize />
        </div>
      )}

      {!_.isUndefined(products) && products.length > 0 && (
        <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-xs sm:gap-sm">
          {products.map(p => (
            <li key={p.articleNumber}>
              <InventoryItem {...p} />
            </li>
          ))}
          <li className="rounded-md bg-lightGray p-xs flex flex-col h-full items-center justify-center gap-md shadow-md">
            <p className="text-center">
              {t("Didn't find the product you were looking for?")}
            </p>
            <AddProductsNowLink />
          </li>
        </ul>
      )}

      {!_.isUndefined(products) && products.length === 0 && (
        <div className="p-md flex flex-col items-center justify-center gap-sm rounded-md bg-white shadow-md">
          <RouteIcon name={RouteIconName.VirtualWarehouse} size={62} />
          <p className="text-center">
            {t(
              "You don't have any product in your Virtual Warehouse yet. Create commitments to add products to your inventory."
            )}
          </p>
          <AddProductsButton />
        </div>
      )}
    </div>
  );
}
