import { DOM_ELEM_ID } from 'contants';
import _ from 'lodash';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface IPortalProps {
  readonly children: ReactNode;
}

export function Portal(props: IPortalProps) {
  let container = document.getElementById(DOM_ELEM_ID.PORTAL);

  if (_.isNull(container) || _.isUndefined(container)) {
    const newContainer = document.createElement('div');
    newContainer.id = DOM_ELEM_ID.PORTAL;
    document.body.appendChild(newContainer);
    container = newContainer;
  }

  return createPortal(props.children, container);
}
