import { PeriodSelector } from 'components/period-selection';
import { useScreenEvents } from 'contexts';
import { DownlineBackButton } from 'features/downline-explorer/DownlineBackButton';
import {
  DonwlineProvider,
  useDownlineContext
} from 'features/downline-explorer/DownlineContext';
import { DownlineExplorer } from 'features/downline-explorer/DownlineExplorer';
import { useOutlet } from 'react-router-dom';

export function DownlineRoute() {
  return (
    <DonwlineProvider>
      <DownlineRouteContent />
    </DonwlineProvider>
  );
}

function DownlineRouteContent() {
  const { xl } = useScreenEvents();
  const outlet = useOutlet();
  const { onPeriodChange } = useDownlineContext();

  return (
    <section className="space-y-xs">
      {!xl && outlet && <DownlineBackButton />}
      <div className="flex justify-end">
        <PeriodSelector onPeriodChange={onPeriodChange} />
      </div>
      {xl ? (
        <section className="grid grid-cols-[minmax(0,350px)_minmax(0,_1fr)] gap-md">
          <DownlineExplorer />
          {outlet}
        </section>
      ) : !outlet ? (
        <DownlineExplorer />
      ) : (
        outlet
      )}
    </section>
  );
}
