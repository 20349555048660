import { Disclaimer } from 'components/text';

interface ISomethingWentWrongProps {
  readonly err?: Error;
}

export function SomethingWentWrong(props: ISomethingWentWrongProps) {
  return (
    <div className="w-fit h-fit flex flex-col items-center justify-center space-y-xs">
      <object
        title="Something went wrong Illustration"
        className="inline-block h-error-img-sm"
        type="image/svg+xml"
        data="/imgs/errors/SomethingWentWrongError.svg"
      ></object>
      <div className="max-w-error-msg">
        <Disclaimer
          type="error"
          message={props.err?.message ?? 'Please contact our support team.'}
        />
      </div>
    </div>
  );
}
