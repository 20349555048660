import { ReactNode } from 'react';

import './AccountChildNavigationGroup.scss';

interface IAccountChildNavigationGroupProps {
  readonly title: string;
  readonly children: ReactNode;
}

export function AccountChildNavigationGroup(
  props: IAccountChildNavigationGroupProps
) {
  return (
    <>
      <h2 className="component__account-child-navigation-group">
        {props.title}
      </h2>
      <div className="component__account-child-navigation-group">
        {props.children}
      </div>
    </>
  );
}
