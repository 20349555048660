import { useChangelog } from 'libs/cms';
import { useTranslation } from 'libs/translations';
import { useMemo } from 'react';

import './WhatsNewContent.scss';
import { WhatsNewPost } from './WhatsNewPost';

export function WhatsNewContent() {
  const { activeLanguage } = useTranslation();
  const { data: changelog } = useChangelog(activeLanguage);

  const descendingChangelog = useMemo(() => {
    if (changelog) {
      return [...changelog].sort((a, b) => {
        // Sort in descending order
        return b.date.getTime() - a.date.getTime();
      });
    }

    return [];
  }, [changelog]);

  return (
    <ol className="component__whats-new-content">
      {descendingChangelog.map(item => {
        return (
          <li
            className="component__whats-new-content--item"
            key={`${item._uid}`}
          >
            <WhatsNewPost {...item} />
          </li>
        );
      })}
    </ol>
  );
}
