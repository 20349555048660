export function CalenderBackIcon(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" stroke="currentColor" strokeWidth="1">
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="8"
          x2="0.5"
          y1="11.5"
          y2="11.5"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="15.5"
          x2="0.5"
          y1="5.5"
          y2="5.5"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="11.5"
          x2="11.5"
          y1="0.5"
          y2="2.5"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="4.5"
          x2="4.5"
          y1="0.5"
          y2="2.5"
        />
        <polyline
          fill="none"
          points="3 9 0.5 11.5 3 14"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M.5,7.5V4A1.5,1.5,0,0,1,2,2.5H14A1.5,1.5,0,0,1,15.5,4V14A1.5,1.5,0,0,1,14,15.5H5.5"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
