import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { Trans } from 'react-i18next';

export function PayquickerDirectCashLegalAgreement(props: {
  readonly origin: 'directcash' | 'bonus';
}) {
  const { t } = useTranslation();

  return (
    <div className="space-y-sm">
      <h1>
        {props.origin === 'directcash'
          ? t('PM DirectCash Application')
          : t('Bonus Payouts GDPR')}
      </h1>
      <Trans t={t} i18nKey="PayquickerDirectCashLegalAgreenent">
        <h2>
          <strong>Consent to data processing</strong>
        </h2>
        <p>
          I agree and hereby expressly declare that PM-International AG can
          share my personal data (name, customer number, e-mail address, phone
          number and address information) for the purpose of creating a personal
          account Payquicker | 400 Linden Oaks, Suite 320, Rochester, NY 14625.
          Bonus payments related to the PM DirectCash program will be payed
          through PM Pay+, a payment solution provided by Payquicker.
        </p>
        <h2>
          <strong>Legal Notice:</strong>
        </h2>
        <p>
          You can request information from us at any time about your stored
          personal data (Art. 15 DSGVO), their correction (Art. 16 DSGVO),
          deletion (Art. 17 DSGVO) or restriction of processing (Art. 18 DSGVO)
          as well as assert your right to data transferability (Art. 20 DSGVO).
          In addition, you can change or revoke your declaration of consent at
          any time without giving reasons with effect for the future (Art. 21
          DSGVO). Please note that data processing that took place before the
          revocation is not affected by this. For the purposes, please contact
          one of the following contact addresses:
        </p>
        <p>Responsible for data processing at PM International AG:</p>
        <ul>
          <li>
            Managing Director: <strong>Patrick Bacher</strong>
          </li>
          <li>
            Address: <strong>An der Hofweide 17, 67346 Speyer, Germany</strong>
          </li>
          <li>
            Telephone: <strong>+49 6232 2960</strong>
          </li>
          <li>
            E-mail: <strong>patrick.bacher@pm-international.de</strong>
          </li>
        </ul>
        <p>
          You can reach our data protection officer/our data protection
          representative at PM International AG:
        </p>
        <ul>
          <li>
            Data protection officer/data protection officer:{' '}
            <strong>Dr. Raimund Abele</strong>
          </li>
          <li>
            Address: <strong>An der Hofweide 17, 67346 Speyer, Germany</strong>
          </li>
          <li>
            Telephone: <strong>+49 6232 2960</strong>
          </li>
          <li>
            E-mail: <strong>datenschutz@pm-international.de</strong>
          </li>
        </ul>
        <p>
          Furthermore, according to Art. 77 DSGVO you have the right to complain
          to a supervisory authority.
        </p>
      </Trans>
    </div>
  );
}
