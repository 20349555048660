import { PillBadge } from 'components/badges';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import {
  TbProgress,
  TbProgressAlert,
  TbProgressCheck,
  TbProgressX
} from 'react-icons/tb';
import { CommitmentStatus } from 'types';

interface ICommitmentStatusBadgeProps {
  readonly status: CommitmentStatus;
  readonly small?: boolean;
}

export function CommitmentStatusBadge(props: ICommitmentStatusBadgeProps) {
  const { t } = useTranslation();

  if (props.status === CommitmentStatus.Expired) {
    return (
      <PillBadge
        title={t('Expired')}
        className="text-white bg-error font-bold uppercase"
        icon={<TbProgressX size={20} />}
        small={props.small}
      />
    );
  }

  if (props.status === CommitmentStatus.Fulfilled) {
    return (
      <PillBadge
        title={t('Fulfilled')}
        className="text-white bg-success font-bold uppercase"
        icon={<TbProgressCheck size={20} />}
        small={props.small}
      />
    );
  }

  if (props.status === CommitmentStatus.PaymentRequired) {
    return (
      <PillBadge
        title={t('Payment Required')}
        className="text-white bg-warn font-bold uppercase"
        icon={<TbProgressAlert size={20} />}
        small={props.small}
      />
    );
  }

  if (props.status === CommitmentStatus.Unfulfilled) {
    return (
      <PillBadge
        title={t('Unfulfilled')}
        className="text-silver bg-lightGray font-bold uppercase"
        icon={<TbProgressX size={20} />}
        small={props.small}
      />
    );
  }

  return (
    <PillBadge
      title={t('Active')}
      className="text-white bg-active font-bold uppercase"
      icon={<TbProgress size={20} />}
      small={props.small}
    />
  );
}
