import { QUALIFICATIONS, QualificationLevel } from '@pmi.libs/react-common';
import { QualificationBadge } from 'components/badges';
import { useFilterPeriod } from 'features/analytics/hooks/useFilterPeriod';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
// eslint-disable-next-line no-restricted-imports
import { Trans } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { IoChevronDown } from 'react-icons/io5';
import { parseQualificationLevel } from 'utils';

import { useQualificationFilter } from './useQualificationFilter';

export function MaximumQualificationFilter() {
  const { activeLanguage } = useTranslation();
  const { register } = useFormContext();
  const { qualiLevelFilterTo, qualiLevelOptionsTo } = useQualificationFilter();
  const currentPeriod = useFilterPeriod();

  return (
    <label htmlFor="byQualificationTo">
      <Trans
        i18nKey={'analytics.quali-level-to'}
        values={{
          period: currentPeriod.toLocaleString(activeLanguage)
        }}
        components={{ bold: <strong /> }}
        defaults="Maximum qualification level in <bold>{{period}}</bold>"
      />
      <div className="select-wrapper">
        {!_.isUndefined(qualiLevelFilterTo) && (
          <QualificationBadge
            hideDetails
            qualification={
              parseQualificationLevel(qualiLevelFilterTo) as QualificationLevel
            }
          />
        )}
        <p className="flex-grow">
          {!_.isUndefined(qualiLevelFilterTo) &&
            QUALIFICATIONS[
              parseQualificationLevel(qualiLevelFilterTo) as QualificationLevel
            ].name}
        </p>
        <IoChevronDown size={16} />
        <select
          {...register('byQualificationTo', {
            valueAsNumber: true
          })}
        >
          {qualiLevelOptionsTo.map(qualificationLevel => (
            <option
              key={qualificationLevel}
              value={QUALIFICATIONS[qualificationLevel].numericValue}
            >
              {QUALIFICATIONS[qualificationLevel].name}
            </option>
          ))}
        </select>
      </div>
    </label>
  );
}
