import { Banner, BannerCarousel } from '@pmi.web/react-common';
import { useGlobalConfigsContext } from 'contexts';
import { trackEvent } from 'libs/telemetry';

export function PromoShopBanner() {
  const { promoShop } = useGlobalConfigsContext();

  if (
    !promoShop ||
    !promoShop.promotions ||
    promoShop.promotions.banners.length === 0
  ) {
    return null;
  }

  const onBannerCtaClick = (bannerId: string) => {
    trackEvent({
      name: 'PromoShopBanner_ClickCTA',
      properties: {
        bannerId
      }
    });
  };

  return (
    <BannerCarousel autoplay>
      {promoShop.promotions.banners.map(banner => {
        const bannerCta = banner.cta
          ? { ctaLabel: banner.cta.label, ctaUrl: banner.cta.url }
          : {};

        return (
          <Banner
            key={banner.id}
            title={banner.title}
            description={banner.details}
            countdownTo={
              banner.countdown
                ? // eslint-disable-next-line @miovision/disallow-date/no-new-date
                  new Date(banner.countdown.getTime())
                : undefined
            }
            onCta={() => onBannerCtaClick(banner.id)}
            {...bannerCta}
          />
        );
      })}
    </BannerCarousel>
  );
}
