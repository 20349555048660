import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { useGlobalConfigsContext } from 'contexts';
import Promocode from 'features/promocodes';
import { FAQButton, FAQSlug } from 'libs/cms';
import { Navigate } from 'react-router-dom';

export function PromocodeRoute() {
  const { promoShop } = useGlobalConfigsContext();

  if (!promoShop || !promoShop.isPromoCodeEnabled) {
    return <Navigate to="/" replace />;
  }

  return (
    <section className="max-w-[860px] m-auto space-y-lg">
      <PageTitle />
      <Promocode />
      <FAQButton slug={FAQSlug.PromoCodes} />
    </section>
  );
}
