import type { FC } from 'react';

export const GroupsOutlineIcon: FC<{ readonly size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
    className="flex-shrink-0 flex-grow-0"
  >
    <g fill="currentColor" stroke="currentColor" strokeWidth="1">
      <rect
        height="3"
        width="3"
        fill="none"
        rx="1.5"
        ry="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        x="1.5"
        y="2.5"
      />
      <rect
        height="3"
        width="3"
        fill="none"
        rx="1.5"
        ry="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(26 8) rotate(-180)"
        x="11.5"
        y="2.5"
      />
      <rect
        height="4"
        width="4"
        fill="none"
        rx="2"
        ry="2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        x="6"
        y="0.5"
      />
      <path
        d="M3.5,14.5h-2v-3H.5v-3a1,1,0,0,1,1-1h1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5,14.5h2v-3h1v-3a1,1,0,0,0-1-1h-1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5,15.5h-5v-4h-1v-3a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2v3h-1Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
