import { BottomSheetContainer } from 'components/modals';
import { LoadingSpinner } from 'components/spinners';
import {
  Commitment,
  CommitmentHeader
} from 'features/virtual-warehouse/components/commitment';
import { useCommitment } from 'features/virtual-warehouse/hooks/useCommitment';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

export function VirtualWarehouseCommitmentIdRoute() {
  const navigate = useNavigate();
  const { activeLanguage } = useTranslation();
  const { commitmentId: urlCommitmentId } = useParams();
  const {
    data: commitment,
    isLoading,
    error
  } = useCommitment(urlCommitmentId ?? '', activeLanguage);

  if (_.isUndefined(urlCommitmentId) || error) {
    return <Navigate to={'..'} />;
  }

  return (
    <BottomSheetContainer
      open={true}
      onDismiss={() => void navigate('..')}
      header={
        isLoading || !commitment ? null : <CommitmentHeader {...commitment} />
      }
    >
      {isLoading && (
        <div className="w-full flex justify-center p-md">
          <LoadingSpinner smallSize />
        </div>
      )}
      {!isLoading && commitment && <Commitment {...commitment} />}
    </BottomSheetContainer>
  );
}
