import { IOrderAddress } from 'types/orders';

import { nonEmptyStringOrUndefined } from '../helpers';
import { IOrderAddressApi } from '../types';

export const mapOrderAddress = (address: IOrderAddressApi): IOrderAddress => {
  return {
    addressLine1: nonEmptyStringOrUndefined(address.addressLine1),
    addressLine2: nonEmptyStringOrUndefined(address.addressLine2),
    streetPob: nonEmptyStringOrUndefined(address.streetPob),
    countryCode: nonEmptyStringOrUndefined(address.countryCode),
    zipCode: nonEmptyStringOrUndefined(address.zipCode),
    city: nonEmptyStringOrUndefined(address.city),
    district: nonEmptyStringOrUndefined(address.district),
    federalState: nonEmptyStringOrUndefined(address.federalState),
    county: nonEmptyStringOrUndefined(address.county),
    email: nonEmptyStringOrUndefined(address.email),
    phone1: nonEmptyStringOrUndefined(address?.phone1),
    phone2: nonEmptyStringOrUndefined(address.phone2),
    mobile1: nonEmptyStringOrUndefined(address.mobile1),
    mobile2: nonEmptyStringOrUndefined(address.mobile2)
  };
};
