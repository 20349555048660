import { execGet } from 'services/RequestUtils';
import { IUserProfile } from 'types';

import { IUserProfileApi } from './types/IUserProfileApi';
import { sanitizeUserProfile } from './UserProfileServiceHelper';

export async function fetchUserProfile(userId: string): Promise<IUserProfile> {
  const searchParams = new URLSearchParams();
  searchParams.set('include', 'BasicBusinessData');

  const apiUser = await execGet<IUserProfileApi>(
    `${
      process.env.REACT_APP_OFFICE_API
    }/users/${userId}?${searchParams.toString()}`,
    { cache: true }
  );

  const profile = sanitizeUserProfile(apiUser, userId);
  return profile;
}
