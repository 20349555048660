import type { FC } from 'react';

export const BadgeOutlineIcon: FC<{ readonly size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
    className="flex-shrink-0 flex-grow-0"
  >
    <g fill="currentColor" stroke="currentColor" strokeWidth="1">
      <circle cx="5" cy="8" r="1" stroke="none" />
      <path
        d="M5,10H5a2,2,0,0,1,2,2v0a0,0,0,0,1,0,0H3a0,0,0,0,1,0,0v0A2,2,0,0,1,5,10Z"
        stroke="none"
      />
      <path
        d="M4.5,3.5h-3a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1h13a1,1,0,0,0,1-1v-9a1,1,0,0,0-1-1h-3"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8,.5H8A1.5,1.5,0,0,1,9.5,2V5.5a0,0,0,0,1,0,0h-3a0,0,0,0,1,0,0V2A1.5,1.5,0,0,1,8,.5Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="9.5"
        x2="12.5"
        y1="8.5"
        y2="8.5"
      />
      <line
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="9.5"
        x2="12.5"
        y1="11.5"
        y2="11.5"
      />
    </g>
  </svg>
);
