import { UnorderedList } from 'components/lists';
import { useGlobalConfigsContext } from 'contexts';
import { Icon, IconName } from 'libs/icons';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DocumentCategory } from 'types';

import { AllDocuments } from './AllDocuments';
import { DocumentCategoryFolder } from './DocumentCategoryFolder';

interface IDocumentsDropdownFilterProps {
  readonly categoryOptions: DocumentCategory[];
}

export function DocumentsDropdownFilter(props: IDocumentsDropdownFilterProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const categoryFilter = searchParams.get('filter') ?? undefined;
  const menuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const {
    app: {
      office: { enableAnimations }
    },
    device: { supportsHover }
  } = useGlobalConfigsContext();

  useEffect(() => {
    const handleClickOutsideTarget = (event: MouseEvent) => {
      if (
        !_.isNull(menuRef.current) &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutsideTarget);
    return () =>
      document.removeEventListener('click', handleClickOutsideTarget);
  }, []);

  return (
    <div className="w-full" ref={menuRef}>
      <button
        className="flex flex-row items-center"
        onClick={() => setOpen(!open)}
      >
        {_.isUndefined(categoryFilter) ||
        !Object.values(DocumentCategory).includes(
          categoryFilter as DocumentCategory
        ) ? (
          <span>
            <AllDocuments />
          </span>
        ) : (
          <span>
            <DocumentCategoryFolder
              category={categoryFilter as DocumentCategory}
            />
          </span>
        )}
        <span className="ml-sm pointer-events-none">
          <Icon
            name={open ? IconName.ChevronUp : IconName.ChevronDown}
            size={12}
          />
        </span>
      </button>
      <div
        className={`${
          open
            ? 'visible opacity-1 translate-y-0'
            : 'invisible opacity-0 -translate-y-2'
        } ${
          enableAnimations
            ? 'transition-all duration-100 ease-linear'
            : 'pmi-force-transition-none'
        } fixed bg-white px-xs shadow-xl rounded-md`}
      >
        <UnorderedList
          items={[
            {
              content: (
                <div
                  className={`${
                    supportsHover ? 'hover:bg-secondary' : ''
                  } p-xs`}
                >
                  <AllDocuments />
                </div>
              ),
              onClick: () => {
                setOpen(false);
                navigate(`/account/documents`);
              }
            },
            ...props.categoryOptions.map(filter => ({
              content: (
                <div
                  className={`${
                    supportsHover ? 'hover:bg-secondary' : ''
                  } p-xs`}
                >
                  <DocumentCategoryFolder category={filter} />
                </div>
              ),
              onClick: () => {
                setOpen(false);
                navigate(`/account/documents?filter=${filter}`);
              }
            }))
          ]}
        />
      </div>
    </div>
  );
}
