import {
  NewBasketWizard,
  NewBasketContextProvider
} from 'features/shared-baskets';

export function CreateBasketRoute() {
  return (
    <NewBasketContextProvider>
      <NewBasketWizard />
    </NewBasketContextProvider>
  );
}
