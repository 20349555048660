import { Icons } from 'libs/icons';
import { ReactNode } from 'react';
import { CommitmentStatus } from 'types';

import { CommitmentStatusBadge } from '../commitment/CommitmentStatusBadge';

export function CommimentCard(props: {
  readonly children: ReactNode;
  readonly status: CommitmentStatus;
}) {
  return (
    <>
      <CommitmentStatusBadge status={props.status} small />
      <div>{props.children}</div>
      <Icons.Common.ChevronRight size={20} className="text-silver" />
    </>
  );
}
