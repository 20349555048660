import { useMemo } from 'react';

// = first page + dots + current page + dots + last page
// E.g., [1, ..., 5, ..., 10]
const MIN_PAGES_NUMBER = 5;

const DOTS = '...';

interface IPaginationConfig {
  readonly totalPageCount: number;
  readonly currentPage: number;

  // Number of elements on the sides of the selected number
  // E.g., siblingCount = 0 ==> [1, ..., 5, ..., 10]
  // E.g., siblingCount = 1 ==> [1, ..., 4, 5, 6, ..., 10]
  // E.g., siblingCount = 2 ==> [1, ..., 3, 4, 5, 6, 7, ..., 10]
  readonly siblingCount?: number;
}
/**
 * Examples:
 *  - Option 1: [1, 2, 3, 4, 5]
 *  - Option 2: [1, ... 3, ... 5]
 *  - Option 3: [1, ..., 4, 5]
 *  - Option 4: [1, 2, ..., 5]
 */
export function usePagination({
  totalPageCount,
  currentPage,
  siblingCount = 1
}: IPaginationConfig) {
  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblingCount + MIN_PAGES_NUMBER;

    /**
     * OPTION 1: [1, 2, 3, 4, 5]
     */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    // Render dots on the left side if the index of the previous page is bigger than 2
    // E.g.,
    //  - Config: { totalPageCount: 5, siblingCount: 1, currentPage: 4 }
    //  - Result: [1, ..., 3, 4, 5]
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const shouldRenderLeftDots = leftSiblingIndex > 2;

    // Render dots on the right side if the index of the next page is 2 places below the last page
    // E.g.,
    //  - Config: { totalPageCount: 5, siblingCount: 1, currentPage: 2 }
    //  - Result: [1, 2, 3, ..., 5]
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );
    const shouldRenderRightDots = rightSiblingIndex < totalPageCount - 2;

    /**
     * OPTION 2: [1, ... 3, ... 5]
     */
    if (shouldRenderLeftDots && shouldRenderRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [1, DOTS, ...middleRange, DOTS, totalPageCount];
    }

    /**
     * OPTION 3: [1, ..., 4, 5]
     */
    if (shouldRenderLeftDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [1, DOTS, ...rightRange];
    }

    /**
     * OPTION 4: [1, 2, ..., 5]
     */
    if (shouldRenderRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);
      return [...leftRange, DOTS, totalPageCount];
    }

    return [];
  }, [siblingCount, totalPageCount, currentPage]);

  return paginationRange;
}

function range(start: number, end: number): ReadonlyArray<number> {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
}
