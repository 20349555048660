import type { FC } from 'react';

import { WidgetSkeleton } from './widgets/WidgetSkeleton';

export const DashboardContentSkeleton: FC = () => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 m-auto">
      <div className="col-span-1">
        <WidgetSkeleton />
      </div>

      <div className="col-span-1">
        <WidgetSkeleton />
      </div>

      <div className="col-span-1">
        <WidgetSkeleton />
      </div>

      <div className="col-span-1">
        <WidgetSkeleton />
      </div>

      <div className="col-span-1">
        <WidgetSkeleton />
      </div>

      <div className="col-span-1">
        <WidgetSkeleton />
      </div>

      <div className="col-span-1">
        <WidgetSkeleton />
      </div>

      <div className="col-span-1">
        <WidgetSkeleton />
      </div>

      <div className="col-span-1">
        <WidgetSkeleton />
      </div>

      <div className="col-span-1">
        <WidgetSkeleton />
      </div>

      <div className="col-span-1">
        <WidgetSkeleton />
      </div>

      <div className="col-span-1">
        <WidgetSkeleton />
      </div>
    </div>
  );
};
