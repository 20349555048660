import { ErrorHandler } from 'components/errors/ErrorHandler';
import { Pagination } from 'components/pagination';
import { LoadingSpinner } from 'components/spinners';
import { useBasketList } from 'features/shared-baskets';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useState } from 'react';

import { BasketListEmpty } from './BasketListEmpty';
import { BasketsListItem } from './BasketsListItem';

const DEFAULT_COMMITMENT_LIST_PAGE_SIZE = 20;

export function BasketsList() {
  const { activeLanguage } = useTranslation();
  const [pageDetails, setPageDetails] = useState<{
    readonly page: number;
    readonly perPage: number;
  }>({
    page: 1,
    perPage: DEFAULT_COMMITMENT_LIST_PAGE_SIZE
  });

  const {
    data: basketsList,
    isLoading,
    error
  } = useBasketList(pageDetails.page, pageDetails.perPage, activeLanguage);

  if (error) {
    return (
      <div className="w-full flex justify-center">
        <ErrorHandler err={error} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="w-full flex justify-center p-md">
        <LoadingSpinner smallSize />
      </div>
    );
  }

  if (basketsList && basketsList.baskets.length === 0) {
    return <BasketListEmpty />;
  }

  return (
    <>
      {!_.isUndefined(basketsList) && basketsList.baskets.length > 0 && (
        <ul className="space-y-sm">
          {basketsList.baskets.map(basket => {
            return <BasketsListItem {...basket} key={basket.basket.basketId} />;
          })}
        </ul>
      )}
      <div className="flex justify-start mt-sm">
        <Pagination
          currentPage={pageDetails.page}
          onPageChange={p =>
            setPageDetails({
              page: p,
              perPage: DEFAULT_COMMITMENT_LIST_PAGE_SIZE
            })
          }
          totalPages={basketsList?.paginationInfo.totalPages ?? 0}
          siblingCount={2}
        />
      </div>
    </>
  );
}
