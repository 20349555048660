import { UnorderedList } from 'components/lists';
import { FormattedUnitValue, TrendValue } from 'components/text';
import { useScreenEvents } from 'contexts';
import { FeatureIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { IRegistration, IRegistrationDetail } from 'types';
import { computePercentageTrend } from 'utils';

import { WidgetRegistrationsDetails } from './WidgetRegistrationsDetails';
import { Widget } from '../Widget';

type WidgetRegistrationProps = IRegistration & {
  readonly title: string;
  readonly icon: FeatureIconName;
  readonly traceId: string;
};

export function WidgetRegistration(props: WidgetRegistrationProps) {
  const { firstline, structure, icon, title, traceId } = props;
  const { t } = useTranslation();
  const { sm } = useScreenEvents();

  const computeRightNodeFor = (details: IRegistrationDetail) => {
    return (
      <span className="flex flex-row items-center">
        <FormattedUnitValue unit="count" value={details.current} />
        {!_.isUndefined(details.lastMonth) && (
          <span
            className={sm ? 'ml-sm flex-end' : 'ml-xs flex-end'}
            title={t('Your current sales compared to last month.')}
          >
            <TrendValue
              value={computePercentageTrend(details.current, details.lastMonth)}
              style="illustration-only"
            />
          </span>
        )}
      </span>
    );
  };

  return (
    <Widget
      traceId={traceId}
      title={title}
      iconName={icon}
      detailedViewButtonLabel={t('History')}
      content={
        <div>
          <UnorderedList
            items={[
              {
                content: t('Firstline'),
                rightNode: computeRightNodeFor(firstline)
              },
              {
                content: t('Structure'),
                rightNode: computeRightNodeFor(structure)
              }
            ]}
          />
        </div>
      }
      detailedView={
        <WidgetRegistrationsDetails
          firstline={firstline}
          structure={structure}
        />
      }
    />
  );
}
