import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { ReactNode } from 'react';

import { Modal } from './Modal';

interface IHelperModalProps {
  readonly onDismiss: () => void;
  readonly showCloseButton?: boolean;
  readonly children: ReactNode;
}

export function HelperModal(props: IHelperModalProps) {
  const { t } = useTranslation();

  const showCloseButton = _.isUndefined(props.showCloseButton)
    ? true
    : props.showCloseButton;

  return (
    <Modal open noBg noPadding hideCloseBtn onClose={props.onDismiss}>
      <div className="bg-white shadow-md rounded-md px-md py-sm space-y-sm relative">
        {props.children}
      </div>

      {showCloseButton && (
        <div className="flex flex-row justify-center mt-sm">
          <button
            onClick={props.onDismiss}
            className="rounded-full shadow-md text-white bg-primary font-bold px-md py-sm"
          >
            {t('Close')}
          </button>
        </div>
      )}
    </Modal>
  );
}
