import { SupportedLanguage } from '@pmi.web/localization';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { fetchPromoShop } from 'services/shops';

const QUERY_KEY = 'pmi.web.office__promo_shop';
const TTL = 900000; // 15 minutes in milliseconds

export function usePromoShop(preferedLanguage?: SupportedLanguage) {
  const query = useQuery({
    enabled: !_.isUndefined(preferedLanguage),
    queryKey: [QUERY_KEY, preferedLanguage],
    queryFn: () => fetchPromoShop(preferedLanguage),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
