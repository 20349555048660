import type { FC } from 'react';

export const BriefcaseOutlineIcon: FC<{ readonly size: number }> = ({
  size
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    className="flex-shrink-0 flex-grow-0"
    height={size}
  >
    <g fill="currentColor" stroke="currentColor" strokeWidth="1">
      <polyline
        fill="none"
        points="5.5 3.5 5.5 0.5 10.5 0.5 10.5 3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5,9.5H.5v-5a1,1,0,0,1,1-1h13a1,1,0,0,1,1,1v5h-6"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5,11.5v2a1,1,0,0,1-1,1H2.5a1,1,0,0,1-1-1v-2"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        height="4"
        width="3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        x="6.5"
        y="7.5"
      />
    </g>
  </svg>
);
