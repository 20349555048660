import { PrimaryButton } from '@pmi.web/react-common';
import { CountryFlag } from '@pmi.web/react-country-flags';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { BsArrows } from 'react-icons/bs';
import AuthenticationService from 'services/AuthenticationService';

export function CountryShopNotAvailable({
  country
}: {
  readonly country: string;
}) {
  const { t } = useTranslation();
  const { dispatchStepEvent } = useNewBasketContext();

  return (
    <div className="flex flex-col items-center justify-center p-sm bg-lightGray rounded text-center">
      <div className="flex flex-row flex-nowrap gap-xs w-fit mt-sm">
        <CountryFlag
          countryCode={AuthenticationService.user.countryCode}
          style={{
            maxWidth: 24
          }}
        />
        <BsArrows size={24} />
        <CountryFlag
          countryCode={country}
          style={{
            maxWidth: 24
          }}
        />
      </div>
      <p className="mt-xs">
        {t(
          'Sharing baskets between these countries is not available at the moment, please chose another person!'
        )}
      </p>
      <br />
      <PrimaryButton
        onClick={() => {
          dispatchStepEvent({ type: 'edit-step', value: 'owner' });
          dispatchStepEvent({ type: 'reset-owner' });
        }}
      >
        {t('Edit Recipient')}
      </PrimaryButton>
    </div>
  );
}
