import { useQuery } from '@tanstack/react-query';
import { previewBasket } from 'services';
import { IBasketDiscountPayload } from 'services/shared-baskets/types/IBasketDiscountPayload';

export function useBasketPreviewer(
  shopId: string | undefined,
  customerId: string | undefined,
  items: ReadonlyArray<{
    readonly articleNumber: string;
    readonly quantity: number;
  }>,
  affiliateDiscount?: ReadonlyArray<IBasketDiscountPayload>
) {
  const query = useQuery({
    queryKey: [
      'pmi.web.office__basketpreview',
      shopId,
      customerId,
      items,
      affiliateDiscount
    ],
    queryFn: () =>
      previewBasket({
        shopId: shopId ?? '',
        customerId: customerId ?? '',
        items,
        affiliateDiscount
      }),
    staleTime: 0,
    enabled: !!shopId && !!customerId,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
