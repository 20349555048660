import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { BasketLinkShare } from 'features/virtual-warehouse';
import { fireConfettiGun } from 'libs/effects';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { IoCheckmarkCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

export function Success() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { checkoutUrl } = useNewBasketContext();

  useEffect(() => {
    _.times(5, () => fireConfettiGun('blue'));
  }, []);

  return (
    <div className="bg-white rounded-md shadow-md p-lg">
      <section className="space-y-md">
        <h1 className="flex flex-col items-center flex-wrap gap-xs justify-center text-center">
          <IoCheckmarkCircle size={32} className="text-primary" />
          {t('Your new basket is ready!')}
        </h1>
        <p className="text-center">
          {t('Share the link below with the basket owner!')}
        </p>

        <BasketLinkShare href={checkoutUrl ?? ''} onClick={_.noop} />

        <div className="flex flex-col items-center justify-center gap-md w-full">
          <button
            onClick={() => {
              navigate('/sales/baskets');
            }}
          >
            {t('Close ')}
          </button>
        </div>
      </section>
    </div>
  );
}
