import { useTranslation } from 'libs/translations';
import { DocumentType, DocumentCategory, IDocument } from 'types';

export function useDocumentI18n() {
  const { t } = useTranslation();

  const translateDocumentType = (doc: IDocument) => {
    switch (doc.docType) {
      case DocumentType.Bonus:
        return t('Bonus');
      case DocumentType.BonusJp:
        return t('Bonus JP');
      case DocumentType.BonusPerformance:
        return t('Performance Bonus');
      case DocumentType.BonusPool:
        return t('Pool Bonus');
      case DocumentType.ProvisionItaly:
        return t('Fiscal Invoice');
      case DocumentType.InvoiceMpm:
        return t('Invoice');
      case DocumentType.Unknown:
        return doc.filename;
    }
  };

  const translateDocumentCategory = (docCategory: DocumentCategory) => {
    switch (docCategory) {
      case DocumentCategory.Bonus:
        return t('Bonus');
      case DocumentCategory.TaxInvoices:
        return t('Tax Invoices');
      case DocumentCategory.PerformanceBonus:
        return t('Performance Bonus');
      case DocumentCategory.Invoices:
        return t('Invoices');
      default:
        return docCategory;
    }
  };

  return { translateDocumentType, translateDocumentCategory };
}
