import type { FC } from 'react';

export const TicketOutlineIcon: FC<{ readonly size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
    className="flex-shrink-0 flex-grow-0"
  >
    <g fill="currentColor" stroke="currentColor" strokeWidth="1">
      <line
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="5.5"
        x2="10.5"
        y1="6.5"
        y2="6.5"
      />
      <line
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="5.5"
        x2="10.5"
        y1="9.5"
        y2="9.5"
      />
      <path
        d="M13.5,8 c0-1.209,0.859-2.218,2-2.45V3.5c0-0.552-0.448-1-1-1h-13c-0.552,0-1,0.448-1,1v2.05c1.141,0.232,2,1.24,2,2.45 c0,1.209-0.859,2.218-2,2.45v2.05c0,0.552,0.448,1,1,1h13c0.552,0,1-0.448,1-1v-2.05C14.359,10.218,13.5,9.209,13.5,8z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
