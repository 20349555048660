import { useTranslation } from 'libs/translations';
import { useEffect, useMemo, useState } from 'react';
import {
  BonusPayoutMethod,
  DirectCashPayoutMethod,
  PayquickerLink
} from 'types';

import { PayquickerBenefits } from './PayquickerBenefits';
import { PayquickerExternalResource } from './PayquickerExternalResource';
import { PayquickerForceSetupButton } from './PayquickerForceAction';
import { usePayoutsContext } from '../../contexts/PayoutsContext';

export function PMPayPlusMarketingBanner() {
  const { payoutDetails } = usePayoutsContext();

  const { isPMPayPlusAvailable } = useMemo(() => {
    const isPMPayPlusAvailable =
      payoutDetails?.availableBonusPayoutMethods
        .map(m => m.bonusPayoutMethod)
        .includes(BonusPayoutMethod.PayQuicker) ||
      payoutDetails?.availableDirectCashPayoutMethods
        .map(m => m.directCashPayoutMethod)
        .includes(DirectCashPayoutMethod.PayQuicker);

    return {
      isPMPayPlusAvailable
    };
  }, [payoutDetails]);

  if (!isPMPayPlusAvailable) {
    return null;
  }

  return <IntroductionPMPayPlusBanner />;
}

function IntroductionPMPayPlusBanner() {
  const { payoutDetails } = usePayoutsContext();
  const { t } = useTranslation();

  const { shouldAdvertisePMPayPlus, pmPayPlusRegion } = useMemo(() => {
    const isPMPayPlusAvailable =
      payoutDetails?.availableBonusPayoutMethods
        .map(m => m.bonusPayoutMethod)
        .includes(BonusPayoutMethod.PayQuicker) ||
      payoutDetails?.availableDirectCashPayoutMethods
        .map(m => m.directCashPayoutMethod)
        .includes(DirectCashPayoutMethod.PayQuicker);

    let pmPayPlusRegion = payoutDetails?.availableBonusPayoutMethods.find(
      x => x.bonusPayoutMethod == BonusPayoutMethod.PayQuicker
    )?.region;

    if (!pmPayPlusRegion) {
      pmPayPlusRegion = payoutDetails?.availableDirectCashPayoutMethods.find(
        x => x.directCashPayoutMethod == DirectCashPayoutMethod.PayQuicker
      )?.region;
    }

    const isPMPayPlusActive =
      payoutDetails?.selectedBonusPayoutMethod ===
        BonusPayoutMethod.PayQuicker ||
      (payoutDetails?.selectedDirectCashPayoutMethod ===
        DirectCashPayoutMethod.PayQuicker &&
        payoutDetails?.directCashState.active);

    const shouldAdvertisePMPayPlus = isPMPayPlusAvailable && !isPMPayPlusActive;

    return {
      shouldAdvertisePMPayPlus,
      pmPayPlusRegion
    };
  }, [payoutDetails]);

  const [both, setBoth] = useState<boolean>(false);
  const [onlyBonus, setOnlyBonus] = useState<boolean>(false);

  const {
    isPayquickerAvailableForBonus,
    isPayquickerAvailableForDirectCash,
    isAlreadyUsingPayquickerForBonus,
    isAlreadyUsingPayquickerForDirectCash
  } = useMemo(() => {
    const isPayquickerAvailableForBonus =
      payoutDetails?.availableBonusPayoutMethods
        .map(payout => payout.bonusPayoutMethod)
        .includes(BonusPayoutMethod.PayQuicker);
    const isPayquickerAvailableForDirectCash =
      payoutDetails?.availableDirectCashPayoutMethods
        .map(payout => payout.directCashPayoutMethod)
        .includes(DirectCashPayoutMethod.PayQuicker) &&
      payoutDetails.isDirectCashAvailable;
    const isAlreadyUsingPayquickerForBonus =
      isPayquickerAvailableForBonus &&
      payoutDetails?.selectedBonusPayoutMethod === BonusPayoutMethod.PayQuicker;
    const isAlreadyUsingPayquickerForDirectCash =
      isPayquickerAvailableForDirectCash &&
      payoutDetails?.selectedDirectCashPayoutMethod ===
        DirectCashPayoutMethod.PayQuicker;

    return {
      isPayquickerAvailableForBonus,
      isPayquickerAvailableForDirectCash,
      isAlreadyUsingPayquickerForBonus,
      isAlreadyUsingPayquickerForDirectCash
    };
  }, [
    payoutDetails?.availableBonusPayoutMethods,
    payoutDetails?.availableDirectCashPayoutMethods,
    payoutDetails?.isDirectCashAvailable,
    payoutDetails?.selectedBonusPayoutMethod,
    payoutDetails?.selectedDirectCashPayoutMethod
  ]);

  useEffect(() => {
    if (
      isPayquickerAvailableForBonus &&
      isPayquickerAvailableForDirectCash &&
      !isAlreadyUsingPayquickerForBonus &&
      !isAlreadyUsingPayquickerForDirectCash
    ) {
      // Set both PM DirectCash and bonus to PayQuicker
      setBoth(true);
      return;
    }

    if (
      isPayquickerAvailableForBonus &&
      !isPayquickerAvailableForDirectCash &&
      !isAlreadyUsingPayquickerForBonus
    ) {
      // Set bonus to PayQuicker
      setOnlyBonus(true);
      return;
    }
  }, [
    isAlreadyUsingPayquickerForBonus,
    isAlreadyUsingPayquickerForDirectCash,
    isPayquickerAvailableForBonus,
    isPayquickerAvailableForDirectCash
  ]);

  return (
    <div className="bg-white shadow-md rounded-md flex flex-col-reverse sm:flex-row justify-between">
      <div className="p-md sm:p-lg space-y-sm">
        {shouldAdvertisePMPayPlus && (
          <h2 className="text-primary">{t('Introducing PM Pay+')}</h2>
        )}
        {!shouldAdvertisePMPayPlus && (
          <h2 className="text-primary">{t('PM Pay+')}</h2>
        )}
        {shouldAdvertisePMPayPlus && (
          <p>{t('Experience the future of payments!')}</p>
        )}
        <PayquickerBenefits />
        {(both || onlyBonus) && (
          <PayquickerForceSetupButton both={both} onlyBonus={onlyBonus} />
        )}
        <div className="flex flex-wrap gap-sm">
          <PayquickerExternalResource
            link={PayquickerLink.Introduction}
            region={pmPayPlusRegion}
            country={payoutDetails?.country ?? ''}
          />
          <PayquickerExternalResource
            link={PayquickerLink.Benefits}
            region={pmPayPlusRegion}
            country={payoutDetails?.country ?? ''}
          />
          <PayquickerExternalResource
            link={PayquickerLink.Fees}
            region={pmPayPlusRegion}
            country={payoutDetails?.country ?? ''}
          />
          <PayquickerExternalResource
            link={PayquickerLink.Verification}
            region={pmPayPlusRegion}
            country={payoutDetails?.country ?? ''}
          />
          <PayquickerExternalResource
            link={PayquickerLink.FAQs}
            region={pmPayPlusRegion}
            country={payoutDetails?.country ?? ''}
          />
          <PayquickerExternalResource
            link={PayquickerLink.Support}
            region={pmPayPlusRegion}
            country={payoutDetails?.country ?? ''}
          />
        </div>
      </div>
      <div
        className="min-h-[220px] min-w-[180px] sm:max-w-[350px] grow bg-center bg-no-repeat bg-cover"
        style={{
          backgroundImage: 'url("/imgs/promo/payquicker/PMPayPlus-min.png")'
        }}
      ></div>
    </div>
  );
}
