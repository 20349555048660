import { useTranslation } from 'libs/translations';

export function PayquickerBenefits() {
  const { t } = useTranslation();

  return (
    <ul className="list-disc list-inside">
      <li>{t('Receive instant and secure commission payments')}</li>
      <li>{t('Virtual PM Pay+ Debit Card')}</li>
      <li>{t('Get a PM-branded Plastic PM Pay+ Debit Card on request')}</li>
      <li>{t('No bank account needed to use PM Pay+')}</li>
      <li>{t('Spend earnings immediately with flexible options')}</li>
      <li>
        {t(
          'Dedicated, multi-lingual support via phone is available in 6 languages'
        )}
      </li>
      <li>{t('Supports PM DirectCash')}</li>
    </ul>
  );
}
