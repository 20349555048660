import { useGlobalConfigsContext } from 'contexts';
import { useTranslation } from 'libs/translations';
import { useMemo } from 'react';
import { PayquickerLink } from 'types';

import { buildPayquickerResouceLink } from './PayquickerHelper';
import { usePayquickerLinkI18n } from '../../hooks/usePayquickerLinkI18n';

interface IPayquickerExternalResourceProps {
  readonly link: PayquickerLink;
  readonly country: string;
  readonly region?: string;
}

export function PayquickerExternalResource(
  props: IPayquickerExternalResourceProps
) {
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();
  const { activeLanguage } = useTranslation();

  const payquickerLinkT = usePayquickerLinkI18n();

  const link = useMemo(
    () =>
      buildPayquickerResouceLink(
        props.link,
        activeLanguage.split('-', 1)[0] ?? undefined,
        props.region,
        props.country
      ),
    [activeLanguage, props.link, props.region, props.country]
  );

  if (!link) {
    return null;
  }

  return (
    <a
      target="_blank"
      rel="noreferrer"
      className={`${
        supportsHover ? 'hover:underline' : 'underline'
      } text-active whitespace-nowrap`}
      href={link}
    >
      {payquickerLinkT(props.link)}
    </a>
  );
}
