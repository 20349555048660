import { FormattedUnitValue } from 'components/text';
import { PII } from 'features/pii';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { IOrder } from 'types';

import { OrderSourceBadge } from './OrderSourceBadge';
import { OrderStatusBadge } from './OrderStatusBadge';
import { ListGroup } from '../components/list/ListGroup';
import { ListItem } from '../components/list/ListItem';

export function OrdersList(props: { readonly orders: ReadonlyArray<IOrder> }) {
  const { orderId: urlOrderId } = useParams();
  const { t, activeLanguage } = useTranslation();

  return (
    <ListGroup>
      {props.orders.map(order => {
        return (
          <ListItem
            title={t('Order details')}
            key={order.id}
            active={order.id === urlOrderId}
            href={`/account/orders/${order.id}`}
          >
            <div className="grid grid-cols-2 gap-xs mr-xs">
              {order.createdOn ? (
                <div className="min-w-[104px]">
                  <PII
                    value={order.createdOn.toLocaleDateString(activeLanguage)}
                    type={'fixed-date'}
                  />
                </div>
              ) : (
                <span className="line-clamp-1 text-ellipsis overflow-hidden">
                  <PII value={order.reference} type="ids" />
                </span>
              )}

              <span className="text-right font-bold truncate">
                <FormattedUnitValue
                  unit={order.totals.currencyCode}
                  value={order.totals.totalGross}
                />
              </span>

              <div className="flex gap-sm items-center">
                <OrderSourceBadge source={order.source} />
                <OrderStatusBadge status={order.status} />
              </div>

              {order.totals.points > 0 && (
                <span className="text-right truncate">
                  <FormattedUnitValue
                    unit="points"
                    value={order.totals.points}
                  />
                </span>
              )}
            </div>
          </ListItem>
        );
      })}
    </ListGroup>
  );
}
