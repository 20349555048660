import { useEffect, useState } from 'react';

export function useLocalStorage(key: string, defaultValue?: string) {
  const [value, setValue] = useState<string | undefined>(
    () => readFromLoadlStorage(key) ?? defaultValue
  );

  useEffect(() => {
    if (value === undefined) {
      removeFromLocalStorage(key);
    } else {
      writeToLocalStorage(key, value);
    }
  }, [key, value]);

  return [value, setValue] as [typeof value, typeof setValue];
}

function readFromLoadlStorage(key: string) {
  try {
    return window.localStorage.getItem(key) ?? undefined;
  } catch (err) {
    return undefined;
  }
}

function writeToLocalStorage(key: string, value: string) {
  try {
    window.localStorage.setItem(key, value);
  } catch (err) {
    // ignore
  }
}

function removeFromLocalStorage(key: string) {
  try {
    window.localStorage.removeItem(key);
  } catch (err) {
    // ignore
  }
}
