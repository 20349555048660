export type TrackingServiceKey = 'DHL-DE';

interface ITrackingService {
  readonly key: TrackingServiceKey;
  computeTrackingUrl(trackingId: string): string;
}

export const TRACKING_SERVICES: Record<TrackingServiceKey, ITrackingService> = {
  'DHL-DE': {
    key: 'DHL-DE',
    computeTrackingUrl(trackingId: string) {
      const source =
        'https://www.dhl.com/de-de/home/tracking/tracking-parcel.html?submit=1&tracking-id={dhl-id}';
      const pattern = '{dhl-id}';

      return source.replace(pattern, trackingId);
    }
  }
  // Add more tracking services here as needed
};
