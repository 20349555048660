import { FAQSection } from './FAQSection';
import { IFAQItem } from './IFAQs';

export function FAQStoryblokContent({
  faqs
}: {
  readonly faqs: ReadonlyArray<IFAQItem>;
}) {
  return (
    <>
      {faqs.map(faq => (
        <FAQSection key={faq._uid} title={faq.question} content={faq.answer} />
      ))}
    </>
  );
}
