import { Month } from '@pmi.libs/utils-common';
import { Disclaimer } from 'components/text';
import { useScreenEvents } from 'contexts';
import { FeatureIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { IPaginatedResponse, IUserProfile } from 'types';

import { FirstlineDataViewer } from './FirstlineDataViewer';
import { FirstlineDetails } from './FirstlineDetails';
import { FirstlineTableSkeleton } from './FirstlineTableSkeleton';
import { Widget } from '../Widget';

interface IWidgetFirstlineProps {
  readonly firstline: IPaginatedResponse<IUserProfile>;
  readonly userId: string;
  readonly period: Month;
}

export function WidgetFirstline(props: IWidgetFirstlineProps) {
  const { firstline, userId, period } = props;

  const { t } = useTranslation();
  const { md } = useScreenEvents();

  const firstlineData = useMemo(
    () =>
      _.isUndefined(firstline)
        ? undefined
        : _.take(firstline.data, md ? 10 : 5),
    [firstline, md]
  );
  const hasDetailedView = useMemo(
    () => (firstline?.totalCount ?? 0) > (firstlineData?.length ?? 0),
    [firstline, firstlineData]
  );

  if (_.isUndefined(firstlineData)) {
    return (
      <Widget
        traceId="Firstline"
        title={t('Firstline')}
        iconName={FeatureIconName.Firstline}
        content={
          <section className="animate-pulse">
            <FirstlineTableSkeleton />
          </section>
        }
      />
    );
  }

  return (
    <Widget
      traceId="Firstline"
      title={t('Firstline')}
      detailedViewButtonLabel={t('Details')}
      iconName={FeatureIconName.Firstline}
      content={
        firstlineData.length !== 0 ? (
          <div className="overflow-x-auto">
            <FirstlineDataViewer
              data={firstlineData}
              period={period}
              isReadonly
            />
          </div>
        ) : (
          <section>
            <Disclaimer
              message={t(
                'The top 5 Team Partners in the firstline will show up here.'
              )}
            />
          </section>
        )
      }
      detailedView={
        hasDetailedView ? (
          <FirstlineDetails userId={userId} period={period} />
        ) : null
      }
      detailedViewMaxWidth={2000}
    />
  );
}
