import { FixedDate, Timestamp } from '@pmi.libs/utils-common';
import { IUser } from 'types';

import { IUserApi } from './types/IUserApi';

export function parseUserApi(userApi: IUserApi): IUser {
  return {
    ...userApi,
    createdAt: Timestamp.createOrThrow(userApi.createdAt),
    givenName: stringOrUndefined(userApi.givenName),
    familyName: stringOrUndefined(userApi.familyName),
    middleName: stringOrUndefined(userApi.middleName),
    publicId: stringOrUndefined(userApi.publicId),
    dateOfBirth: userApi.dateOfBirth
      ? FixedDate.create(userApi.dateOfBirth)
      : undefined,
    sponsorId: userApi.sponsor,
    salutation:
      userApi.salutation && isValidSalutation(userApi.salutation)
        ? userApi.salutation
        : undefined
  };
}

function stringOrUndefined(value: string): string | undefined {
  return value?.length > 0 ? value : undefined;
}

function isValidSalutation(value: unknown): value is 'MR' | 'MRS' {
  return typeof value === 'string' && ['MR', 'MRS'].includes(value);
}
