import { Month } from '@pmi.libs/utils-common';
import _ from 'lodash';
import { IPaginatedResponse, IUserProfile } from 'types';

import { IUserProfileApi } from './types/IUserProfileApi';
import { IUserSearchResultApi } from './types/IUserSearchResultApi';
import { fetchUserProfile } from './UserProfileService';
import { sanitizeUserProfile } from './UserProfileServiceHelper';
import { execGet } from '../RequestUtils';

export async function computePathTo(userId: string) {
  const searchParams = new URLSearchParams();
  searchParams.set('expand', 'BasicProfile,ExtendedProfile');
  searchParams.set('include', 'BasicBusinessData');
  searchParams.set('pathTo', userId);
  const response = await execGet<IUserSearchResultApi>(
    `${process.env.REACT_APP_OFFICE_API}/users?${searchParams}`,
    { cache: true }
  );

  return (response.data ?? []).map(u => sanitizeUserProfile(u));
}

export async function fetchDownlineForPath(
  userIds: string[],
  period?: Month
): Promise<ReadonlyArray<ReadonlyArray<IUserProfile>>> {
  if (userIds.length === 0) {
    return Promise.resolve([]);
  }

  const profileRequests: IUserProfile[] = await Promise.all(
    userIds.map(userId => fetchUserProfile(userId))
  );
  const firstline = await fetchFirstlineOf(_.takeRight(userIds, 1)[0], {
    period
  });

  return [...profileRequests.map(p => [p]), firstline.data].filter(
    arr => arr.length !== 0
  );
}

export async function fetchFirstlineOf(
  userId: string,
  options?: { pageSize?: number; pageNumber?: number; period?: Month }
): Promise<IPaginatedResponse<IUserProfile>> {
  const searchParams = new URLSearchParams();
  searchParams.set('expand', 'BasicProfile,ExtendedProfile');
  searchParams.set('include', 'BasicBusinessData');

  if (options?.period) {
    searchParams.append('period', options.period.toString());
  }

  if (!_.isUndefined(options?.pageSize)) {
    searchParams.set('PageSize', '' + options?.pageSize);
  }

  if (!_.isUndefined(options?.pageNumber)) {
    searchParams.set('PageNumber', '' + options?.pageNumber);
  }

  const firstlinePage = await execGet<IPaginatedResponse<IUserProfileApi>>(
    `${
      process.env.REACT_APP_OFFICE_API
    }/users/${userId}/firstline?${searchParams.toString()}`,
    { cache: true }
  );

  return {
    ...firstlinePage,
    data: (firstlinePage.data ?? []).map(u => sanitizeUserProfile(u))
  };
}
