import { PrimaryButton } from '@pmi.web/react-common';
import {
  type IProspectCreationFormData,
  ProspectCreationForm
} from '@pmi.web/react-user-forms';
import { LoadingSpinner } from 'components/spinners';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useBoolean } from 'hooks';
import { triggerErrorToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import { useEffect, useMemo, useState } from 'react';
import { createProspect, fetchUser } from 'services';
import AuthenticationService from 'services/AuthenticationService';

import './BasketOwnerSelection.scss';
import { SelectNewProspectCountry } from './SelectNewProspectCountry';

export function SelectNewProspect() {
  const { userId: sponsorId } = AuthenticationService.user;
  const { t, activeLanguage } = useTranslation();

  const {
    dispatchStepEvent,
    basketOwnerId,
    basketOwnerCountry,
    isBasketOwnerProspect,
    shopId
  } = useNewBasketContext();

  const [isLoading, { setFalse: stopLoading, setTrue: startLoading }] =
    useBoolean(false);

  const [prospectData, setProspectData] = useState<
    IProspectCreationFormData | undefined
  >();

  const [prospectCreationError, setProspectCreationError] = useState<
    Error | undefined
  >();

  /**
   * Business errors reported by the API.
   * Proceed with caution. You will only find hacks inside until we can
   * know for sure what the UM service is returning in case of errors.
   * (╯°□°）╯︵ ┻━┻
   *
   * For now, we can only brace ourselves and hope for the best!
   */
  const businessLogicErrors = useMemo(() => {
    const errors: Partial<
      Record<
        keyof IProspectCreationFormData | 'address.addressLine1',
        { readonly message: string; readonly type: string }
      >
    > = {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const backendError: any = prospectCreationError;

    if (backendError && typeof backendError === 'object') {
      const knownBackendError: {
        readonly detail: string;
        readonly errors: Record<string, string>;
      } = {
        detail: 'detail' in backendError ? (backendError.detail as string) : '',
        errors:
          'Errors' in backendError
            ? backendError.Errors
            : 'errors' in backendError
            ? backendError.errors
            : {}
      };

      // Duplicate address error
      if (knownBackendError.detail === 'address is flagged as duplicate') {
        errors['address.addressLine1'] = {
          type: 'duplicate_address',
          message: t('Address is already in use')
        };
      } // Bad address error
      else if (
        typeof knownBackendError.errors === 'object' &&
        Object.keys(knownBackendError.errors).find(k => k.startsWith('address'))
      ) {
        errors['address.addressLine1'] = {
          type: 'duplicate_address',
          message: t('Address is invalid')
        };
      }

      // Duplicate email error
      if (
        typeof knownBackendError.errors === 'object' &&
        knownBackendError.errors['email'] &&
        knownBackendError.errors['email'].includes('email is taken')
      ) {
        errors['email'] = {
          type: 'duplicate_email',
          message: t('Email is already in use')
        };
      }

      // Too young error
      if (
        typeof knownBackendError.errors === 'object' &&
        knownBackendError.errors['dateOfBirth'] &&
        knownBackendError.errors['dateOfBirth'].includes('18')
      ) {
        errors['dateOfBirth'] = {
          type: 'too_young',
          message: t('You must be at least 18 years old')
        };
      }

      // Other errors
      if (
        knownBackendError.errors &&
        typeof knownBackendError.errors === 'object'
      ) {
        Object.keys(knownBackendError.errors)
          .filter(
            k =>
              !k.startsWith('address') && k !== 'email' && k !== 'dateOfBirth'
          )
          .forEach(k => {
            errors[k as keyof IProspectCreationFormData] = {
              type: 'unknow_error',
              message: knownBackendError.errors[k] ?? t('Something went wrong.')
            };
          });
      }
    }

    if (backendError && Object.keys(errors).length === 0) {
      // TODO: write generic error message at the top
    }

    return errors;
  }, [prospectCreationError, t]);

  const onError = (err: Error) => {
    triggerErrorToast(err?.message);
    setProspectCreationError(err);
  };

  const onSaveProspect = async (
    prospectCreationProps: IProspectCreationFormData
  ) => {
    if (!prospectCreationProps || !shopId) {
      return;
    }

    // Save data to state in case something goes wrong
    setProspectData(prospectCreationProps);

    startLoading();
    setProspectCreationError(undefined);
    try {
      const prospectId = await createProspect(prospectCreationProps);

      if (!prospectId) {
        onError(new Error('Prospect was not created successfully'));
        return;
      }

      dispatchStepEvent({
        type: 'set-prospect-id',
        value: prospectId
      });

      dispatchStepEvent({
        type: 'confirm-user-id'
      });
    } catch (err) {
      onError(err as Error);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    if (basketOwnerId && isBasketOwnerProspect && shopId) {
      startLoading();
      fetchUser(basketOwnerId)
        .then(apiData =>
          setProspectData({
            sponsorId: apiData.sponsorId,
            familyName: apiData.familyName,
            givenName: apiData.givenName,
            salutation: apiData.salutation,
            email: apiData.emails[0]?.email,
            address: apiData.addresses[0],
            dateOfBirth: apiData.dateOfBirth?.rawValue,
            phoneNumber: apiData.phoneNumbers[0]?.number
          })
        )
        .finally(() => stopLoading());
    }
  }, [basketOwnerId, isBasketOwnerProspect, shopId, startLoading, stopLoading]);

  if (isLoading) {
    return (
      <div className="w-full flex justify-center p-md">
        <LoadingSpinner smallSize />
      </div>
    );
  }

  return (
    <>
      <div className="px-xs">
        <SelectNewProspectCountry />
      </div>
      {basketOwnerCountry && (
        <div className="px-xs mt-md">
          <ProspectCreationForm
            country={basketOwnerCountry}
            language={activeLanguage}
            formId="prospect-creation-form"
            onSubmit={onSaveProspect}
            defaultValues={prospectData ?? { sponsorId }}
            businessLogicErrors={businessLogicErrors}
          />
        </div>
      )}
      {isLoading && (
        <div className="p-sm flex items-center justify-center w-full min-h-[100px]">
          <LoadingSpinner smallSize />
        </div>
      )}
      <div className="flex justify-end mt-sm sticky bottom-0 bg-white py-xs bg-opacity-90">
        <PrimaryButton type="submit" form="prospect-creation-form">
          {t('Continue')}
        </PrimaryButton>
      </div>
    </>
  );
}
