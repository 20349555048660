import { PillBadge } from 'components/badges';
import { useFiltersAndSortingContext } from 'features/analytics/contexts/FiltersAndSortingContext';
import { ReactNode } from 'react';

import { FilterSectionName } from '../filter-sections/FilterSectionName';

interface IActiveFilterProps {
  readonly filterSection: FilterSectionName;
  readonly children: ReactNode;
}

export function ActiveFilter(props: IActiveFilterProps) {
  const { openDialog } = useFiltersAndSortingContext();

  return (
    <button title={undefined} onClick={() => openDialog(props.filterSection)}>
      <PillBadge
        className="bg-secondary border border-active text-active"
        title={props.children}
      />
    </button>
  );
}
