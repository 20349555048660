import { useTranslation } from 'libs/translations';
import { IProgress } from 'types';
import { formatNumberLocalized } from 'utils';

interface ICheckAssuranceStatusMessageProps {
  readonly percent: number;
  readonly options: ReadonlyArray<IProgress>;
}

export function CheckAssuranceStatusMessage(
  props: ICheckAssuranceStatusMessageProps
) {
  const { t, activeLanguage } = useTranslation();

  if (hasCheckAssurance(props)) {
    return null;
  }

  const ownVolumeDiff = computeOwnVolumeDiff(props);
  const aboAndOwnVolumeDiff = computeAboAndOwnVolumeDiff(props);
  const isOwnVolumeDiffHigher =
    ownVolumeDiff !== undefined &&
    aboAndOwnVolumeDiff !== undefined &&
    ownVolumeDiff > aboAndOwnVolumeDiff;

  if (hasAutoship(props) && isOwnVolumeDiffHigher) {
    return (
      <p>
        {t('Need {{points}} more points to achieve Check Assurance.', {
          points: formatNumberLocalized(activeLanguage, aboAndOwnVolumeDiff)
        })}
      </p>
    );
  }

  if (hasAutoship(props) && ownVolumeDiff !== undefined) {
    return (
      <p>
        {t('Need {{points}} more points to achieve Check Assurance.', {
          points: formatNumberLocalized(activeLanguage, ownVolumeDiff)
        })}
      </p>
    );
  }

  // If has reached autoship+own-volume target
  if (hasReachAboAndOwnVolume(props)) {
    return (
      <p>{t('Achieved the Check Assurance with a 100-point autoship.')}</p>
    );
  }

  if (isOwnVolumeDiffHigher) {
    return (
      <p>
        {t(
          'Need {{points}} more points and 100-points autoship to achieve Check Assurance.',
          { points: formatNumberLocalized(activeLanguage, aboAndOwnVolumeDiff) }
        )}
      </p>
    );
  }

  if (ownVolumeDiff !== undefined) {
    return (
      <p>
        {t('Need {{points}} more points to achieve Check Assurance.', {
          points: formatNumberLocalized(activeLanguage, ownVolumeDiff)
        })}
      </p>
    );
  }

  return null;
}

function hasCheckAssurance(
  checkAssuranceData: ICheckAssuranceStatusMessageProps
): boolean {
  return checkAssuranceData.percent === 100;
}

function hasAutoship(
  checkAssuranceData: ICheckAssuranceStatusMessageProps
): boolean {
  const ownAutoshipDetails = checkAssuranceData.options
    .flatMap(option => option.details)
    .find(opt => opt.type === 'own-autoship');

  if (ownAutoshipDetails === undefined) {
    return false;
  }

  return (
    computePositiveDiff(
      ownAutoshipDetails?.target,
      ownAutoshipDetails?.value
    ) === 0
  );
}

function hasReachAboAndOwnVolume(
  checkAssuranceData: ICheckAssuranceStatusMessageProps
): boolean {
  const aboAndOwnVolumeDiff = computeAboAndOwnVolumeDiff(checkAssuranceData);
  return aboAndOwnVolumeDiff === undefined ? false : aboAndOwnVolumeDiff === 0;
}

function computeOwnVolumeDiff(
  checkAssuranceData: ICheckAssuranceStatusMessageProps
): number | undefined {
  // For Check Assurance porpuses, we consider "own-volume" ONLY if it
  // is the only element in the array of details for a given option.
  // This happens because, if a given option has "own-volume" + something else
  // it is possible for the target of "own-volume" to be lower than the target
  // of the same type when being the only requirement of a given option.
  //
  // E.g. {
  //   options: [
  //     { details: [
  //         { type: 'own-volume', target: '100' }
  //     ]},
  //     { details: [
  //         { type: 'own-volume', target: '50' },
  //         { type: 'autoship+own-volume', target: '100' }
  //     ]}
  //   ]
  // }
  // In the example above, we want to "solo" one.
  const ownVolume = checkAssuranceData.options
    .filter(opt => opt.details.length === 1)
    .flatMap(opt => opt.details)
    .find(opt => opt.type === 'own-volume');

  // 'own-volume' is missing
  if (ownVolume === undefined) {
    return undefined;
  }

  return computePositiveDiff(ownVolume.target, ownVolume.value);
}

function computeAboAndOwnVolumeDiff(
  checkAssuranceData: ICheckAssuranceStatusMessageProps
): number | undefined {
  const aboAndOwnVolume = checkAssuranceData.options
    .map(opt => opt.details)
    .flat()
    .find(opt => opt.type === 'autoship+own-volume');

  if (aboAndOwnVolume === undefined) {
    // 'autoship+own-volume' is missing
    return undefined;
  }

  return computePositiveDiff(aboAndOwnVolume.target, aboAndOwnVolume.value);
}

function computePositiveDiff(target: number, value: number): number {
  const diffWith2DecimalCases = Math.round((target - value) * 100) / 100;
  return Math.max(diffWith2DecimalCases, 0);
}
