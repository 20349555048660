export function SeatFillIcon(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <path d="M5,6.45V8h6V6.5a3.54,3.54,0,0,1,3-3.444V2a2,2,0,0,0-2-2H4A2,2,0,0,0,2,2V3.065A3.524,3.524,0,0,1,5,6.45Z" />
        <path
          d="M14.5,5A1.538,1.538,0,0,0,13,6.5V10H3V6.5A1.538,1.538,0,0,0,1.5,5,1.538,1.538,0,0,0,0,6.5V13a1,1,0,0,0,1,1H2v2H4V14h8v2h2V14h1a1,1,0,0,0,1-1V6.5A1.538,1.538,0,0,0,14.5,5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
