import { AccountOrdersPage } from '@pmi.libs/react-common';
import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { useScreenEvents } from 'contexts';
import { AccountBackButton } from 'features/account';
import { useOrders } from 'features/account/orders';
import { OrdersList } from 'features/account/orders/OrdersList';
import { useTranslation } from 'libs/translations';

export function AccountOrdersRoute() {
  const { t } = useTranslation();
  const { lg } = useScreenEvents();

  return (
    <>
      <PageTitle />
      <AccountOrdersPage
        t={t}
        lg={lg}
        useOrders={useOrders}
        OrdersList={OrdersList}
        AccountBackButton={AccountBackButton}
      />
    </>
  );
}
