// eslint-disable-next-line no-restricted-imports
import type {
  IPageViewTelemetry,
  IMetricTelemetry
} from '@microsoft/applicationinsights-common';
// eslint-disable-next-line no-restricted-imports
import {
  ApplicationInsights,
  SeverityLevel
} from '@microsoft/applicationinsights-web';
// eslint-disable-next-line no-restricted-imports
import type {
  ICustomProperties,
  IEventTelemetry,
  ITelemetryItem
} from '@microsoft/applicationinsights-web';
import { IS_PWA } from 'contants';
import AuthenticationService from 'services/AuthenticationService';

let optionalTelemetryConsent = true;
let APP_INSIGHTS_INTANCE: ApplicationInsights | undefined;

export function initTelemetryCollector(): Promise<void> {
  if (APP_INSIGHTS_INTANCE) {
    APP_INSIGHTS_INTANCE.unload(false);
    APP_INSIGHTS_INTANCE = undefined;
  }

  APP_INSIGHTS_INTANCE = new ApplicationInsights({
    config: {
      // Client
      instrumentationKey: process.env.REACT_APP_AI_KEY as string,
      appId: process.env.REACT_APP_AI_APP_NAME,
      enableAutoRouteTracking: true
    }
  });

  APP_INSIGHTS_INTANCE.addTelemetryInitializer(telemetryInitializer);
  APP_INSIGHTS_INTANCE.loadAppInsights();
  trackPageView();
  console.info('AppInsights is enabled');

  return Promise.resolve();
}

export function setOptionalTelementryConsent(consent: boolean) {
  optionalTelemetryConsent = consent;
  trackEvent({
    name: 'TelemetryService__OptionalConsentUpdate',
    properties: {
      isOn: optionalTelemetryConsent
    }
  });
}

export function trackEvent(
  event: IEventTelemetry,
  customProperties?: ICustomProperties
) {
  if (optionalTelemetryConsent) {
    APP_INSIGHTS_INTANCE?.trackEvent(event, customProperties);
  }
}

export function trackException(
  err: unknown,
  from: string,
  details: string,
  customProperties?: Record<string, unknown>
) {
  const error =
    err instanceof Error
      ? (err as Error)
      : typeof err === 'string'
      ? new Error(err)
      : new Error('Unkown error.');

  APP_INSIGHTS_INTANCE?.trackException({
    error,
    exception: error,
    severityLevel: SeverityLevel.Error,
    properties: {
      from,
      details,
      type: error?.name,
      cause: error?.cause ?? undefined,
      rawErr: err,
      ...customProperties
    }
  });
}

export function trackPageView(pageView?: IPageViewTelemetry) {
  APP_INSIGHTS_INTANCE?.trackPageView(pageView);
}

export function trackMetric(metric: IMetricTelemetry) {
  APP_INSIGHTS_INTANCE?.trackMetric(metric);
}

function telemetryInitializer(envelope: ITelemetryItem) {
  try {
    if (envelope.baseData) {
      envelope.baseData.properties = {
        ...envelope.baseData.properties,
        userId: AuthenticationService.user.userId,
        currentQualification: AuthenticationService.user.qualification,
        rank: AuthenticationService.user.rank,
        isTeampartner: AuthenticationService.user.isTeampartner,
        isDiscountCustomer: AuthenticationService.user.isDiscountCustomer,
        isNewRegistration: AuthenticationService.user.isNewRegistration,
        isQuickstarter: AuthenticationService.user.isQuickStarter,
        userCountry: AuthenticationService.user.countryCode,
        pwa: IS_PWA
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (envelope as any).tags['ai.cloud.role'] = process.env.REACT_APP_AI_APP_NAME;
  } catch (err) {
    console.error('Failed to update telemetry envelope!', err);
  }
}
