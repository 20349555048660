import _ from 'lodash';

const IS_STANDALONE_NAVIGATOR =
  window.navigator && _.get(window.navigator, 'standalone');

const IS_STANDALONE_DISPLAYMODE = window.matchMedia(
  '(display-mode: standalone)'
).matches;

export const IS_PWA = IS_STANDALONE_NAVIGATOR || IS_STANDALONE_DISPLAYMODE;
