import { Month } from '@pmi.libs/utils-common';
import { useScreenEvents } from 'contexts';
import { IUserProfile } from 'types';

import { FirstlineDataViewerDesktop } from './FirstlineDataViewerDesktop';
import { FirstlineDataViewerMobile } from './FirstlineDataViewerMobile';

export interface IFirstlineDataViewerProps {
  readonly data: ReadonlyArray<IUserProfile>;
  readonly period: Month;
  readonly isReadonly?: boolean;
}

export function FirstlineDataViewer(props: IFirstlineDataViewerProps) {
  const { md } = useScreenEvents();

  if (!md) {
    return (
      <FirstlineDataViewerMobile
        period={props.period}
        data={props.data}
        isReadonly={props.isReadonly}
      />
    );
  }

  return (
    <FirstlineDataViewerDesktop
      period={props.period}
      data={props.data}
      isReadonly={props.isReadonly}
    />
  );
}
