import { BottomSheetContainer } from 'components/modals';
import { useAnalyticsContext } from 'features/analytics/contexts/AnalyticsContext';
import { useFiltersAndSortingContext } from 'features/analytics/contexts/FiltersAndSortingContext';
import { ISortFormValues } from 'features/analytics/types/ISortFormValues';
import { useTranslation } from 'libs/translations';
import { FormProvider, useForm } from 'react-hook-form';

import { SortByForm } from './SortByForm';

export function SortByDialog() {
  const { sortParams, setSortByValue } = useAnalyticsContext();
  const { closeDialog, isSortingDialogOpen } = useFiltersAndSortingContext();

  const sortbyMethods = useForm<ISortFormValues>({
    defaultValues: sortParams
  });

  const onSubmitSortby = (data: ISortFormValues) => {
    setSortByValue(data);
    closeDialog();
  };

  const { t } = useTranslation();

  return (
    <BottomSheetContainer
      open={isSortingDialogOpen}
      onDismiss={closeDialog}
      header={
        <div className="flex justify-center items-center py-sm">
          <h2 className="font-bold">{t('Filter by')}</h2>
        </div>
      }
    >
      <FormProvider {...sortbyMethods}>
        <form onSubmit={sortbyMethods.handleSubmit(onSubmitSortby)}>
          <SortByForm />
        </form>
      </FormProvider>
    </BottomSheetContainer>
  );
}
