import { ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import reactStringReplace from 'react-string-replace';

export function replaceStringWithComponent(
  source: string,
  replace: string,
  replacementCallback: (match: string, index?: number) => ReactNode
): ReactNode[] {
  return reactStringReplace(
    source,
    replace,
    replacementCallback
  ) as ReactNode[];
}
