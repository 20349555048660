import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { AccountBackButton } from 'features/account';
import {
  BonusPayouts,
  DirectCashPayouts,
  PMDirectCashSignupMarketingBanner,
  PMPayPlusMarketingBanner,
  PayoutsProvider,
  usePayoutsContext
} from 'features/payouts';

export function AccountPayoutsRoute() {
  return (
    <PayoutsProvider>
      <AccountPayoutsRouteContent />
    </PayoutsProvider>
  );
}

function AccountPayoutsRouteContent() {
  const { contextState } = usePayoutsContext();

  return (
    <>
      <PageTitle />
      <section className="space-y-md m-auto">
        <AccountBackButton />

        {contextState.loading ? (
          <section className="animate-pulse space-y-md">
            <div className="bg-lightGray shadow-md rounded-md w-full h-[150px]"></div>
            <div className="bg-lightGray shadow-md rounded-md w-full h-[150px]"></div>
          </section>
        ) : (
          <>
            <DirectCashPayouts />
            <BonusPayouts />
            <PMPayPlusMarketingBanner />
            <PMDirectCashSignupMarketingBanner />
          </>
        )}
      </section>
    </>
  );
}
