import { RadioGroup } from '@headlessui/react';
import { Modal } from 'components/dialogs';
import { usePopCardContext } from 'components/popcards/PopCardContext';
import { LoadingSpinner } from 'components/spinners';
import { Disclaimer } from 'components/text';
import { useGlobalConfigsContext } from 'contexts';
import { trackEvent, trackException } from 'libs/telemetry';
import { triggerErrorToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { Fragment, useMemo, useState } from 'react';
import AuthenticationService from 'services/AuthenticationService';
import { BonusPayoutMethod, DirectCashPayoutMethod } from 'types';

import { usePayoutsContext } from '../../contexts/PayoutsContext';
import { usePayoutsI18n } from '../../hooks/usePayoutsI18n';
import { PayoutOptionStatusIcon } from '../common/PayoutOptionStatusIcon';
import { PayquickerDirectCashLegalAgreement } from '../payquicker/PayquickerDirectCashLegalAgreement';
import { getPayQuickerExternalLink } from '../payquicker/PayquickerHelper';

export const DirectCashPayouts = () => {
  const { t } = useTranslation();
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();
  const { translatePayoutOptionType } = usePayoutsI18n();
  const { queuePopCard } = usePopCardContext();
  const { contextState, payoutDetails, updatePayouts } = usePayoutsContext();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [openPayquickerLegalAgreement, setOpenPayquickerLegalAgreement] =
    useState<boolean>(false);

  const hasAvailableMethods = useMemo(() => {
    return (
      (payoutDetails?.availableDirectCashPayoutMethods ?? []).length > 0 &&
      (payoutDetails?.availableDirectCashPayoutMethods ?? []).findIndex(
        method => method.locked === false
      ) !== -1
    );
  }, [payoutDetails?.availableDirectCashPayoutMethods]);

  const preselectedMethod = useMemo(() => {
    return payoutDetails?.directCashState.active
      ? payoutDetails?.selectedDirectCashPayoutMethod
      : undefined;
  }, [payoutDetails]);

  const pmPayPlusRegion = useMemo(() => {
    return payoutDetails?.availableDirectCashPayoutMethods.find(
      x => x.directCashPayoutMethod == DirectCashPayoutMethod.PayQuicker
    )?.region;
  }, [payoutDetails?.availableDirectCashPayoutMethods]);

  const onSelectionChange = async (dcMethod: DirectCashPayoutMethod) => {
    if (_.isUndefined(preselectedMethod) || preselectedMethod === dcMethod) {
      return;
    }

    const alreadyAcceptedPayquickerAgreement =
      payoutDetails?.selectedBonusPayoutMethod === BonusPayoutMethod.PayQuicker;

    if (
      dcMethod === DirectCashPayoutMethod.PayQuicker &&
      !alreadyAcceptedPayquickerAgreement
    ) {
      setOpenPayquickerLegalAgreement(true);
      trackEvent({
        name: 'DirectCashPayouts_OpenedPayQuickerAgreement'
      });
      return;
    }

    try {
      setLoading(true);
      await updatePayouts({
        activeDirectCashState: true,
        directCashMethod: dcMethod
      });
      trackEvent({
        name: 'DirectCashPayouts_OptionChanged',
        properties: {
          selectMethod: dcMethod
        }
      });
    } catch (err) {
      console.error('Failed to update PM DirectCash payout method', err);
      trackException(
        err,
        'DirectCashPayouts',
        `Failed while changing DC method to "${dcMethod}"`
      );
      triggerErrorToast(t('Something went wrong.'));
    } finally {
      setLoading(false);
    }
  };

  const refusePQAgreement = () => {
    setLoading(false);
    setOpenPayquickerLegalAgreement(false);
    trackEvent({
      name: 'DirectCashPayouts_RefusedPayQuickerAgreement'
    });
  };

  const acceptPQAgreement = async () => {
    setOpenPayquickerLegalAgreement(false);

    trackEvent({
      name: 'DirectCashPayouts_AcceptedPayQuickerAgreement'
    });

    try {
      setLoading(true);
      await updatePayouts({
        activeDirectCashState: true,
        directCashMethod: DirectCashPayoutMethod.PayQuicker
      });

      if (
        !_.isUndefined(AuthenticationService.user) &&
        !_.isUndefined(AuthenticationService.user.email)
      ) {
        queuePopCard({
          title: AuthenticationService.user.email,
          description: t(
            "You successfully upgraded to PM Pay+! However, your registration still needs to be completed. Along with your next payout, you will receive an email with further instructions on how to register. Don't forget to complete your registration so you can access your future payouts."
          ),
          id: _.uniqueId(),
          imgUri: '/imgs/promo/Email.svg',
          cta: t('Close'),
          ctaAction: _.noop
        });
      }

      trackEvent({
        name: 'DirectCashPayouts_OptionChanged',
        properties: {
          selectMethod: DirectCashPayoutMethod.PayQuicker
        }
      });
    } catch (err) {
      console.error('Failed to update PM DirectCash payout method', err);
      trackException(
        err,
        'DirectCashPayouts',
        `Failed while changing DC method to "Payquicker" after accepting legal agreement`
      );
      triggerErrorToast(t('Something went wrong.'));
    } finally {
      setLoading(false);
    }
  };

  const disableDirectCash = async () => {
    setLoading(true);

    try {
      await updatePayouts({ activeDirectCashState: false });
    } catch (err) {
      console.error('Failed to update PM DirectCash payout method', err);
      trackException(err, 'DirectCashPayouts', `Failed to disable direct cash`);
      triggerErrorToast(t('Something went wrong.'));
    } finally {
      setLoading(false);
    }
  };

  if (contextState.loading) {
    return null;
  }

  if (
    !payoutDetails ||
    payoutDetails.isDirectCashAvailable === false ||
    payoutDetails.directCashState.active === false ||
    _.isEmpty(payoutDetails.availableDirectCashPayoutMethods)
  ) {
    return null;
  }

  return (
    <section className="space-y-sm bg-white p-sm shadow-md rounded-md relative">
      {isLoading && (
        <div className="absolute inset-0 pmi-glass-white flex items-center justify-center">
          <LoadingSpinner smallSize />
        </div>
      )}

      <h2 className="text-primary">{t('PM DirectCash')}</h2>

      {!hasAvailableMethods && (
        <Disclaimer
          message={t(
            'There are no options available right now. Please come back later or contact the support team.'
          )}
        />
      )}

      {hasAvailableMethods && (
        <>
          <p>
            {t(
              'PM DirectCash allows you to receive cash immediately after a customer places an order through your webshop.'
            )}
          </p>

          <RadioGroup
            value={preselectedMethod}
            onChange={isLoading ? undefined : onSelectionChange}
            className={'mt-sm'}
          >
            {_.sortBy(payoutDetails.availableDirectCashPayoutMethods, [
              'locked'
            ]).map(dcMethod => (
              /* Use the `active` state to conditionally style the active option. */
              /* Use the `checked` state to conditionally style the checked option. */
              <RadioGroup.Option
                key={dcMethod.directCashPayoutMethod}
                value={dcMethod.directCashPayoutMethod}
                as={Fragment}
                disabled={dcMethod.locked}
              >
                {({ checked, disabled }) => (
                  <div
                    className={`transition-all list-none px-4 py-3 my-xs rounded-md relative ${
                      checked ? 'text-active bg-secondary' : ''
                    } ${
                      disabled
                        ? 'cursor-not-allowed text-silver bg-zinc-100'
                        : 'cursor-pointer'
                    } ${
                      supportsHover && !disabled
                        ? 'hover:bg-secondary hover:text-active'
                        : ''
                    }`}
                  >
                    <span className="flex flex-col">
                      <span className="flex flex-row items-center">
                        <PayoutOptionStatusIcon
                          checked={checked}
                          disabled={disabled}
                        />
                        <span className="flex-grow text-ellipsis overflow-hidden whitespace-nowrap">
                          {translatePayoutOptionType(
                            dcMethod.directCashPayoutMethod
                          )}
                        </span>
                      </span>
                      {checked &&
                        dcMethod.directCashPayoutMethod ===
                          DirectCashPayoutMethod.PayQuicker &&
                        payoutDetails.payQuickerAccount?.registrationStatus !==
                          'Completed' && (
                          <div className="mt-xs">
                            <Disclaimer
                              type="warning"
                              message={t(
                                "You successfully upgraded to PM Pay+! However, your registration still needs to be completed. Along with your next payout, you will receive an email with further instructions on how to register. Don't forget to complete your registration so you can access your future payouts."
                              )}
                            />
                          </div>
                        )}

                      {checked &&
                        dcMethod.directCashPayoutMethod ===
                          DirectCashPayoutMethod.PayQuicker &&
                        payoutDetails.payQuickerAccount?.registrationStatus ===
                          'Completed' && (
                          <a
                            className={`${
                              supportsHover
                                ? 'hover:text-active hover:underline'
                                : 'underline'
                            } mt-xs text-primary text-[12px] leading-[18px]`}
                            target="pmi_web_office__pmpayplus"
                            rel="noreferrer"
                            href={getPayQuickerExternalLink(pmPayPlusRegion)}
                            onClick={() => {
                              trackEvent({
                                name: 'DirectCashPayouts_OpenExternalPayquicker'
                              });

                              return true;
                            }}
                          >
                            {t('Go to your PM Pay+ account')}
                          </a>
                        )}
                    </span>
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>

          <div className="w-full text-left">
            <button
              className={`transition-all border border-transparent text-error p-xs`}
              onClick={disableDirectCash}
            >
              {t('I want to opt out from the PM DirectCash program')}
            </button>
          </div>
        </>
      )}
      <Modal
        hideCloseBtn
        open={openPayquickerLegalAgreement}
        onClose={() => setOpenPayquickerLegalAgreement(false)}
      >
        <PayquickerDirectCashLegalAgreement origin="directcash" />
        <div className="flex flex-row flex-wrap mt-md">
          <button
            className="rounded-full px-md py-sm bg-primary text-white"
            onClick={acceptPQAgreement}
          >
            {t('I accept')}
          </button>
          <button
            className={`text-primary ${
              supportsHover ? 'hover:text-active' : 'text-primary'
            }`}
            onClick={refusePQAgreement}
          >
            {t('Go back')}
          </button>
        </div>
      </Modal>
    </section>
  );
};
