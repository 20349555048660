import { Disclosure } from '@headlessui/react';
// eslint-disable-next-line no-restricted-imports
import { IoAddOutline, IoRemoveOutline } from 'react-icons/io5';

interface IFAQSectionProps {
  readonly title: string;
  readonly content: string;
}

export function FAQSection(props: IFAQSectionProps) {
  return (
    <Disclosure defaultOpen={false}>
      {({ open }) => {
        return (
          <div>
            <Disclosure.Button
              className={`${
                open ? 'pt-sm' : 'py-sm border-b border-lightGray'
              } w-full flex flex-nowrap gap-sm justify-between items-center text-left`}
            >
              {props.title}
              {open ? (
                <IoRemoveOutline size={16} />
              ) : (
                <IoAddOutline size={16} />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className="text-silver py-sm border-b border-lightGray">
              {props.content}
            </Disclosure.Panel>
          </div>
        );
      }}
    </Disclosure>
  );
}
