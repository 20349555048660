import { Timestamp } from '@pmi.libs/utils-common';
import { BasketOwner } from 'features/virtual-warehouse';

export function OnwerSelectionStepSummary({
  basketOwnerId
}: {
  readonly basketOwnerId: string;
}) {
  return (
    <BasketOwner
      basketOwnerId={basketOwnerId}
      basketCreadtedOn={Timestamp.now()}
    />
  );
}
