import { PMLogoWithClaim } from './PMLogoWithClaim';
import { PMLogoWithoutClaim } from './PMLogoWithoutClaim';

interface IPMLogoProps {
  readonly className?: string;
  readonly showClaim?: boolean;
}

export function PMLogo(props: IPMLogoProps) {
  const { className, showClaim = false } = props;

  if (showClaim) {
    return <PMLogoWithClaim className={className} />;
  }

  return <PMLogoWithoutClaim className={className} />;
}
