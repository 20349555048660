import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { WizardStepId } from 'features/shared-baskets/contexts/WizardStepId';
import { useBoolean } from 'hooks';
import { useTranslation } from 'libs/translations';
import { ReactNode, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { IoCheckmark, IoPencilSharp } from 'react-icons/io5';

import { OnwerSelectionStepSummary } from './01-owner-selection/OwnerSelectionStepSummary';
import { ProductSelectionStepSummary } from './02-product-selection/ProductSelectionStepSummary';

interface IWizarSectionWrapperProps {
  readonly step: WizardStepId;
  readonly title: string | ReactNode;
  readonly children: ReactNode;
  readonly active: boolean;
  readonly completed: boolean;
  readonly index: number;
  readonly onEdit?: () => void;
  readonly editOnDetails?: boolean;
  readonly headerActiveContent?: ReactNode;
}

export function WizardSectionWrapper({
  step,
  title,
  children,
  active,
  completed,
  index,
  onEdit,
  editOnDetails,
  headerActiveContent
}: IWizarSectionWrapperProps) {
  const { t } = useTranslation();

  const [
    isEditingDetails,
    { setFalse: stopEditingDetails, setTrue: startEditingDetails }
  ] = useBoolean(false);

  useEffect(() => {
    stopEditingDetails();
  }, [completed, stopEditingDetails]);

  return (
    <div className="bg-white h-fit rounded-md shadow-md">
      <div
        className={`p-sm rounded-md bg-white flex flex-nowrap gap-sm justify-between items-center ${
          active ? 'sticky top-0 z-1' : ''
        }`}
      >
        <h2 className="flex items-center line-clamp-1 gap-sm">
          <span
            className={`${
              completed ? 'bg-success' : 'bg-lightGray'
            } w-[24px] h-[24px] rounded-full flex items-center justify-center`}
          >
            {completed ? (
              <IoCheckmark size={20} className="text-white" />
            ) : (
              <span className="pmi-caption">{index}</span>
            )}
          </span>
          {typeof title === 'string' ? (
            <span className="">{title}</span>
          ) : (
            <div>{title}</div>
          )}
        </h2>
        {active && headerActiveContent && <>{headerActiveContent}</>}
        {completed && onEdit && (
          <button
            onClick={
              editOnDetails
                ? isEditingDetails
                  ? stopEditingDetails
                  : startEditingDetails
                : onEdit
            }
            className="flex flex-row flex-nowrap gap-xs items-center w-fit text-primary border border-transparent hover:text-active hover:border-active hover:bg-secondary hover:rounded-full px-sm py-xs transition-all"
          >
            {isEditingDetails ? null : <IoPencilSharp size={16} />}
            {isEditingDetails ? t('Done') : t('Edit')}
          </button>
        )}
      </div>

      {active ? (
        children && (
          <div className="p-sm border-t border-lightGray">{children}</div>
        )
      ) : (
        <StepDetails step={step} editInDetails={isEditingDetails} />
      )}
    </div>
  );
}

function StepDetails({
  step,
  editInDetails = false
}: {
  readonly step: WizardStepId;
  readonly editInDetails?: boolean;
}) {
  const { basketOwnerId, articles } = useNewBasketContext();
  let details: ReactNode | null = null;

  if (step === 'owner' && basketOwnerId) {
    details = <OnwerSelectionStepSummary basketOwnerId={basketOwnerId} />;
  }

  if (step === 'content' && articles.size > 0) {
    details = <ProductSelectionStepSummary editInDetails={editInDetails} />;
  }

  return details ? <div className="px-sm pb-sm">{details}</div> : null;
}
