import { CommitmentsList } from 'features/virtual-warehouse/components/commitments-list';
import { InventoryList } from 'features/virtual-warehouse/components/inventory/InventoryList';
import { Outlet } from 'react-router-dom';

export function VirtualWarehouseCommitmentsListRoute() {
  return (
    <section className="space-y-md">
      <InventoryList />
      <div>
        <CommitmentsList />
        <Outlet />
      </div>
    </section>
  );
}
