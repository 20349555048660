import _ from 'lodash';

export enum FirstlineDataProperty {
  CheckAssurance = 'CheckAssurance',
  StructureSales = 'StructureSales',
  PersonalSales = 'PersonalSales',
  SixLevelSales = 'SixLevelSales',
  Autoships = 'Autoships',
  CurrentQualification = 'CurrentQualification',
  PreviousQualification = 'PreviousQualification',
  HighestQualification = 'HighestQualification',
  FitlineClubMember = 'FitlineClubMember'
}

export function isValidFirstlineDataProperty(
  value: string
): FirstlineDataProperty | undefined {
  const acceptedValues = Object.values(FirstlineDataProperty);

  if (
    _.isUndefined(value) ||
    !acceptedValues.includes(value as FirstlineDataProperty)
  ) {
    return undefined;
  }

  return value as FirstlineDataProperty;
}
