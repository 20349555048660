import { ApiError } from 'errors';
import _ from 'lodash';
import { useEffect } from 'react';

import { FullScreenError } from './FullScreenError';
import { SomethingWentWrong } from './general-errors/SomethingWentWrong';
import { HttpError } from './http-errors/HttpError';

interface IErrorHandlerProps {
  readonly err?: unknown;
  readonly fullscreen?: boolean;
}

export function ErrorHandler(props: IErrorHandlerProps) {
  useEffect(() => {
    console.error(props.err);
  }, [props.err]);

  let errorComponent;

  if (!_.isUndefined(props.err) && !(props.err instanceof Error)) {
    errorComponent = <SomethingWentWrong err={props.err as Error} />;
  } else if (!_.isUndefined(props.err) && props.err.name === 'ApiError') {
    errorComponent = <HttpError err={props.err as ApiError} />;
  } else {
    errorComponent = <SomethingWentWrong err={props.err} />;
  }

  if (props.fullscreen) {
    return <FullScreenError>{errorComponent}</FullScreenError>;
  }

  return <>{errorComponent}</>;
}
