import { useAnalyticsContext } from 'features/analytics/contexts/AnalyticsContext';
import { useFiltersAndSortingContext } from 'features/analytics/contexts/FiltersAndSortingContext';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { IoSwapVerticalOutline } from 'react-icons/io5';

import { FilterSectionName } from '../filter-sections/FilterSectionName';

export function SortByButton() {
  const { hasSortByApplied } = useAnalyticsContext();
  const { openDialog } = useFiltersAndSortingContext();

  const { t } = useTranslation();

  return (
    <button
      onClick={() => openDialog(FilterSectionName.SortBy)}
      className="w-fit flex flex-row items-center justify-center flex-nowrap gap-xs line-clamp-1 text-ellipsis text-active font-bold relative"
    >
      <IoSwapVerticalOutline size={16} />
      {hasSortByApplied && (
        <div className="bg-red-600 w-[8px] h-[8px] rounded-full absolute inset-0 left-2.5 top-0.5"></div>
      )}
      <span className="relative">{t('Sort by')}</span>
    </button>
  );
}
