import { Badge } from 'components/badges';
import { useTranslation } from 'libs/translations';

export function QuickStarterBadge() {
  const { t } = useTranslation();

  return (
    <Badge
      title={t('Quickstarter')}
      tooltipDetailsContent={t(
        'A Quickstart happens when a Team Partner buys products worth 600 Points upon registration and immediately gets the Manager status.'
      )}
    />
  );
}
