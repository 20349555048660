export function UnknownDocFormatIcon() {
  return (
    <svg
      height="24"
      width="24"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z"
          fill="#E6E6E6"
        />
        <path d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z" fill="#B3B3B3" />
      </g>
    </svg>
  );
}
