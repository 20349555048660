import { IOrder } from 'types';

export function useOrderCommitments(order: IOrder): ReadonlyArray<string> {
  const relatedCommitments = Array.from(
    new Set(
      order.items.flatMap(i => i.commitments ?? []).map(i => i.commitmentId)
    )
  );

  return relatedCommitments;
}
