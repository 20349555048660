import type { ReactNode } from 'react';

interface IToastProps {
  readonly children: ReactNode;
}

export function Toast(props: IToastProps) {
  return (
    <div className="w-full flex flex-row space-x-sm text-sm font-app items-start">
      {props.children}
    </div>
  );
}

export function ToastContent(props: IToastProps) {
  return <div className="w-full flex flex-col">{props.children}</div>;
}
