import { DocumentCategory } from 'types';

import { Folder } from './Folder';
import { useDocumentI18n } from './hooks/useDocumentI18n';

interface IDocumentCategoryFolderProps {
  readonly category: DocumentCategory;
}

export function DocumentCategoryFolder(props: IDocumentCategoryFolderProps) {
  const { translateDocumentCategory } = useDocumentI18n();

  return (
    <span className="flex flex-row items-center">
      <Folder />
      <span className="ml-xs">{translateDocumentCategory(props.category)}</span>
    </span>
  );
}
