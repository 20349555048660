import { UnorderedList } from 'components/lists';
import { ProgressBar } from 'components/progress/progress-bar/ProgressBar';
import { ProgressWidget } from 'components/progress/progress-loop/ProgressWidget';
import { Disclaimer, FormattedUnitValue } from 'components/text';
import { Icons } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import { useMemo } from 'react';
import { IProgress } from 'types';

import { useProgressTypeI18n } from '../../hooks';

interface IWidgetCheckAssuranceDetailsProps {
  readonly percent: number;
  readonly options: ReadonlyArray<IProgress>;
}

export function WidgetCheckAssuranceDetails(
  props: IWidgetCheckAssuranceDetailsProps
) {
  const { t } = useTranslation();
  const progressTypeT = useProgressTypeI18n();

  const checkAssuranceProgressChart = useMemo(() => {
    return (
      <div className="flex flex-col items-center">
        <ProgressWidget percentage={props.percent} />
        {props.percent === 100 ? (
          <Icons.Common.Success size={24} className="text-success" />
        ) : (
          <Icons.Common.Cancel size={24} className="text-error" />
        )}
      </div>
    );
  }, [props.percent]);

  return (
    <>
      <div>{checkAssuranceProgressChart}</div>
      <div className="mt-md">
        <Disclaimer
          message={t(
            'To achieve Check Assurance, you must achieve at least one of the following options:'
          )}
        />
      </div>
      <ul className="mt-sm space-y-md">
        {props.options.map((opt, i) => {
          return (
            <li
              key={`ca-opt-${i}`}
              className={`first-of-type:mt-0 p-sm ${
                opt.percent === 100 ? 'bg-[#ebf5f1]' : ''
              }`}
            >
              <ProgressBar percentage={opt.percent} />
              <div className="mt-xs">
                <UnorderedList
                  items={opt.details.map(o => ({
                    leftNode:
                      o.value >= o.target ? (
                        <Icons.Common.Success
                          size={16}
                          className="text-success"
                        />
                      ) : (
                        <Icons.Common.Cancel size={16} className="text-error" />
                      ),
                    content: progressTypeT(o.type),
                    rightNode: (
                      <span className="flex flex-row text-right whitespace-nowrap">
                        <FormattedUnitValue value={o.value} unit={o.unit} />
                        /
                        <FormattedUnitValue value={o.target} unit={o.unit} />
                      </span>
                    )
                  }))}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}
