export function BoxOutlineIcon(props: { readonly size: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      className="flex-shrink-0 flex-grow-0"
    >
      <g
        strokeLinecap="round"
        strokeWidth={props.size < 16 ? '2' : '1'}
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
      >
        <line data-cap="butt" x1="6.5" y1="3.25" x2="18.5" y2="9.25"></line>
        <polyline data-cap="butt" points="23,7 13,12 1,6 "></polyline>
        <line data-cap="butt" x1="13" y1="12" x2="13" y2="23"></line>
        <polygon points="23,7 23,17 13,23 1,17 1,6 11,1 "></polygon>
      </g>
    </svg>
  );
}
