import { AreaChart } from 'components/charts/area/AreaChart';
import { FormattedUnitValue, TrendValue } from 'components/text';
import { FeatureIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { ITurnover, ITurnoverExtended } from 'types';
import { computePercentageTrend } from 'utils';

import { WidgetTurnoverDetails } from './WidgetTurnoverDetails';
import { Widget } from '../Widget';

type WidgetTurnoverProps = ITurnover &
  Partial<ITurnoverExtended> & {
    readonly title: string;
    readonly traceId: string;
    readonly icon: FeatureIconName;
    readonly tooltip: string;
  };

export function WidgetTurnover(props: WidgetTurnoverProps) {
  const { t } = useTranslation();

  const data = useMemo(() => {
    return [...props.history].sort((a, b) => {
      // Sort in ascending order
      return a.date.getTime() - b.date.getTime();
    });
  }, [props.history]);

  const { monthlyTrend, yearlyTrend } = useMemo(() => {
    return {
      monthlyTrend: !_.isUndefined(props.lastMonth)
        ? computePercentageTrend(props.current, props.lastMonth)
        : undefined,
      yearlyTrend: !_.isUndefined(props.lastYear)
        ? computePercentageTrend(props.current, props.lastYear)
        : undefined
    };
  }, [props]);

  const chart = useMemo(() => {
    const filteredData = _.takeRight(data, 12);
    const dataPoints = filteredData.map(h => ({
      label: '', // we do not show the label
      value: h.volume
    }));

    return (
      <div className="absolute bottom-0 left-0 h-1/2 w-full opacity-40 pointer-events-none">
        <AreaChart dataPoints={dataPoints} isIllustration />
      </div>
    );
  }, [data]);

  return (
    <Widget
      tooltip={props.tooltip}
      traceId={props.traceId}
      title={props.title}
      iconName={props.icon}
      detailedViewButtonLabel={t('History')}
      content={
        <>
          <h2 className="font-bold text-[18px] leading-[24px] sm:text-[24px] sm:leading-[36px] flex flex-row items-center">
            {!_.isUndefined(monthlyTrend) && (
              <TrendValue style="illustration-only" value={monthlyTrend} />
            )}
            <FormattedUnitValue unit="points" value={props.current} animate />
          </h2>
          {chart}
        </>
      }
      detailedView={
        <WidgetTurnoverDetails
          currentValue={props.current}
          monthlyTrend={monthlyTrend}
          yearlyTrend={yearlyTrend}
          lastMonth={props.lastMonth}
          lastYear={props.lastYear}
          historicalData={data}
        />
      }
    />
  );
}
