import { useQuery } from '@tanstack/react-query';
import { getEventStory } from 'services';

const QUERY_KEY = 'pmi.web.office__event-story';
const TTL = 900000; // 15 minutes in milliseconds

export function useEventStory(
  eventId: string,
  eventSlug: string | undefined,
  language: string | undefined
) {
  const query = useQuery({
    queryKey: [QUERY_KEY, eventSlug, language],
    queryFn: () => getEventStory(eventId, eventSlug ?? '', language ?? ''),
    staleTime: TTL,
    retry: false,
    enabled: !!eventSlug && !!language
  });

  return { ...query, error: query.error ?? undefined };
}
