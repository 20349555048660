import type { FC } from 'react';

export const ArrowDownIcon: FC<{ readonly size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
    className="flex-shrink-0 flex-grow-0"
  >
    <g fill="currentColor">
      <path
        d="M13.5,8H11V5a1,1,0,0,0-1-1H6A1,1,0,0,0,5,5V8H2.5a.5.5,0,0,0-.393.809l5.5,7a.518.518,0,0,0,.786,0l5.5-7A.5.5,0,0,0,13.5,8Z"
        fill="currentColor"
      />
      <path d="M6,2h4a1,1,0,0,0,0-2H6A1,1,0,0,0,6,2Z" />
    </g>
  </svg>
);
