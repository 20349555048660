import { useQuery } from '@tanstack/react-query';
import { getFirstlineTickets } from 'services';

const QUERY_KEY = 'pmi.web.office-firstline-tickets';
const TTL = 900000; // 15 minutes in milliseconds
const DEFAULT_PAGE_SIZE = 10;

export function useFirstlineTickets(
  eventId: string,
  userId: string | undefined,
  pageNumber: number
) {
  const query = useQuery({
    queryKey: [QUERY_KEY, eventId, userId, pageNumber],
    queryFn: () =>
      getFirstlineTickets(eventId, userId ?? '', {
        pageNumber,
        pageSize: DEFAULT_PAGE_SIZE
      }),
    staleTime: TTL,
    retry: false,
    enabled: !!userId
  });

  return { ...query, error: query.error ?? undefined };
}
