import { useQuery } from '@tanstack/react-query';
import { getWalletAccount } from 'services/wallet';

const TTL = 900000; // 15 minutes in milliseconds
const WALLET_QUERY_KEY = 'pmi.web.office__ledger_account';

export function useWalletAccount(userId: string) {
  const query = useQuery({
    queryKey: [WALLET_QUERY_KEY, userId],
    queryFn: () => getWalletAccount(userId),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
