/* eslint-disable no-restricted-imports */
import { useMemo } from 'react';
import { BsCartPlus } from 'react-icons/bs';
import {
  IoAdd,
  IoBagHandle,
  IoCardOutline,
  IoCartOutline,
  IoCheckmark,
  IoChevronBack,
  IoChevronDown,
  IoChevronForward,
  IoChevronUp,
  IoCopyOutline,
  IoDocumentsOutline,
  IoExitOutline,
  IoEyeOffOutline,
  IoEyeOutline,
  IoInformationCircleOutline,
  IoKeyOutline,
  IoLanguageOutline,
  IoLink,
  IoMegaphoneOutline,
  IoPerson,
  IoPersonAddOutline,
  IoPersonOutline,
  IoQrCode,
  IoRemove,
  IoSearchOutline,
  IoSettingsOutline,
  IoShareOutline,
  IoTrashOutline,
  IoVideocamOutline,
  IoWalletOutline,
  IoWarningOutline,
  IoReceiptOutline,
  IoCalendarClearOutline
} from 'react-icons/io5';

import { IconName } from '../IconName';
import { ArrowDownIcon } from './svgs/arrow-down';
import { ArrowRightIcon } from './svgs/arrow-right';
import { ArrowUpIcon } from './svgs/arrow-top';
import { BadgeOutlineIcon } from './svgs/badge-outline';
import { BanOutlineIcon } from './svgs/ban-outline';
import { BarChartIcon } from './svgs/bar-chart';
import { BasketAddOutlineIcon } from './svgs/basket-add-outline';
import { BasketOutlineIcon } from './svgs/basket-outline';
import { BoxOutlineIcon } from './svgs/box-outline';
import { BriefcaseOutlineIcon } from './svgs/briefcase-outline';
import { CalenderBackIcon } from './svgs/calendar-back';
import { CallOutlineIcon } from './svgs/call-outline';
import { CarOutlineIcon } from './svgs/car-outline';
import { CheckPaymentFillIcon } from './svgs/check-payment-fill';
import { ClockOutlineIcon } from './svgs/clock-outline';
import { CloseIcon } from './svgs/close';
import { CoachIcon } from './svgs/coach';
import { CoinsOutlineIcon } from './svgs/coins-outline';
import { CrossIcon } from './svgs/cross';
import { EmailOutlineIcon } from './svgs/email-outline';
import { FilterIcon } from './svgs/filter';
import { FitLineIcon } from './svgs/fitline';
import { FlagOutlineIcon } from './svgs/flag-outline';
import { GroupsFillIcon } from './svgs/groups-fill';
import { GroupsOutlineIcon } from './svgs/groups-outline';
import { HighestPriorityIcon } from './svgs/high-priority';
import { LocationOutlineIcon } from './svgs/location-outline';
import { MenuIcon } from './svgs/menu';
import { NetworkFillIcon } from './svgs/network-fill';
import { ParentAndChildIcon } from './svgs/parent-and-child-icon';
import { PercentageIcon } from './svgs/percetange';
import { PmIcon } from './svgs/pm-icon';
import { ScanIcon } from './svgs/scan-icon';
import { SeatFillIcon } from './svgs/seat-fill';
import { ShieldFillIcon } from './svgs/shield-fill';
import { SimpleArrowIcon } from './svgs/simple-arrow-icon';
import { SixCircleIcon } from './svgs/six-circle';
import { SortIcon } from './svgs/sort';
import { StarFillIcon } from './svgs/star-fill';
import { TicketOutlineIcon } from './svgs/ticket-outline';

interface IIconProps {
  /**
   * Determines if the icon should have a fill or be only outlined.
   */
  readonly fill?: boolean | undefined;
  readonly name: IconName;
  readonly className?: string | undefined;
  readonly size: 10 | 12 | 14 | 16 | 20 | 24 | 32 | 48 | 62;
}

function getIcon(icon: IconName, size: number, fill?: boolean): JSX.Element {
  switch (icon) {
    case IconName.Add:
      return <IoPersonAddOutline size={size} />;
    case IconName.ArrowDown:
      return <ArrowDownIcon size={size} />;
    case IconName.ArrowRight:
      return <ArrowRightIcon size={size} />;
    case IconName.ArrowUp:
      return <ArrowUpIcon size={size} />;
    case IconName.Badge:
      return <BadgeOutlineIcon size={size} />;
    case IconName.Ban:
      return <BanOutlineIcon size={size} />;
    case IconName.BarChart:
      return <BarChartIcon size={size} />;
    case IconName.BasketAdd:
      return <BasketAddOutlineIcon size={size} />;
    case IconName.Basket:
      return <BasketOutlineIcon size={size} />;
    case IconName.Box:
      return <BoxOutlineIcon size={size} />;
    case IconName.Briefcase:
      return <BriefcaseOutlineIcon size={size} />;
    case IconName.CalendarBack:
      return <CalenderBackIcon size={size} />;
    case IconName.Calendar:
      return <IoCalendarClearOutline size={size} />;
    case IconName.Call:
      return <CallOutlineIcon size={size} />;
    case IconName.Car:
      return <CarOutlineIcon size={size} />;
    case IconName.CheckMark:
      return <IoCheckmark size={size} />;
    case IconName.CheckPayment:
      return <CheckPaymentFillIcon size={size} />;
    case IconName.Clock:
      return <ClockOutlineIcon size={size} />;
    case IconName.Close:
      return <CloseIcon size={size} />;
    case IconName.Coach:
      return <CoachIcon size={size} />;
    case IconName.Coins:
      return <CoinsOutlineIcon size={size} />;
    case IconName.Copy:
      return <IoCopyOutline size={size} />;
    case IconName.CreditCard:
      return <IoCardOutline size={size} />;
    case IconName.Cross:
      return <CrossIcon size={size} />;
    case IconName.Documents:
      return <IoDocumentsOutline size={size} />;
    case IconName.Receipt:
      return <IoReceiptOutline size={size} />;
    case IconName.Email:
      return <EmailOutlineIcon size={size} />;
    case IconName.Exit:
      return <IoExitOutline size={size} />;
    case IconName.FitLine:
      return <FitLineIcon size={size} />;
    case IconName.Flag:
      return <FlagOutlineIcon size={size} />;
    case IconName.Gear:
      return <IoSettingsOutline size={size} />;
    case IconName.Groups:
      return fill ? (
        <GroupsFillIcon size={size} />
      ) : (
        <GroupsOutlineIcon size={size} />
      );
    case IconName.HighPriority:
      return <HighestPriorityIcon size={size} />;
    case IconName.Info:
      return <IoInformationCircleOutline size={size} />;
    case IconName.Key:
      return <IoKeyOutline size={size} />;
    case IconName.Link:
      return <IoLink size={size} />;
    case IconName.Location:
      return <LocationOutlineIcon size={size} />;
    case IconName.Language:
      return <IoLanguageOutline size={size} />;
    case IconName.Menu:
      return <MenuIcon size={size} />;
    case IconName.MoneyTransfer:
      return <IoWalletOutline size={size} />;
    case IconName.Network:
      return <NetworkFillIcon size={size} />;
    case IconName.ParentAndChild:
      return <ParentAndChildIcon size={size} />;
    case IconName.Percentage:
      return <PercentageIcon size={size} />;
    case IconName.Person:
      return fill ? <IoPerson size={size} /> : <IoPersonOutline size={size} />;
    case IconName.PM:
      return <PmIcon size={size} />;
    case IconName.QRCode:
      return <IoQrCode size={size} />;
    case IconName.Search:
      return <IoSearchOutline size={size} />;
    case IconName.Seat:
      return <SeatFillIcon size={size} />;
    case IconName.SixCircle:
      return <SixCircleIcon size={size} />;
    case IconName.Star:
      return <StarFillIcon size={size} />;
    case IconName.Ticket:
      return <TicketOutlineIcon size={size} />;
    case IconName.Warning:
      return <IoWarningOutline size={size} />;
    case IconName.Shield:
      return <ShieldFillIcon size={size} />;
    case IconName.EyeHide:
      return <IoEyeOffOutline size={size} />;
    case IconName.EyeShow:
      return <IoEyeOutline size={size} />;
    case IconName.ShoppingBag:
      return <IoBagHandle size={size} />;
    case IconName.Scan:
      return <ScanIcon size={size} />;
    case IconName.Filter:
      return <FilterIcon size={size} />;
    case IconName.Sort:
      return <SortIcon size={size} />;
    case IconName.SimpleArrowUp:
      return <SimpleArrowIcon size={size} direction="up" />;
    case IconName.SimpleArrowDown:
      return <SimpleArrowIcon size={size} direction="down" />;
    case IconName.Video:
      return <IoVideocamOutline size={size} />;
    case IconName.ShoppingCart:
      return <IoCartOutline size={size} />;
    case IconName.ShoppingCartWithPlusSign:
      return <BsCartPlus size={size} />;
    case IconName.TrashCan:
      return <IoTrashOutline size={size} />;
    case IconName.Share:
      return <IoShareOutline size={size} />;
    case IconName.ChevronLeft:
      return <IoChevronBack size={size} />;
    case IconName.ChevronRight:
      return <IoChevronForward size={size} />;
    case IconName.ChevronUp:
      return <IoChevronUp size={size} />;
    case IconName.ChevronDown:
      return <IoChevronDown size={size} />;
    case IconName.MinusSign:
      return <IoRemove size={size} />;
    case IconName.PlusSign:
      return <IoAdd size={size} />;
    case IconName.Megaphone:
      return <IoMegaphoneOutline size={size} />;
    default:
      return <></>;
  }
}

export function Icon(props: IIconProps) {
  const icon = useMemo(
    () => getIcon(props.name, props.size, props.fill),
    [props.name, props.size, props.fill]
  );

  return <span className={props.className}>{icon}</span>;
}
