import { ErrorHandler } from 'components/errors/ErrorHandler';
import { LogoSplashScreen } from 'components/splash-screen/LogoSplashScreen';
import { useGlobalConfigsContext } from 'contexts';
import { useBoolean } from 'hooks';
import { trackException, initTelemetryCollector } from 'libs/telemetry';
import {
  changeTranslationsLanguage,
  initTranslations
} from 'libs/translations';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

interface IServiceBootstrapperProps {
  readonly children: ReactNode;
}

export function ServiceBootstrapper(props: IServiceBootstrapperProps) {
  const {
    app: {
      me: { preferedLanguage }
    }
  } = useGlobalConfigsContext();

  const [error, setError] = useState<unknown>();
  const [hasServicesRunning, { setTrue: markServicesAsRunning }] =
    useBoolean(false);
  const [hasServicesConfigured, { setTrue: markServicesAsConfigured }] =
    useBoolean(false);

  useEffect(() => {
    Promise.all([initTelemetryCollector(), initTranslations()])
      .then(() => markServicesAsRunning())
      .catch(err => setError(err));
  }, [markServicesAsRunning]);

  useEffect(() => {
    if (hasServicesRunning && preferedLanguage) {
      changeTranslationsLanguage(preferedLanguage)
        .then(() => markServicesAsConfigured())
        .catch(err => setError(err));
    }
  }, [hasServicesRunning, preferedLanguage, markServicesAsConfigured]);

  if (!_.isUndefined(error)) {
    return <ServiceBootstrapperError error={error} />;
  }

  if (!hasServicesRunning || !hasServicesConfigured) {
    return <LogoSplashScreen loading />;
  }

  return <>{props.children}</>;
}

interface IServiceBootstrapperErrorProps {
  readonly error: unknown;
}

function ServiceBootstrapperError(props: IServiceBootstrapperErrorProps) {
  useEffect(() => {
    // It will only track the exception in case AppInsights has started
    trackException(
      props.error,
      'ServiceBootstrapper.tsx',
      'Failed to start application'
    );

    console.error('Startup failure', props.error);
  }, [props.error]);

  return <ErrorHandler err={props.error} fullscreen />;
}
