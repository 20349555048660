import { ReactNode } from 'react';

interface IInvoiceSectionProps {
  readonly children: ReactNode;
}

export function InvoiceSection(props: IInvoiceSectionProps) {
  return (
    <div className="grid grid-cols-1 md:max-lg:grid-cols-2 lg:max-xl:grid-colis-1 xl:grid-cols-2 gap-lg">
      {props.children}
    </div>
  );
}
