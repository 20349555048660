import { UserHeader, UserHeaderSkeleton } from 'components/headers';
import { Profile } from 'components/Profile';
import { useScreenEvents, useGlobalConfigsContext } from 'contexts';
import { ApiError } from 'errors';
import { DashboardLoader } from 'features/dashboard';
import { useDownlineContext } from 'features/downline-explorer/DownlineContext';
import { Icon, IconName } from 'libs/icons';
import { trackEvent } from 'libs/telemetry';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchUserProfile } from 'services';
import AuthenticationService from 'services/AuthenticationService';
import { IUserProfile } from 'types';
import { decodeAsArray } from 'utils';

export function DownlineUserDashboardRoute() {
  const navigate = useNavigate();
  const { historicalMonths, selectedPeriod } = useDownlineContext();
  const { t } = useTranslation();
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();
  const { encodedUsersSelectedPath } = useParams();
  const { sm } = useScreenEvents();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [showUnavailableUserErrorMsg, setUnavailableUserErrorMsg] =
    useState<boolean>(false);
  const [isOpenUserProfile, setOpenUserProfile] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<IUserProfile>();
  const [isBottomSheetContainerOpen, setBottomSheetContainerOpen] =
    useState<boolean>(false);

  const userId = useMemo(() => {
    if (_.isUndefined(encodedUsersSelectedPath)) {
      return undefined;
    }

    return decodeAsArray(encodedUsersSelectedPath).pop();
  }, [encodedUsersSelectedPath]);

  const teampartnerUpTo3rdLevelOrFirstlineCustomer = useMemo(() => {
    const isFirstlineCustomer =
      !userProfile?.isDiscountCustomer &&
      !userProfile?.isTeampartner &&
      userProfile?.sponsor.userId === AuthenticationService.user.userId;

    const isTeampartnerUpTo3Level =
      (userProfile?.isDiscountCustomer || userProfile?.isTeampartner) &&
      [1, 2, 3].includes(userProfile.depthLevel);

    return isFirstlineCustomer || isTeampartnerUpTo3Level;
  }, [
    userProfile?.depthLevel,
    userProfile?.isDiscountCustomer,
    userProfile?.isTeampartner,
    userProfile?.sponsor.userId
  ]);

  useEffect(() => {
    if (sm && isBottomSheetContainerOpen) {
      setBottomSheetContainerOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sm]);

  useEffect(() => {
    if (_.isUndefined(userId)) {
      return;
    }

    setLoading(true);
    setUnavailableUserErrorMsg(false);
    setUserProfile(undefined);

    fetchUserProfile(userId)
      .then(userProfile => {
        setUserProfile(userProfile);
      })
      .catch(err => {
        if (
          err instanceof Error &&
          err.name === 'ApiError' &&
          (err as ApiError).status === 403
        ) {
          // Probably, this user is trying to access a country node which he cannot.
          // Show a nice message in the screen in this case.
          setUnavailableUserErrorMsg(true);
          return;
        }

        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);

  if (_.isUndefined(userId)) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="w-full animate-pulse space-y-8">
        <UserHeaderSkeleton />
      </div>
    );
  }

  if (showUnavailableUserErrorMsg) {
    return null;
  }

  if (_.isUndefined(userProfile)) {
    return null;
  }

  return (
    <section className="flex flex-col w-full">
      <header className="mb-sm">
        <div className="flex flex-row flex-wrap w-full items-center justify-between px-sm">
          <UserHeader
            user={userProfile}
            showBadges
            showQualification
            showCheckAssurance
            showNewRegistration
            showCountryFlag
            disableNavigation
          />
          {userProfile.userId !== AuthenticationService.user.userId && (
            <div className="flex flex-row gap-lg flex-wrap">
              {teampartnerUpTo3rdLevelOrFirstlineCustomer && (
                <button
                  onClick={() => {
                    trackEvent({
                      name: 'DownlineShareBasketButtonClick'
                    });

                    if (userProfile.isTeampartner) {
                      navigate(
                        `/sales/createbasket?teampartnerId=${userProfile.userId}`
                      );
                    } else {
                      navigate(
                        `/sales/createbasket?customerId=${userProfile.userId}`
                      );
                    }
                  }}
                  className={`w-fit flex flex-row items-center gap-xxs ${
                    supportsHover
                      ? 'hover:text-active hover:bg-secondary hover:shadow-md'
                      : 'text-primary underline'
                  } transition-all px-sm py-xs -mx-sm -my-xs rounded-md`}
                >
                  <Icon
                    name={IconName.Basket}
                    size={16}
                    className="inline-block mr-xs"
                  />
                  <span className="hidden md:block">{t('Share Basket')}</span>
                </button>
              )}

              <button
                onClick={() => {
                  trackEvent({
                    name: 'DownlineUserProfileButtonClick'
                  });
                  setOpenUserProfile(true);
                }}
                className={`w-fit flex flex-row items-center gap-xxs ${
                  supportsHover
                    ? 'hover:text-active hover:bg-secondary hover:shadow-md'
                    : 'text-primary underline'
                } transition-all px-sm py-xs -mx-sm -my-xs rounded-md`}
              >
                <Icon
                  name={IconName.Badge}
                  size={16}
                  className="inline-block mr-xs"
                />
                <span className="hidden md:block">{t('User profile')}</span>
              </button>
            </div>
          )}
        </div>
      </header>
      <Profile
        userId={userId}
        onDismiss={() => setOpenUserProfile(false)}
        open={isOpenUserProfile}
      />
      <DashboardLoader
        userId={userId}
        dashboardMonth={selectedPeriod}
        historicalMonths={historicalMonths}
      />
    </section>
  );
}
