import { useFilterPeriod } from 'features/analytics/hooks/useFilterPeriod';
import { useTranslation } from 'libs/translations';
import { useFormContext } from 'react-hook-form';
// eslint-disable-next-line no-restricted-imports
import { Trans } from 'react-i18next';

export function FitLineClubMemberFilter() {
  const { register } = useFormContext();
  const { t, activeLanguage } = useTranslation();
  const currentPeriod = useFilterPeriod();

  return (
    <fieldset>
      <legend>
        <Trans
          i18nKey={'analytics.fitline-club-member-filter-title'}
          values={{
            period: currentPeriod.toLocaleString(activeLanguage)
          }}
          components={{ bold: <strong /> }}
          defaults="FitLine Club members in <bold>{{period}}</bold>?"
        />
      </legend>
      <label>
        <input type="radio" {...register('fitlineClubMembers')} value={''} />
        <span>{t('All')}</span>
      </label>
      <label>
        <input
          type="radio"
          {...register('fitlineClubMembers')}
          value={'true'}
        />
        <span>{t('Members only')}</span>
      </label>
      <label>
        <input
          type="radio"
          {...register('fitlineClubMembers')}
          value={'false'}
        />
        <span>{t('Non members')}</span>
      </label>
    </fieldset>
  );
}
