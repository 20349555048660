import { IS_PROD_BUILD } from 'contants';
import { useCallback } from 'react';
import AuthenticationService from 'services/AuthenticationService';

import { useLocalStorage } from './useLocalStorage';

const PREVIEW_UI_STORAGE_KEY = 'pmi.web.office-preview-ui';

export function useSuperUserConfigs() {
  const [isPreviewUiEnabled, setPreviewUIEnabled] = useLocalStorage(
    PREVIEW_UI_STORAGE_KEY
  );

  const isImpersonator = AuthenticationService.isImpersonator;
  const isPreviewUser = !IS_PROD_BUILD || isImpersonator;
  const isPreviewEnabled = isPreviewUser && isPreviewUiEnabled === '1';

  const setPreviewMode = useCallback(
    (enable: boolean) => {
      setPreviewUIEnabled(enable ? '1' : undefined);
    },
    [setPreviewUIEnabled]
  );

  return {
    isImpersonator,
    isPreviewUser,
    isPreviewEnabled,
    setPreviewMode
  };
}
