import _ from 'lodash';
import { ReactNode } from 'react';
import { IDocument } from 'types';

import { DocFormatPdfIcon } from './DocFormatPdfIcon';
import { UnknownDocFormatIcon } from './UnknownDocFormatIcon';

export function DocFormatIcon(props: IDocument) {
  const extension = props.filename.split('.').pop()?.toLowerCase();

  let element: ReactNode;

  if (_.isEqual(extension, 'pdf')) {
    element = <DocFormatPdfIcon />;
  } else {
    element = <UnknownDocFormatIcon />;
  }

  return <span className="flex-shrink-0">{element}</span>;
}
