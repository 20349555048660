import { Month } from '@pmi.libs/utils-common';
import { LoadingSpinner } from 'components/spinners';
import { useGlobalConfigsContext } from 'contexts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { fetchFirstlineOf } from 'services';
import { IUserProfile } from 'types';

import { FirstlineDataViewer } from './FirstlineDataViewer';
import { FirstlineTableSkeleton } from './FirstlineTableSkeleton';

interface IFirstlineDetailsProps {
  readonly userId: string;
  readonly period: Month;
}

export function FirstlineDetails(props: IFirstlineDetailsProps) {
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [records, setRecords] = useState<ReadonlyArray<IUserProfile>>();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    fetchFirstlineOf(props.userId, {
      pageSize: 100,
      pageNumber,
      period: props.period
    })
      .then(firstlinePage => {
        setRecords([...(records ?? []), ...firstlinePage.data]);
        setHasNextPage(firstlinePage.hasNextPage);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, props.userId]);

  if (isLoading && _.isUndefined(records)) {
    return (
      <section className="animate-pulse">
        <FirstlineTableSkeleton />
      </section>
    );
  }

  return (
    <>
      <section className="overflow-auto">
        {!_.isUndefined(records) && (
          <FirstlineDataViewer data={records} period={props.period} />
        )}
      </section>
      <div className="w-full text-center my-sm text-primary">
        {hasNextPage && !isLoading && (
          <button
            onClick={() =>
              setPageNumber(pageNumberState => pageNumberState + 1)
            }
            className={`${
              supportsHover
                ? 'hover:text-active hover:bg-secondary'
                : 'underline text-primary'
            } transition-all px-sm py-xs -mx-sm -my-xs`}
          >
            {t('Load more')}
          </button>
        )}
        {isLoading && (
          <div className="flex flex-row justify-center">
            <LoadingSpinner smallSize />
          </div>
        )}
      </div>
    </>
  );
}
