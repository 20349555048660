import type { FC } from 'react';

export const CloseIcon: FC<{ readonly size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="flex-shrink-0 flex-grow-0"
    viewBox="0 0 16 16"
    width={size}
    height={size}
  >
    <g fill="currentColor" stroke="currentColor" strokeWidth="1">
      <line
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="13.5"
        x2="2.5"
        y1="2.5"
        y2="13.5"
      />
      <line
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="2.5"
        x2="13.5"
        y1="2.5"
        y2="13.5"
      />
    </g>
  </svg>
);
