import { IBasket } from 'types';

import { BasketCard } from './BasketCard';

export function BasketsListItem(basket: IBasket) {
  return (
    <li className="bg-white shadow-md rounded-md">
      <BasketCard {...basket} />
    </li>
  );
}
