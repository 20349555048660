import { HelperModal } from 'components/dialogs';
import { useBoolean } from 'hooks';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { PiSealCheckFill } from 'react-icons/pi';
import { IUserProfile } from 'types';

export function FitlineClubMemberColumn(props: {
  readonly userProfile: IUserProfile;
}) {
  if (props.userProfile.isFitlineClubMember) {
    return <FitlineClubMember />;
  }

  return <span></span>;
}

function FitlineClubMember() {
  const { t } = useTranslation();

  const [
    isOpenHelperModal,
    { setFalse: closeHelperModal, setTrue: openHelperModal }
  ] = useBoolean(false);

  return (
    <>
      <button
        className="cursor-help inline-block"
        title={t('FitLine Club member')}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          openHelperModal();
        }}
      >
        <PiSealCheckFill size={20} className="text-success" />
      </button>

      {isOpenHelperModal && (
        <HelperModal onDismiss={closeHelperModal}>
          <div className="flex flex-row items-center justify-center flex-wrap gap-xs">
            <PiSealCheckFill size={20} className="text-success" />
            <p>{t('FitLine Club member')}</p>
          </div>
        </HelperModal>
      )}
    </>
  );
}
