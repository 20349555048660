import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import { useNavigate } from 'react-router-dom';

import './AccountBackButton.scss';

export function AccountBackButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <button
      title={t('Account')}
      className="account-back-button"
      onClick={() => navigate('/account')}
    >
      <Icon name={IconName.ChevronLeft} size={16} />
      <span className="ml-xs">{t('Account')}</span>
    </button>
  );
}
