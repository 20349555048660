export function Payment(props: { readonly size: number }) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.9734 9V5.625C26.9734 3.55397 25.2944 1.875 23.2234 1.875H8.22339C6.15236 1.875 4.47339 3.55397 4.47339 5.625V42.375C4.47339 44.446 6.15236 46.125 8.22339 46.125H23.2234C25.2944 46.125 26.9734 44.446 26.9734 42.375V39M15.7234 46.125V42.375M15.7234 1.875V5.625"
        stroke="#A6A9AC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7233 30.0002C13.4074 30.911 14.4966 31.5 15.7234 31.5M15.7234 31.5C17.7944 31.5 19.4734 29.821 19.4734 27.75C19.4734 25.679 17.7944 24 15.7234 24C13.6524 24 11.9734 22.321 11.9734 20.25C11.9734 18.179 13.6524 16.5 15.7234 16.5M15.7234 31.5V35.25M15.7234 16.5C16.9501 16.5 18.0393 17.089 18.7235 17.9998M15.7234 16.5V12.75M29.17 16.5L25.42 20.25L29.17 24M26.9734 20.25H41.9734M39.7766 27.75L43.5266 31.5L39.7766 35.25M41.9734 31.5H26.9734"
        stroke="#A6A9AC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
