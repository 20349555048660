import { AreaChart } from 'components/charts/area/AreaChart';
import { usePII } from 'features/pii';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUserPayoutData } from 'types';
import { formatCurrencyLocalized } from 'utils';

import './WidgetPayoutsDetails.scss';

export function WidgetPayoutsDetails(props: IUserPayoutData) {
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();

  const historicalAreaChart = useMemo(() => {
    const data = [...(props.payoutHistory ?? [])].sort((a, b) => {
      // Sort in ascending order
      return a.date.getTime() - b.date.getTime();
    });

    const dataPoints = data.map(h => ({
      label: h.date.toLocaleString(activeLanguage),
      value: h.volume
    }));

    return <AreaChart yAxesLabel={t('Payouts')} dataPoints={dataPoints} />;
  }, [props, activeLanguage, t]);

  if (
    _.isUndefined(props.payoutCurrency) ||
    _.isUndefined(props.payoutCurrentValue) ||
    _.isUndefined(props.payoutHistory)
  ) {
    return null;
  }

  return (
    <section className="text-center">
      <h1 className="font-app font-bold">
        {pii(
          formatCurrencyLocalized(
            activeLanguage,
            props.payoutCurrentValue,
            props.payoutCurrency
          ),
          'currency'
        )}
      </h1>
      <div className="mt-sm">{historicalAreaChart}</div>
      <div className="mt-md flex flex-col items-center gap-sm">
        <AccountPayoustButton />
        <AccountBonusDocumentsButton />
      </div>
    </section>
  );
}

function AccountPayoustButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <button
      title={t('Configure your payouts')}
      className="payoutswidget-redirect-button"
      onClick={() => navigate('/account/payouts')}
    >
      <Icon name={IconName.MoneyTransfer} size={16} />
      {t('Configure your payouts')}
    </button>
  );
}

function AccountBonusDocumentsButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <button
      title={t('Check your Bonus statements')}
      className="payoutswidget-redirect-button"
      onClick={() => navigate('/account/documents?filter=Bonus')}
    >
      <Icon name={IconName.Documents} size={16} />
      {t('Check your Bonus statements')}
    </button>
  );
}
