import { Icon, IconName } from 'libs/icons';

import { Toast, ToastContent } from './Toast';

interface IWarningToastProps {
  readonly description: string;
}

export function WarningToast(props: IWarningToastProps) {
  return (
    <Toast>
      <Icon name={IconName.Warning} className="text-warn" size={16} />
      <ToastContent>
        <p className={'whitespace-pre-wrap text-warn'}>{props.description}</p>
      </ToastContent>
    </Toast>
  );
}
