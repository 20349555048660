import { useQuery } from '@tanstack/react-query';
import { getBaskets } from 'services';

import { BASKETS_QUERY_KEY, TTL } from './BasketQueryConfig';

export function useBasketList(page: number, perPage: number, locale: string) {
  const query = useQuery({
    queryKey: [BASKETS_QUERY_KEY, page, perPage, locale],
    queryFn: () => getBaskets(page, perPage, locale),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
