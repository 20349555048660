import { useTranslation } from 'libs/translations';
import { PayquickerLink } from 'types';

export function usePayquickerLinkI18n() {
  const { t } = useTranslation();

  const translate = (link: PayquickerLink) => {
    switch (link) {
      case PayquickerLink.Benefits:
        return t('Your Benefits');
      case PayquickerLink.FAQs:
        return t('FAQs');
      case PayquickerLink.Fees:
        return t('Fees');
      case PayquickerLink.Verification:
        return t('Identity Verification');
      case PayquickerLink.Introduction:
        return t('Introduction');
      case PayquickerLink.Support:
        return t('Help');
    }
  };

  return translate;
}
