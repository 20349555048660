export function ParentAndChildIcon(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path
          d="M13,23h10v-8.8 c0-0.739-0.407-1.417-1.059-1.765l-4.007-2.15"
          fill="none"
        />
        <path
          d="M15.5,11 L15.5,11C13.015,11,11,8.985,11,6.5v-1C11,3.015,13.015,1,15.5,1h0C17.985,1,20,3.015,20,5.5v1C20,8.985,17.985,11,15.5,11z"
          fill="none"
        />
        <path
          d="M4.592,17.195l-2.487,1.253 C1.428,18.786,1,19.479,1,20.236V23h12v-2.764c0-0.758-0.428-1.45-1.106-1.789l-2.487-1.253"
          fill="none"
          stroke="currentColor"
        />
        <path
          d="M7,18L7,18 c-2.209,0-4-1.791-4-4v-1c0-2.209,1.791-4,4-4h0c2.209,0,4,1.791,4,4v1C11,16.209,9.209,18,7,18z"
          fill="none"
          stroke="currentColor"
        />
        <line fill="none" x1="13.077" x2="10.727" y1="10.292" y2="11.545" />
      </g>
    </svg>
  );
}
