import { Timestamp } from '@pmi.libs/utils-common';
import { IPopCard } from 'components/popcards/IPopCard';
import { usePopCardContext } from 'components/popcards/PopCardContext';
import { useGlobalConfigsContext } from 'contexts';
import { usePatchOfficeServerState } from 'hooks';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect } from 'react';

export function WelcomeToPMOffice() {
  const { t } = useTranslation();
  const {
    app: {
      office: { welcomeMessageDisplayDate }
    }
  } = useGlobalConfigsContext();
  const { patchPreferences } = usePatchOfficeServerState();
  const { queuePopCard } = usePopCardContext();

  useEffect(() => {
    if (_.isUndefined(welcomeMessageDisplayDate)) {
      const welcomeMessage: IPopCard = {
        title: t('Introducing PM Office'),
        description: t(
          "We're introducing the ultimate solution for Team Partners! Keep track of your business performance, gain valuable insights, and drive growth with our state-of-the-art platform built on cutting-edge technology!"
        ),
        descriptionList: [
          t('Check your business metrics'),
          t("Effortlessly track your team's progress"),
          t('Manage your personal data'),
          t('And much more!')
        ],
        id: 'pmi.web.office-popcard--welcome',
        fireworks: 'blue',
        cta: t('Try it now!'),
        ctaAction: () =>
          patchPreferences({
            welcomeMessageDisplayDate: Timestamp.now()
          }),
        imgUri: '/imgs/promo/New.svg'
      };

      queuePopCard(welcomeMessage);
    }
  }, [patchPreferences, queuePopCard, t, welcomeMessageDisplayDate]);

  return null;
}
