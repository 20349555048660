import _ from 'lodash';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

import { IPopCard } from './IPopCard';
import { PopCardContainer } from './PopCardContainer';

interface IPopCardContext {
  closePopCard: (id: string) => void;
  queuePopCard: (popcard: IPopCard) => void;
}

export const PopCardContext = createContext<IPopCardContext>({
  closePopCard: _.noop,
  queuePopCard: _.noop
});

interface IPopCardContextProviderProps {
  readonly children: ReactNode;
}

/**
 * @deprecated TODO: replace with portal implementation where new cards
 * can be triggered to be shown on deman. Just like toast messages
 */
export function PopCardContextProvider(props: IPopCardContextProviderProps) {
  const [displayedPopcardIds, setDisplayedPopCardIds] = useState<Set<string>>(
    new Set()
  );
  const [popcards, setPopCards] = useState<IPopCard[]>([]);
  const popcardToRender: IPopCard | undefined = useMemo(
    () => popcards[0],
    [popcards]
  );

  const closePopCard = (popcardId: string) => {
    if (popcardId !== popcardToRender?.id) {
      return;
    }

    setDisplayedPopCardIds(displayedPopcardIdsInState => {
      return displayedPopcardIdsInState.add(popcardId);
    });
    setPopCards(popcardsInState => {
      return _.dropWhile(popcardsInState, p => p.id === popcardId);
    });
  };

  const queuePopCard = (popcard: IPopCard) => {
    if (
      !_.isUndefined(popcards.find(card => card.id === popcardToRender?.id)) ||
      displayedPopcardIds.has(popcard.id)
    ) {
      return;
    }

    setPopCards(popcardsInState => {
      return [...popcardsInState, popcard];
    });
  };

  return (
    <PopCardContext.Provider
      value={{
        closePopCard,
        queuePopCard
      }}
    >
      <>{props.children}</>
      {!_.isUndefined(popcardToRender) && (
        <PopCardContainer key={popcardToRender.id} popcard={popcardToRender} />
      )}
    </PopCardContext.Provider>
  );
}

/**
 * @deprecated replace with a trigger function that will render the content
 * in the newly created portal
 */
export function usePopCardContext() {
  const context = useContext(PopCardContext);

  if (context === undefined) {
    throw new Error('usePopCardContext must be used within PopCardContext');
  }

  return context;
}
