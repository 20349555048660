import { NewBasketState } from './NewBasketState';
import { WizardStepId } from './WizardStepId';

export const StepSequence: ReadonlyArray<WizardStepId> = [
  'owner',
  'content',
  'summary',
  'done'
];

export const DefaultStep: NewBasketState = {
  id: 'owner',
  availableCountryShops: [],
  allowedBasketOnwerType: ['BusinessBuilder', 'Customer', 'Prospect'],

  basketOwnerId: undefined,
  basketOwnerCountry: undefined,
  isBasketOwnerCustomer: false,
  isBasketOwnerTeampartner: false,
  isBasketOwnerProspect: false,

  articles: new Map(),
  checkoutUrl: undefined,

  shopId: undefined,
  isAffiliateDiscountEnabled: false
};
