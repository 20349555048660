import { DateTime } from 'luxon';

import { FixedDate } from './FixedDate';

export class Timestamp {
  private _date: DateTime;

  private constructor(value: string | number) {
    this._date = DateTime.fromJSDate(new Date(value)).setZone('utc');

    // this._date = DateTime.fromISO(value, { zone: 'utc' });
  }

  static now() {
    return new Timestamp(DateTime.utc().toString());
  }

  static create(value: string | number): Timestamp | undefined {
    const date = new Timestamp(value);

    if (!date._date.isValid) {
      return undefined;
    }

    return date;
  }

  static createOrThrow(value: string | number): Timestamp {
    const result = this.create(value);
    if (result === void 0) {
      throw new Error(`Invalid date could not be parsed: ${value}`);
    }

    return result;
  }

  static diffInMonths(ts1: Timestamp | FixedDate, ts2: Timestamp | FixedDate) {
    let dateToMonth: number,
      dateToYear: number,
      dateFromMonth: number,
      dateFromYear: number;

    if (ts1.getTime() >= ts2.getTime()) {
      dateFromMonth = ts2.month;
      dateFromYear = ts2.year;

      dateToMonth = ts1.month;
      dateToYear = ts1.year;
    } else {
      dateFromMonth = ts1.month;
      dateFromYear = ts1.year;

      dateToMonth = ts2.month;
      dateToYear = ts2.year;
    }

    return (
      dateToMonth - dateFromMonth + 1 + 12 * Math.abs(dateToYear - dateFromYear)
    );
  }

  public get year() {
    return this._date.year;
  }

  public get month() {
    return this._date.month;
  }

  public get day() {
    return this._date.day;
  }

  public isBetween(from: Timestamp, to: Timestamp): boolean {
    const dateAfterFromDate = this.getTime() > from.getTime();
    const dateBeforeToDate = this.getTime() < to.getTime();
    return dateAfterFromDate && dateBeforeToDate;
  }

  public getTime() {
    return this._date.toMillis();
  }

  public getEpochSeconds() {
    return this._date.toSeconds();
  }

  public isTodaysMonth(): boolean {
    return (
      Timestamp.now().year === this.year && Timestamp.now().month === this.month
    );
  }

  public toString(): string {
    return this._date.toString();
  }

  public toJSON(): string {
    return this.toString();
  }

  public toLocaleDateString(
    locale: string,
    style?: 'medium' | 'short' | 'long'
  ): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this._date as any)
      .toLocal()
      .setLocale(locale)
      .toLocaleString({
        dateStyle: style ?? 'medium'
      });
  }

  public toLocaleTimeString(locale: string): string {
    return this._date.toLocal().setLocale(locale).toLocaleString({
      timeZoneName: 'short',
      hour: 'numeric',
      minute: 'numeric'
    });
  }

  public toLocaleDateTimeString(
    locale: string,
    style?: 'medium' | 'short' | 'long'
  ): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this._date as any)
      .toLocal()
      .setLocale(locale)
      .toLocaleString({
        dateStyle: style ?? 'medium',
        timeStyle: style ?? 'short'
      });
  }
}
