import { Badge } from 'components/badges';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';

interface ISponsorBadgeProps {
  readonly sponsorName: string;
}

export function SponsorBadge(props: ISponsorBadgeProps) {
  const { t } = useTranslation();

  return (
    <Badge
      icon={IconName.ParentAndChild}
      title="S"
      tooltipDetailsContent={
        <p className="flex flex-row gap-xs items-baseline">
          <Icon name={IconName.ParentAndChild} size={16} />
          {t('Sponsored by {{sponsorName}}', {
            sponsorName: props.sponsorName
          })}
        </p>
      }
    />
  );
}
