import { usePII } from 'features/pii';
import _ from 'lodash';
import { PaymentInstrumentType } from 'types';

const PAYMENT_ICON_SIZE = {
  width: 40,
  height: 30
};

interface IPaymentIconProps {
  readonly brand: PaymentInstrumentType;
}

export function PaymentIcon(props: IPaymentIconProps) {
  const { isPrivacyGuardEnabled } = usePII();
  let objData = undefined;

  if (isPrivacyGuardEnabled) {
    objData = '/imgs/payment-brands/GenericCard.svg';
  } else {
    switch (props.brand) {
      case PaymentInstrumentType.Amex:
        objData = '/imgs/payment-brands/AmericanExpress.svg';
        break;
      case PaymentInstrumentType.ApplePay:
        objData = '/imgs/payment-brands/ApplePay.svg';
        break;
      case PaymentInstrumentType.Bancontact:
        objData = '/imgs/payment-brands/Bancontact.svg';
        break;
      case PaymentInstrumentType.Diners:
        objData = '/imgs/payment-brands/DinnersClub.svg';
        break;
      case PaymentInstrumentType.DirectDebit:
        objData = '/imgs/payment-brands/DirectDebit.svg';
        break;
      case PaymentInstrumentType.Discover:
        objData = '/imgs/payment-brands/Discover.svg';
        break;
      case PaymentInstrumentType.Elo:
        objData = '/imgs/payment-brands/Elo.svg';
        break;
      case PaymentInstrumentType.GooglePay:
        objData = '/imgs/payment-brands/GooglePay.svg';
        break;
      case PaymentInstrumentType.iDeal:
        objData = '/imgs/payment-brands/iDeal.svg';
        break;
      case PaymentInstrumentType.Jcb:
        objData = '/imgs/payment-brands/JCB.svg';
        break;
      case PaymentInstrumentType.Maestro:
        objData = '/imgs/payment-brands/MaestroUK.svg';
        break;
      case PaymentInstrumentType.MasterCard:
        objData = '/imgs/payment-brands/Mastercard.svg';
        break;
      case PaymentInstrumentType.PayPal:
        objData = '/imgs/payment-brands/PayPal.svg';
        break;
      case PaymentInstrumentType.Transbank:
        objData = '/imgs/payment-brands/Transbank.svg';
        break;
      case PaymentInstrumentType.UnionPay:
        objData = '/imgs/payment-brands/UnionPay.svg';
        break;
      case PaymentInstrumentType.UPC:
      case PaymentInstrumentType.ECommerceConnect:
        objData = '/imgs/payment-brands/UPC.svg';
        break;
      case PaymentInstrumentType.Visa:
        objData = '/imgs/payment-brands/Visa.svg';
        break;
      case PaymentInstrumentType.PMSubsidiary:
        objData = '/imgs/payment-brands/Subsidiary.svg';
        break;
      case PaymentInstrumentType.NuveiTwint:
        objData = '/imgs/payment-brands/NuveiTwint.svg';
        break;
      case PaymentInstrumentType.SEPA:
        objData = '/imgs/payment-brands/SEPA.svg';
        break;
      case PaymentInstrumentType.TwoCTwoP:
        objData = '/imgs/payment-brands/2c2p.svg';
        break;
      default:
        objData = '/imgs/payment-brands/GenericCard.svg';
        break;
    }
  }

  if (_.isUndefined(objData)) {
    return null;
  }

  return (
    <object
      title="Not Found Error Illustration"
      className="inline-block"
      type="image/svg+xml"
      data={objData}
      width={PAYMENT_ICON_SIZE.width}
      height={PAYMENT_ICON_SIZE.height}
    ></object>
  );
}
