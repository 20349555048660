import { SupportedLanguage } from '@pmi.web/localization';
import { useQuery } from '@tanstack/react-query';
import { getCommitment } from 'services';

import { COMMITMENTS_QUERY_KEY } from './useCommitments';

const TTL = 900000; // 15 minutes in milliseconds

export function useCommitment(
  commitmentId: string,
  language: SupportedLanguage
) {
  const query = useQuery({
    queryKey: [COMMITMENTS_QUERY_KEY, commitmentId, language],
    queryFn: () => getCommitment(commitmentId, language),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
