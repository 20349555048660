import { IUserProfile } from 'types';

import './DownlineGraph.scss';
import { DownlineGroup } from './DownlineGroup';

interface IDownlineGraphProps {
  readonly items: ReadonlyArray<ReadonlyArray<IUserProfile>>;
  readonly highlightedUserId?: string;
  readonly onUserClick: (userId: string) => void;
  readonly onUserExpansion: (userId: string) => void;
}

export function DownlineGraph(props: IDownlineGraphProps) {
  return (
    <div className="downline-graph">
      {props.items.map((i, level) => (
        <DownlineGroup
          items={i}
          highlightedUserId={props.highlightedUserId}
          key={`level-${level}`}
          onUserClick={props.onUserClick}
          onUserExpansion={props.onUserExpansion}
        />
      ))}
    </div>
  );
}
