import { useTranslation } from 'libs/translations';
import { useFormContext } from 'react-hook-form';
// eslint-disable-next-line no-restricted-imports
import { TUserSearchSorting } from 'services';
// eslint-disable-next-line import/order, no-restricted-imports
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';

import { FilterSection } from '../filter-sections/FilterSection';
import { FilterSectionName } from '../filter-sections/FilterSectionName';

export function SortByForm() {
  const { t } = useTranslation();

  return (
    <div className="space-y-sm">
      <FilterSection name={FilterSectionName.SortBy}>
        <fieldset className="analytics-filters-fieldset inline-radios">
          <SortByStructureSales />
          <SortBy6LevelSales />
          <SortByPersonalSales />
          <SortByRank />
          <SortByQualification />
          <SortByLastMonthQualification />
          <SortByName />
          <SortByCheckAssurance />
          <SortBySponsorActivity />
        </fieldset>
      </FilterSection>
      <input
        type="submit"
        value={t('Apply')}
        className="bg-primary rounded-full px-sm py-xs text-center w-full text-white cursor-pointer font-bold"
      />
    </div>
  );
}

function SortByStructureSales() {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <fieldset>
      <legend>{t('Structure Sales')}</legend>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByStructureVolumeAsc}
        />
        <span>
          <IoArrowUp size={16} />
        </span>
      </label>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByStructureVolumeDesc}
        />
        <span>
          <IoArrowDown size={16} />
        </span>
      </label>
    </fieldset>
  );
}

function SortBy6LevelSales() {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <fieldset>
      <legend>{t('6-Level Sales')}</legend>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.BySixLevelVolumeAsc}
        />
        <span>
          <IoArrowUp size={16} />
        </span>
      </label>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.BySixLevelVolumeDesc}
        />
        <span>
          <IoArrowDown size={16} />
        </span>
      </label>
    </fieldset>
  );
}

function SortByPersonalSales() {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <fieldset>
      <legend>{t('Personal Sales')}</legend>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByOwnVolumeAsc}
        />
        <span>
          <IoArrowUp size={16} />
        </span>
      </label>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByOwnVolumeDesc}
        />
        <span>
          <IoArrowDown size={16} />
        </span>
      </label>
    </fieldset>
  );
}

function SortByRank() {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <fieldset>
      <legend>{t('Rank')}</legend>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByRankAsc}
        />
        <span>
          <IoArrowUp size={16} />
        </span>
      </label>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByRankDesc}
        />
        <span>
          <IoArrowDown size={16} />
        </span>
      </label>
    </fieldset>
  );
}

function SortByQualification() {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <fieldset>
      <legend>{t('Qualification')}</legend>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByQualificationAsc}
        />
        <span>
          <IoArrowUp size={16} />
        </span>
      </label>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByQualificationDesc}
        />
        <span>
          <IoArrowDown size={16} />
        </span>
      </label>
    </fieldset>
  );
}

function SortByLastMonthQualification() {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <fieldset>
      <legend>{t('Qualification last month')}</legend>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByQualificationLastMonthAsc}
        />
        <span>
          <IoArrowUp size={16} />
        </span>
      </label>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByQualificationLastMonthDesc}
        />
        <span>
          <IoArrowDown size={16} />
        </span>
      </label>
    </fieldset>
  );
}

function SortByName() {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <fieldset>
      <legend>{t('Name')}</legend>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByNameAsc}
        />
        <span>
          <IoArrowUp size={16} />
        </span>
      </label>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByNameDesc}
        />
        <span>
          <IoArrowDown size={16} />
        </span>
      </label>
    </fieldset>
  );
}

function SortByCheckAssurance() {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <fieldset>
      <legend>{t('Check Assurance')}</legend>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByHasCheckAssuranceAsc}
        />
        <span>
          <IoArrowUp size={16} />
        </span>
      </label>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.ByHasCheckAssuranceDesc}
        />
        <span>
          <IoArrowDown size={16} />
        </span>
      </label>
    </fieldset>
  );
}

function SortBySponsorActivity() {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <fieldset>
      <legend>{t('Sponsor Acitvity')}</legend>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.BySponsorActivityAsc}
        />
        <span>
          <IoArrowUp size={16} />
        </span>
      </label>
      <label className="label-radio">
        <input
          type="radio"
          {...register('sorting')}
          value={TUserSearchSorting.BySponsorActivityDesc}
        />
        <span>
          <IoArrowDown size={16} />
        </span>
      </label>
    </fieldset>
  );
}
