import { AreaChart } from 'components/charts/area/AreaChart';
import { FormattedUnitValue, LabelledText, TrendValue } from 'components/text';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { IHistoryVolume } from 'types';
import { computePercentageTrend } from 'utils';

export interface IWidgetTurnoverDetails {
  readonly currentValue: number;
  readonly monthlyTrend?: number;
  readonly yearlyTrend?: number;
  readonly lastMonth?: number;
  readonly lastYear?: number;
  readonly historicalData: IHistoryVolume[];
}

export function WidgetTurnoverDetails(props: IWidgetTurnoverDetails) {
  const { t, activeLanguage } = useTranslation();

  const { dataPoints, dataDescending } = useMemo(() => {
    const dataAscending = [...props.historicalData].sort((a, b) => {
      // Sort in ascending order
      return a.date.getTime() - b.date.getTime();
    });

    const dataDescending = [...props.historicalData].sort((a, b) => {
      return b.date.getTime() - a.date.getTime();
    });

    return {
      dataDescending,
      dataPoints: dataAscending.map(h => ({
        label: h.date.toLocaleString(activeLanguage),
        value: h.volume
      }))
    };
  }, [props.historicalData, activeLanguage]);

  return (
    <>
      <div className="text-center">
        <div className="w-full flex justify-center">
          <h1 className="font-app font-bold">
            <FormattedUnitValue unit="points" value={props.currentValue} />
          </h1>
        </div>

        {props.lastMonth !== undefined &&
          props.lastYear !== undefined &&
          props.monthlyTrend !== undefined &&
          props.yearlyTrend !== undefined && (
            <div className="w-full flex flex-row justify-center mt-sm">
              <div
                className="inline-block basis-1/2 lg:basis-1/4"
                title={t('Your current sales compared to last month.')}
              >
                <LabelledText
                  label={t('Monthly trend')}
                  value={
                    <div className="flex flex-col items-center gap-xxs">
                      <span className="text-2xl">
                        <FormattedUnitValue
                          value={props.lastMonth}
                          unit="points"
                        />
                      </span>
                      <span className="flex justify-center text-sm">
                        <TrendValue
                          value={props.monthlyTrend}
                          style="illustrated"
                        />
                      </span>
                    </div>
                  }
                />
              </div>
              <div
                className="inline-block basis-1/2 lg:basis-1/4"
                title={t('Your current sales compared to last year.')}
              >
                <LabelledText
                  label={t('Yearly trend')}
                  value={
                    <div className="flex flex-col items-center gap-xxs min-w-24">
                      <span className="text-2xl">
                        <FormattedUnitValue
                          value={props.lastYear}
                          unit="points"
                        />
                      </span>
                      <span className="flex justify-center text-sm">
                        <TrendValue
                          value={props.yearlyTrend}
                          style="illustrated"
                        />
                      </span>
                    </div>
                  }
                />
              </div>
            </div>
          )}
      </div>
      <div className="mt-sm">
        <AreaChart yAxesLabel={t('Points')} dataPoints={dataPoints} />
      </div>
      <table className="mt-sm m-auto w-full">
        <thead>
          <tr>
            <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
              {t('Month')}
            </th>
            <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
              {t('Points')}
            </th>
            <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
              {t('Monthly trend')}
            </th>
          </tr>
        </thead>
        <tbody>
          {dataDescending.map((d, index) => {
            const monthlyTrendValue =
              index < dataDescending.length - 1
                ? computePercentageTrend(
                    d.volume,
                    dataDescending[index + 1].volume
                  )
                : undefined;

            return (
              <tr key={`${d.date}_${d.volume}`}>
                <td className="px-sm py-xxs text-center">
                  {d.date.toLocaleString(activeLanguage)}
                </td>
                <td className="px-sm py-xxs text-center">
                  <FormattedUnitValue unit="points" value={d.volume} />
                </td>
                <td className="px-sm py-xxs text-center">
                  {!_.isUndefined(monthlyTrendValue) && (
                    <TrendValue value={monthlyTrendValue} />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
