import { Icon, IconName } from 'libs/icons';

import './Pagination.scss';
import { usePagination } from './usePagination';

interface IPaginationProps {
  readonly onPageChange: (pageNumber: number) => void;
  readonly totalPages: number;
  readonly currentPage: number;
  readonly siblingCount: number;
}

export function Pagination(props: IPaginationProps) {
  const paginationRange = usePagination({
    currentPage: props.currentPage,
    totalPageCount: props.totalPages,
    siblingCount: props.siblingCount
  });

  // Do not render if there's only one page to show
  if (paginationRange.length < 2) {
    return null;
  }

  return (
    <ul className="component__pagination">
      {/* Left navigation arrow */}
      <li>
        <button
          onClick={() => {
            if (props.currentPage === 1) {
              return;
            }

            props.onPageChange(props.currentPage - 1);
          }}
          disabled={props.currentPage === 1}
          title="Previous page"
        >
          <Icon name={IconName.ChevronLeft} size={16} />
        </button>
      </li>

      {paginationRange.map((pageNumber, index) => {
        // If the pageItem is a DOTS, render the DOTS unicode character
        if (typeof pageNumber === 'string') {
          return <li key={`${index}_dots`}>&#8230;</li>;
        }

        // Page navigation items
        return (
          <li key={pageNumber}>
            <button
              onClick={() => {
                if (pageNumber !== props.currentPage) {
                  props.onPageChange(pageNumber);
                }
              }}
              className={pageNumber === props.currentPage ? 'active-page' : ''}
            >
              {pageNumber}
            </button>
          </li>
        );
      })}

      {/*  Right Navigation arrow */}
      <li>
        <button
          onClick={() => {
            if (props.currentPage === props.totalPages) {
              return;
            }

            props.onPageChange(props.currentPage + 1);
          }}
          disabled={props.currentPage === props.totalPages}
          title="Next page"
        >
          <Icon name={IconName.ChevronRight} size={16} />
        </button>
      </li>
    </ul>
  );
}
