import { useLayoutEffect } from 'react';

export function useScrollLock(rootDocument: HTMLElement) {
  useLayoutEffect(() => {
    const bodyOverflowStyle = window.getComputedStyle(rootDocument).overflow;
    rootDocument.style.overflow = 'hidden';

    return () => {
      rootDocument.style.overflow = bodyOverflowStyle;
    };
  }, [rootDocument]);
}
