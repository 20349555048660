import { useTranslation } from 'libs/translations';

import { MaximumQualificationFilter } from './MaximumQualificationFilter';
import { MinimumQualificationFilter } from './MinimumQualificationFilter';
import { FilterSection } from '../../filter-sections/FilterSection';
import { FilterSectionName } from '../../filter-sections/FilterSectionName';

export function QualificationFilter() {
  const { t } = useTranslation();

  return (
    <FilterSection name={FilterSectionName.Qualification}>
      <p className="text-silver pmi-caption font-bold">
        {t('Choose the range of Qualifications you want to see.')}
      </p>
      <fieldset className="analytics-filters-fieldset mt-xs">
        <MinimumQualificationFilter />
      </fieldset>
      <fieldset className="analytics-filters-fieldset">
        <MaximumQualificationFilter />
      </fieldset>
    </FilterSection>
  );
}
