import { useGlobalConfigsContext } from 'contexts';
import { fireConfettiGun } from 'libs/effects';
import { Icon, IconName } from 'libs/icons';
import _ from 'lodash';
import { useEffect } from 'react';

import { IPopCard } from './IPopCard';

export function PopCard(props: IPopCard) {
  const {
    app: {
      office: { enableAnimations }
    }
  } = useGlobalConfigsContext();

  useEffect(() => {
    if (enableAnimations) {
      _.times(3, () => {
        if (!_.isUndefined(props.fireworks)) {
          fireConfettiGun(props.fireworks);
        }
      });
    }
    // eslint-disable-next-line@reason: We want to trigger the confetti only on mount to avoid triggering it multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="space-y-md p-md">
      {!_.isUndefined(props.imgUri) && (
        <object
          className="w-full text-center inline-block h-[150px]"
          type="image/svg+xml"
          data={props.imgUri}
        ></object>
      )}
      <h1 className="text-center">
        {!_.isUndefined(props.titleIcon) && (
          <Icon
            name={props.titleIcon}
            size={20}
            className="inline-block mr-xs"
          />
        )}
        {props.title}
      </h1>
      {!_.isUndefined(props.descriptionContent) && props.descriptionContent}
      {!_.isUndefined(props.description) && (
        <p
          className={
            !_.isUndefined(props.descriptionList) ? 'text-left' : 'text-center'
          }
        >
          {props.description}
        </p>
      )}
      {!_.isUndefined(props.descriptionList) && (
        <ul className="text-left">
          {props.descriptionList.map((item, index) => (
            <li key={`${props.id}__listitem-${index}`}>
              <Icon
                name={IconName.CheckMark}
                size={12}
                className="inline-block text-active mr-xs"
              />
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
