import { useBoolean } from 'hooks';
import { ReactNode, createContext, useContext, useState } from 'react';

import { FilterSectionName } from '../components/filter-sections/FilterSectionName';

interface IFiltersAndSortingContextValues {
  readonly isFiltersDialogOpen: boolean;
  readonly isSortingDialogOpen: boolean;
  readonly openSection?: FilterSectionName;
  readonly closeDialog: () => void;
  readonly openDialog: (section?: FilterSectionName) => void;
}

const FiltersContext = createContext<IFiltersAndSortingContextValues | null>(
  null
);

interface IFiltersAndSortingContextProps {
  readonly children: ReactNode;
}

export function FiltersAndSortingContext(
  props: IFiltersAndSortingContextProps
) {
  const [openSection, setOpenSection] = useState<FilterSectionName | undefined>(
    undefined
  );

  const [
    isFiltersDialogOpen,
    { setFalse: closeFilters, setTrue: openFilters }
  ] = useBoolean(false);
  const [
    isSortingDialogOpen,
    { setFalse: closeSorting, setTrue: openSorting }
  ] = useBoolean(false);

  const closeDialog = () => {
    closeSorting();
    closeFilters();
    setOpenSection(undefined);
  };
  const openDialog = (section?: FilterSectionName) => {
    if (section === FilterSectionName.SortBy) {
      closeFilters();
      openSorting();
    } else {
      openFilters();
      closeSorting();
    }
    setOpenSection(section);
  };

  return (
    <FiltersContext.Provider
      value={{
        isFiltersDialogOpen,
        isSortingDialogOpen,
        openSection,
        closeDialog,
        openDialog
      }}
    >
      {props.children}
    </FiltersContext.Provider>
  );
}

export function useFiltersAndSortingContext() {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error(
      'useFiltersAndSortingContext must be used within FiltersAndSortingContext'
    );
  }

  return context;
}
