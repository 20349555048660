import { useQuery } from '@tanstack/react-query';
import { getEventOrders, getTicketsForEvent } from 'services';
import AuthenticationService from 'services/AuthenticationService';

import { IEventTicket } from '../schemas/IEventTicket';

const EVENT_TICKETS_QUERY_KEY = 'pmi.web.office__event_tickets';
const TTL = 900000; // 15 minutes in milliseconds

export function useEventTickets(eventId: string) {
  const query = useQuery({
    queryKey: [EVENT_TICKETS_QUERY_KEY, eventId],
    queryFn: () =>
      Promise.all([getTicketsForEvent(eventId), getEventOrders(eventId)]).then(
        ([userTickets, ticketOrders]) => {
          const uniqueTicketList = new Map();
          [
            ...userTickets,
            ...ticketOrders.reduce((acc, ticketOrder) => {
              return [...acc, ...ticketOrder.tickets];
            }, [] as IEventTicket[])
          ].forEach(t => uniqueTicketList.set(t.ticketId, t));

          const allTickets = Array.from(uniqueTicketList.values());

          const signedInUserTicket = allTickets.find(
            t =>
              t.tpId === AuthenticationService.user.userId &&
              t.attendeeType === 'tp'
          );

          return {
            signedInUserTicket: signedInUserTicket,
            tickets: Array.from(uniqueTicketList.values()).filter(
              ticket => ticket.ticketId !== signedInUserTicket?.ticketId
            )
          };
        }
      ),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
