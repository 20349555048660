import { execDelete, execGet, execPost } from 'services/RequestUtils';
import {
  BonusPayoutMethod,
  DirectCashPayoutMethod,
  IDirectCashState,
  IUserPayoutDetails
} from 'types';

export function getUserPayoutDetails(): Promise<IUserPayoutDetails> {
  return execGet(
    `${process.env.REACT_APP_PAYOUTS_API_V1}/customers/me/enriched`,
    { cache: false }
  );
}

export function setBonusPayoutMethod(method: BonusPayoutMethod): Promise<void> {
  return execPost(
    `${process.env.REACT_APP_PAYOUTS_API_V2}/customers/me/bonusPayoutMethod`,
    {
      bonusPayoutMethod: method
    }
  );
}

export function activateDirectCash(): Promise<IDirectCashState> {
  return execPost<IDirectCashState>(
    `${process.env.REACT_APP_PAYOUTS_API_V1}/customers/me/directcash`,
    {}
  );
}

export function deactivateDirectCash(): Promise<IDirectCashState> {
  return execDelete<IDirectCashState>(
    `${process.env.REACT_APP_PAYOUTS_API_V1}/customers/me/directcash`
  );
}

export function setDirectCashPayoutMethod(
  method: DirectCashPayoutMethod
): Promise<void> {
  return execPost<void>(
    `${process.env.REACT_APP_PAYOUTS_API_V1}/customers/me/directcashpayoutmethod`,
    {
      directCashPayoutMethod: method
    }
  );
}
