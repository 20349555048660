import { Month } from '@pmi.libs/utils-common';
import { IPeriodSelectorChangeEvent } from 'components/period-selection';
import _ from 'lodash';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState
} from 'react';

interface IDownlineContextValues extends IPeriodSelectorChangeEvent {
  readonly onPeriodChange: (event: IPeriodSelectorChangeEvent) => void;
}

const DownlineContext = createContext<IDownlineContextValues>({
  onPeriodChange: _.noop,
  selectedPeriod: Month.today(),
  historicalMonths: 12
});

export function DonwlineProvider(props: { readonly children: ReactNode }) {
  const [dashboardLoaderProps, setDashboardLoaderProps] =
    useState<IPeriodSelectorChangeEvent>({
      selectedPeriod: Month.today(),
      historicalMonths: 12
    });

  const onPeriodChange = useCallback((event: IPeriodSelectorChangeEvent) => {
    setDashboardLoaderProps(event);
  }, []);

  return (
    <DownlineContext.Provider
      value={{
        ...dashboardLoaderProps,
        onPeriodChange
      }}
    >
      {props.children}
    </DownlineContext.Provider>
  );
}

export function useDownlineContext() {
  const context = useContext(DownlineContext);

  if (context === undefined) {
    throw new Error('useDownlineContext must be used within DonwlineProvider');
  }

  return context;
}
