import { QualificationBadge } from 'components/badges';
import { usePII } from 'features/pii';
import { FeatureIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import { IUserQualificationTeamData } from 'types';
import { formatNumberLocalized } from 'utils';

import { Widget } from '../Widget';

export function WidgetTeamQualification(props: IUserQualificationTeamData) {
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();

  return (
    <Widget
      traceId="TeamQualifications"
      title={t('Qualifications of Team Members')}
      tooltip={t(
        'The Qualifications of Team Members shows the distribution of Team Partners per qualification level that contribute to your turnover of the given month.'
      )}
      iconName={FeatureIconName.TeamQualifications}
      content={
        <div className="flex flex-row flex-wrap gap-1">
          {props.qualificationTeam.map(collection => {
            return (
              <div
                className="flex items-center"
                key={JSON.stringify(collection)}
              >
                <div className="w-[32px] text-right mr-xxs">
                  {pii(
                    formatNumberLocalized(activeLanguage, collection.count),
                    'number'
                  )}
                </div>
                <div className="w-[32px] flex">
                  <QualificationBadge
                    qualification={collection.qualification}
                  />
                </div>
              </div>
            );
          })}
        </div>
      }
    />
  );
}
