import AuthenticationService from 'services/AuthenticationService';

export function getCommonAuthenticatedHeaders(mimeType = 'application/json') {
  return authenticateHeaders({ 'Content-Type': mimeType });
}

export function authenticateHeaders(headers: object) {
  return {
    ...headers,
    Authorization: `Bearer ${AuthenticationService.accessToken ?? ''}`
  };
}
