import { SupportedLanguage } from '@pmi.web/localization';
import { useQuery } from '@tanstack/react-query';
import { getCommitments } from 'services';

export const COMMITMENTS_QUERY_KEY = 'pmi.web.office__commitments';
const TTL = 900000; // 15 minutes in milliseconds

export function useCommitments(
  page: number,
  perPage: number,
  language: SupportedLanguage
) {
  const query = useQuery({
    queryKey: [COMMITMENTS_QUERY_KEY, page, perPage, language],
    queryFn: () => getCommitments(page, perPage, language),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
