import { GenerateFakeSoldBasketBuyerNotifications } from './fake-generators/GenerateFakeSoldBasketBuyerNotifications';
import { GenerateFakeWalletNotifications } from './fake-generators/GenerateFakeWalletNotifications';
import { GenerateFakeWhatsNewNotifications } from './fake-generators/GenerateFakeWhatsNewNotifications';

export function FakeNotificationsGenerator() {
  return (
    <>
      <GenerateFakeWhatsNewNotifications />
      <GenerateFakeSoldBasketBuyerNotifications />
      <GenerateFakeWalletNotifications />
    </>
  );
}
