/* eslint-disable no-restricted-imports */
import { BsFileEarmarkLock2 } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa6';
import { PiGarageLight, PiStarFourFill } from 'react-icons/pi';

import { FeatureIconName, IconName, RouteIconName } from './IconName';
import { Icon as Icon2 } from './svg-icons/Icon';

export function Icon(props: {
  readonly name: IconName;
  readonly className?: string;
  readonly fill?: boolean;
  readonly size: 10 | 12 | 14 | 16 | 20 | 24 | 32 | 48;
}) {
  return (
    <Icon2
      name={props.name as IconName}
      size={props.size}
      fill={props.fill}
      className={props.className}
    />
  );
}

export function RouteIcon(props: {
  readonly name: RouteIconName;
  readonly className?: string;
  readonly size: 10 | 12 | 14 | 16 | 20 | 24 | 32 | 48 | 62;
}) {
  switch (props.name) {
    case RouteIconName.Dashboard:
      return (
        <Icon2
          name={IconName.BarChart}
          size={props.size}
          className={props.className}
        />
      );
    case RouteIconName.MyTeam:
      return (
        <Icon2
          name={IconName.Groups}
          size={props.size}
          className={props.className}
        />
      );
    case RouteIconName.Analytics:
      return (
        <Icon2
          name={IconName.Search}
          size={props.size}
          className={props.className}
        />
      );
    case RouteIconName.Events:
      return (
        <Icon2
          name={IconName.Calendar}
          size={props.size}
          className={props.className}
        />
      );
    case RouteIconName.Sales:
      return (
        <Icon2
          name={IconName.Basket}
          size={props.size}
          className={props.className}
        />
      );
    case RouteIconName.Promocode:
      return (
        <Icon2
          name={IconName.Ticket}
          size={props.size}
          className={props.className}
        />
      );
    case RouteIconName.VirtualWarehouse:
      return (
        <span className={props.className}>
          <PiGarageLight size={props.size} />
        </span>
      );
    case RouteIconName.Account:
      return (
        <Icon2
          name={IconName.Person}
          size={props.size}
          className={props.className}
        />
      );
    case RouteIconName.StructureAnalyzer:
      return (
        <Icon2
          name={IconName.Briefcase}
          size={props.size}
          className={props.className}
        />
      );
    case RouteIconName.WhatsNew:
      return <PiStarFourFill size={props.size} className={props.className} />;
  }
}

export function FeatureIcon(props: {
  readonly name: FeatureIconName;
  readonly className?: string;
  readonly size: 10 | 12 | 14 | 16 | 20 | 24 | 32 | 48;
}) {
  switch (props.name) {
    case FeatureIconName.PrivacyGuard:
      return (
        <span className={props.className}>
          <BsFileEarmarkLock2 size={props.size} />
        </span>
      );
    case FeatureIconName.CheckAssurance:
      return (
        <Icon2
          name={IconName.CheckPayment}
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.Qualification:
      return (
        <Icon2
          name={IconName.Flag}
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.Rank:
      return (
        <Icon2
          name={IconName.HighPriority}
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.PersonalSales:
      return (
        <Icon2
          name={IconName.Person}
          size={props.size}
          fill
          className={props.className}
        />
      );
    case FeatureIconName.GroupSales:
      return (
        <Icon2
          name={IconName.Groups}
          size={props.size}
          fill
          className={props.className}
        />
      );
    case FeatureIconName.SixLevelSales:
      return (
        <Icon2
          name={IconName.SixCircle}
          fill
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.StructureSales:
      return (
        <Icon2
          name={IconName.Network}
          fill
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.Registrations:
      return (
        <Icon2
          name={IconName.Add}
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.Payouts:
      return (
        <Icon2
          name={IconName.Coins}
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.Autoships:
      return (
        <Icon2
          name={IconName.Box}
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.TeamQualifications:
      return (
        <Icon2
          name={IconName.Groups}
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.CarBonus:
      return (
        <Icon2
          name={IconName.Car}
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.PreviousQualification:
      return (
        <Icon2
          name={IconName.CalendarBack}
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.NextQualification:
      return (
        <Icon2
          name={IconName.ArrowRight}
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.Firstline:
      return (
        <Icon2
          name={IconName.Star}
          size={props.size}
          className={props.className}
        />
      );
    case FeatureIconName.FitlineClubMember:
      return (
        <span className={props.className}>
          <FaUsers size={props.size} />
        </span>
      );
    default:
      return null;
  }
}
