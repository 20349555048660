import { localizedCountryName } from '@pmi.web/countries';
import { InvoiceItem } from 'features/invoices';
import { PII, usePII } from 'features/pii';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { IoCallOutline, IoAt, IoLocationOutline } from 'react-icons/io5';
import { ICommitment } from 'types';

export function CommitmentDeliveryAddress(props: ICommitment) {
  /* TODO: handle DSC pickup option here */
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();

  if (_.isUndefined(props.delivery)) {
    return null;
  }

  return (
    <>
      <InvoiceItem
        icon={<IoLocationOutline size={16} />}
        title={t('Delivery address')}
        value={
          <div>
            {!_.isUndefined(props.delivery.address.addressLine1) && (
              <p>
                <PII
                  type="address"
                  value={props.delivery.address.addressLine1}
                />
              </p>
            )}
            {!_.isUndefined(props.delivery.address.addressLine2) && (
              <p>
                <PII
                  type="address"
                  value={props.delivery.address.addressLine2}
                />
              </p>
            )}
            {!_.isUndefined(props.delivery.address.streetPob) && (
              <p>
                <PII type="address" value={props.delivery.address.streetPob} />
              </p>
            )}
            {!_.isUndefined(props.delivery.address.city) && (
              <p>
                <span>
                  <PII type="address" value={props.delivery.address.city} />
                </span>
                {!_.isUndefined(props.delivery.address.zipCode) && (
                  <span>
                    ,&nbsp;
                    <PII
                      type="address"
                      value={props.delivery.address.zipCode}
                    />
                  </span>
                )}
              </p>
            )}

            {!_.isUndefined(props.delivery.address.county) && (
              <p>
                <span>
                  <PII type="address" value={props.delivery.address.county} />
                </span>
                {!_.isUndefined(props.delivery.address.district) && (
                  <span>
                    ,&nbsp;
                    <PII
                      type="address"
                      value={props.delivery.address.district}
                    />
                  </span>
                )}
              </p>
            )}
            {!_.isUndefined(props.delivery.address.countryCode) && (
              <p>
                {localizedCountryName(
                  pii(props.delivery.address.countryCode, 'country'),
                  activeLanguage
                )}
              </p>
            )}
          </div>
        }
      />

      {!_.isUndefined(props.delivery.address.email) && (
        <InvoiceItem
          title={t('Email')}
          icon={<IoAt size={16} />}
          value={<PII type="email" value={props.delivery.address.email} />}
        />
      )}

      {!_.isUndefined(props.delivery.address.mobile1) && (
        <InvoiceItem
          title={t('Phone number')}
          icon={<IoCallOutline size={16} />}
          value={<PII type="phone" value={props.delivery.address.mobile1} />}
        />
      )}
    </>
  );
}
