import { LoadingSpinner } from 'components/spinners';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { computePathTo } from 'services';
import { encodeArray } from 'utils';

export function DownlinePathToRoute() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const computeDownlineStateAndRedirect = async (userId: string) => {
    const pathToUserId = await computePathTo(userId);
    const userIds = pathToUserId.map(u => u.userId);
    const encodedState = encodeArray(userIds);
    navigate(`/downline/${encodedState}/dashboard`, { replace: true });
  };

  useEffect(() => {
    const userId = searchParams.get('userId');
    if (userId) {
      computeDownlineStateAndRedirect(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <div className="flex flex-row justify-center">
      <LoadingSpinner smallSize />
    </div>
  );
}
