import { PrimaryButton } from '@pmi.web/react-common';
import { BottomSheetContainer } from 'components/modals';
import { IS_PWA } from 'contants';
import { PromoVideo } from 'features/virtual-warehouse/components/promo-video/PromoVideo';
import { useTranslation } from 'libs/translations';
import { useReducer } from 'react';

import { FAQSlug } from './FAQSlug';
import { FAQStoryblokContent } from './FAQStoryblokContent';
import { useFAQs } from './useFAQs';

const defaultState: FAQButtonState = {
  isFAQOpen: false,
  isPromoVideoOpen: false
};

export function FAQButton({ slug }: { readonly slug: FAQSlug }) {
  const { t, activeLanguage } = useTranslation();
  const [state, dispatchStateEvent] = useReducer(reducer, defaultState);

  const { data: faqs, isLoading, error } = useFAQs(slug, activeLanguage);

  if (isLoading || error || !faqs || faqs.length === 0) {
    return null;
  }

  return (
    <div
      className={
        IS_PWA
          ? 'z-10 fixed right-[16px] bottom-[72px] pmi-safe-area-inset-b pmi-safe-area-inset-r'
          : 'z-10 fixed right-sm bottom-sm md:right-lg md:bottom-lg'
      }
    >
      <button
        className="rounded-full text-white bg-primary w-[52px] h-[52px] text-[22px] shadow-md"
        onClick={() => dispatchStateEvent({ type: 'open-faq' })}
      >
        ?
      </button>
      <BottomSheetContainer
        open={state.isFAQOpen}
        onDismiss={() => dispatchStateEvent({ type: 'close-faq' })}
      >
        <h1>{t('FAQs')}</h1>
        <FAQStoryblokContent faqs={faqs} />
        {slug === FAQSlug.VirtualWarehouse && (
          <div className="mt-md flex justify-center">
            <PrimaryButton
              onClick={() => dispatchStateEvent({ type: 'open-video' })}
            >
              {t('Watch a Short Video')}
            </PrimaryButton>
          </div>
        )}
      </BottomSheetContainer>
      {slug === FAQSlug.VirtualWarehouse && (
        <PromoVideo
          open={state.isPromoVideoOpen}
          onDismiss={() => dispatchStateEvent({ type: 'close-video' })}
        />
      )}
    </div>
  );
}

interface FAQButtonState {
  readonly isFAQOpen: boolean;
  readonly isPromoVideoOpen: boolean;
}

type FAQButtonStateAction =
  | { type: 'open-faq' }
  | { type: 'open-video' }
  | { type: 'close-faq' }
  | { type: 'close-video' };

function reducer(
  state: FAQButtonState,
  action: FAQButtonStateAction
): FAQButtonState {
  if (action.type === 'open-faq') {
    return {
      isFAQOpen: true,
      isPromoVideoOpen: false
    };
  }

  if (action.type === 'close-faq') {
    return {
      isFAQOpen: false,
      isPromoVideoOpen: false
    };
  }

  if (action.type === 'open-video') {
    return {
      isFAQOpen: false,
      isPromoVideoOpen: true
    };
  }

  if (action.type === 'close-video') {
    return {
      isFAQOpen: true,
      isPromoVideoOpen: false
    };
  }

  return state;
}
