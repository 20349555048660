import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from 'services/AuthenticationService';

export function UnauthorizedRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      AuthenticationService.isSignedIn &&
      AuthenticationService.isAllowedToUseThisOffice
    ) {
      navigate('/');
    } else {
      window.open(process.env.REACT_APP_LINK_WEBSHOP, '_self');
    }
  }, [navigate]);

  return null;
}
