import { useTranslation } from 'libs/translations';
import { OrderSource } from 'types/orders';

import { OrderSourceBadge } from './OrderSourceBadge';

export function OrderSourceDescription() {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t('Order Sources')}</h1>
      <div className="space-x-xs">
        <OrderSourceBadge
          iconSize="md"
          hideDetails
          source={OrderSource.Online}
        />
        <span>{t('Online Order')}</span>
      </div>
      <div className="space-x-xs">
        <OrderSourceBadge
          iconSize="md"
          hideDetails
          source={OrderSource.Events}
        />
        <span>{t('Ticket Order')}</span>
      </div>
      <div className="space-x-xs">
        <OrderSourceBadge
          iconSize="md"
          hideDetails
          source={OrderSource.Offline}
        />
        <span>{t('Offline Order')}</span>
      </div>
      <div className="space-x-xs">
        <OrderSourceBadge
          iconSize="md"
          hideDetails
          source={OrderSource.Autoship}
        />
        <span>{t('Autoship Order')}</span>
      </div>
    </>
  );
}
