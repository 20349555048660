import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { useGlobalConfigsContext } from 'contexts';
import { CreateNewBasketButton } from 'features/shared-baskets/components/create-basket-button/CreateNewBasketButton';
import { Navigate, Outlet } from 'react-router-dom';

export function SalesRoute() {
  const { promoShop } = useGlobalConfigsContext();

  if (!promoShop || !promoShop.id) {
    return <Navigate to="/" replace />;
  }

  return (
    <section className="max-w-[860px] m-auto">
      <div className="flex flex-row justify-between items-center flex-wrap mb-xl">
        <PageTitle />
        <CreateNewBasketButton />
      </div>
      <Outlet />
    </section>
  );
}
