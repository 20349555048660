import { Badge } from 'components/badges';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { FaGlobe, FaBagShopping, FaTicket } from 'react-icons/fa6';
// eslint-disable-next-line no-restricted-imports
import { MdAutoMode } from 'react-icons/md';
import { OrderSource } from 'types/orders';

import { OrderSourceDescription } from './OrderSourceDescription';

interface OrderSourceBadgeProps {
  readonly source: OrderSource;
  readonly hideDetails?: boolean;
  readonly iconSize?: 'sm' | 'md';
}

const iconSizes = {
  sm: 12,
  md: 16
};

export function OrderSourceBadge({
  source,
  hideDetails,
  iconSize = 'sm'
}: OrderSourceBadgeProps) {
  const { t } = useTranslation();

  const badgeInfo = {
    [OrderSource.Online]: {
      icon: <FaGlobe size={iconSizes[iconSize]} />,
      title: t('Online').toUpperCase()
    },
    [OrderSource.Events]: {
      icon: <FaTicket size={iconSizes[iconSize]} />,
      title: t('Tickets').toUpperCase()
    },
    [OrderSource.Offline]: {
      icon: <FaBagShopping size={iconSizes[iconSize]} />,
      title: t('Offline').toUpperCase()
    },
    [OrderSource.Autoship]: {
      icon: <MdAutoMode size={iconSizes[iconSize]} />,
      title: t('Autoship').toUpperCase()
    }
  }[source];

  return (
    <Badge
      hideDetails={hideDetails}
      icon={badgeInfo.icon}
      title={badgeInfo.title}
      tooltipDetailsContent={<OrderSourceDescription />}
    />
  );
}
