import { ReactNode } from 'react';

interface IPageContentProps {
  readonly children: ReactNode;
}

export function PageContent(props: IPageContentProps) {
  return (
    <section className="transition-all p-xs sm:p-sm md:p-md relative max-w-[1500px] w-full m-auto">
      <div className="pmi-safe-area-inset-l pmi-safe-area-inset-r">
        {props.children}
      </div>
    </section>
  );
}
