import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  addEventListenerToMediaQuery,
  removeEventListenerFromMediaQuery
} from 'utils';

interface IScreensContextValues {
  readonly xxs: boolean;
  readonly xs: boolean;
  readonly sm: boolean;
  readonly md: boolean;
  readonly lg: boolean;
  readonly xl: boolean;
  readonly xxl: boolean;
}

const SCREEN_SIZES = {
  xxs: '(min-width: 390px)',
  xs: '(min-width: 475px)',
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  xxl: '(min-width: 1536px)'
};

export const ScreensContext = createContext<IScreensContextValues>({
  xxs: false,
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false
});

interface IScreensContextProviderProps {
  readonly children: ReactNode;
}

export function ScreensContextProvider(props: IScreensContextProviderProps) {
  const [xxs, setXXS] = useState(window.matchMedia(SCREEN_SIZES.xxs).matches);
  const [xs, setXS] = useState(window.matchMedia(SCREEN_SIZES.xs).matches);
  const [sm, setSM] = useState(window.matchMedia(SCREEN_SIZES.sm).matches);
  const [md, setMD] = useState(window.matchMedia(SCREEN_SIZES.md).matches);
  const [lg, setLG] = useState(window.matchMedia(SCREEN_SIZES.lg).matches);
  const [xl, setXL] = useState(window.matchMedia(SCREEN_SIZES.xl).matches);
  const [xxl, setXXL] = useState(window.matchMedia(SCREEN_SIZES.xxl).matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setXXS(e.matches);
    const mediaQuery = window.matchMedia(SCREEN_SIZES.xxs);
    addEventListenerToMediaQuery(mediaQuery, handler);

    return () => {
      const mediaQuery = window.matchMedia(SCREEN_SIZES.xxs);
      removeEventListenerFromMediaQuery(mediaQuery, handler);
    };
  }, []);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setXS(e.matches);
    const mediaQuery = window.matchMedia(SCREEN_SIZES.xs);
    addEventListenerToMediaQuery(mediaQuery, handler);

    return () => {
      const mediaQuery = window.matchMedia(SCREEN_SIZES.xs);
      removeEventListenerFromMediaQuery(mediaQuery, handler);
    };
  }, []);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setSM(e.matches);
    const mediaQuery = window.matchMedia(SCREEN_SIZES.sm);
    addEventListenerToMediaQuery(mediaQuery, handler);

    return () => {
      const mediaQuery = window.matchMedia(SCREEN_SIZES.sm);
      removeEventListenerFromMediaQuery(mediaQuery, handler);
    };
  }, []);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setMD(e.matches);
    const mediaQuery = window.matchMedia(SCREEN_SIZES.md);
    addEventListenerToMediaQuery(mediaQuery, handler);

    return () => {
      const mediaQuery = window.matchMedia(SCREEN_SIZES.md);
      removeEventListenerFromMediaQuery(mediaQuery, handler);
    };
  }, []);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setLG(e.matches);
    const mediaQuery = window.matchMedia(SCREEN_SIZES.lg);
    addEventListenerToMediaQuery(mediaQuery, handler);

    return () => {
      const mediaQuery = window.matchMedia(SCREEN_SIZES.lg);
      removeEventListenerFromMediaQuery(mediaQuery, handler);
    };
  }, []);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setXL(e.matches);
    const mediaQuery = window.matchMedia(SCREEN_SIZES.xl);
    addEventListenerToMediaQuery(mediaQuery, handler);

    return () => {
      const mediaQuery = window.matchMedia(SCREEN_SIZES.xl);
      removeEventListenerFromMediaQuery(mediaQuery, handler);
    };
  }, []);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setXXL(e.matches);
    const mediaQuery = window.matchMedia(SCREEN_SIZES.xxl);
    addEventListenerToMediaQuery(mediaQuery, handler);

    return () => {
      const mediaQuery = window.matchMedia(SCREEN_SIZES.xxl);
      removeEventListenerFromMediaQuery(mediaQuery, handler);
    };
  }, []);

  return (
    <ScreensContext.Provider
      value={{
        xxs,
        xs,
        sm,
        md,
        lg,
        xl,
        xxl
      }}
    >
      {props.children}
    </ScreensContext.Provider>
  );
}

export function useScreenEvents() {
  const context = useContext(ScreensContext);

  if (context === undefined) {
    throw new Error(
      'useScreensContext must be used within ScreensContextProvider'
    );
  }

  return context;
}
