import { Switch } from '@headlessui/react';
import {
  Chart as ChartJS,
  registerables as ChartJSRegisterables,
  type ScriptableContext,
  type ChartArea
} from 'chart.js';
import { usePII } from 'features/pii';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { formatNumberLocalized } from 'utils';

ChartJS.register(...ChartJSRegisterables);

const ChartColors = {
  Green: 'rgb(15, 154, 101)',
  Red: 'rgb(205, 0, 57)',
  Blue: 'rgb(39, 154, 255)'
};

interface ILineChartProps {
  readonly xAxesLabel?: string;
  readonly yAxesLabel?: string;
  readonly labels: string[];
  readonly fill?: boolean;
  readonly datasets: ILineChartDataset[];
}

export interface ILineChartDataset {
  readonly dataTrend?: ChartDataTrend;
  readonly label: string;
  readonly data: number[];
  readonly cssRgbColor?: string;
}

export enum ChartDataTrend {
  Positive = 1,
  Negative
}

export function LineChart(props: ILineChartProps) {
  const { activeLanguage } = useTranslation();
  const { isPrivacyGuardEnabled } = usePII();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chartRef, setChartRef] = useState<ChartJS | null>(null);
  const [activeDatasets, setActiveDatasets] = useState<boolean[]>([]);

  const renderChart = useCallback(() => {
    if (!canvasRef.current) {
      return;
    }

    const chart = new ChartJS(canvasRef.current, {
      type: 'line',
      data: {
        labels: props.labels,
        datasets: computeChartDataset(props.datasets, !!props.fill)
      },
      options: {
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: props.xAxesLabel
            }
          },
          y: {
            beginAtZero: true,
            grace: '5%',
            type: 'linear',
            display: isPrivacyGuardEnabled ? false : true,
            title: {
              display: true,
              text: props.yAxesLabel
            },
            ticks: {
              callback: value => {
                return formatNumberLocalized(activeLanguage, value as number);
              }
            }
          }
        },
        elements: {
          point: {
            radius: isPrivacyGuardEnabled ? 0 : 3,
            hoverRadius: isPrivacyGuardEnabled ? 0 : 3
          }
        },
        plugins: {
          tooltip: {
            enabled: isPrivacyGuardEnabled ? false : true
          },
          legend: {
            display: false
          }
        }
      }
    });

    setActiveDatasets(chart.data.datasets.map(() => true));
    setChartRef(chart);
  }, [
    activeLanguage,
    isPrivacyGuardEnabled,
    props.datasets,
    props.fill,
    props.labels,
    props.xAxesLabel,
    props.yAxesLabel
  ]);

  const destroyChart = () => {
    if (chartRef) {
      chartRef.destroy();
      setChartRef(null);
    }
  };

  const handleDatasetLabelClick = (i: number) => {
    if (!chartRef) {
      return;
    }

    const isDatasetVisible = chartRef.isDatasetVisible(i);

    if (isDatasetVisible === true) {
      chartRef.hide(i);
      setActiveDatasets(state => {
        const newState = [...state];
        newState[i] = false;
        return newState;
      });
    }

    if (isDatasetVisible === false) {
      chartRef.show(i);
      setActiveDatasets(state => {
        const newState = [...state];
        newState[i] = true;
        return newState;
      });
    }
  };

  useEffect(() => {
    renderChart();

    return () => destroyChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!chartRef) {
      return;
    }

    chartRef.data.labels = props.labels;
    chartRef.data.datasets = computeChartDataset(props.datasets, !!props.fill);
    chartRef.update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.labels, props.datasets]);

  return (
    <>
      {chartRef && (chartRef.data.datasets ?? []).length > 1 && (
        <div className="flex flex-row justify-center items-center gap-sm mb-sm">
          {chartRef.data.datasets.map((dataset, i) => {
            return (
              <Switch.Group
                as="div"
                className="flex flex-row items-center"
                key={`${dataset.label}__${i}`}
              >
                <Switch.Label className="cursor-pointer pr-xs select-none">
                  {dataset.label}
                </Switch.Label>
                <Switch
                  checked={activeDatasets[i]}
                  onChange={() => handleDatasetLabelClick(i)}
                  style={{
                    backgroundColor: activeDatasets[i]
                      ? (dataset.borderColor as string)
                      : 'var(--disableColor)'
                  }}
                  className="h-[24px] w-[48px] cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-non"
                >
                  <span
                    aria-hidden="true"
                    className={`${
                      activeDatasets[i]
                        ? 'translate-x-[26px]'
                        : 'translate-x-[2px]'
                    } pointer-events-none block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </Switch.Group>
            );
          })}
        </div>
      )}
      <canvas ref={canvasRef} role="img"></canvas>
    </>
  );
}

function addAlphaToRGB(rgbColor: string, alpha: number) {
  const matchColors = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
  const match = matchColors.exec(rgbColor);

  if (_.isNull(match)) {
    throw new Error('Invalid RGB color');
  }

  return `rgba(${match[1]}, ${match[2]}, ${match[3]}, ${alpha})`;
}

function computeChartDataset(data: ILineChartDataset[], fill: boolean) {
  return data.map(d => ({
    label: d.label,
    data: d.data,
    fill: fill,
    borderColor:
      d.cssRgbColor ??
      (d.dataTrend === ChartDataTrend.Positive
        ? ChartColors.Green
        : d.dataTrend === ChartDataTrend.Negative
        ? ChartColors.Red
        : ChartColors.Blue),
    tension: 0.1,
    backgroundColor: (context: ScriptableContext<'line'>) => {
      const { ctx, chartArea } = context.chart;
      if (!chartArea) {
        return 'rgba(0, 0, 0, 0)';
      }

      return computeBgGradient(ctx, chartArea, d);
    }
  }));
}

function computeBgGradient(
  ctx: CanvasRenderingContext2D,
  chartArea: ChartArea,
  dataset: ILineChartDataset
) {
  const { top, bottom } = chartArea;
  const gradientColor = ctx.createLinearGradient(0, top, 0, bottom);

  gradientColor.addColorStop(
    0,
    dataset.cssRgbColor ??
      (dataset.dataTrend === ChartDataTrend.Positive
        ? addAlphaToRGB(ChartColors.Green, 1)
        : dataset.dataTrend === ChartDataTrend.Negative
        ? addAlphaToRGB(ChartColors.Red, 1)
        : addAlphaToRGB(ChartColors.Blue, 1))
  );
  gradientColor.addColorStop(
    1,
    dataset.cssRgbColor ??
      (dataset.dataTrend === ChartDataTrend.Positive
        ? addAlphaToRGB(ChartColors.Green, 0)
        : dataset.dataTrend === ChartDataTrend.Negative
        ? addAlphaToRGB(ChartColors.Red, 0)
        : addAlphaToRGB(ChartColors.Blue, 0))
  );
  return gradientColor;
}
