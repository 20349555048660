import { Timestamp } from '@pmi.libs/utils-common';
import { IEvent } from 'features/events';
import {
  IDiscountBenefit,
  IEventBenefit,
  IProductEligibilityBenefit
} from 'types';

import { IEventApi } from './types/IEventApi';
import { IEventBenefitApi } from './types/IEventBenefitApi';
import { IEventOrderApi } from './types/IEventOrderApi';

export function parseEvent(apiEvent: IEventApi): IEvent {
  return {
    ...apiEvent,
    dates: {
      countdown: Timestamp.createOrThrow(apiEvent.dates.countdown),
      pageVisibilityStart: Timestamp.createOrThrow(
        apiEvent.dates.pageVisibilityStart
      ),
      pageVisibilityEnd: Timestamp.createOrThrow(
        apiEvent.dates.pageVisibilityEnd
      ),
      registrationStart: Timestamp.createOrThrow(
        apiEvent.dates.registrationStart
      ),
      registrationEnd: Timestamp.createOrThrow(apiEvent.dates.registrationEnd)
    }
  };
}

export function parseEvents(
  apiEvents: ReadonlyArray<IEventApi>
): ReadonlyArray<IEvent> {
  return apiEvents.map(parseEvent);
}

export function parseEventOrders(orders: ReadonlyArray<IEventOrderApi>) {
  return orders.map(order => ({
    ...order,
    createdOn: Timestamp.create(order.createdOn)
  }));
}

export function parseEventBenefits(
  benefits: ReadonlyArray<IEventBenefitApi>
): ReadonlyArray<IEventBenefit> {
  return benefits.map(b => {
    const { event_id: eventId, ticket_id: ticketId, ...props } = b;

    if (props.type === 'productEligibility') {
      const productBenefit: IProductEligibilityBenefit = {
        ticketId,
        eventId,
        type: props.type,
        shopCountries: props.shopCountries ?? [],
        categories: props.categories ?? [],
        from: Timestamp.createOrThrow(props.from),
        to: Timestamp.createOrThrow(props.to)
      };

      return productBenefit;
    }

    if (props.type === 'discount') {
      const productBenefit: IDiscountBenefit = {
        ticketId,
        eventId,
        type: props.type,
        shopCountries: props.shopCountries ?? [],
        from: Timestamp.createOrThrow(props.from),
        to: Timestamp.createOrThrow(props.to)
      };

      return productBenefit;
    }

    return {
      ticketId,
      eventId,
      ...props
    };
  });
}
