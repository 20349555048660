export function Payout(props: { readonly size: number }) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.6951 35.7967C29.6951 37.3436 30.9491 38.5975 32.4959 38.5975C34.0427 38.5975 35.2967 37.3436 35.2967 35.7967C35.2967 34.2499 34.0427 32.9959 32.4959 32.9959C30.9491 32.9959 29.6951 31.7419 29.6951 30.195C29.6951 28.6482 30.9491 27.3942 32.4959 27.3942C34.0427 27.3942 35.2967 28.6295 35.2967 30.1764"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M32.4958 23.193V27.3942"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M32.4958 38.5976V42.7801"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M46.5 32.9958C46.5 40.7301 40.2302 47 32.4959 47C24.7616 47 18.4917 40.7301 18.4917 32.9958C18.4917 25.2615 24.7616 18.9917 32.4959 18.9917C40.2302 18.9917 46.5 25.2615 46.5 32.9958Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M29.6951 9.00207C29.6951 12.8692 23.4252 16.0042 15.6909 16.0042C7.95657 16.0042 1.5 12.8692 1.5 9.00207C1.5 5.13496 7.95657 1.99998 15.6909 1.99998C23.4252 1.99998 29.6951 5.13496 29.6951 9.00207Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M21.8667 23.8775C20.0038 24.3359 17.9075 24.5934 15.6909 24.5934C7.95657 24.5934 1.5 21.4585 1.5 17.5913"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1.5 9.00201V34.3962C1.5 38.2633 7.95658 41.3983 15.6909 41.3983C17.5427 41.3983 19.3106 41.2186 20.9286 40.8921"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M29.6951 9.00204V19.2719"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M18.4925 32.8558C17.5616 32.9496 16.6265 32.9964 15.6909 32.9959C7.95657 32.9959 1.5 29.8609 1.5 25.9938"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
