import { useChangelog } from 'libs/cms';
import { useTranslation } from 'libs/translations';
import { useEffect } from 'react';

import { INotification, NotificationType } from '../schemas/INotificationItem';
import NotificationsService from '../services/NotificationsService';

export function GenerateFakeWhatsNewNotifications() {
  const { activeLanguage } = useTranslation();
  const { data } = useChangelog(activeLanguage);

  useEffect(() => {
    if (data) {
      data.forEach(d => {
        const notification: INotification = {
          type: NotificationType.Notice,
          timestamp: d.date,
          dateTo: d.dateTo,
          dateFrom: d.dateFrom,
          id: d._uid,
          title: d.title,
          description: d.description,
          internalHref: `/whatsnew#${d._uid}`
        };

        NotificationsService.addNotification(notification);
      });
    }
  }, [data]);

  return null;
}
