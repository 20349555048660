import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cancelBasket } from 'services';

import { BASKETS_QUERY_KEY } from './BasketQueryConfig';

export function useCancelBasket() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: cancelBasket,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BASKETS_QUERY_KEY] });
    }
  });

  return {
    cancelBasket: mutation.mutate,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    error: mutation.error ?? undefined,
    data: mutation.data
  };
}
