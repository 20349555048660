export function SimpleArrowIcon(props: {
  readonly size: number;
  readonly direction: 'up' | 'down' | 'left' | 'right';
}) {
  if (props.direction === 'up') {
    return <ArrowUp size={props.size} />;
  }

  if (props.direction === 'down') {
    return <ArrowDown size={props.size} />;
  }

  if (props.direction === 'left') {
    return <ArrowLeft size={props.size} />;
  }

  return <ArrowRight size={props.size} />;
}

function ArrowUp(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <path
          d="M13.707,5.293l-5-5a1,1,0,0,0-1.414,0l-5,5A1,1,0,0,0,3.707,6.707L7,3.414V15a1,1,0,0,0,2,0V3.414l3.293,3.293a1,1,0,0,0,1.414-1.414Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

function ArrowDown(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <path
          d="M13.707,9.293a1,1,0,0,0-1.414,0L9,12.586V1A1,1,0,0,0,7,1V12.586L3.707,9.293a1,1,0,0,0-1.414,1.414l5,5a1,1,0,0,0,1.414,0l5-5A1,1,0,0,0,13.707,9.293Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

function ArrowRight(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <path
          d="M10.707,2.293A1,1,0,0,0,9.293,3.707L12.586,7H1A1,1,0,0,0,1,9H12.586L9.293,12.293a1,1,0,1,0,1.414,1.414l5-5a1,1,0,0,0,0-1.414Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

function ArrowLeft(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <path
          d="M15,7H3.414L6.707,3.707A1,1,0,0,0,5.293,2.293l-5,5a1,1,0,0,0,0,1.414l5,5a1,1,0,1,0,1.414-1.414L3.414,9H15a1,1,0,0,0,0-2Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
