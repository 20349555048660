import { PaymentInfo } from 'components/payment-icons';
import { InvoiceItem } from 'features/invoices';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { IoCashOutline } from 'react-icons/io5';
import { ICommitment } from 'types';

export function CommitmentAuthorizationDetails(props: ICommitment) {
  const { t } = useTranslation();

  return (
    <InvoiceItem
      icon={<IoCashOutline size={16} />}
      title={t('Authorized method')}
      value={<PaymentInfo {...props.payment.instrumentReference} />}
    />
  );
}
