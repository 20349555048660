import { Disclosure } from '@headlessui/react';
import { useFiltersAndSortingContext } from 'features/analytics/contexts/FiltersAndSortingContext';
import { ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import { IoChevronDown } from 'react-icons/io5';

import { FilterSectionName } from './FilterSectionName';
import { useFilterSectionTitle } from './useFilterSectionTitle';

interface IFilterSectionProps {
  readonly name: FilterSectionName;
  readonly children: ReactNode;
}

export function FilterSection(props: IFilterSectionProps) {
  const { openSection } = useFiltersAndSortingContext();
  const title = useFilterSectionTitle(props.name);
  const defaultOpen = openSection === props.name;

  return (
    <Disclosure defaultOpen={defaultOpen} as="div">
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full py-xs flex flex-row justify-between flex-nowrap gap-xs items-center">
            <h2 className="line-clamp-1 text-ellipsis font-bold">{title}</h2>
            <IoChevronDown
              size={16}
              className={`transition-all ${open ? '-rotate-180' : 'rotate-0'}`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="mx-xs relative">
            {props.children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
