import { ScrollLock } from 'components/ScrollLock';
import { useKeydownWindowEvent } from 'hooks';
import { useTranslation } from 'libs/translations';
import { ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import { FaXmark } from 'react-icons/fa6';

import './HelperModalContainer.scss';
import { Portal } from './Portal';

interface IHelperModalContainerProps {
  readonly children: ReactNode;
  readonly maxWidth?: number;
  readonly open: boolean;
  readonly onDismiss: () => void;
}

export function HelperModalContainer({
  children,
  ...props
}: IHelperModalContainerProps) {
  if (!props.open) {
    return null;
  }

  return (
    <HelperModalContainerContent {...props}>
      {children}
    </HelperModalContainerContent>
  );
}

function HelperModalContainerContent(props: IHelperModalContainerProps) {
  useKeydownWindowEvent(keyboardEvent => {
    if (keyboardEvent.key.toLowerCase() === 'escape') {
      props.onDismiss();
    }
  });

  const { t } = useTranslation();

  return (
    <Portal>
      <ScrollLock>
        <div
          className="component__helper-modal"
          data-open={props.open ? 'true' : 'false'}
        >
          <div
            className="modal-overlay"
            style={{ maxWidth: `${props.maxWidth ?? 800}px` }}
          >
            <div className="modal-content-wrapper">
              <div className="modal-close-btn-container">
                <button
                  className="modal-close-btn"
                  onClick={props.onDismiss}
                  title={t('Close')}
                >
                  <FaXmark size={20} />
                </button>
              </div>
              <br />
              {props.children}
            </div>
          </div>
        </div>
      </ScrollLock>
    </Portal>
  );
}
