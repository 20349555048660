import { PMLogo } from '@pmi.libs/react-common';
import _ from 'lodash';
import { Component } from 'react';

interface ILogoSplashScreenProps {
  readonly loading?: boolean;
  readonly onAnimationEnd?: () => void;
}

interface ILogoSplashScreenState {
  readonly shouldAnimate: boolean;
  readonly hasCompletedLoading: boolean;
}

export class LogoSplashScreen extends Component<
  ILogoSplashScreenProps,
  ILogoSplashScreenState
> {
  private _containerRef: HTMLDivElement | undefined;

  constructor(props: ILogoSplashScreenProps) {
    super(props);
    this.state = {
      shouldAnimate: props.loading ?? false,
      hasCompletedLoading: props.loading === false
    };
  }

  shouldComponentUpdate(nextProps: ILogoSplashScreenProps): boolean {
    if (
      nextProps.loading === false &&
      this.state.shouldAnimate &&
      !this.state.hasCompletedLoading
    ) {
      this.setState({ hasCompletedLoading: true });
      this.triggerFadeOutAnimation();
      return true;
    }

    return false;
  }

  render() {
    return (
      <div
        ref={this.setContainerRef}
        className={`${
          this.state.shouldAnimate
            ? 'transition-opacity delay-200 duration-1000 ease-in-out opacity-100'
            : ''
        } fixed inset-0 w-screen h-screen bg-body`}
      >
        <div
          className={`${
            this.state.shouldAnimate ? 'animate-pulse' : ''
          } flex w-full h-full items-center justify-center`}
        >
          <PMLogo className="h-[96px]" showClaim />
        </div>
      </div>
    );
  }

  private setContainerRef = (ref: HTMLDivElement) => {
    this._containerRef = ref;
  };

  private triggerFadeOutAnimation = () => {
    if (_.isUndefined(this._containerRef)) {
      return;
    }

    this._containerRef.style.setProperty('opacity', '0');

    if (!_.isUndefined(this.props.onAnimationEnd)) {
      setTimeout(() => {
        !_.isUndefined(this.props.onAnimationEnd) &&
          this.props.onAnimationEnd();
      }, /* css animation delay + duration */ 1200);
    }
  };
}
