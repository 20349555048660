import { localizedCountryName } from '@pmi.web/countries';
import { InvoiceItem } from 'features/invoices';
import { PII, usePII } from 'features/pii';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import {
  IoAt,
  IoFileTrayStackedOutline,
  IoLocationOutline,
  IoQrCode,
  IoScan
} from 'react-icons/io5';
import { IOrder, OrderDeliveryType } from 'types/orders';

import { OrderTrackingNumber } from './components/order-tracking-number/OrderTrackingNumber';

export function OrderDeliveryDetails(props: IOrder) {
  if (props.delivery.selected.type === OrderDeliveryType.Email) {
    return <EmailOrder {...props} />;
  }

  if (props.delivery.selected.type === OrderDeliveryType.Pickup) {
    return <PickupOrder {...props} />;
  }

  if (props.delivery.selected.type === OrderDeliveryType.Shipment) {
    return <DeliveryOrder {...props} />;
  }

  return null;
}

function EmailOrder(props: IOrder) {
  const { address } = props.delivery;

  return (
    <>
      {!_.isUndefined(address.email) && (
        <InvoiceItem
          icon={<IoAt size={16} />}
          value={<PII type="email" value={address.email} />}
        />
      )}
    </>
  );
}

function PickupOrder(props: IOrder) {
  const { t } = useTranslation();
  const { pii } = usePII();

  return (
    <>
      {!_.isUndefined(props.qrCodeUri) && (
        <InvoiceItem
          title={t('Pickup Code')}
          icon={<IoQrCode size={16} />}
          value={
            <img
              alt="qr code"
              className="max-h-[180px] max-w-[180px] rounded-3xl"
              src={pii(props.qrCodeUri, 'qrcode')}
            />
          }
        />
      )}

      {!_.isUndefined(props.delivery.pickupNumber) && (
        <InvoiceItem
          title={t('Pickup Number')}
          icon={<IoScan size={16} />}
          value={<PII type="ids" value={`${props.delivery.pickupNumber}`} />}
        />
      )}

      {!_.isUndefined(props.delivery.packages) &&
        props.delivery.packages.length > 0 && (
          <InvoiceItem
            title={t('Packages')}
            icon={<IoFileTrayStackedOutline size={16} />}
            value={
              <div>
                {props.delivery.packages.map(p => (
                  <p key={p.id}>
                    <PII type="ids" value={p.id} />
                  </p>
                ))}
              </div>
            }
          />
        )}
    </>
  );
}

function DeliveryOrder(props: IOrder) {
  const { address } = props.delivery;

  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();

  return (
    <>
      <InvoiceItem
        icon={<IoLocationOutline size={16} />}
        title={t('Delivery address')}
        value={
          <div>
            {!_.isUndefined(address.addressLine1) && (
              <p>
                <PII type="address" value={address.addressLine1} />
              </p>
            )}
            {!_.isUndefined(address.addressLine2) && (
              <p>
                <PII type="address" value={address.addressLine2} />
              </p>
            )}
            {!_.isUndefined(address.streetPob) && (
              <p>
                <PII type="address" value={address.streetPob} />
              </p>
            )}
            {!_.isUndefined(address.city) && (
              <p>
                <span>
                  <PII type="address" value={address.city} />
                </span>
                {!_.isUndefined(address.zipCode) && (
                  <span>
                    ,&nbsp;
                    <PII type="address" value={address.zipCode} />
                  </span>
                )}
              </p>
            )}

            {!_.isUndefined(address.county) && (
              <p>
                <span>
                  <PII type="address" value={address.county} />
                </span>
                {!_.isUndefined(address.district) && (
                  <span>
                    ,&nbsp;
                    <PII type="address" value={address.district} />
                  </span>
                )}
              </p>
            )}
            {!_.isUndefined(address.countryCode) && (
              <p>
                {localizedCountryName(
                  pii(address.countryCode, 'country'),
                  activeLanguage
                )}
              </p>
            )}
          </div>
        }
      />

      {(!_.isUndefined(address.email) ||
        !_.isUndefined(address.mobile1) ||
        !_.isUndefined(address.mobile2)) && (
        <>
          <InvoiceItem
            title={t('Delivery contact information')}
            value={
              <>
                {!_.isUndefined(address.email) && (
                  <p>
                    <PII type="email" value={address.email} />
                  </p>
                )}
                {!_.isUndefined(address.mobile1) && (
                  <p>
                    <PII type="phone" value={address.mobile1} />
                  </p>
                )}
                {!_.isUndefined(address.mobile2) && (
                  <p>
                    <PII type="phone" value={address.mobile2} />
                  </p>
                )}
              </>
            }
          />
        </>
      )}

      {!_.isUndefined(props.delivery.packages) &&
        props.delivery.packages.length > 0 && (
          <InvoiceItem
            title={t('Tracking Numbers')}
            icon={<IoFileTrayStackedOutline size={16} />}
            value={
              <div className="flex flex-col gap-0.5">
                {props.delivery.packages.map(p => (
                  <OrderTrackingNumber key={p.id} {...p} />
                ))}
              </div>
            }
          />
        )}
    </>
  );
}
