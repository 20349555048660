import { FormattedUnitValue } from 'components/text';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { BiMinus } from 'react-icons/bi';
// eslint-disable-next-line no-restricted-imports
import { FaClock } from 'react-icons/fa6';
// eslint-disable-next-line no-restricted-imports
import { GrRevert } from 'react-icons/gr';
import { IWalletTransaction } from 'types';

export function WalletTransactionItem(props: IWalletTransaction) {
  const { t, activeLanguage } = useTranslation();

  const isCanceled = props.status === 'archived';
  const isPending = props.status === 'pending';
  const isRefund = props.type === 'refund';

  return (
    <div className="flex flex-row items-center flex-nowrap gap-sm p-sm md:p-md rounded-md my-xs shadow bg-white">
      <div
        className={`relative rounded-full w-[36px] h-[36px] flex items-center justify-center flex-none ${
          isCanceled ? 'text-silver bg-body' : 'text-active bg-secondary'
        }`}
      >
        <TransationIcon size={24} />
        {(isPending || isRefund || isCanceled) && (
          <div
            className={`w-[13px] h-[13px] flex items-center justify-center flex-none absolute bottom-0 right-0 rounded-full
            ${isPending ? 'text-warn bg-[#FBE0CE]' : ''}
            ${isRefund || isCanceled ? 'bg-silver text-white' : ''}`}
          >
            {isPending && <FaClock size={13} />}
            {isRefund && <GrRevert size={11} />}
            {isCanceled && <BiMinus size={11} />}
          </div>
        )}
      </div>
      <div className="flex-grow">
        <span className="line-clamp-1 text-ellipsis">
          {props.otherSideAccountName}
        </span>
        <div className="pmi-caption text-silver">
          <span>{props.createdAt.toLocaleDateString(activeLanguage)}</span>
          <span>{' • '}</span>
          <span>{props.reference}</span>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <span
          className={`${isCanceled ? 'line-through' : ''} ${
            isCanceled || isPending ? 'text-silver' : ''
          } ${
            props.amount > 0 && !isCanceled && !isPending
              ? 'text-[#158800]'
              : ''
          }`}
        >
          <FormattedUnitValue
            unit={props.currency}
            value={props.amount}
            signDisplay
          />
        </span>
        {props.originalCurrency && props.originalAmount && (
          <span className="pmi-caption font-bold text-silver">
            <FormattedUnitValue
              unit={props.originalCurrency}
              value={props.originalAmount}
              signDisplay
            />
          </span>
        )}
        {(isCanceled || isPending || isRefund) && (
          <span className="text-silver font-bold pmi-caption">
            {isCanceled && <>{t('Canceled')}</>}
            {isPending && <>{t('Authorized')}</>}
            {isRefund && <>{t('Refund')}</>}
          </span>
        )}
      </div>
    </div>
  );
}

function TransationIcon(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_148_66)">
        <path
          d="M20.2389 9.41617L17.1618 6.339C17.0167 6.19886 16.8223 6.12132 16.6206 6.12308C16.4189 6.12483 16.226 6.20573 16.0833 6.34837C15.9407 6.491 15.8598 6.68395 15.8581 6.88566C15.8563 7.08736 15.9338 7.28169 16.074 7.42678L17.838 9.19077H7.38634C7.18231 9.19077 6.98664 9.27182 6.84237 9.41609C6.6981 9.56036 6.61705 9.75603 6.61705 9.96006C6.61705 10.1641 6.6981 10.3598 6.84237 10.504C6.98664 10.6483 7.18231 10.7294 7.38634 10.7294H19.695C19.8472 10.7293 19.9959 10.6842 20.1224 10.5997C20.2488 10.5151 20.3474 10.395 20.4056 10.2544C20.4638 10.1139 20.4791 9.95923 20.4494 9.81002C20.4197 9.66082 20.3465 9.52376 20.2389 9.41617ZM16.6179 12.2679H4.30917C4.15704 12.268 4.00834 12.3131 3.88186 12.3976C3.75538 12.4822 3.6568 12.6023 3.59859 12.7429C3.54038 12.8834 3.52514 13.0381 3.55481 13.1873C3.58448 13.3365 3.65772 13.4735 3.76528 13.5811L6.84245 16.6583C6.98754 16.7984 7.18187 16.876 7.38357 16.8742C7.58528 16.8725 7.77823 16.7916 7.92086 16.6489C8.0635 16.5063 8.1444 16.3133 8.14615 16.1116C8.14791 15.9099 8.07037 15.7156 7.93023 15.5705L6.16624 13.8065H16.6179C16.8219 13.8065 17.0176 13.7255 17.1618 13.5812C17.3061 13.4369 17.3872 13.2413 17.3872 13.0372C17.3872 12.8332 17.3061 12.6375 17.1618 12.4933C17.0176 12.349 16.8219 12.2679 16.6179 12.2679Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_148_66">
          <rect
            width="16.9241"
            height="10.7512"
            fill="white"
            transform="translate(3.54004 6.12305)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
