import { Theme, ThemeManager } from '@pmi.web/react-theme';
import { AppRouter } from 'AppRouter';
import { DOM_ELEM_ID } from 'contants';
import { ScreensContextProvider } from 'contexts';
import { AppToastContainer } from 'libs/toasts';
import { QueryProvider } from 'QueryProvider';

export function App() {
  return (
    <>
      <section id={DOM_ELEM_ID.MAIN_CONTENT}>
        <ThemeManager defaultTheme={Theme.PM} />
        <ScreensContextProvider>
          <QueryProvider>
            <AppRouter />
          </QueryProvider>
        </ScreensContextProvider>
      </section>
      <section id={DOM_ELEM_ID.TOASTS}>
        <AppToastContainer />
      </section>
    </>
  );
}
