import {
  IPeriodSelectorChangeEvent,
  PeriodSelector
} from 'components/period-selection';
import { DashboardLoader } from 'features/dashboard/DashboardLoader';
import { PromoShopBanner } from 'features/promo-shop';
import _ from 'lodash';
import { useState } from 'react';
import AuthenticationService from 'services/AuthenticationService';

export function DashboardRoute() {
  const [dashboardLoaderProps, setDashboardLoaderProps] =
    useState<IPeriodSelectorChangeEvent>();

  return (
    <section className="space-y-sm">
      <PromoShopBanner />
      <div className="flex justify-end">
        <PeriodSelector onPeriodChange={setDashboardLoaderProps} />
      </div>
      {!_.isUndefined(dashboardLoaderProps) && (
        <DashboardLoader
          userId={AuthenticationService.user.userId}
          historicalMonths={dashboardLoaderProps.historicalMonths}
          dashboardMonth={dashboardLoaderProps.selectedPeriod}
        />
      )}
    </section>
  );
}
