import { isISOCountryCode } from '@pmi.web/countries';
import _ from 'lodash';
import { execGet } from 'services/RequestUtils';
import { ICountryShop, IPromoShop } from 'types';

import { parseApiCountryShop, parseApiPromoShop } from './ShopHelper';
import { IApiCountryShop } from './types/IApiCountryShop';
import { IApiPromoShop } from './types/IApiPromoShop';

const SHOP_API_URL = `${process.env.REACT_APP_SHOPS_API ?? ''}`;

export async function fetchPromoShop(locale?: string): Promise<IPromoShop> {
  const searchParams = new URLSearchParams();

  if (!_.isUndefined(locale)) {
    searchParams.set('locale', locale);
  }

  const endpoint = `${SHOP_API_URL}/promotions?${searchParams.toString()}`;

  const response = await execGet<IApiPromoShop>(endpoint);

  return parseApiPromoShop(response);
}

export async function getCountryShopMappings(): Promise<
  ReadonlyArray<ICountryShop>
> {
  const response = await execGet<ReadonlyArray<IApiCountryShop>>(
    `${SHOP_API_URL}/countries`
  );

  return response
    .filter(countryShop => isISOCountryCode(countryShop.countryCode))
    .map(parseApiCountryShop);
}
