import { SupportedLanguage } from '@pmi.web/localization';
import { useQuery } from '@tanstack/react-query';
import { getProductDetails } from 'services';

const QUERY_KEY = 'pmi.web.office__pim-product';
const TTL = 900000; // 15 minutes in milliseconds

export function useProduct(
  articleNumber: string,
  shopId: string | undefined,
  language: SupportedLanguage
) {
  const query = useQuery({
    queryKey: [QUERY_KEY, articleNumber, shopId, language],
    queryFn: () => getProductDetails(articleNumber, shopId ?? '', language),
    staleTime: TTL,
    retry: false,
    enabled: !!shopId
  });

  return { ...query, error: query.error ?? undefined };
}
