import { SupportedLanguage } from '@pmi.web/localization';
import { useQuery } from '@tanstack/react-query';
import { execGet } from 'services/RequestUtils';
import type {
  BasketItemType,
  CostType,
  DiscountType,
  MicroMeasurementUnit
} from 'types/common';

const INVENTORY_QUERY_KEY = 'pmi.web.office__basket_available_items';
const TTL = 900000; // 15 minutes in milliseconds

export function useProductCatalog(
  language: SupportedLanguage,
  targetUserId: string | undefined,
  page: number,
  perPage: number,
  search?: string
) {
  const query = useQuery({
    queryKey: [
      INVENTORY_QUERY_KEY,
      language,
      targetUserId,
      page,
      perPage,
      search
    ],
    queryFn: () => {
      const searchParams = new URLSearchParams();
      searchParams.set('locale', language);

      if (targetUserId) {
        searchParams.set('targetUserId', targetUserId);
      }

      searchParams.set('perPage', perPage + '');
      searchParams.set('page', page + '');

      if (search) {
        searchParams.set('search', search);
      }

      return execGet<IProductCatalogData>(
        `${process.env.REACT_APP_SHOPS_API}/baskets/shared/products/?${searchParams}`
      );
    },
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}

interface IProductCatalogData {
  readonly vwInventory: ReadonlyArray<{
    readonly details?: {
      readonly attributes: Record<
        string,
        {
          readonly label: string;
          readonly value: string;
          readonly type: string;
          readonly priority?: number;
        }
      >;
      readonly media: ReadonlyArray<{
        readonly id: string;
        readonly type: string;
        readonly url: string;
        readonly localCode?: string;
        readonly priority?: number;
        readonly mimeType?: string;
      }>;
    };
    readonly articleNumber: string;
    readonly type: BasketItemType;
    readonly quantity: number;
    readonly cost: {
      readonly base: number;
      readonly final: number;
      readonly displayPrice: number;
      readonly type: CostType;
      readonly tax: number;
      readonly currencyCode: string;
    };
    readonly points: number;
    readonly gvPoints: number;
    readonly isDiscountable: boolean;
    readonly discount: ReadonlyArray<{
      readonly id: string;
      readonly value: number;
      readonly promoCode?: string;
      readonly type: DiscountType;
    }>;
  }>;

  // TODO: paginated but different than the interface we have.
  // Standardise the pagination information comming from different APIs
  readonly productCatalog: {
    readonly paginationInfo: {
      readonly page: number;
      readonly perPage: number;
      readonly totalPages: number;
      readonly totalRecords: number;
    };
    readonly results: ReadonlyArray<{
      readonly articleNumber: string;
      readonly tags?: ReadonlyArray<
        | '@new'
        | 'limited'
        | 'promotion'
        | 'ba'
        | 'pa'
        | 'monthEnd'
        | 'actionPack'
        | 'bbd'
      >;
      readonly disclaimers?: ReadonlyArray<{
        readonly id: string;
        readonly localizedLabels?: ReadonlyArray<{
          readonly localeCode: string;
          readonly name: string;
          readonly info?: string;
          readonly url?: string;
        }>;
      }>;
      readonly discounts?: ReadonlyArray<{
        readonly id: string;
        readonly value: number;
        readonly promoCode?: string;
        readonly type: DiscountType;
      }>;
      readonly restrictions?: ReadonlyArray<{
        readonly shopStockQuantity?: number;
        readonly maxQuantityPerOrder?: number;
        readonly maxQuantityPerCustomer?: {
          readonly value?: number;
          readonly timeInterval?: number;
        };
        readonly isHiddenFromBrowsing?: boolean;
        readonly isBlockedFromCheckout?: boolean;
      }>;
      readonly inStock: boolean;
      readonly details?: {
        readonly localeCode: string;
        readonly basePrice: number;
        readonly finalPrice: number;
        readonly displayPrice: number;
        readonly tax: number;
        readonly currencyCode: string;
        readonly points: number;
        readonly gvPoints: number;
        readonly grossWeightInGrams?: number;
        readonly netWeight?: number;
        readonly netWeigthUnit?: MicroMeasurementUnit;
        readonly priceType: CostType;
        readonly isDiscountable: boolean;
        readonly attributes: Record<
          string,
          {
            readonly label: string;
            readonly value: string;
            readonly type: string;
            readonly priority?: number;
          }
        >;
        readonly media: ReadonlyArray<{
          readonly id: string;
          readonly type: string;
          readonly url: string;
          readonly localCode?: string;
          readonly priority?: number;
          readonly mimeType?: string;
        }>;
        readonly isFitlineClubExclusive: boolean;
        readonly supportsVirtualWarehouse: boolean;
        readonly availabilityStatus:
          | 'Available'
          | 'LocallyAvailable'
          | 'Unavailable';
      };
    }>;
  };

  readonly shopId: string;
  readonly isAffiliateDiscountEnabled: boolean;
}
