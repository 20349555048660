import { IOrder } from 'types/orders';

import { mapAPIOrder } from './ApiOrderMapper';
import { IOrderApi, IOrderPaginatedResults } from '../types';

export function mapPaginatedOrders(
  apiResponse: IOrderPaginatedResults<IOrderApi>
): IOrderPaginatedResults<IOrder> {
  return {
    ...apiResponse,
    results: apiResponse.results.map(mapAPIOrder)
  };
}
