import { useEffect, useState } from 'react';
import {
  addEventListenerToMediaQuery,
  removeEventListenerFromMediaQuery
} from 'utils';

export function useSupportsHover() {
  const [supportsHover, setSupportsHover] = useState(
    window.matchMedia('(hover: hover)').matches
  );

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setSupportsHover(e.matches);

    const mediaQuery = window.matchMedia('(hover: hover)');
    addEventListenerToMediaQuery(mediaQuery, handler);

    return () => {
      const mediaQuery = window.matchMedia('(hover: hover)');
      removeEventListenerFromMediaQuery(mediaQuery, handler);
    };
  }, []);

  return supportsHover;
}
