import { useTranslation } from 'libs/translations';
import { ICommitment } from 'types';

import { CommimentCard } from './CommimentCard';

export function FulfilledCommitmentCard(commitment: ICommitment) {
  const { t } = useTranslation();

  return (
    <CommimentCard status={commitment.status}>
      <p>
        {t('Amazing! You fulfilled your commitment! Keep up the great work!')}
      </p>
    </CommimentCard>
  );
}
