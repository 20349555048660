import { useGlobalConfigsContext } from 'contexts';
import { PMOLink } from 'features/pmo-access';
import { RouteIcon, RouteIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { usePageTitle } from './layouts/page-title/usePageTitle';

interface ICustomNavLinkProps {
  readonly to: string;
  readonly title: string;
  readonly icon: RouteIconName;
  readonly iconOnly?: boolean;
  readonly label?: string;
}

function CustomNavLink(props: ICustomNavLinkProps) {
  return (
    <NavLink to={props.to} title={props.title}>
      {({ isActive }) => {
        return (
          <div
            className={`relative ${isActive ? 'text-active bg-secondary' : ''}`}
          >
            {isActive && (
              <div className="absolute inset-0 w-[4px] bg-active rounded-full"></div>
            )}
            <LinkContent
              title={props.title}
              icon={props.icon}
              iconOnly={props.iconOnly}
              label={props.label}
            />
          </div>
        );
      }}
    </NavLink>
  );
}

interface ILinkContentProps {
  readonly title: string;
  readonly icon: RouteIconName;
  readonly iconOnly?: boolean;
  readonly label?: string;
}

function LinkContent(props: ILinkContentProps) {
  const {
    app: {
      office: { enableAnimations }
    },
    device: { supportsHover }
  } = useGlobalConfigsContext();

  return (
    <span
      className={`flex px-sm py-xs items-center ${
        supportsHover ? 'hover:bg-secondary' : ''
      }`}
    >
      <span className="h-[24px] py-xxs">
        <RouteIcon name={props.icon} size={16} />
      </span>
      <span
        className={`${
          enableAnimations
            ? 'transition-all ease-in-out duration-200'
            : 'pmi-force-transition-none'
        } text-ellipsis overflow-hidden whitespace-nowrap ${
          props.iconOnly
            ? 'invisible opacity-0 max-w-0 -ml-xs'
            : 'visible opacity-100 max-w-full ml-xs'
        }`}
      >
        {props.title}
      </span>
      {props.label && (
        <span className="ml-xs h-fit w-fit bg-primary p-1 text-white font-bold uppercase leading-[8px] text-[8px] rounded tracking-widest">
          {props.label}
        </span>
      )}
    </span>
  );
}

interface INavBarProps {
  readonly expanded?: boolean;
}

export function NavBar(props: INavBarProps) {
  const { t } = useTranslation();
  const { getRouteTitle } = usePageTitle();
  const {
    promoShop,
    app: {
      office: { collapseNavBar }
    }
  } = useGlobalConfigsContext();

  const isExpanded = useMemo(
    () => props.expanded || !collapseNavBar,
    [collapseNavBar, props.expanded]
  );

  return (
    <ul className="mt-sm space-y-sm pmi-safe-area-inset-l">
      <li>
        <CustomNavLink
          to="/dashboard"
          title={getRouteTitle('/dashboard') ?? ''}
          icon={RouteIconName.Dashboard}
          iconOnly={!isExpanded}
        />
      </li>
      <li>
        <CustomNavLink
          to="/downline"
          title={getRouteTitle('/downline') ?? ''}
          icon={RouteIconName.MyTeam}
          iconOnly={!isExpanded}
        />
      </li>
      <li>
        <CustomNavLink
          to="/analytics"
          title={getRouteTitle('/analytics') ?? ''}
          icon={RouteIconName.Analytics}
          iconOnly={!isExpanded}
        />
      </li>
      <li>
        <CustomNavLink
          to="/events"
          title={getRouteTitle('/events') ?? ''}
          icon={RouteIconName.Events}
          iconOnly={!isExpanded}
        />
      </li>
      {promoShop && promoShop.isVirtualWarehouseEnabled && (
        <li>
          <CustomNavLink
            to="/virtual-warehouse"
            title={getRouteTitle('/virtual-warehouse') ?? ''}
            icon={RouteIconName.VirtualWarehouse}
            iconOnly={!isExpanded}
          />
        </li>
      )}
      {promoShop && promoShop.id && (
        <li>
          <CustomNavLink
            to="/sales"
            title={getRouteTitle('/sales') ?? ''}
            icon={RouteIconName.Sales}
            iconOnly={!isExpanded}
            label="New"
          />
        </li>
      )}
      {promoShop && promoShop.isPromoCodeEnabled && (
        <li>
          <CustomNavLink
            to="/promocode"
            title={getRouteTitle('/promocode') ?? ''}
            icon={RouteIconName.Promocode}
            iconOnly={!isExpanded}
            label="New"
          />
        </li>
      )}
      <li>
        <CustomNavLink
          to="/account"
          title={getRouteTitle('/account') ?? ''}
          icon={RouteIconName.Account}
          iconOnly={!isExpanded}
        />
      </li>
      <li>
        <PMOLink>
          <LinkContent
            icon={RouteIconName.StructureAnalyzer}
            title={t('Structure analyzer')}
            iconOnly={!isExpanded}
          />
        </PMOLink>
      </li>
    </ul>
  );
}
