import { ReactNode } from 'react';

interface IInvoiceCostTotal {
  readonly label: string;
  readonly value: ReactNode;
}

export function InvoiceCostTotal(props: IInvoiceCostTotal) {
  return (
    <>
      <p className="truncate">{props.label}</p>
      <p className="text-right truncate">{props.value}</p>
    </>
  );
}
