import { SupportedLanguage } from '@pmi.web/localization';
import { useQuery } from '@tanstack/react-query';
import { getCommitmentsInventory } from 'services';

export const INVENTORY_QUERY_KEY = 'pmi.web.office__inventory';
const TTL = 900000; // 15 minutes in milliseconds

export function useCommitmentsInventory(language: SupportedLanguage) {
  const query = useQuery({
    queryKey: [INVENTORY_QUERY_KEY, language],
    queryFn: () => getCommitmentsInventory(language),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
