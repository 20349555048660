import { useTranslation } from 'libs/translations';

import { UnknownDocFormatIcon } from './UnknownDocFormatIcon';

export function AllDocuments() {
  const { t } = useTranslation();

  return (
    <span className="flex flex-row items-center">
      <UnknownDocFormatIcon />
      <span className="ml-xs">{t('All documents')}</span>
    </span>
  );
}
