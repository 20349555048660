import { useTranslation } from 'libs/translations';

import { CountryAndPerformanceFilter } from './country-and-performance/CountryAndPerformanceFilter';
import './Filters.scss';
import { PeriodFilter } from './period/PeriodFilter';
import { QualificationFilter } from './qualification/QualificationFilter';
import { StructureSalesFilter } from './structure-sales/StructureSalesFilter';

export function FiltersForm() {
  const { t } = useTranslation();

  return (
    <div className="space-y-sm">
      <PeriodFilter />
      <CountryAndPerformanceFilter />
      <QualificationFilter />
      <StructureSalesFilter />
      <input
        type="submit"
        value={t('Apply')}
        className="bg-primary rounded-full px-sm py-xs text-center w-full text-white cursor-pointer font-bold"
      />
    </div>
  );
}
