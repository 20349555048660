import { FormattedUnitValue, LabelledText } from 'components/text';
import { usePII } from 'features/pii';
import { FeatureIconName, Icons } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useState } from 'react';
import AuthenticationService from 'services/AuthenticationService';
import { IUserPersonalAutoshipsData, IUserStructureAutoshipsData } from 'types';
import { formatNumberLocalized } from 'utils';

import { AutoshipDetailsModal, IAutoshipDetails } from './AutoshipDetailsModal';
import { AutoshipStatus } from './AutoshipStatus';
import { WidgetAutoshipsDetails } from './WidgetAutoshipsDetails';
import { Widget } from '../Widget';

interface IWidgetAutoshipsProps
  extends IUserStructureAutoshipsData,
    IUserPersonalAutoshipsData {}

export function WidgetAutoships(props: IWidgetAutoshipsProps) {
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();

  const [autoshipDetails, setAutoshipDetails] = useState<IAutoshipDetails>();

  return (
    <>
      <Widget
        traceId="Autoships"
        title={t('Autoships')}
        detailedViewButtonLabel={t('History')}
        iconName={FeatureIconName.Autoships}
        content={
          <div className="grid grid-cols-2 gap-sm">
            <LabelledText
              label={t('Personal Autoships')}
              value={
                <>
                  {props.autoshipsPersonalList.map((autoship, index) => (
                    <button
                      title={undefined}
                      type="button"
                      key={`autoship_${index}`}
                      className="space-x-xs"
                      onClick={() =>
                        setAutoshipDetails({
                          ...autoship,
                          owner: AuthenticationService.user
                        })
                      }
                    >
                      <FormattedUnitValue
                        unit="points"
                        value={autoship.points}
                      />
                      <AutoshipStatus status={autoship.status} showLabel />
                    </button>
                  ))}
                  {props.autoshipsPersonalList.length === 0 && (
                    <Icons.Common.Cancel size={20} className="text-error" />
                  )}
                </>
              }
            />
            <div className="space-y-sm">
              {!_.isUndefined(props.autoshipsOpenTurnover) && (
                <LabelledText
                  label={t('Open Volume')}
                  value={
                    <FormattedUnitValue
                      unit="points"
                      value={props.autoshipsOpenTurnover}
                    />
                  }
                />
              )}

              <LabelledText
                label={t('Number of active autoships')}
                value={
                  <>
                    <p>
                      {t('Firstline: {{total}}', {
                        total: pii(
                          formatNumberLocalized(
                            activeLanguage,
                            props.autoshipsInFirstline.current ?? 0
                          ),
                          'number'
                        )
                      })}
                    </p>
                    <p>
                      {t('Structure: {{total}}', {
                        total: pii(
                          formatNumberLocalized(
                            activeLanguage,
                            props.autoshipsInStructure.current ?? 0
                          ),
                          'number'
                        )
                      })}
                    </p>
                  </>
                }
              />
            </div>
          </div>
        }
        detailedView={
          <WidgetAutoshipsDetails
            autoshipsInFirstline={props.autoshipsInFirstline}
            autoshipsInStructure={props.autoshipsInStructure}
            gainedAutoshipsInStructure={props.gainedAutoshipsInStructure}
            lostAutoshipsInStructure={props.lostAutoshipsInStructure}
          />
        }
      />
      {autoshipDetails && (
        <AutoshipDetailsModal
          {...autoshipDetails}
          onDismiss={() => setAutoshipDetails(undefined)}
        />
      )}{' '}
    </>
  );
}
