import { SupportedLanguage } from '@pmi.web/localization';

export const LANGUAGE_COUNTRY_CODES = {
  [SupportedLanguage.Bulgarian]: 'bg',
  [SupportedLanguage.Czech]: 'cz',
  [SupportedLanguage.German]: 'de',
  [SupportedLanguage.English]: 'us',
  [SupportedLanguage.Spanish]: 'es',
  [SupportedLanguage.Finnish]: 'fi',
  [SupportedLanguage.French]: 'fr',
  [SupportedLanguage.Hungarian]: 'hu',
  [SupportedLanguage.Indonesian]: 'id',
  [SupportedLanguage.Icelandic]: 'is',
  [SupportedLanguage.Italian]: 'it',
  [SupportedLanguage.Japanese]: 'jp',
  [SupportedLanguage.Korean]: 'kr',
  [SupportedLanguage.Norwegian]: 'no',
  [SupportedLanguage.Dutch]: 'nl',
  [SupportedLanguage.Polish]: 'pl',
  [SupportedLanguage.Portuguese]: 'pt',
  [SupportedLanguage.Russian]: 'ru',
  [SupportedLanguage.Swedish]: 'se',
  [SupportedLanguage.Thai]: 'th',
  [SupportedLanguage.Turkish]: 'tr',
  [SupportedLanguage.Ukrainian]: 'ua',
  [SupportedLanguage.ChineseSimplified]: 'cn',
  [SupportedLanguage.ChineseTraditional]: 'cn',
  [SupportedLanguage.Vietnamese]: 'vn',
  [SupportedLanguage.Romanian]: 'ro'
};
