import { ReactNode } from 'react';

interface IInvoiceItemProps {
  readonly icon?: ReactNode;
  readonly title?: string;
  readonly value: ReactNode;
}

export function InvoiceItem(props: IInvoiceItemProps) {
  return (
    <div className="space-y-xs">
      <h2 className="pmi-caption flex flex-row flex-nowrap gap-xs uppercase text-silver">
        {props.icon && <span>{props.icon}</span>}
        <span>{props.title}</span>
      </h2>
      <div>{props.value}</div>
    </div>
  );
}
