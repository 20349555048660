import { ErrorHandler } from 'components/errors/ErrorHandler';
import { LoadingSpinner } from 'components/spinners';
import { Disclaimer } from 'components/text';
import { useScreenEvents } from 'contexts';
import { ApiError } from 'errors';
import { trackException } from 'libs/telemetry';
import { triggerWarningToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { FC, useMemo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchDownlineForPath } from 'services';
import AuthenticationService from 'services/AuthenticationService';
import { IUserProfile } from 'types';
import { decodeAsArray, encodeArray } from 'utils';

import { useDownlineContext } from './DownlineContext';
import { DownlineGraph } from './DownlineGraph';

export const DownlineExplorer: FC = () => {
  const { selectedPeriod } = useDownlineContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { encodedUsersSelectedPath } = useParams();
  const { xl } = useScreenEvents();

  const [isLoadingDownline, setLoadingDownline] = useState<boolean>(false);

  const selectedUserId = useMemo(() => {
    const selectedUsersPath = _.isUndefined(encodedUsersSelectedPath)
      ? []
      : decodeAsArray(encodedUsersSelectedPath);

    if (selectedUsersPath.length === 0) {
      return undefined;
    }

    if (selectedUsersPath.length === 1) {
      return selectedUsersPath[0];
    }

    return selectedUsersPath[selectedUsersPath.length - 1];
  }, [encodedUsersSelectedPath]);

  const [downline, setDownline] = useState<
    ReadonlyArray<ReadonlyArray<IUserProfile>>
  >([]);

  const [downlineComputationError, setDownlineComputationError] =
    useState<unknown>(undefined);

  const handleUserClick = (userId: string) => {
    if (!_.isUndefined(encodedUsersSelectedPath)) {
      const decodedState = decodeAsArray(encodedUsersSelectedPath);
      const newStateToEncode = [
        ..._.takeWhile(decodedState, predicate => predicate !== userId),
        userId
      ];
      const encodedState = encodeArray(newStateToEncode);
      navigate(`/downline/${encodedState}/dashboard`);
    } else {
      const newStateToEncode = [userId];
      const encodedState = encodeArray(newStateToEncode);
      navigate(`/downline/${encodedState}/dashboard`);
    }
  };

  const handleUserExpansion = (userId: string) => {
    if (selectedUserId === userId) {
      return;
    }

    if (!_.isUndefined(encodedUsersSelectedPath)) {
      const decodedState = decodeAsArray(encodedUsersSelectedPath);
      const newStateToEncode = [
        ..._.takeWhile(decodedState, predicate => predicate !== userId),
        userId
      ];
      const encodedState = encodeArray(newStateToEncode);
      navigate(`/downline/${encodedState}`);
    } else {
      const newStateToEncode = [userId];
      const encodedState = encodeArray(newStateToEncode);
      navigate(`/downline/${encodedState}`);
    }
  };

  const redirectToBaseCase = () => {
    const encodedState = encodeArray([AuthenticationService.user.userId]);
    navigate(`/downline/${encodedState}${xl ? '/dashboard' : ''}`);
  };

  useEffect(() => {
    if (_.isUndefined(encodedUsersSelectedPath)) {
      redirectToBaseCase();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encodedUsersSelectedPath]);

  useEffect(() => {
    if (_.isUndefined(encodedUsersSelectedPath)) {
      return;
    }

    const selectedUsersPath = decodeAsArray(encodedUsersSelectedPath);

    // Show the loading indicator while the new downline is computed
    setLoadingDownline(true);
    setDownlineComputationError(undefined);

    // At the same time, shorten the current tree to remove all nodes below
    // the one the user clicked. This is just to ensure the loading indicador
    // renderers where users expects to see changes.
    const selectedUserLevelIndex = downline.findIndex(
      downlineLevel =>
        !_.isUndefined(
          downlineLevel.find(user => user.userId === selectedUserId)
        )
    );

    if (downline.length > 1 && selectedUserLevelIndex !== -1) {
      const selectedUser = downline[selectedUserLevelIndex].filter(
        user => user.userId === selectedUserId
      );
      const shortenedDownline = _.concat(
        _.slice(downline, 0, selectedUserLevelIndex),
        [selectedUser]
      );
      setDownline(shortenedDownline);
    } else {
      setDownline([]);
    }

    fetchDownlineForPath(selectedUsersPath, selectedPeriod)
      .then(setDownline)
      .catch(err => {
        setDownlineComputationError(err);
        trackException(
          err,
          'DownlineExplorer.tsx',
          `Failed while fetching downline path [${selectedUsersPath.join(
            ', '
          )}]`
        );

        if (downline.length === 0) {
          // If we failed to open the page in a certain state, redirect to root
          redirectToBaseCase();
          return;
        }

        if (
          err instanceof Error &&
          err.name === 'ApiError' &&
          (err as ApiError).status === 403
        ) {
          return;
        }

        triggerWarningToast(
          t(
            'Something went wrong. Cannot load your team members right now. Please try again later or contact the support team.'
          )
        );
        console.error('Downline computation error.', err);
      })
      .finally(() => setLoadingDownline(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encodedUsersSelectedPath, selectedPeriod]);

  return (
    <section className="h-full space-y-md">
      <DownlineGraph
        items={downline}
        onUserClick={handleUserClick}
        onUserExpansion={handleUserExpansion}
        highlightedUserId={selectedUserId}
      />
      {isLoadingDownline && (
        <div className="mt-sm flex flex-row justify-center">
          <LoadingSpinner smallSize />
        </div>
      )}

      {_.isUndefined(
        downlineComputationError
      ) ? null : downlineComputationError instanceof Error &&
        downlineComputationError.name === 'ApiError' &&
        (downlineComputationError as ApiError).status === 403 ? (
        <div className="w-downline m-auto">
          <Disclaimer
            message={t(
              'Unfortunately, we are unable to grant access to the requested data.'
            )}
          />
        </div>
      ) : (
        <ErrorHandler err={downlineComputationError} />
      )}
    </section>
  );
};
