import { ICommitment } from 'types';

export function useCommimentOrders(
  commitment: ICommitment
): ReadonlyArray<string> {
  const relatedOrders = Array.from(
    new Set(
      commitment.items.flatMap(i => i.fulfillmentItems).map(i => i.orderId)
    )
  );

  return relatedOrders;
}
