import { QUALIFICATIONS, QualificationLevel } from '@pmi.libs/react-common';
import _ from 'lodash';
import { execGet } from 'services/RequestUtils';
import { IUserSearchResults } from 'types';

import { IUserSearchResultApi } from './types/IUserSearchResultApi';
import { sanitizeUserProfile } from './UserProfileServiceHelper';

export enum TUserSearchSorting {
  ByStructureVolumeDesc = '0',
  ByStructureVolumeAsc = '1',
  ByNameAsc = '2',
  ByNameDesc = '3',
  ByHasCheckAssuranceDesc = '4',
  ByHasCheckAssuranceAsc = '5',
  ByQualificationDesc = '6',
  ByQualificationAsc = '7',
  ByQualificationLastMonthDesc = '8',
  ByQualificationLastMonthAsc = '9',
  ByRankDesc = '10',
  ByRankAsc = '11',
  ByOwnVolumeDesc = '12',
  ByOwnVolumeAsc = '13',
  BySixLevelVolumeDesc = '14',
  BySixLevelVolumeAsc = '15',
  BySponsorActivityDesc = '16',
  BySponsorActivityAsc = '17'
}

export interface IUserSearchParamsV2 {
  // Text search value
  readonly search?: string;

  // Period to look at
  readonly period?: string;

  // Filters v2: a value means to filter, the absence of value means no filter
  readonly newRegistrations?: boolean;
  readonly fitlineClubMembers?: boolean;
  readonly withCheckAssurance?: boolean;
  readonly withNewQualification?: boolean;
  readonly withSponsorActivity?: boolean;
  readonly endCustomers?: boolean;

  // Range filters
  readonly byCountry?: string;
  readonly byQualificationFrom?: number;
  readonly byQualificationTo?: number;
  readonly byStructureVolumeFrom?: number;
  readonly byStructureVolumeTo?: number;
  readonly byDepthLevelFrom?: number;
  readonly byDepthLevelTo?: number;

  // Sorting
  readonly sorting?: TUserSearchSorting | null;
}

export async function searchUsers(
  params: IUserSearchParamsV2,
  pageNumber: number,
  pageSize: number
): Promise<IUserSearchResults> {
  const urlSearchParams = new URLSearchParams();
  Object.keys(params).map(k => {
    const kValue = _.get(params, k) ?? undefined;
    if (!_.isUndefined(kValue) && !_.isNull(kValue)) {
      urlSearchParams.append(k, kValue);
    }
  });

  if (_.isNull(urlSearchParams.get('byQualificationTo'))) {
    urlSearchParams.set(
      'byQualificationTo',
      `${QUALIFICATIONS[QualificationLevel.Champion].numericValue}`
    );
  }

  urlSearchParams.append('pageNumber', pageNumber.toString());
  urlSearchParams.append('pageSize', pageSize.toString());
  urlSearchParams.append('expand', 'BasicProfile,ExtendedProfile');
  urlSearchParams.set('include', 'BasicBusinessData');

  const searchResults = await execGet<IUserSearchResultApi>(
    `${process.env.REACT_APP_OFFICE_API}/users?${urlSearchParams.toString()}`,
    { cache: false }
  );

  return {
    ...searchResults,
    data: (searchResults.data ?? []).map(u => sanitizeUserProfile(u))
  };
}
