import { PrimaryButton } from '@pmi.web/react-common';
import { BottomSheetContainer } from 'components/modals';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useBoolean } from 'hooks';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import { useEffect, useMemo } from 'react';

import { ProductsInBasketSummary } from './ProductsInBasketSummary';

export function BasketButton() {
  const { t } = useTranslation();
  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);
  const { articles, dispatchStepEvent } = useNewBasketContext();
  const numberOfSelectedArticles = useMemo(() => {
    return Array.from(articles).reduce((acc, [, quantity]) => {
      return acc + quantity;
    }, 0);
  }, [articles]);

  useEffect(() => {
    if (articles.size === 0 && isOpen) {
      close();
    }
  }, [articles, close, isOpen]);

  return (
    <>
      <button
        disabled={numberOfSelectedArticles === 0}
        title={undefined}
        onClick={open}
        className={`relative border-2 rounded-full p-xs ${
          numberOfSelectedArticles > 0
            ? 'text-primary border-primary hover:brightness-125'
            : 'text-lightGray'
        }`}
      >
        <Icon name={IconName.Basket} size={20} />
        {numberOfSelectedArticles > 0 && (
          <span className="absolute -top-2 -right-1 bg-primary text-white px-1 py-0.5 font-bold pmi-caption rounded-md">
            {numberOfSelectedArticles}
          </span>
        )}
      </button>
      <BottomSheetContainer open={isOpen} onDismiss={close}>
        <h1 className="mb-sm">{t('Products in the basket')}</h1>
        <ProductsInBasketSummary allowEditing small />
        <div className="w-full flex flex-col items-center justify-center gap-sm mt-md">
          <PrimaryButton
            onClick={() => dispatchStepEvent({ type: 'confirm-articles' })}
          >
            {t('Continue')}
          </PrimaryButton>
          <button
            className="hover:bg-lightGray rounded-full px-sm py-xs"
            onClick={close}
          >
            {t('Add more products')}
          </button>
        </div>
      </BottomSheetContainer>
    </>
  );
}
