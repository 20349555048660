import { useGlobalConfigsContext } from 'contexts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { formatPercentLocalized } from 'utils';

import './ProgressWidget.scss';

interface IProgressWidgetProps {
  readonly percentage: number;
}

export function ProgressWidget(props: IProgressWidgetProps) {
  const { activeLanguage } = useTranslation();
  const {
    app: {
      office: { enableAnimations }
    }
  } = useGlobalConfigsContext();

  const [svgPercent, setSvgPercentage] = useState<number>(
    enableAnimations ? 0 : props.percentage
  );

  const textRef = useRef<SVGTextElement>(null);
  const foregroundPathRef = useRef<SVGPathElement>(null);

  const computeOffset = (percentage: number) => {
    // This is the same value in the styles!
    // It represents the size of the of the curved line
    const offset = 277.47;
    const reversePercentage = (100 - percentage) / 100;
    return reversePercentage * offset;
  };

  const setOffsetForPercentage = (percentage: number) => {
    if (!foregroundPathRef.current) {
      return;
    }

    foregroundPathRef.current.style.strokeDashoffset = `${computeOffset(
      percentage
    )}`;
  };

  const setLabel = (percentage: number) => {
    if (!textRef.current) {
      return;
    }

    setSvgPercentage(percentage);
    const label = formatPercentLocalized(activeLanguage, percentage);
    textRef.current.textContent = label;
  };

  const updatePercentage = (percentage: number) => {
    if (!textRef.current || !foregroundPathRef.current) {
      return;
    }

    const currentValue = svgPercent;
    let value = currentValue;
    let increment = Math.ceil((Math.abs(percentage - currentValue) * 4) / 100);
    increment = Math.max(increment, 1); // Make sure the increment is at least 1!
    const delay = 33;

    // If updating for a higher value, increment
    if (currentValue < percentage) {
      const incInterval = setInterval(() => {
        if (value > percentage) {
          setLabel(percentage);
          clearInterval(incInterval);
        } else {
          setLabel(value);
          value = value + increment;
        }
      }, delay);
    } else {
      // Otherwise, decrement
      const decInterval = setInterval(() => {
        if (value < percentage) {
          setLabel(percentage);
          clearInterval(decInterval);
        } else {
          setLabel(value);
          value = value - increment;
        }
      }, delay);
    }

    setOffsetForPercentage(percentage);
  };

  useEffect(() => {
    if (enableAnimations) {
      // Defer the execution of the update to ensure the css animation of
      // the filling will always play in sync with the label text updates
      _.defer(() => updatePercentage(props.percentage));
    } else {
      setLabel(props.percentage);
      setOffsetForPercentage(props.percentage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableAnimations, props.percentage]);

  return (
    <svg
      className="progress-widget w-[110px]"
      viewBox="0 0 132 113"
      version="1.1"
    >
      <defs>
        <linearGradient
          x1="100%"
          y1="29.9022415%"
          x2="15.8830704%"
          y2="84.984558%"
          id="successGreenGradient"
        >
          <stop stopColor="#58D083" offset="0%"></stop>
          <stop stopColor="#0F9A65" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="100%"
          y1="29.9022415%"
          x2="15.8830704%"
          y2="84.984558%"
          id="blueGradient"
        >
          <stop stopColor="#71BDFF" offset="0%"></stop>
          <stop stopColor="#0066C0" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(6.000000, 6.000000)">
          <path
            d="M103.391254,100.377053 C113.680718,89.7187728 120,75.2754965 120,59.3717277 C120,26.5816279 93.137085,0 60,0 C26.862915,0 0,26.5816279 0,59.3717277 C0,74.997234 6.10008455,89.212908 16.0723113,99.8141367"
            stroke="#E5F1FB"
            strokeWidth="12"
            strokeLinecap="round"
            transform="translate(60.000000, 50.188526) scale(-1, 1) translate(-60.000000, -50.188526) "
          ></path>
          <path
            ref={foregroundPathRef}
            d="M103.391254,100.377053 C113.680718,89.7187728 120,75.2754965 120,59.3717277 C120,26.5816279 93.137085,2.13162821e-14 60,2.13162821e-14 C26.862915,2.13162821e-14 0,26.5816279 0,59.3717277 C0,74.997234 6.10008455,89.212908 16.0723113,99.8141367"
            className={`progress-widget--foreground ${
              enableAnimations ? 'progress-widget--foreground-animation' : ''
            }`}
            stroke={
              props.percentage >= 100
                ? 'url(#successGreenGradient)'
                : 'url(#blueGradient)'
            }
            strokeWidth="12"
            strokeLinecap="round"
            transform="translate(60.000000, 50.188526) scale(-1, 1) translate(-60.000000, -50.188526) "
          ></path>
          <text
            ref={textRef}
            className="font-app text-[32px] font-bold"
            textAnchor="middle"
            fill="#000000"
            x="60.5"
            y="66.4240838"
          >
            0%
          </text>
        </g>
      </g>
    </svg>
  );
}
