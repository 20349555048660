import _ from 'lodash';

import { DefaultStep } from './contextDefaults';
import { NewBasketState } from './NewBasketState';
import { NewBasketStateAction } from './NewBasketStateAction';

export function newBasketStateReducer(
  state: NewBasketState,
  action: NewBasketStateAction
): NewBasketState {
  const updateStepState = reduceStepConfigurations(state, action);
  if (updateStepState) {
    return updateStepState;
  }

  const updateOwnerState = reduceOwnerConfigurations(state, action);
  if (updateOwnerState) {
    return updateOwnerState;
  }

  const updateBasketState = reduceBasketConfigurations(state, action);
  if (updateBasketState) {
    return updateBasketState;
  }

  const updateShopState = reduceShopConfigurations(state, action);
  if (updateShopState) {
    return updateShopState;
  }

  return state;
}

function reduceStepConfigurations(
  state: NewBasketState,
  action: NewBasketStateAction
): NewBasketState | undefined {
  if (action.type === 'edit-step') {
    return {
      ...state,
      id: action.value
    };
  }

  if (action.type === 'set-available-country-shops') {
    return {
      ...state,
      availableCountryShops: action.value
    };
  }

  return undefined;
}

function reduceOwnerConfigurations(
  state: NewBasketState,
  action: NewBasketStateAction
): NewBasketState | undefined {
  if (action.type === 'set-owner-country') {
    return {
      ...state,
      basketOwnerCountry: action.value
    };
  }

  if (
    action.type === 'set-teampartner-id' ||
    action.type === 'set-customer-id' ||
    action.type === 'set-prospect-id'
  ) {
    const isTeampartner = action.type === 'set-teampartner-id';
    const isCustomer = action.type === 'set-customer-id';
    const isProspect = action.type === 'set-prospect-id';

    // Coming from the VW, the user starts by selecting products thus
    // the owner will be missing. If we already have an owner, changing it
    // should reset the articles. Otherwise, keep the articles.
    const shouldResetProducts =
      state.basketOwnerId !== undefined && state.basketOwnerId !== action.value;

    const shop = state.availableCountryShops.find(
      countryShop => countryShop.countryCode === state.basketOwnerCountry
    );

    // When changing users, we need to ensure the discount is disable for teampartners
    // and we also should cancel the discount in case of changing between countries
    const shouldResetShopConfigurations =
      (shop && shop.shopId === state.shopId && isTeampartner) ||
      (shop && shop.shopId !== state.shopId);

    return {
      ...state,
      basketOwnerId: action.value,
      isBasketOwnerCustomer: isCustomer,
      isBasketOwnerProspect: isProspect,
      isBasketOwnerTeampartner: isTeampartner,
      shopId: shop?.shopId,
      isAffiliateDiscountEnabled: shouldResetShopConfigurations
        ? false
        : state.isAffiliateDiscountEnabled,
      articles: shouldResetProducts ? new Map() : state.articles
    };
  }

  if (action.type === 'confirm-user-id') {
    // If there are items, skip the content step. Let the user to decide to edit
    if (state.articles.size > 0) {
      return {
        ...state,
        id: 'summary'
      };
    }

    return {
      ...state,
      id: 'content'
    };
  }

  if (action.type === 'reset-owner') {
    return {
      ...state,
      basketOwnerId: undefined,
      basketOwnerCountry: undefined,
      isBasketOwnerTeampartner: false,
      isBasketOwnerCustomer: false,
      isBasketOwnerProspect: false
    };
  }

  return undefined;
}

function reduceBasketConfigurations(
  state: NewBasketState,
  action: NewBasketStateAction
): NewBasketState | undefined {
  if (action.type === 'increment-article-quantity') {
    const articles = new Map(state.articles);
    const [articleNumber, quantityUpdate] = action.value;
    const stateQuantity = state.articles.get(articleNumber) ?? 0;
    const newQuantity = quantityUpdate + stateQuantity;

    if (newQuantity === 0) {
      articles.delete(articleNumber);
    } else {
      articles.set(articleNumber, newQuantity);
    }

    return {
      ...state,
      articles
    };
  }

  if (action.type === 'set-article-quantity') {
    const articles = new Map(state.articles);
    const [articleNumber, quantity] = action.value;

    if (quantity === 0) {
      articles.delete(articleNumber);
    } else {
      articles.set(articleNumber, quantity);
    }

    // If products are preselected, we assume those are VW products because
    // that's the only option (at this point...). In this case, we must remove
    // the option to select a teampartner.
    const allowedBasketOnwerType = !state.basketOwnerId
      ? DefaultStep.allowedBasketOnwerType.filter(t => t !== 'BusinessBuilder')
      : DefaultStep.allowedBasketOnwerType;

    return {
      ...state,
      articles,
      allowedBasketOnwerType
    };
  }

  if (action.type === 'confirm-articles') {
    return {
      ...state,
      id: 'summary'
    };
  }

  if (action.type === 'set-basket-id') {
    return {
      ...DefaultStep,
      checkoutUrl: action.value,
      id: 'done'
    };
  }

  return undefined;
}

function reduceShopConfigurations(
  state: NewBasketState,
  action: NewBasketStateAction
): NewBasketState | undefined {
  if (action.type === 'set-shop-id') {
    return {
      ...state,
      shopId: action.value,
      isAffiliateDiscountEnabled: false
    };
  }

  if (
    action.type === 'set-affiliate-discount' &&
    !_.isUndefined(state.shopId) &&
    !_.isUndefined(state.basketOwnerId)
  ) {
    return {
      ...state,
      isAffiliateDiscountEnabled:
        action.value.enabled &&
        (state.isBasketOwnerCustomer || state.isBasketOwnerProspect)
    };
  }

  return undefined;
}
