import { isISOCountryCode } from '@pmi.web/countries';
import { CountryFlag } from '@pmi.web/react-country-flags';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useStepI18n } from 'features/shared-baskets/hooks/useStepI18n';
import { useMemo } from 'react';

import { WizardSectionWrapper } from '../WizardSectionWrapper';
import { BasketButton } from './BasketButton';
import { BasketProductCatalog } from './BasketProductCatalog';
import { CountryShopNotAvailable } from './CountryShopNotAvailable';

export function ProductSelectionStep() {
  const {
    id: activeStep,
    steps,
    basketOwnerCountry,
    basketOwnerId,
    shopId,
    dispatchStepEvent
  } = useNewBasketContext();
  const tStep = useStepI18n();

  const { completed, active, step, title } = useMemo(() => {
    const step = steps[1];
    const completed = [steps[2], steps[3]].includes(activeStep);
    const active = activeStep === step;
    const title = tStep(step, completed) ?? '';

    return {
      step,
      completed,
      active,
      title
    };
  }, [activeStep, steps, tStep]);

  return (
    <WizardSectionWrapper
      step={step}
      index={2}
      completed={completed}
      title={
        (active || completed) && shopId && isISOCountryCode(shopId) ? (
          <div className="flex flex-row items-center gap-xs">
            <span>{title}</span>
            <CountryFlag
              countryCode={shopId}
              style={{ maxWidth: 24, maxHeight: 24, borderRadius: '100%' }}
              squared
            />
          </div>
        ) : (
          title
        )
      }
      active={active}
      onEdit={() => {
        dispatchStepEvent({ type: 'edit-step', value: 'content' });
      }}
      editOnDetails
      headerActiveContent={<BasketButton />}
    >
      {basketOwnerId && basketOwnerCountry && !shopId && (
        <CountryShopNotAvailable country={basketOwnerCountry} />
      )}
      {basketOwnerId && shopId && <BasketProductCatalog />}
    </WizardSectionWrapper>
  );
}
