import { ReactNode } from 'react';

interface IInvoiceCostsProps {
  readonly children: ReactNode;
}

export function InvoiceCosts(props: IInvoiceCostsProps) {
  return (
    <div className="col-span-2 grid grid-cols-2 gap-x-xs sm:gap-x-sm">
      {props.children}
    </div>
  );
}
