import { PromocodeStatus } from 'types/promocodes';

export function isStatusActive(status: PromocodeStatus): boolean | null {
  if (status === PromocodeStatus.Active) {
    return true;
  } else if (status === PromocodeStatus.Inactive) {
    return false;
  } else {
    return null;
  }
}
