import { Badge } from 'components/badges';
import { UserAvatar } from 'components/icons';
import { PII, usePII } from 'features/pii';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';

interface IEventTicketProps {
  readonly eventName: string;
  readonly nameInTheTicket: string;
  readonly tpIdInTheTicket: string;
  readonly ticketName?: string;
  readonly qrCodeUri: string;
  readonly isGuest?: boolean;
}
export function EventTicket(props: IEventTicketProps) {
  const { t } = useTranslation();
  const { pii } = usePII();

  return (
    <div className="max-w-[350px] m-auto mb-2">
      <div className="rounded-3xl bg-secondary p-sm shadow-md space-y-sm">
        <h1 className="text-center font-bold text-primary line-clamp-1">
          {props.eventName}
        </h1>
        {!_.isUndefined(props.ticketName) && (
          <h2 className="text-center text-primary line-clamp-2">
            {props.ticketName}
          </h2>
        )}
        <div className="flex flex-row justify-evenly items-center">
          <div className="flex flex-row items-center gap-xs">
            <UserAvatar
              userName={props.nameInTheTicket}
              userType={props.isGuest ? 'external-user' : 'teampartner'}
            />
            <div>
              <h2 className="overflow-hidden whitespace-nowrap text-ellipsis line-clamp-1">
                <PII value={props.nameInTheTicket} type="name" />
              </h2>
              {!props.isGuest && (
                <p className="pmi-caption">
                  #<PII value={props.tpIdInTheTicket} type="tpid" />
                </p>
              )}
            </div>
          </div>
          {props.isGuest && <Badge hideDetails title={t('External guest')} />}
        </div>
      </div>
      <div className="rounded-3xl bg-primary shadow-md flex flex-col items-center">
        <img
          alt="qr code"
          className="max-h-[300px] max-w-[300px] w-auto rounded-3xl m-md"
          src={pii(props.qrCodeUri, 'qrcode')}
        />
      </div>
    </div>
  );
}
