import { useScreenEvents } from 'contexts';
import { Icon, IconName } from 'libs/icons';
import { trackEvent } from 'libs/telemetry';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import './AccountChildNavigationItem.scss';

export interface IAccountChildNavigationItemProps {
  readonly title: string;
  readonly description?: string;
  readonly icon: IconName;
  readonly href: string | Promise<string | undefined>;
  readonly telemetryId: string;
}

export function AccountChildNavigationItem(
  props: IAccountChildNavigationItemProps
) {
  const { md } = useScreenEvents();

  const [resolvedHref, setResolvedHref] = useState<string>();

  const onClick = () => {
    trackEvent({
      name: props.telemetryId
    });
    return true;
  };

  useEffect(() => {
    (props.href instanceof Promise
      ? props.href
      : Promise.resolve(props.href)
    ).then(setResolvedHref);
  }, [props.href]);

  if (_.isUndefined(resolvedHref)) {
    return null;
  }

  let isExternalLink: boolean;
  try {
    new URL(resolvedHref);
    isExternalLink = true;
  } catch (err) {
    isExternalLink = false;
  }

  return (
    <NavLink
      to={resolvedHref}
      target={
        isExternalLink ? `pmi_web_office__${props.telemetryId}` : undefined
      }
      className="component__account-child-navigation-item"
      onClick={onClick}
    >
      <p className="whitespace-nowrap text-ellipsis overflow-hidden">
        {props.title}
        {isExternalLink && (
          <Icon
            name={IconName.Link}
            size={16}
            className="text-primary inline-block ml-xxs"
          />
        )}
      </p>
      <p className="pmi-caption text-silver pr-[24px] md:pr-[48px] mt-xxs">
        {props.description}
      </p>
      <div className="flex justify-end absolute bottom-1 right-1">
        <Icon
          name={props.icon}
          size={md ? 48 : 24}
          className="text-active opacity-25"
        />
      </div>
    </NavLink>
  );
}
