import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';

interface PayoutOptionsStatusIconProps {
  readonly checked: boolean;
  readonly disabled: boolean;
}

export const PayoutOptionStatusIcon = ({
  checked,
  disabled
}: PayoutOptionsStatusIconProps) => {
  const { t } = useTranslation();

  return (
    <span className="absolute right-4 top-4 flex flex-row items-center">
      {disabled ? (
        <span className="text-silver text-[12px] uppercase italic">
          <Icon name={IconName.Ban} className="inline-block" size={12} />
          <span className="ml-xs">{t('Not available')}</span>
        </span>
      ) : (
        <span
          className={`w-6 h-6 rounded-full flex justify-center items-center ${
            checked ? 'bg-active' : 'border border-silver'
          }`}
        >
          <span
            className={`w-2 h-2 rounded-full ${
              checked ? 'bg-white' : ''
            } align-middle`}
          />
        </span>
      )}
    </span>
  );
};
