import type { FC } from 'react';

export const CallOutlineIcon: FC<{ readonly size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
    className="flex-shrink-0 flex-grow-0"
  >
    <g fill="currentColor" stroke="currentColor" strokeWidth="1">
      <path
        d="M10.514,9.764,9.292,11.292A12.89,12.89,0,0,1,4.708,6.708L6.236,5.486a1.038,1.038,0,0,0,.3-1.232L5.143,1.117A1.038,1.038,0,0,0,3.933.534l-2.652.687A1.046,1.046,0,0,0,.51,2.375,15.407,15.407,0,0,0,13.625,15.49a1.044,1.044,0,0,0,1.153-.771l.688-2.651a1.038,1.038,0,0,0-.583-1.21L11.746,9.465A1.04,1.04,0,0,0,10.514,9.764Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
