import _ from 'lodash';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { encode, decode } from 'utils';

import { IFilterFormValues } from '../types/IFilterFormValues';
import { ISearchFormValues } from '../types/ISearchFormValues';
import { ISortFormValues } from '../types/ISortFormValues';
import {
  isValidFilterFormValues,
  isValidSearchFormValues,
  isValidSortFormValues
} from '../utils/UrlSearchParamUtils';

export function useUrlSearchParams() {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  function updateUrlSearchParams(
    searchParams: ISearchFormValues,
    filterParams: IFilterFormValues,
    sortbyParams: ISortFormValues
  ) {
    const fixedFilterParams = {
      ...filterParams,
      byQualificationFrom: filterParams.byQualificationFrom || undefined,
      byStructureVolumeTo:
        filterParams.byStructureVolumeTo === Infinity
          ? 'Infinity' // Infinity becomes null when serialized. Use string instead
          : filterParams.byStructureVolumeTo
    };

    const queryParamValue = encode(
      JSON.stringify({
        searchParams,
        filterParams: fixedFilterParams,
        sortbyParams
      })
    );

    urlSearchParams.set('q', queryParamValue);
    setUrlSearchParams(urlSearchParams);
  }

  const { searchParams, filterParams, sortbyParams } = useMemo(() => {
    let searchParams: ISearchFormValues | undefined;
    let filterParams: IFilterFormValues | undefined;
    let sortbyParams: ISortFormValues | undefined;

    try {
      const query = urlSearchParams.get('q');

      if (!query) {
        return {
          searchParams,
          filterParams,
          sortbyParams
        };
      }

      const decodedValues = JSON.parse(decode(query));

      // Infinity is a special case, when stringifying the value by default
      // it is set to null, so when getting the value back we need to restore
      // it. As a workaround, we set Infinity to 'Infinity' when serealising.
      if (
        decodedValues.filterParams &&
        decodedValues.filterParams.byStructureVolumeTo === 'Infinity'
      ) {
        _.set(decodedValues.filterParams, 'byStructureVolumeTo', Infinity);
      }

      if (
        'searchParams' in decodedValues &&
        isValidSearchFormValues(decodedValues.searchParams)
      ) {
        searchParams = decodedValues.searchParams;
      }

      if (
        'filterParams' in decodedValues &&
        isValidFilterFormValues(decodedValues.filterParams)
      ) {
        filterParams = decodedValues.filterParams;
      }

      if (
        'sortbyParams' in decodedValues &&
        isValidSortFormValues(decodedValues.sortbyParams)
      ) {
        sortbyParams = decodedValues.sortbyParams;
      }

      return {
        searchParams,
        filterParams,
        sortbyParams
      };
    } catch {
      // ignore
      return {
        searchParams,
        filterParams,
        sortbyParams
      };
    }
  }, [urlSearchParams]);

  return { searchParams, filterParams, sortbyParams, updateUrlSearchParams };
}
