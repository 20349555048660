import { FeatureIconName } from 'libs/icons';

import { FirstlineDataHeader } from './FirstlineDataHeader';
import { useFirstlineHeaderI18n } from './useFirstlineHeaderI18n';
import { FirstlineDataProperty } from '../FirstlineDataProperty';

export function PersonalSalesHeader(props: { readonly iconOnly?: boolean }) {
  const t = useFirstlineHeaderI18n();

  return (
    <FirstlineDataHeader
      icon={FeatureIconName.PersonalSales}
      text={t(FirstlineDataProperty.PersonalSales)}
      onlyIcon={props.iconOnly}
    />
  );
}

export function SixLevelSalesHeader(props: { readonly iconOnly?: boolean }) {
  const t = useFirstlineHeaderI18n();

  return (
    <FirstlineDataHeader
      icon={FeatureIconName.SixLevelSales}
      text={t(FirstlineDataProperty.SixLevelSales)}
      onlyIcon={props.iconOnly}
    />
  );
}

export function StructureSalesHeader(props: { readonly iconOnly?: boolean }) {
  const t = useFirstlineHeaderI18n();

  return (
    <FirstlineDataHeader
      icon={FeatureIconName.StructureSales}
      text={t(FirstlineDataProperty.StructureSales)}
      onlyIcon={props.iconOnly}
    />
  );
}
