export function LocationOutlineIcon(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" stroke="currentColor" strokeWidth="1">
        <path
          d="M13.5,6c0,4.5-5.5,9.5-5.5,9.5S2.5,10.5,2.5,6a5.5,5.5,0,0,1,11,0Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="8"
          cy="6"
          fill="none"
          r="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
