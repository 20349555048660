import { LoadingSpinner } from 'components/spinners';
import { useEventStory } from 'libs/cms';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { computeEventPreferedLanguage } from 'services';

import { useEvent } from './hooks/useEvent';

export function EventCard(props: { readonly eventId: string }) {
  const { t, activeLanguage } = useTranslation();
  const navigate = useNavigate();

  const { data: eventConfig, isError: isEventConfigError } = useEvent(
    props.eventId
  );
  const { data: eventStory, isError: isEventStoryError } = useEventStory(
    props.eventId,
    eventConfig?.storyblokSlug,
    computeEventPreferedLanguage(eventConfig?.languages ?? [], activeLanguage)
  );

  const isOnlineEvent = useMemo(() => {
    return !eventConfig?.ticketSettings.items.find(t => t.type !== 'online');
  }, [eventConfig]);

  if (isEventStoryError || isEventConfigError) {
    return null;
  }

  return (
    <button
      className="shadow-md rounded-md bg-white p-sm hover:bg-secondary transition-all flex flex-col w-full min-h-[100px]"
      onClick={() => {
        navigate(props.eventId);
      }}
    >
      {!eventStory || !eventConfig ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingSpinner smallSize />
        </div>
      ) : (
        <>
          <div className="max-h-[150px] overflow-hidden rounded-md">
            {!_.isUndefined(eventStory.imgSrc) && (
              <img
                alt=""
                src={eventStory.imgSrc}
                className="w-full object-cover object-center min-h-[150px]"
              />
            )}
          </div>

          <h2 className="text-primary mt-sm">{eventStory.title}</h2>

          <div className="text-slate-500 flex flex-row items-start gap-xs mt-sm">
            <Icon
              name={IconName.Calendar}
              size={14}
              className="w-[16px] h-[16px] m-[1px]"
            />
            <p className="pmi-caption text-left">
              {eventConfig.dates.countdown.toLocaleDateString(activeLanguage)}
            </p>
          </div>

          <div className="text-slate-500 flex flex-row items-start gap-xs mt-xs">
            <Icon
              name={IconName.Clock}
              size={14}
              className="w-[16px] h-[16px] m-[1px]"
            />
            <p className="pmi-caption text-left">
              {eventConfig.dates.countdown.toLocaleTimeString(activeLanguage)}
            </p>
          </div>

          {(!_.isUndefined(eventStory.venue) ||
            !_.isUndefined(eventStory.address)) && (
            <div className="text-slate-500 flex flex-row gap-xs mt-xs items-start">
              <Icon name={IconName.Location} size={16} />
              <p className="text-left">
                {!_.isUndefined(eventStory.venue) && (
                  <span className="pmi-caption block">{eventStory.venue}</span>
                )}
                {!_.isUndefined(eventStory.address) && (
                  <span className="pmi-caption block">
                    {eventStory.address}
                  </span>
                )}
              </p>
            </div>
          )}

          {isOnlineEvent && (
            <div className="text-slate-500 flex flex-row gap-xs mt-xs">
              <Icon name={IconName.Video} size={16} />
              <div>
                <p className="pmi-caption">{t('Online Event')}</p>
              </div>
            </div>
          )}
        </>
      )}
    </button>
  );
}
