import { useGlobalConfigsContext } from 'contexts';
import { FeatureIcon, FeatureIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import { NavLink } from 'react-router-dom';

import './PIIBadge.scss';

export function PIIBadge() {
  const { pii } = useGlobalConfigsContext();
  const { t } = useTranslation();

  if (!!pii.enableDemoPersonalData || !!pii.enableDemoBusinessData) {
    return (
      <NavLink to="/account/app-settings" className="component__pii-badge">
        <FeatureIcon name={FeatureIconName.PrivacyGuard} size={20} />
        <span className="component__pii-badge--title">
          {t('Demo Mode is On')}
        </span>
      </NavLink>
    );
  }

  return null;
}
