import { PageTitle } from 'components/layouts/page-title/PageTitle';

import { AnalyticsForm } from './components/AnalyticsForm';
import { AnalyticsResults } from './components/AnalyticsResults';
import { ActiveFiltersAndSorting } from './components/filters/ActiveFiltersAndSorting';
import { AnalyticsProvider } from './contexts/AnalyticsContext';
import { FiltersAndSortingContext } from './contexts/FiltersAndSortingContext';

export function Analytics() {
  return (
    <AnalyticsProvider>
      <PageTitle />
      <br />
      <FiltersAndSortingContext>
        <AnalyticsForm />
        <ActiveFiltersAndSorting />
      </FiltersAndSortingContext>
      <br />
      <AnalyticsResults />
    </AnalyticsProvider>
  );
}
