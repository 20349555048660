import { useTranslation } from 'libs/translations';
import type { CountdownRenderProps } from 'react-countdown';
import { padWithZeros } from 'utils';

export function CountdownRenderer(props: CountdownRenderProps) {
  const { t } = useTranslation();

  const { days, hours, minutes, seconds } = props;
  const paddedHours = padWithZeros(hours, 2);
  const paddedMinutes = padWithZeros(minutes, 2);
  const paddedSeconds = padWithZeros(seconds, 2);

  return (
    <div className="">
      {days > 0 && (
        <span className="inline-block mr-xs">
          {t('Expires in {{days}} days', {
            days
          })}
        </span>
      )}
      {days === 0 && (
        <pre className="inline-block text-[15px] leading-6">
          {`${paddedHours}:${paddedMinutes}:${paddedSeconds}`}
        </pre>
      )}
    </div>
  );
}
