import { QualificationLevel } from '@pmi.libs/react-common';
import { QualificationBadgeDescription, Badge } from 'components/badges';
import { IconName } from 'libs/icons';
import { useMemo } from 'react';
import {
  isChampionLevel,
  isManagerLevel,
  isPresidentLevel,
  isTeampartnerLevel
} from 'utils';

interface IQualificationBadgeProps {
  readonly qualification: QualificationLevel;
  readonly previousMonth?: boolean;
  readonly currentMonth?: boolean;
  readonly showIcon?: boolean;
  readonly hideDetails?: boolean;
}

export function QualificationBadge(props: IQualificationBadgeProps) {
  const isOnlyPreviousMonth = props.previousMonth && !props.currentMonth;
  const isOnlyCurrentMonth = props.currentMonth && !props.previousMonth;
  const hasIcon = props.showIcon && (isOnlyPreviousMonth || isOnlyCurrentMonth);

  const { backgroundColorClass, textColorClass } = useMemo(() => {
    if (isTeampartnerLevel(props.qualification)) {
      return {
        backgroundColorClass: '#9de1d3',
        textColorClass: '#1d6154'
      };
    }

    if (isManagerLevel(props.qualification)) {
      return {
        backgroundColorClass: '#93c1ea',
        textColorClass: '#14426b'
      };
    }

    if (isPresidentLevel(props.qualification)) {
      return {
        backgroundColorClass: '#a89be2',
        textColorClass: '#281c63'
      };
    }

    if (isChampionLevel(props.qualification)) {
      return {
        backgroundColorClass: '#fad184',
        textColorClass: '#7a5204'
      };
    }

    return {
      backgroundColorClass: undefined,
      textColorClass: undefined
    };
  }, [props.qualification]);

  const badgeComponent = (
    <Badge
      title={props.qualification}
      icon={
        hasIcon && isOnlyPreviousMonth
          ? IconName.CalendarBack
          : hasIcon && isOnlyCurrentMonth
          ? IconName.Flag
          : undefined
      }
      hideDetails={props.hideDetails}
      backgroundColorClass={backgroundColorClass}
      textColorClass={textColorClass}
      tooltipDetailsContent={<QualificationBadgeDescription />}
    />
  );

  return <>{badgeComponent}</>;
}
