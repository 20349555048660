import { IUser } from 'types';

import { IUserApi } from './types/IUserApi';
import { parseUserApi } from './UsersServiceHelper';
import { execGet } from '../RequestUtils';

export async function fetchUser(id: string): Promise<IUser> {
  const userApi = await execGet<IUserApi>(
    `${process.env.REACT_APP_USER_MANAGEMENT_API}/users/${id}`
  );
  return parseUserApi(userApi);
}
