import { useAnalyticsContext } from 'features/analytics/contexts/AnalyticsContext';
import { useFiltersAndSortingContext } from 'features/analytics/contexts/FiltersAndSortingContext';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { IoFunnelOutline } from 'react-icons/io5';

import { FilterSectionName } from '../filter-sections/FilterSectionName';

export function FiltersButton() {
  const { hasFiltersApplied } = useAnalyticsContext();
  const { openDialog } = useFiltersAndSortingContext();

  const { t } = useTranslation();

  return (
    <button
      onClick={() => openDialog(FilterSectionName.CountryAndPerformance)}
      className="w-fit flex flex-row items-center justify-center flex-nowrap gap-xs line-clamp-1 text-ellipsis text-active font-bold relative"
    >
      <IoFunnelOutline size={16} />
      {hasFiltersApplied && (
        <div className="bg-red-600 w-[8px] h-[8px] rounded-full absolute inset-0 left-2.5 top-0.5"></div>
      )}
      <span className="relative">{t('Filters')}</span>
    </button>
  );
}
