import { useGlobalConfigsContext } from 'contexts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';

import { piiCountryCode, piiName, piiUserId } from './PIIHelper';
import { TPII } from './TPII';

const PII_TYPES__DEMO_MODE: ReadonlyArray<TPII | undefined> = [
  undefined,
  'country',
  'name',
  'tpid',
  'fixed-date',
  'timestamp',
  'address',
  'phone',
  'email',
  'online-meeting-id',
  'qrcode',
  'payment-instrument',
  'ids',
  'link'
];
const PII_TYPES__DEMO_NUMBERS_MODE: ReadonlyArray<TPII> = [
  'currency',
  'points',
  'number'
];

export function usePII() {
  const { activeLanguage } = useTranslation();
  const { pii } = useGlobalConfigsContext();

  const piiFn = <T,>(value: T, type: TPII): T | string => {
    if (pii.enableDemoPersonalData && PII_TYPES__DEMO_MODE.includes(type)) {
      return piiDataTransformer(value, type, activeLanguage);
    }

    if (
      pii.enableDemoBusinessData &&
      !_.isUndefined(type) &&
      PII_TYPES__DEMO_NUMBERS_MODE.includes(type)
    ) {
      return piiDataTransformer(value, type, activeLanguage);
    }

    return value;
  };

  return {
    pii: piiFn,
    isPrivacyGuardEnabled:
      pii.enableDemoPersonalData || pii.enableDemoBusinessData
  };
}

function piiDataTransformer<T>(
  value: T,
  type: TPII,
  activeLanguage?: string
): T | string {
  switch (type) {
    case 'country':
      return piiCountryCode(value);
    case 'name':
      return piiName(value);
    case 'tpid':
      return piiUserId(value);
    case 'timestamp':
      return (
        new Intl.DateTimeFormat(activeLanguage, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
          // eslint-disable-next-line @miovision/disallow-date/no-static-date
          .format(Date.now())
          .replace(/\d/g, '•')
      );
    case 'fixed-date':
      return (
        new Intl.DateTimeFormat(activeLanguage, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
          // eslint-disable-next-line @miovision/disallow-date/no-static-date
          .format(Date.now())
          .replace(/\d/g, '•')
      );
    case 'currency':
      return (
        (value as string)
          ?.replace(/[\d., \u00A0]/g, '•')
          ?.replace(/•+/, ' •••• ')
          .trim() ?? '•••'
      );
    case 'payment-instrument':
      return '••••';
    case 'points':
      return '••••';
    case 'number':
      return '••••';
    case 'qrcode':
      return '/imgs/BluredQRCode.png';
    case 'address':
      return '••••••••••••';
    case 'phone':
      return '••••••••••••';
    case 'email':
      return '••••••••••••';
    case 'online-meeting-id':
      return '••••••••••••';
    case 'ids':
      return '••••••-••••-••••••';
    default:
      return '••••••••••••';
  }
}
