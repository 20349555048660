import { computePeriodOptions } from 'components/period-selection';
import { useTranslation } from 'libs/translations';
import { useFormContext } from 'react-hook-form';

import { FilterSection } from '../../filter-sections/FilterSection';
import { FilterSectionName } from '../../filter-sections/FilterSectionName';

const PERIOD_OPTIONS = computePeriodOptions();

export function PeriodFilter() {
  const { activeLanguage } = useTranslation();
  const { register } = useFormContext();

  return (
    <FilterSection name={FilterSectionName.Period}>
      <fieldset className="analytics-filters-fieldset">
        {PERIOD_OPTIONS.map(period => (
          <label className="label-radio" key={period.toString()}>
            <input
              type="radio"
              {...register('period')}
              value={period.toString()}
            />
            <span>{period.toLocaleString(activeLanguage)}</span>
          </label>
        ))}
      </fieldset>
    </FilterSection>
  );
}
