import { ErrorHandler } from 'components/errors/ErrorHandler';
import { useBoolean } from 'hooks';
import _ from 'lodash';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  getUserPayoutDetails,
  setBonusPayoutMethod,
  setDirectCashPayoutMethod,
  deactivateDirectCash,
  activateDirectCash
} from 'services';
import {
  BonusPayoutMethod,
  DirectCashPayoutMethod,
  IUserPayoutDetails
} from 'types';

interface IPayoutsContextValues {
  updatePayouts: (options: {
    bonusMethod?: BonusPayoutMethod;
    directCashMethod?: DirectCashPayoutMethod;
    activeDirectCashState?: boolean;
  }) => Promise<void>;
  readonly payoutDetails?: IUserPayoutDetails;
  readonly contextState: {
    readonly loading: boolean;
    readonly error?: Error;
    readonly payoutUpdated: boolean;
  };
}

export const PayoutsContext = createContext<IPayoutsContextValues>({
  updatePayouts: () => Promise.resolve(),
  contextState: { loading: false, payoutUpdated: false }
});

interface IPayoutsProviderProps {
  readonly children: ReactNode;
}

export function PayoutsProvider(props: IPayoutsProviderProps) {
  const [payoutDetails, setPayoutDetails] = useState<IUserPayoutDetails>();
  const [loading, { setTrue: startLoading, setFalse: stopLoading }] =
    useBoolean(false);
  const [error, setError] = useState<Error>();
  const [
    payoutUpdated,
    { setTrue: setPayoutUpdatedTrue, setFalse: setPayoutUpdatedFalse }
  ] = useBoolean();

  const loadContent = useCallback(() => {
    startLoading();
    return getUserPayoutDetails()
      .then(setPayoutDetails)
      .catch(setError)
      .finally(() => stopLoading());
  }, [startLoading, stopLoading]);

  const updatePayouts = async (options: {
    bonusMethod?: BonusPayoutMethod;
    directCashMethod?: DirectCashPayoutMethod;
    activeDirectCashState?: boolean;
  }) => {
    if (_.isUndefined(payoutDetails)) {
      throw new Error('Updating too early...');
    }

    if (!_.isUndefined(options.bonusMethod)) {
      await setBonusPayoutMethod(options.bonusMethod);
    }

    if (!_.isUndefined(options.directCashMethod)) {
      await setDirectCashPayoutMethod(options.directCashMethod);
    }

    if (!_.isUndefined(options.activeDirectCashState)) {
      if (options.activeDirectCashState) {
        await activateDirectCash();
      } else {
        await deactivateDirectCash();
      }
    }

    setPayoutUpdatedTrue();

    await loadContent();
  };

  useEffect(() => {
    loadContent();

    return () => {
      setPayoutUpdatedFalse();
    };
  }, [loadContent, setPayoutUpdatedFalse]);

  if (error) {
    return <ErrorHandler err={error} />;
  }

  return (
    <PayoutsContext.Provider
      value={{
        payoutDetails: payoutDetails,
        contextState: {
          loading: loading,
          error: error,
          payoutUpdated: payoutUpdated
        },
        updatePayouts
      }}
    >
      {props.children}
    </PayoutsContext.Provider>
  );
}

export function usePayoutsContext() {
  const context = useContext(PayoutsContext);

  if (context === undefined) {
    throw new Error('usePayoutsContext must be used within PayoutsContext');
  }

  return context;
}
