import { UserAvatar } from 'components/icons';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useStepI18n } from 'features/shared-baskets/hooks/useStepI18n';
import { useTranslation } from 'libs/translations';
import { ReactNode, useMemo, useState } from 'react';

import { WizardSectionWrapper } from '../WizardSectionWrapper';
import { SelectFirstlineCustomer } from './SelectFirstlineCustomer';
import { SelectNewProspect } from './SelectNewProspect';
import { SelectTeamparter } from './SelectTeampartner';

export function OwnerSelectionStep() {
  const { t } = useTranslation();
  const {
    isBasketOwnerTeampartner,
    isBasketOwnerCustomer,
    isBasketOwnerProspect,
    id: activeStep,
    steps,
    allowedBasketOnwerType,
    dispatchStepEvent
  } = useNewBasketContext();
  const tStep = useStepI18n();

  const { completed, active, step, title } = useMemo(() => {
    const step = steps[0];
    const completed = [steps[1], steps[2], steps[3]].includes(activeStep);
    const active = activeStep === step;
    const title = tStep(step, completed) ?? '';

    return {
      step,
      completed,
      active,
      title
    };
  }, [activeStep, steps, tStep]);

  const [userType, setUserType] = useState<
    'teampartner' | 'customer' | 'prospect' | undefined
  >(
    isBasketOwnerTeampartner
      ? 'teampartner'
      : isBasketOwnerCustomer
      ? 'customer'
      : isBasketOwnerProspect
      ? 'prospect'
      : undefined
  );

  return (
    <WizardSectionWrapper
      step={step}
      index={1}
      completed={completed}
      title={title}
      active={active}
      onEdit={() => {
        dispatchStepEvent({ type: 'edit-step', value: 'owner' });
      }}
    >
      <RadioGroup
        items={[
          {
            id: 'prospect',
            title: t('New Customer'),
            description: t('Share basket with a new Customer'),
            icon: <UserAvatar userType="prospect" />,
            isSelected: userType === 'prospect',
            onClick: () => setUserType('prospect'),
            disabled: !allowedBasketOnwerType.includes('Prospect')
          },
          {
            id: 'customer',
            title: t('Existing Customer'),
            description: t('Share basket with one of your existing Customers'),
            icon: <UserAvatar userType="customer" />,
            isSelected: userType === 'customer',
            onClick: () => setUserType('customer'),
            disabled: !allowedBasketOnwerType.includes('Customer')
          },
          {
            id: 'teampartner',
            title: t('Team Partner'),
            description: t(
              'Share basket with a Team Partner in your downline up to 3rd level'
            ),
            icon: <UserAvatar userType="teampartner" />,
            isSelected: userType === 'teampartner',
            onClick: () => setUserType('teampartner'),
            disabled: !allowedBasketOnwerType.includes('BusinessBuilder')
          }
        ]}
      />

      <div className="pmi-caption flex justify-end w-full mt-xxs transition-all">
        <a
          className="hover:underline hover:text-active"
          href={process.env.REACT_APP_LINK_REGISTRATION ?? ''}
          target="_blank"
          rel="noreferrer"
        >
          {t('Register new Team Partner')}
        </a>
      </div>

      <br />

      {userType === 'customer' && <SelectFirstlineCustomer />}
      {userType === 'teampartner' && <SelectTeamparter />}
      {userType === 'prospect' && <SelectNewProspect />}
    </WizardSectionWrapper>
  );
}

function RadioGroup({
  items
}: {
  readonly items: ReadonlyArray<IRadioItemProps>;
}) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-sm">
      {items.map(i => (
        <RadioItem {...i} key={i.id} />
      ))}
    </div>
  );
}

interface IRadioItemProps {
  readonly id: string;
  readonly icon: ReactNode;
  readonly title: string;
  readonly description: string;
  readonly isSelected: boolean;
  readonly onClick: () => void;
  readonly disabled?: boolean;
}
function RadioItem({
  icon,
  title,
  description,
  isSelected,
  onClick,
  disabled = false
}: IRadioItemProps) {
  const isDisabled = disabled;
  const internalOnClick = isDisabled ? undefined : onClick;

  return (
    <button
      className={`transition-all flex flex-col p-sm items-start text-left rounded border ${
        isSelected
          ? 'bg-secondary border-secondary'
          : 'bg-lightGray border-lightGray'
      } ${isDisabled ? 'opacity-50' : 'hover:border-primary'}`}
      onClick={internalOnClick}
      disabled={isDisabled}
    >
      <div className="flex flex-row justify-between items-start w-full">
        <div className="flex flex-row items-center gap-xs md:flex-col md:items-start">
          {icon}
          <span className="line-clamp-1 text-ellipsis block md:hidden">
            {title}
          </span>
        </div>
        {!isDisabled && (
          <span
            className={`w-6 h-6 rounded-full flex justify-center items-center flex-none ${
              isSelected ? 'bg-active' : 'border border-silver'
            }`}
          >
            <span
              className={`w-2.5 h-2.5 rounded-full ${
                isSelected ? 'bg-white' : ''
              } align-middle`}
            />
          </span>
        )}
      </div>
      <span className="line-clamp-1 text-ellipsis md:block hidden mt-xs">
        {title}
      </span>
      <span className="text-sm text-silver mt-xs">{description}</span>
    </button>
  );
}
