import { useTranslation } from 'libs/translations';

import { FilterSectionName } from './FilterSectionName';

export function useFilterSectionTitle(section: FilterSectionName) {
  const { t } = useTranslation();

  switch (section) {
    case FilterSectionName.Period:
      return t('Period');
    case FilterSectionName.CountryAndPerformance:
      return t('Country & Performance');
    case FilterSectionName.Qualification:
      return t('Qualification');
    case FilterSectionName.StructureSales:
      return t('Structure Sales');
    case FilterSectionName.SortBy:
      return t('Sort by');
  }
}
