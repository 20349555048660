export enum CommitmentStatus {
  Active = 'active',
  Fulfilled = 'fulfilled',
  PaymentRequired = 'paymentrequired',
  Unfulfilled = 'unfulfilled',

  /**
   * "Expired" is an artificial status. This status does not exist, the API
   * will never return this valus. However, since the API runs period jobs to
   * handle commitments where the expirationDate has passed, it might happen
   * that a commitment status is "active" with a past expiration date for a
   * specific moment in time. For UI consistency, when this happens or when
   * the commitment expires while the commitment is shown on the screen, the UI
   * forces the status to "expired" until the API computes the new status (either
   * fulfilled, paymentrequired or unfilfilled).
   */
  Expired = 'expired'
}
