import { Icon, IconName } from 'libs/icons';
import { SyntheticEvent } from 'react';

import './IconButton.scss';

interface IIconButtonProps {
  readonly name: IconName;
  readonly size: 16 | 20;
  readonly onClick: (event: SyntheticEvent) => void;
}

export function IconButton(props: IIconButtonProps) {
  return (
    <button
      className="comp__icon-button"
      title={undefined}
      onClick={props.onClick}
    >
      <Icon name={props.name} size={props.size} />
    </button>
  );
}
