import { ScrollLock } from 'components/ScrollLock';
import { useKeydownWindowEvent } from 'hooks';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { FaXmark } from 'react-icons/fa6';

interface IPromoVideoProps extends IPromoVideoContentProps {
  readonly open: boolean;
}

export function PromoVideo({ open, onDismiss }: IPromoVideoProps) {
  if (!open) {
    return null;
  }

  return <PromoVideoContent onDismiss={onDismiss} />;
}

interface IPromoVideoContentProps {
  readonly onDismiss: () => void;
}

function PromoVideoContent({ onDismiss }: IPromoVideoContentProps) {
  const { t } = useTranslation();

  useKeydownWindowEvent(keyboardEvent => {
    if (keyboardEvent.key.toLowerCase() === 'escape') {
      onDismiss();
    }
  });

  return (
    <div className="fixed inset-0 w-screen h-screen bg-black">
      <ScrollLock>
        <iframe
          src="
      https://player.vimeo.com/video/954736145?h=87994b3e95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title="Virtual Warehouse Tutorial"
          className="absolute inset-0 p-lg w-full h-full"
        ></iframe>
        <div className="fixed top-lg right-lg">
          <button
            className="bg-white text-silver rounded-full p-xs flex justify-end"
            onClick={onDismiss}
            title={t('Close')}
          >
            <FaXmark size={20} />
          </button>
        </div>
      </ScrollLock>
    </div>
  );
}
