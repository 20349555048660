export function BanOutlineIcon(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" stroke="currentColor" strokeWidth="1">
        <circle
          cx="8"
          cy="8"
          fill="none"
          r="7.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="2.697"
          x2="13.303"
          y1="13.303"
          y2="2.697"
        />
      </g>
    </svg>
  );
}
