import { PrimaryButton } from '@pmi.web/react-common';
import { useAnalyticsContext } from 'features/analytics/contexts/AnalyticsContext';
import { ISearchFormValues } from 'features/analytics/types/ISearchFormValues';
import { useTranslation } from 'libs/translations';
import { FormProvider, useForm } from 'react-hook-form';
// eslint-disable-next-line no-restricted-imports
import { IoSearch } from 'react-icons/io5';

import { SearchInput } from './SearchInput';

export function SearchForm() {
  const { searchParams, setSearchValues } = useAnalyticsContext();

  const searchMethods = useForm<ISearchFormValues>({
    defaultValues: searchParams
  });

  const onSubmitSearch = (data: ISearchFormValues) => {
    setSearchValues(data);
  };

  const { t } = useTranslation();

  return (
    <FormProvider {...searchMethods}>
      <form
        onSubmit={searchMethods.handleSubmit(onSubmitSearch)}
        className="flex flex-row gap-xs"
      >
        <div className="flex-grow">
          <SearchInput />
        </div>
        <div>
          <PrimaryButton>
            <IoSearch size={24} className="block sm:hidden" />
            <span className="sm:block hidden">{t('Search')}</span>
          </PrimaryButton>
        </div>
      </form>
    </FormProvider>
  );
}
