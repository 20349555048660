import { IUserProfile } from 'types';

import './DownlineGroup.scss';
import { DownlineItem } from './DownlineItem';

interface IDownlineGroupProps {
  readonly items: ReadonlyArray<IUserProfile>;
  readonly highlightedUserId?: string;
  readonly onUserClick: (userId: string) => void;
  readonly onUserExpansion: (userId: string) => void;
}

export function DownlineGroup(props: IDownlineGroupProps) {
  return (
    <div className="downline-group">
      {props.items.map(i => (
        <DownlineItem
          {...i}
          highlighted={i.userId === props.highlightedUserId}
          key={i.userId}
          onUserExpansion={() => props.onUserExpansion(i.userId)}
          onUserClick={() => props.onUserClick(i.userId)}
        />
      ))}
    </div>
  );
}
