import { ReactNode } from 'react';

interface IFullScreenErrorProps {
  readonly children: ReactNode;
}

export function FullScreenError(props: IFullScreenErrorProps) {
  return (
    <div className="fixed inset-0 w-screen h-screen bg-body">
      <div className="flex flex-col w-full h-full items-center justify-center">
        {props.children}
      </div>
    </div>
  );
}
