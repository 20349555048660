import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import { useLocation, useNavigate } from 'react-router-dom';

import './DownlineBackButton.scss';

export function DownlineBackButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <button
      title={t('My Team')}
      className="downline-back-button"
      onClick={() => navigate(location.pathname.replace('/dashboard', ''))}
    >
      <Icon name={IconName.ChevronLeft} size={16} />
      <span className="ml-xs">{t('My Team')}</span>
    </button>
  );
}
