import {
  QueryCache,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { isApiError } from 'errors';
import { ReactNode } from 'react';
import AuthenticationService from 'services/AuthenticationService';

interface IQueryProviderProps {
  readonly children: ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  },
  queryCache: new QueryCache({
    onError: (_error, query) => {
      if (
        query.state.status === 'error' &&
        isApiError(query.state.error) &&
        query.state.error.status === 401
      ) {
        const location = window?.location;
        AuthenticationService.startSignIn(
          JSON.stringify({
            search: location.search,
            hash: location.hash,
            pathname: location.pathname
          })
        );
      }
    }
  })
});

export function QueryProvider({ children }: IQueryProviderProps) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
