import { useInfiniteQuery } from '@tanstack/react-query';
import AuthenticationService from 'services/AuthenticationService';
import { getWalletTransactions } from 'services/wallet';

const WALLET_QUERY_KEY = 'pmi.web.office__ledger_transactions';

export function useWalletTransactions(userId: string) {
  const query = useInfiniteQuery({
    queryKey: [WALLET_QUERY_KEY, userId],
    queryFn: fetchTransactions,
    initialPageParam: 1,
    getNextPageParam: lastPage =>
      lastPage.hasNextPage ? lastPage.pageNumber + 1 : undefined,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}

const DEFAULT_PAGE_SIZE = 10;

function fetchTransactions({ pageParam }: { readonly pageParam: number }) {
  return getWalletTransactions(
    AuthenticationService.user.userId,
    pageParam,
    DEFAULT_PAGE_SIZE
  );
}
