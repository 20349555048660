import { Disclaimer } from 'components/text';
import { ApiError } from 'errors';

interface IBadRequestErrorProps {
  readonly err?: ApiError;
}

export function BadRequestError(props: IBadRequestErrorProps) {
  return (
    <div className="w-fit h-fit flex flex-col items-center justify-center space-y-xs">
      <object
        title="Bad Request Error Illustration"
        className="inline-block h-error-img-sm"
        type="image/svg+xml"
        data="/imgs/errors/BadRequestError.svg"
      ></object>
      <div className="max-w-error-msg">
        <Disclaimer
          type="error"
          message={props.err?.message ?? '400 Bad Request'}
        />
      </div>
    </div>
  );
}
