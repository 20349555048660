import { ContentContainer } from 'components/containers';
import { HelperModal } from 'components/dialogs';
import { useBoolean } from 'hooks';
import { FeatureIcon, FeatureIconName } from 'libs/icons';
import { trackEvent } from 'libs/telemetry';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { ReactNode, useEffect } from 'react';

import './Widget.scss';
import { WidgetDetailsButton } from './WidgetDetailsButton';

interface IWidgetProps {
  readonly title: string;
  readonly tooltip?: string;
  readonly iconName?: FeatureIconName;
  readonly content: ReactNode;
  readonly detailedView?: ReactNode;
  readonly detailedViewMaxWidth?: number;
  readonly traceId: string;
  readonly detailedViewButtonLabel?: string;
}

export function Widget(props: IWidgetProps) {
  const { t } = useTranslation();

  const [
    isDetailedViewOpen,
    { setFalse: closeDetailedView, setTrue: openDetailedView }
  ] = useBoolean(false);
  const [
    isHelperMenuOpen,
    { setFalse: closeHelperMenu, setTrue: openHelperMenu }
  ] = useBoolean(false);

  useEffect(() => {
    if (isDetailedViewOpen === true) {
      trackEvent({
        name: `WidgetDetailsClick_${props.traceId}`
      });
    }
  }, [isDetailedViewOpen, props.title, props.traceId]);

  return (
    <>
      <div className="component__widget">
        <h4
          className="widget-title"
          title={props.tooltip}
          onClick={!_.isUndefined(props.tooltip) ? openHelperMenu : undefined}
        >
          {!_.isUndefined(props.iconName) && (
            <FeatureIcon name={props.iconName} size={16} />
          )}
          {props.title}
        </h4>
        <div className="widget-content-wrapper">{props.content}</div>
        {props.detailedView && (
          <div className="widget-details-button">
            <WidgetDetailsButton
              onClick={() => openDetailedView()}
              label={props.detailedViewButtonLabel ?? t('Details')}
            />
          </div>
        )}
      </div>

      <ContentContainer
        title={props.title}
        icon={
          props.iconName !== undefined && (
            <FeatureIcon
              name={props.iconName}
              size={16}
              className="inline-block mr-xs"
            />
          )
        }
        onDismiss={() => closeDetailedView()}
        open={isDetailedViewOpen}
        maxWidth={props.detailedViewMaxWidth}
      >
        {props.detailedView}
      </ContentContainer>

      {isHelperMenuOpen && (
        <HelperModal onDismiss={closeHelperMenu}>
          <h1 className="flex flex-row items-center w-fit gap-xs">
            {props.iconName && <FeatureIcon name={props.iconName} size={24} />}
            {props.title}
          </h1>
          <p>{props.tooltip}</p>
        </HelperModal>
      )}
    </>
  );
}
