import { Disclaimer } from 'components/text';
import { ApiError } from 'errors';

interface IInternalErrorProps {
  readonly err?: ApiError;
}

export function InternalError(props: IInternalErrorProps) {
  return (
    <div className="w-fit h-fit flex flex-col items-center justify-center space-y-xs">
      <object
        title="Internal Error Illustration"
        className="inline-block h-error-img-sm"
        type="image/svg+xml"
        data="/imgs/errors/InternalError.svg"
      ></object>
      <div className="max-w-error-msg">
        <Disclaimer
          type="error"
          message={props.err?.message ?? '500 Internal Server Error'}
        />
      </div>
    </div>
  );
}
