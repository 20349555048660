import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthenticationService from 'services/AuthenticationService';

interface IAuthenticatedRouteProps {
  readonly children: ReactNode;
}

export function AuthenticatedRoute(props: IAuthenticatedRouteProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const [isReady, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (!AuthenticationService.isSignedIn) {
      // This state will be recovered on the identity redirect to the auth callback
      // URL. Please ensure the state passed here is properly handled in file
      // "routes/callback.tsx"
      const currentLocationState = JSON.stringify(location);
      AuthenticationService.startSignIn(currentLocationState);
    } else if (AuthenticationService.isAllowedToUseThisOffice) {
      setReady(true);
    } else {
      navigate('/401');
    }
  }, [location, navigate]);

  if (isReady) {
    return <>{props.children}</>;
  }

  return null;
}
