import { SwitchButton } from 'components/buttons/SwitchButton';
import { Modal } from 'components/dialogs';
import { useGlobalConfigsContext } from 'contexts';
import { Icons } from 'libs/icons';
import { useState } from 'react';

import { PreviewIds } from './PreviewIds';

export function PreviewSwitch() {
  const {
    previewMode: { isPreviewEnabled, setPreviewMode }
  } = useGlobalConfigsContext();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <button
        title="Preview Configurations"
        onClick={() => setModalOpen(true)}
        className="text-[#FC3A52] relative"
      >
        <PreviewUserIcon size={20} />
        {isPreviewEnabled ? (
          <Icons.Common.Checkmark
            size={16}
            className="text-white bg-success rounded-full absolute -top-2.5 -right-2.5 border border-white"
          />
        ) : (
          <Icons.Common.Cancel
            size={16}
            className="text-error bg-white rounded-full absolute -top-2.5 -right-2.5"
          />
        )}
      </button>
      <Modal
        noPadding
        noBg
        hideCloseBtn
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
      >
        <div className="bg-[#0E2431] shadow-md rounded-md text-[#FC3A52] p-sm space-y-sm">
          <h1 className="flex flex-row gap-xs items-center font-bold justify-center">
            <PreviewUserIcon size={32} />
            <span>Preview</span>
          </h1>

          <div className="flex flex-row items-baseline gap-xs justify-between">
            <h2>Show Preview Features</h2>
            <SwitchButton
              isOn={isPreviewEnabled}
              onClick={on => {
                setPreviewMode(on);
              }}
            />
          </div>

          <div className="flex flex-col gap-xs">
            <h2>Preview Features</h2>
            <ul className="text-white text-sm">
              {Object.values(PreviewIds).map(id => (
                <li key={id}>{id}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex flex-row justify-center mt-sm">
          <button
            onClick={() => {
              setModalOpen(false);
            }}
            className="rounded-full shadow-md text-[#0E2431] bg-[#FC3A52] font-bold px-md py-xs"
          >
            OK
          </button>
        </div>
      </Modal>
    </>
  );
}

function PreviewUserIcon(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <circle cx="9.5" cy="12.5" r="1.5" />
        <path
          d="M15,6.523V2h2V0H7V2H9V6.523a9,9,0,1,0,6,0ZM12,15a6.986,6.986,0,0,1-7,.083c0-.028,0-.055,0-.083a6.994,6.994,0,0,1,6-6.92V2h2V8.08a6.993,6.993,0,0,1,6,6.837A6.986,6.986,0,0,0,12,15Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
