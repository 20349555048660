import { InvoiceProductList } from 'features/invoices';
import { IBasket } from 'types';

interface IBasketProductsProps extends IBasket {
  readonly showPoints: boolean;
}

export function BasketProducts({
  showPoints,
  ...basketProps
}: IBasketProductsProps) {
  return (
    <InvoiceProductList
      showPoints={showPoints}
      items={basketProps.basket.items.map(i => ({
        articleNumber: i.articleNumber,
        currencyCode: i.details.currencyCode,
        itemPrice: i.details.displayPrice,
        points: i.details.points,
        quantity: i.quantity,
        name: i.details.attributes.name?.value,
        imgSrc: i.details.media.find(m => m.type === 'image' && !!m.url)?.url,
        addOnItems: i.addOnItems
      }))}
    />
  );
}
