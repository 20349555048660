import type { FC } from 'react';

export const CheckPaymentFillIcon: FC<{ readonly size: number }> = ({
  size
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
    className="flex-shrink-0 flex-grow-0"
  >
    <g fill="currentColor" stroke="currentColor" strokeWidth="1">
      <polygon
        fill="none"
        points="10.5 0.5 12.5 2.5 7.5 7.5 4.5 8.5 5.5 5.5 10.5 0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        fill="none"
        points="11.5 6.5 15.5 6.5 15.5 15.5 0.5 15.5 0.5 6.5 2.5 6.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="3.5"
        x2="12.5"
        y1="11.5"
        y2="11.5"
      />
    </g>
  </svg>
);
