import { SupportedLanguage } from '@pmi.web/localization';
import { useQuery } from '@tanstack/react-query';

import { fetchChangelogStory } from './ChangelogStoryblok';

const CHANGELOG_SLUG = 'whats-new';

const WHATSNEW_QUERY_KEY = 'pmi.web.office__whatsnew';
const TTL = 900000; // 15 minutes in milliseconds

export function useChangelog(language: SupportedLanguage) {
  const query = useQuery({
    queryKey: [WHATSNEW_QUERY_KEY, CHANGELOG_SLUG, language],
    queryFn: () => fetchChangelogStory(CHANGELOG_SLUG, language),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
