import { BottomSheetContainer } from 'components/modals';
import { useAnalyticsContext } from 'features/analytics/contexts/AnalyticsContext';
import { useFiltersAndSortingContext } from 'features/analytics/contexts/FiltersAndSortingContext';
import { IFilterFormValues } from 'features/analytics/types/IFilterFormValues';
import { useTranslation } from 'libs/translations';
import { FormProvider, useForm } from 'react-hook-form';

import { FiltersForm } from './FiltersForm';

export function FiltersDialog() {
  const { filterParams, setFiltersValues } = useAnalyticsContext();
  const { closeDialog, isFiltersDialogOpen } = useFiltersAndSortingContext();

  const filterMethods = useForm<IFilterFormValues>({
    defaultValues: filterParams
  });

  const onSubmitFilters = (data: IFilterFormValues) => {
    setFiltersValues(data);
    closeDialog();
  };

  const { t } = useTranslation();

  return (
    <BottomSheetContainer
      open={isFiltersDialogOpen}
      onDismiss={closeDialog}
      header={
        <div className="flex justify-center items-center py-sm">
          <h2 className="font-bold">{t('Filter by')}</h2>
        </div>
      }
    >
      <FormProvider {...filterMethods}>
        <form onSubmit={filterMethods.handleSubmit(onSubmitFilters)}>
          <FiltersForm />
        </form>
      </FormProvider>
    </BottomSheetContainer>
  );
}
