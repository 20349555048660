export function FilterIcon(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" stroke="currentColor" strokeWidth="1">
        <line
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="10.5"
          x2="15.5"
          y1="3.5"
          y2="3.5"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1=".5"
          x2="2.5"
          y1="3.5"
          y2="3.5"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="5.5"
          x2=".5"
          y1="12.5"
          y2="12.5"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="15.5"
          x2="13.5"
          y1="12.5"
          y2="12.5"
        />
        <circle
          cx="5"
          cy="3.5"
          fill="none"
          r="2.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="11"
          cy="12.5"
          fill="none"
          r="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
