import { execGet } from 'services/RequestUtils';
import { IPaginatedResponse, IWalletAccount, IWalletTransaction } from 'types';

import { IWalletAccountApi } from './types/IWalletAccountApi';
import { IWalletTransactionsApi } from './types/IWalletTransactionsApi';
import {
  parseWalletAccount,
  parseWalletTransactions
} from './WalletServiceHelper';

export async function getWalletAccount(
  userId: string
): Promise<IWalletAccount> {
  const response = await execGet<IWalletAccountApi>(
    `${process.env.REACT_APP_LEDGER_API}/account/${userId}`
  );

  return parseWalletAccount(response);
}

export async function getWalletTransactions(
  accountId: string,
  pageNumber: number,
  pageSize: number
): Promise<IPaginatedResponse<IWalletTransaction>> {
  const searchParams = new URLSearchParams();
  searchParams.set('accountId', accountId);
  // ... because the first page is number 0
  searchParams.set('transactionPageNumber', `${pageNumber - 1}`);
  searchParams.set('transactionPageSize', `${pageSize}`);

  const response = await execGet<IWalletTransactionsApi>(
    `${
      process.env.REACT_APP_LEDGER_API
    }/report/account?${searchParams.toString()}`
  );

  return parseWalletTransactions(response);
}
