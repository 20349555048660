import _ from 'lodash';

export const nonEmptyStringOrUndefined = (
  value?: string
): string | undefined => {
  if (!_.isUndefined(value) && !_.isNull(value) && value !== '') {
    return value;
  }

  return undefined;
};
