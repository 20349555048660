import { FixedDate, Timestamp } from '@pmi.libs/utils-common';
import { parseCountryCode } from '@pmi.web/countries';
import _ from 'lodash';
import { IUserProfile } from 'types';
import { parseQualificationLevel } from 'utils';

import { IUserProfileApi } from './types/IUserProfileApi';

export function sanitizeUserProfile(
  apiUserProfile: IUserProfileApi,
  // The requestedUserId is needed here for testing purposes only. On test
  // environments, when requesting user A, API responds with user B which
  // can lead to issues in the frontend. To avoid those, the user profile ID
  // must be replaced by the requestedUserId in case they are different
  requestedUserId?: string
): IUserProfile {
  return {
    ...apiUserProfile,
    countryCode: parseCountryCode(apiUserProfile.countryCode),
    calculationDate: _.isUndefined(apiUserProfile.calculationDate)
      ? undefined
      : Timestamp.create(apiUserProfile.calculationDate),
    phoneNumber:
      parseString(apiUserProfile.mobileNumber) ??
      parseString(apiUserProfile.phoneNumber) ??
      undefined,
    address:
      Object.keys(apiUserProfile.address ?? {}).length === 0
        ? undefined
        : apiUserProfile.address,
    email: parseString(apiUserProfile.email),
    rank: parseQualificationLevel(apiUserProfile.rank),
    qualification: parseQualificationLevel(apiUserProfile.qualification),
    userId: _.isUndefined(requestedUserId)
      ? apiUserProfile.userId + ''
      : apiUserProfile.userId + '' === requestedUserId
      ? apiUserProfile.userId + ''
      : requestedUserId,
    isQuickStarter: apiUserProfile.hasSpeedstarter,
    isNewRegistration: apiUserProfile.isNewRegistration ?? false,
    registrationDate: apiUserProfile.registrationDate
      ? FixedDate.create(apiUserProfile.registrationDate)
      : undefined,
    birthDate: apiUserProfile.birthDate
      ? FixedDate.create(apiUserProfile.birthDate)
      : undefined,
    sponsor: {
      userId: apiUserProfile.upline.sponsor.userId + '',
      name: apiUserProfile.upline.sponsor.name
    },
    coach: apiUserProfile.upline.coach
      ? {
          userId: apiUserProfile.upline.coach.userId + '',
          name: apiUserProfile.upline.coach.name
        }
      : undefined,
    businessDataSummary: !_.isUndefined(apiUserProfile.businessData)
      ? {
          hasCheckAssurance:
            apiUserProfile.businessData.hasCheckAssurance ?? false,
          ownVolume: apiUserProfile.businessData.ownVolume?.current ?? 0,
          sixLevelVolume:
            apiUserProfile.businessData.sixLevelVolume?.current ?? 0,
          structureVolume: {
            current: apiUserProfile.businessData.structureVolume?.current ?? 0,
            lastMonth:
              apiUserProfile.businessData.structureVolume?.lastMonth ?? 0
          },
          qualificationLastMonth: parseQualificationLevel(
            apiUserProfile.businessData.qualificationLastMonth
          ),
          ownAutoships: apiUserProfile.businessData.ownAutoships.map(
            ownAutoship => {
              return {
                ...ownAutoship,
                lastExecutionDate: ownAutoship.lastExecutionDate
                  ? FixedDate.create(ownAutoship.lastExecutionDate)
                  : undefined,
                nextExecutionDate: ownAutoship.nextExecutionDate
                  ? FixedDate.create(ownAutoship.nextExecutionDate)
                  : undefined
              };
            }
          )
        }
      : undefined,
    hasDownline: apiUserProfile.hasDownline ?? false,
    hasSponsorActivity: apiUserProfile.hasSponsorActivity ?? false,
    isFitlineClubMember: apiUserProfile.isFitlineClubMember ?? false
  };
}

function parseString(value: unknown): string | undefined {
  if (!_.isUndefined(value) && typeof value === 'string' && value.length > 0) {
    return value as string;
  }

  return undefined;
}
