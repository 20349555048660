export enum DiscountType {
  Percentage = 'percentage',
  Amount = 'amount'
}

export interface IDiscount {
  readonly id: string;
  readonly type: DiscountType;
  readonly value: number;
}

export const filterByType = (
  discounts: ReadonlyArray<IDiscount>,
  type: DiscountType
): ReadonlyArray<IDiscount> => {
  return discounts.filter(discount => discount.type === type);
};

export const groupByType = (
  discounts: ReadonlyArray<IDiscount>
): Record<string, ReadonlyArray<IDiscount>> => {
  return discounts.reduce(
    (grouped: Record<string, IDiscount[]>, discount: IDiscount) => {
      if (!grouped[discount.type]) {
        grouped[discount.type] = [];
      }
      grouped[discount.type].push(discount);
      return grouped;
    },
    {}
  );
};

export const getTotalValue = (discounts: ReadonlyArray<IDiscount>): number => {
  return discounts.reduce((sum, discount) => sum + discount.value, 0);
};

export const getTotalValueByType = (
  discounts: ReadonlyArray<IDiscount>,
  type: DiscountType
): number => {
  return getTotalValue(filterByType(discounts, type));
};
