import {
  compressToEncodedURIComponent as LZStringCompress,
  decompressFromEncodedURIComponent as LZStringDecompress
} from 'lz-string';

export function encodeArray(arr: unknown[]) {
  return encode(arr.join('-'));
}

export function decodeAsArray(encodedArray: string): string[] {
  return decode(encodedArray).split('-');
}

export function encode(uncompressedString: string) {
  return LZStringCompress(uncompressedString);
}

export function decode(compressedString: string) {
  return LZStringDecompress(compressedString);
}
