import { DevelopmentChart } from 'components/charts/development/DevelopmentChart';
import { FormattedUnitValue } from 'components/text';
import { useTranslation } from 'libs/translations';
import { useMemo } from 'react';
import { IUserStructureAutoshipsData } from 'types';

export function WidgetAutoshipDevelopment(
  props: Pick<
    IUserStructureAutoshipsData,
    'lostAutoshipsInStructure' | 'gainedAutoshipsInStructure'
  >
) {
  const { t, activeLanguage } = useTranslation();

  const tabularData = useMemo(() => {
    if (!props.lostAutoshipsInStructure || !props.gainedAutoshipsInStructure) {
      return undefined;
    }

    const lostAutoshipsDescending = [
      ...props.lostAutoshipsInStructure.history
    ].sort((a, b) => {
      // Sort in descending order
      return b.date.getTime() - a.date.getTime();
    });

    const gainedAutoshipsDescending = [
      ...props.gainedAutoshipsInStructure.history
    ].sort((a, b) => {
      // Sort in descending order
      return b.date.getTime() - a.date.getTime();
    });

    return gainedAutoshipsDescending.map((gained, i) => {
      const difference =
        gained.volume -
        (lostAutoshipsDescending ? lostAutoshipsDescending[i].volume : 0);

      return {
        gained: gained.volume,
        lost: lostAutoshipsDescending[i].volume,
        difference,
        date: gained.date
      };
    });
  }, [props.gainedAutoshipsInStructure, props.lostAutoshipsInStructure]);

  const developmentDataPoints = useMemo(() => {
    if (!props.lostAutoshipsInStructure || !props.gainedAutoshipsInStructure) {
      return undefined;
    }

    const lost = [...props.lostAutoshipsInStructure.history]
      .sort((a, b) => {
        // Sort in ascending order
        return a.date.getTime() - b.date.getTime();
      })
      .map(h => ({
        label: h.date.toLocaleString(activeLanguage),
        value: h.volume
      }));

    const gained = [...props.gainedAutoshipsInStructure.history]
      .sort((a, b) => {
        // Sort in ascending order
        return a.date.getTime() - b.date.getTime();
      })
      .map(h => ({
        label: h.date.toLocaleString(activeLanguage),
        value: h.volume
      }));

    return { lost, gained };
  }, [
    activeLanguage,
    props.gainedAutoshipsInStructure,
    props.lostAutoshipsInStructure
  ]);

  if (!developmentDataPoints) {
    return null;
  }

  return (
    <>
      <h2 className="font-app font-bold text-center text-[18px] leading-[24px] sm:text-[24px] sm:leading-[36px]">
        {t('Structure development')}
      </h2>
      <div className="mt-lg">
        <DevelopmentChart
          yAxesLabel={t('Number of gained / lost autoships')}
          negativeDataPoints={developmentDataPoints.lost}
          positiveDataPoints={developmentDataPoints.gained}
        />
      </div>
      {tabularData && (
        <table className="mt-sm m-auto w-full">
          <thead>
            <tr>
              <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
                {t('Month')}
              </th>
              <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
                {t('Lost')}
              </th>
              <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
                {t('Difference')}
              </th>
              <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
                {t('Gained')}
              </th>
            </tr>
          </thead>
          <tbody>
            {tabularData.map(d => {
              return (
                <tr key={d.date.toString()}>
                  <td className="py-xxs text-center">
                    {d.date.toLocaleString(activeLanguage)}
                  </td>
                  <td className="py-xxs text-center text-error">
                    <FormattedUnitValue unit="count" value={d.lost} />
                  </td>
                  <td
                    className={`py-xxs text-center ${
                      d.difference > 0 ? 'text-success' : 'text-error'
                    }`}
                  >
                    <FormattedUnitValue
                      value={d.difference}
                      unit="count"
                      signDisplay
                    />
                  </td>
                  <td className="py-xxs text-center text-success">
                    <FormattedUnitValue unit="count" value={d.gained} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
}
