import _ from 'lodash';
import type { ReactNode } from 'react';

import './UnorderedList.scss';

export interface IListItemProps {
  readonly leftNode?: ReactNode;
  readonly content: string | ReactNode;
  readonly rightNode?: ReactNode;
  readonly onClick?: () => void;
}

interface IUnorderedListProps {
  readonly items: ReadonlyArray<IListItemProps>;
}

export function UnorderedList(props: IUnorderedListProps) {
  return (
    <ul className="pmi-ul">
      {props.items.map((i, index) => (
        <ListItem {...i} key={`li-${i.content}-${index}`} />
      ))}
    </ul>
  );
}

function ListItem(props: IListItemProps) {
  return (
    <li className="pmi-li">
      {!_.isUndefined(props.leftNode) && (
        <div className="pmi-li--left-content">{props.leftNode}</div>
      )}
      <div className="pmi-li--content-wrapper">
        <div className="pmi-li--main-content">
          {_.isUndefined(props.onClick) ? (
            <>{props.content}</>
          ) : (
            <button
              type="button"
              className="pmi-li--main-content"
              onClick={props.onClick}
            >
              {props.content}
            </button>
          )}
        </div>
        {!_.isUndefined(props.rightNode) && (
          <div className="pmi-li--right-content">{props.rightNode}</div>
        )}
      </div>
    </li>
  );
}
