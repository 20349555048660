import { ActiveSponsorshipFilter } from './ActiveSponsorshipFilter';
import { CheckAssuranceFilter } from './CheckAssuranceFilter';
import { CountryFilter } from './CountryFilter';
import { FitLineClubMemberFilter } from './FitLineClubMemberFilter';
import { NewQualificationFilter } from './NewQualificationFilter';
import { NewRegistrationsFilter } from './NewRegistrationsFilter';
import { FilterSection } from '../../filter-sections/FilterSection';
import { FilterSectionName } from '../../filter-sections/FilterSectionName';

export function CountryAndPerformanceFilter() {
  return (
    <FilterSection name={FilterSectionName.CountryAndPerformance}>
      <fieldset className="analytics-filters-fieldset">
        <CountryFilter />
        <CheckAssuranceFilter />
        <NewRegistrationsFilter />
        <NewQualificationFilter />
        <ActiveSponsorshipFilter />
        <FitLineClubMemberFilter />
      </fieldset>
    </FilterSection>
  );
}
