import { FormattedUnitValue } from 'components/text';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { IoStorefront } from 'react-icons/io5';
import type { IBasketItem } from 'types/virtual-warehouse/shared-baskets/IBasketItem';

interface IInvoiceArticle {
  readonly name?: string;
  readonly imgSrc?: string;
  readonly articleNumber: string;
  readonly quantity: number;
  readonly currencyCode: string;
  readonly itemPrice: number;
  readonly points: number;
  readonly addOnItems?: ReadonlyArray<IBasketItem>;
}

interface IInvoiceProductListProps {
  readonly items: ReadonlyArray<IInvoiceArticle>;
  readonly showPoints: boolean;
}

export function InvoiceProductList({
  items,
  showPoints
}: IInvoiceProductListProps) {
  const { t } = useTranslation();

  return (
    <ul className="space-y-sm">
      {items.map(item => {
        return (
          <li
            key={item.articleNumber}
            className="flex flex-row items-center gap-xs"
          >
            <div className="w-[56px] h-[56px] rounded flex-shrink-0 bg-cover bg-no-repeat bg-center bg-lightGray">
              {item.imgSrc ? (
                <img
                  title={item.name ?? item.articleNumber}
                  src={item.imgSrc}
                  className="rounded-md"
                />
              ) : (
                <IoStorefront size={24} className="text-silver m-auto h-full" />
              )}
            </div>
            <div className="flex-grow">
              <p className="line-clamp-1">{item.name ?? item.articleNumber}</p>
              <p className="pmi-caption leading-[24px]">
                {t('Quantity: {{number}}', {
                  number: item.quantity
                })}
              </p>
              {(item?.addOnItems ?? [])?.map(addOnItem => (
                <p className="pmi-caption" key={addOnItem.articleNumber}>
                  <span>{`${addOnItem?.details?.attributes?.name?.value}: `}</span>
                  <span>{`${addOnItem.quantity * item.quantity} x `}</span>
                  <FormattedUnitValue
                    value={addOnItem.details?.displayPrice}
                    unit={addOnItem.details?.currencyCode}
                  />
                </p>
              ))}
            </div>
            <div className="grid grid-rows-2 text-right">
              {showPoints && (
                <FormattedUnitValue
                  unit="points"
                  value={item.points * item.quantity}
                />
              )}
              <span className="font-bold">
                <FormattedUnitValue
                  unit={item.currencyCode}
                  value={item.itemPrice * item.quantity}
                />
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
