import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { IDocument } from 'types';

export function DocDate(props: IDocument) {
  const { t, activeLanguage } = useTranslation();
  let content: string;

  if (_.isEqual(props.startRange, props.endRange)) {
    content = props.startRange.toLocaleString(activeLanguage, 'short');
  } else {
    // https://www.i18next.com/translation-function/interpolation
    // Here we don't want to escape the dates otherwise symbols like "/" or "-"
    // will be escaped. The "-" before the variable name means "unescaped"
    content = t('From {{- startDate}} to {{- endDate}}', {
      startDate: props.startRange.toLocaleString(activeLanguage, 'short'),
      endDate: props.endRange.toLocaleString(activeLanguage, 'short')
    });
  }

  return <>{content}</>;
}
