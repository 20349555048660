import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ErrorToast } from './ErrorToast';
import { InfoToast } from './InfoToast';
import { SuccessToast } from './SuccessToast';
import { WarningToast } from './WarningToast';

// TODO: evaluate again this dependency. On mobile devices, toasts seems to
// be difficult or even impossible to dismiss. Maybe I should stop using these
// and replace with in-place error messages
export function AppToastContainer() {
  return (
    <ToastContainer
      icon={false}
      draggable={false}
      pauseOnFocusLoss
      pauseOnHover
      containerId="pmi-toast-container"
      role={'alert'}
      position={'top-right'}
    />
  );
}

export function triggerSuccessToast(description: string) {
  toast.success(<SuccessToast description={description} />, {
    progressStyle: { backgroundColor: 'var(--success)' },
    closeOnClick: true,
    closeButton: true,
    draggable: true
  });
}

export function triggerInfoToast(description: string) {
  toast.info(<InfoToast description={description} />, {
    progressStyle: { backgroundColor: 'var(--info)' },
    closeOnClick: true,
    closeButton: true,
    draggable: true
  });
}

export function triggerWarningToast(description: string) {
  toast.warn(<WarningToast description={description} />, {
    progressStyle: { backgroundColor: 'var(--warn)' },
    closeOnClick: true,
    closeButton: true,
    draggable: true
  });
}

export function triggerErrorToast(description: string) {
  toast.error(<ErrorToast description={description} />, {
    progressStyle: { backgroundColor: 'var(--error)' },
    closeOnClick: true,
    closeButton: true,
    draggable: true
  });
}
