import { Timestamp } from '@pmi.libs/utils-common';
import { BasketStatus, IBasket, IBasketList, IBasketPreview } from 'types';
import { IDiscount, DiscountType, getTotalValueByType } from 'utils';

import { IBasketApi } from './types/IBasketApi';
import { IBasketPreviewApi } from './types/IBasketPreviewApi';
import { IBasketsPaginatedApi } from './types/IBasketsPaginatedApi';

export function parseIBasketPaginatedAPIResponse(
  apiResponse: IBasketsPaginatedApi
): IBasketList {
  return {
    ...apiResponse,
    baskets: apiResponse.results.map(parseAPIBasket)
  };
}

export function parseAPIBasket(apiBasket: IBasketApi): IBasket {
  return {
    basket: {
      ...apiBasket.basket,
      status: apiBasket.basket.status.toLowerCase() as BasketStatus,
      createdOn: Timestamp.createOrThrow(apiBasket.basket.createdOn),
      autoDeleteOn: Timestamp.createOrThrow(apiBasket.basket.autoDeleteOn),
      discount: getTotalValueByType(
        (apiBasket.basket.discount ?? []) as ReadonlyArray<IDiscount>,
        DiscountType.Percentage
      ),
      affiliateDiscount: getTotalValueByType(
        (apiBasket.basket.affiliateDiscount ?? []) as ReadonlyArray<IDiscount>,
        DiscountType.Percentage
      )
    },
    checkoutUrl: apiBasket.checkoutUrl
  };
}

export function parseAPIBasketPreview(
  apiBasket: IBasketPreviewApi
): IBasketPreview {
  return {
    basket: {
      ...apiBasket.basket,
      status: apiBasket.basket.status.toLowerCase() as BasketStatus,
      createdOn: Timestamp.createOrThrow(apiBasket.basket.createdOn),
      autoDeleteOn: Timestamp.createOrThrow(apiBasket.basket.autoDeleteOn)
    },
    checkoutUrl: apiBasket.checkoutUrl
  };
}
