import { execGet, execPost, execPut } from 'services/RequestUtils';
import { IPromocode, PromocodeStatus } from 'types/promocodes';

import { parsePromocode } from './PromocodeServiceHelper';
import { IPromocodeApi } from './types/IPromocodeApi';

const API_URL = `${process.env.REACT_APP_SHOPS_API}/promocodes`;

export async function getPromocode(): Promise<IPromocode | null> {
  const response = await execGet<IPromocodeApi[]>(API_URL);

  return parsePromocode(response);
}

export async function createPromocode() {
  return await execPost(API_URL, {});
}

export async function changePromocodeStatus({
  id,
  status
}: {
  id: string;
  status: PromocodeStatus;
}) {
  return await execPut<void>(`${API_URL}/${id}`, {
    status
  });
}
