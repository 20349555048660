import { IS_PROD_BUILD } from 'contants';
import { useGlobalConfigsContext } from 'contexts';
import { usePII } from 'features/pii';

import { ImpersonatorBadge } from './impersonator/ImpersonatorBadge';
import { PreviewSwitch } from './preview';

export function PowerUserActiveFeatures() {
  const {
    previewMode: { isImpersonator }
  } = useGlobalConfigsContext();
  const { isPrivacyGuardEnabled } = usePII();

  const isPreviewUser = !IS_PROD_BUILD || isImpersonator;

  if (isPrivacyGuardEnabled) {
    return null;
  }

  if (isImpersonator === false && isPreviewUser === false) {
    return null;
  }

  return (
    <div className="p-xs rounded-full shadow-md bg-[#0E2431] flex items-center gap-sm">
      {isImpersonator && <ImpersonatorBadge />}
      {isPreviewUser && <PreviewSwitch />}
    </div>
  );
}
