import { SupportedLanguage } from '@pmi.web/localization';
import { useQuery } from '@tanstack/react-query';

import { fetchFAQsStory } from './FAQService';
import { FAQSlug } from './FAQSlug';

const FAQS_QUERY_KEY = 'pmi.web.office__faqs';
const TTL = 900000; // 15 minutes in milliseconds

export function useFAQs(slug: FAQSlug, language: SupportedLanguage) {
  const query = useQuery({
    queryKey: [FAQS_QUERY_KEY, slug, language],
    queryFn: () => fetchFAQsStory(slug, language),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
