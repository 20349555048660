import { UserHeader } from 'components/headers';
import { IUserProfile } from 'types';

export function TeampartnerColumn(props: {
  readonly userProfile: IUserProfile;
}) {
  return (
    <UserHeader
      user={props.userProfile}
      showQualification={false}
      showCheckAssurance={false}
      showBadges={false}
      showCountryFlag
    />
  );
}
