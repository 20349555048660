import { PrimaryButton } from '@pmi.web/react-common';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { FaCirclePlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

export function CreateNewBasketButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PrimaryButton
      onClick={() => {
        navigate('/sales/createbasket');
      }}
    >
      <FaCirclePlus size={16} />
      {t('Create Basket')}
    </PrimaryButton>
  );
}
