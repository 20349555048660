// eslint-disable-next-line no-restricted-imports
import CanvasConfetti from 'canvas-confetti';

const GREEN_COLOR_VARIATIONS = [
  '#085437',
  '#0d8356',
  '#0f9a65',
  '#11b174',
  '#14c883',
  '#16e093'
];
const BLUE_COLOR_VARIATIONS = [
  '#279AFF',
  '#71BDFF',
  '#0081F3',
  '#8DC1ED',
  '#59ABF2',
  '#0066C0'
];

const MAX_CONFETTI_PARTICLES = 200;

export function fireConfettiGun(colorScheme: 'blue' | 'green') {
  fire(
    0.25,
    {
      spread: 26,
      startVelocity: 55,
      zIndex: 99999
    },
    colorScheme
  );

  fire(
    0.2,
    {
      spread: 60,
      zIndex: 99999
    },
    colorScheme
  );

  fire(
    0.35,
    {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
      zIndex: 99999
    },
    colorScheme
  );

  fire(
    0.1,
    {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
      zIndex: 99999
    },
    colorScheme
  );

  fire(
    0.1,
    {
      spread: 120,
      startVelocity: 45,
      zIndex: 99999
    },
    colorScheme
  );
}

function fire(
  particleRatio: number,
  opts: confetti.Options,
  colorScheme: 'blue' | 'green'
) {
  const cannonConfettisConfig = {
    origin: { y: 0.7 },
    colors:
      colorScheme === 'green' ? GREEN_COLOR_VARIATIONS : BLUE_COLOR_VARIATIONS
  };

  CanvasConfetti(
    Object.assign({}, cannonConfettisConfig, opts, {
      particleCount: Math.floor(
        MAX_CONFETTI_PARTICLES * Math.min(particleRatio, 1)
      )
    })
  );
}
