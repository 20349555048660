import { BottomSheetContainer } from 'components/modals';
import { LoadingSpinner } from 'components/spinners';
import { useBasket } from 'features/shared-baskets';
import { Basket, BasketHeader } from 'features/virtual-warehouse';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

export function BasketIdRoute() {
  const navigate = useNavigate();
  const { activeLanguage } = useTranslation();
  const { basketId: urlBasketId } = useParams();
  const {
    data: basket,
    isLoading,
    error
  } = useBasket(urlBasketId ?? '', activeLanguage);

  if (_.isUndefined(urlBasketId) || error) {
    return <Navigate to={'/sales'} />;
  }

  return (
    <BottomSheetContainer
      open={true}
      onDismiss={() => void navigate('..')}
      header={isLoading || !basket ? null : <BasketHeader {...basket} />}
    >
      {isLoading && (
        <div className="w-full flex justify-center p-md">
          <LoadingSpinner smallSize />
        </div>
      )}
      {!isLoading && basket && <Basket {...basket} />}
    </BottomSheetContainer>
  );
}
