import { useTranslation } from 'libs/translations';

import { FirstlineDataProperty } from '../FirstlineDataProperty';

export function useFirstlineHeaderI18n() {
  const { t } = useTranslation();

  function translate(property: FirstlineDataProperty) {
    switch (property) {
      case FirstlineDataProperty.CurrentQualification:
        return t('Current qualification');
      case FirstlineDataProperty.PreviousQualification:
        return t('Last month qualification');
      case FirstlineDataProperty.HighestQualification:
        return t('Rank');
      case FirstlineDataProperty.PersonalSales:
        return t('Personal Sales');
      case FirstlineDataProperty.SixLevelSales:
        return t('6-Level Sales');
      case FirstlineDataProperty.StructureSales:
        return t('Structure Sales');
      case FirstlineDataProperty.Autoships:
        return t('Autoship');
      case FirstlineDataProperty.CheckAssurance:
        return t('Check Assurance');
      case FirstlineDataProperty.FitlineClubMember:
        return t('FitLine Club member');
    }
  }

  return translate;
}
