import { FormattedUnitValue } from 'components/text';
import {
  InvoiceSectionDivider,
  InvoiceCosts,
  InvoiceCostItem,
  InvoiceCostTotal
} from 'features/invoices';
import { useTranslation } from 'libs/translations';
import { ICommitment } from 'types';

export function CommitmentCosts(props: ICommitment) {
  const { t } = useTranslation();

  return (
    <InvoiceCosts>
      <InvoiceCostItem
        label={t('Subtotal')}
        value={
          <FormattedUnitValue
            unit={props.totals.currencyCode}
            value={props.totals.itemsDisplayTotal}
          />
        }
      />

      {props.delivery?.selected.cost?.displayPrice && (
        <InvoiceCostItem
          label={t('Shipping & Handling')}
          value={
            <FormattedUnitValue
              unit={props.delivery.selected.cost.currencyCode}
              value={props.delivery.selected.cost.displayPrice}
            />
          }
        />
      )}

      {props.additionalCharges && props.additionalCharges.length > 0 && (
        <InvoiceCostItem
          label={t('Additional charges')}
          value={
            <FormattedUnitValue
              unit={props.totals.currencyCode}
              value={props.additionalCharges.reduce(
                (acc, elem) => acc + (elem.cost?.displayPrice ?? 0),
                0
              )}
            />
          }
        />
      )}

      <InvoiceCostItem
        label={t('Tax')}
        value={
          <FormattedUnitValue
            unit={props.totals.currencyCode}
            value={props.totals.totalTaxAmount}
          />
        }
      />

      <InvoiceSectionDivider thick />
      <InvoiceCostTotal
        label={t('Total')}
        value={
          <span className="font-bold text-xl">
            <FormattedUnitValue
              unit={props.totals.currencyCode}
              value={props.totals.totalGross}
            />
          </span>
        }
      />
      <InvoiceCostTotal
        label={t('Points')}
        value={<FormattedUnitValue unit="points" value={props.totals.points} />}
      />
    </InvoiceCosts>
  );
}
