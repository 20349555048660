import { Timestamp } from '@pmi.libs/utils-common';
import { parseCountryCode } from '@pmi.web/countries';
import { ICountryShop, IPromoShop, IShopBanner, IShopPromotions } from 'types';

import { IApiCountryShop } from './types/IApiCountryShop';
import { IApiPromoShop } from './types/IApiPromoShop';
import { IApiShopBanner } from './types/IApiShopBanner';
import { IApiShopPromotions } from './types/IApiShopPromotions';

export function parseApiPromoShop(apiPromoShop: IApiPromoShop): IPromoShop {
  const shopUrl = new URL(apiPromoShop.shopUrl);
  shopUrl.searchParams.set('autosignin', '');

  return {
    id: apiPromoShop.id,
    shopUrl: shopUrl.toString(),
    promotions: apiPromoShop.promotions
      ? parseApiPromotions(apiPromoShop.promotions)
      : undefined,
    isVirtualWarehouseEnabled: apiPromoShop.isVirtualWarehouseEnabled,
    isPromoCodeEnabled: apiPromoShop.isPromoCodeEnabled,
    isAffiliateDiscountEnabled: apiPromoShop.isAffiliateDiscountEnabled,
    canCreateCommitments: apiPromoShop.canCreateCommitments
  };
}

function parseApiPromotions(
  apiPromotions: IApiShopPromotions
): IShopPromotions {
  return {
    banners: (apiPromotions.banners ?? [])?.map(parseShopBanner)
  };
}

function parseShopBanner(shopBanner: IApiShopBanner): IShopBanner {
  return {
    ...shopBanner,
    countdown: shopBanner.countdown
      ? Timestamp.create(shopBanner.countdown)
      : undefined
  };
}

export function parseApiCountryShop(
  countryShop: IApiCountryShop
): ICountryShop {
  return {
    countryCode: parseCountryCode(countryShop.countryCode),
    shopId: countryShop.shopId
  };
}
