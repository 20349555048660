import type { FC } from 'react';

export const CoinsOutlineIcon: FC<{ readonly size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="flex-shrink-0 flex-grow-0"
    viewBox="0 0 16 16"
    width={size}
    height={size}
  >
    <g fill="currentColor" stroke="currentColor" strokeWidth="1">
      <ellipse
        cx="8"
        cy="4"
        fill="none"
        rx="5.5"
        ry="2.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5,6.5V7C2.5,8.381,4.962,9.5,8,9.5S13.5,8.381,13.5,7V6.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5,9.5V10c0,1.381,2.462,2.5,5.5,2.5s5.5-1.119,5.5-2.5V9.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5,12.5V13c0,1.381,2.462,2.5,5.5,2.5s5.5-1.119,5.5-2.5v-.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
