import { BottomSheetContainer } from 'components/modals';
import { useBoolean } from 'hooks';
import { useTranslation } from 'libs/translations';
import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { FaXmark } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';

export function CancelBasketCreationButton() {
  const { t } = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();
  const confirmCancelation = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate('/sales');
    }
  };

  const [
    askCancelConfirmation,
    { setFalse: closeCancelationPanel, setTrue: openCancelationPanel }
  ] = useBoolean(false);

  useEffect(() => {
    // TODO: prevent history nav back
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;
      return true;
    };

    window.addEventListener('beforeunload', handler);
    window.addEventListener('popstate', handler);

    return () => {
      window.removeEventListener('beforeunload', handler);
      window.removeEventListener('popstate', handler);
    };
  }, []);

  return (
    <>
      <button
        title={t('Exit')}
        className="text-silver bg-lightGray p-xxs rounded-full hover:bg-silver hover:text-white transition-all"
        onClick={openCancelationPanel}
      >
        <FaXmark size={20} />
      </button>
      <BottomSheetContainer
        open={askCancelConfirmation}
        onDismiss={closeCancelationPanel}
      >
        <div className="text-center">
          <h1 className="font-bold">
            {t('Are you sure you want to go back?')}
          </h1>
          <p>{t('If you continue, all progress will be lost.')}</p>

          <br />

          <button
            className="m-auto block min-w-[200px] bg-primary text-white hover:brightness-125 rounded-full px-sm py-xs"
            onClick={closeCancelationPanel}
          >
            {t('Stay')}
          </button>

          <button
            className="mt-xxs m-auto block min-w-[200px] hover:bg-lightGray rounded-full px-sm py-xs"
            onClick={confirmCancelation}
          >
            {t('Exit')}
          </button>
        </div>
      </BottomSheetContainer>
    </>
  );
}
