import { useScrollLock } from 'hooks';
import { ReactNode } from 'react';

interface IScrollLockProps {
  readonly children: ReactNode;
}

export function ScrollLock(props: IScrollLockProps) {
  useScrollLock(document.body);

  return <>{props.children}</>;
}
