import { UserAvatarSkeleton } from 'components/icons';

export function UserHeaderSkeleton() {
  return (
    <div className="flex flex-row items-center">
      <UserAvatarSkeleton />
      <div className="ml-xs">
        <div className="bg-lightGray h-[24px] w-[200px]"></div>
        <div className="bg-lightGray h-[14px] w-[120px] mt-[6px]"></div>
      </div>
    </div>
  );
}
