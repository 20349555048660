import { useQuery } from '@tanstack/react-query';
import { getPromotedEvents } from 'services';
import AuthenticationService from 'services/AuthenticationService';

const PROMO_EVENTS_QUERY_KEY = 'pmi.web.office__events_promo';
const TTL = 900000; // 15 minutes in milliseconds

export function usePromotedEvents() {
  const userCountry = AuthenticationService.user.countryCode ?? '';

  const query = useQuery({
    queryKey: [PROMO_EVENTS_QUERY_KEY, userCountry],
    queryFn: () => getPromotedEvents(userCountry),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
