import { LoadingSpinner } from 'components/spinners';
import { Disclaimer } from 'components/text';
import { IOnlineMeetingBenefit, IEventTicketSettings } from 'features/events';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';

import { EventTicketThumbnail } from './EventTicketThumbnail';
import { useEventTickets } from '../hooks/useEventTickets';

export function EventTicketsList(props: {
  readonly eventId: string;
  readonly ticketSettings: IEventTicketSettings;
  readonly eventName: string;
}) {
  const { t } = useTranslation();
  const { data: tickets, error, isLoading } = useEventTickets(props.eventId);
  const allTickets = [
    ...(tickets?.signedInUserTicket ? [tickets.signedInUserTicket] : []),
    ...(tickets?.tickets ?? [])
  ];

  if (allTickets.length === 0) {
    return null;
  }

  return (
    <div>
      <h2 className="text-primary mt-md md:mt-lg mb-sm text-center">
        {t('Purchased Tickets')}
      </h2>

      {isLoading && (
        <div className="absolute inset-0 pmi-glass-white flex items-center justify-center">
          <LoadingSpinner smallSize />
        </div>
      )}

      {!_.isUndefined(error) && (
        <Disclaimer
          type="error"
          message={t(
            'Something went wrong. We could not search for your tickets. Please try again later or contact the support team.'
          )}
        />
      )}

      {allTickets.length > 0 && (
        <ul className="flex flex-row flex-wrap gap-md justify-center">
          {allTickets.map(ticket => {
            const name = `${ticket.firstName} ${ticket.lastName}`;
            const ticketSettings = props.ticketSettings.items.find(
              t => t.articleNumber === ticket.articleNumber
            );

            const onlineMeetingBenefit = ticketSettings?.benefits.find(
              b => b.type === 'onlineMeetingAccess'
            ) as IOnlineMeetingBenefit | undefined;

            return (
              <li key={ticket.ticketId}>
                <EventTicketThumbnail
                  attendeeType={ticket.attendeeType}
                  eventName={props.eventName ?? props.eventId}
                  owner={name}
                  ownerId={ticket.tpId}
                  isOnlineTicket={ticketSettings?.type === 'online'}
                  onlineMeeting={onlineMeetingBenefit}
                  qrCodeUri={ticket.qrCodeUri}
                  ticketName={ticketSettings?.name}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
