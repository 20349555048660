/* eslint-disable no-restricted-imports */
import {
  BsFileEarmarkLock2,
  BsCartPlus,
  BsFill6CircleFill
} from 'react-icons/bs';
import {
  IoAdd,
  IoBagHandle,
  IoCartOutline,
  IoCheckmark,
  IoChevronBack,
  IoChevronDown,
  IoChevronForward,
  IoChevronUp,
  IoEyeOffOutline,
  IoEyeOutline,
  IoInformationCircleOutline,
  IoKeyOutline,
  IoLanguageOutline,
  IoPerson,
  IoLink,
  IoMegaphoneOutline,
  IoQrCode,
  IoCarSport,
  IoRemove,
  IoPersonOutline,
  IoSearchOutline,
  IoSettingsOutline,
  IoPersonAddOutline,
  IoShareOutline,
  IoTrashOutline,
  IoVideocamOutline,
  IoWalletOutline,
  IoCopyOutline,
  IoWarningOutline,
  IoExitOutline,
  IoDocumentsOutline,
  IoCardOutline,
  IoCheckmarkCircleSharp,
  IoNotificationsSharp
} from 'react-icons/io5';
import { MdCancel } from 'react-icons/md';
import { PiPackageLight } from 'react-icons/pi';

import { ArrowRightIcon } from './common/ArrowRightIcon';
import { CalenderBackIcon } from './common/CalenderBackIcon';
import { CheckPaymentFillIcon } from './common/CheckPaymentFillIcon';
import { CoinsOutlineIcon } from './common/CoinsOutlineIcon';
import { FlagOutlineIcon } from './common/FlagOutlineIcon';
import { GroupsFillIcon } from './common/GroupsFillIcon';
import { GroupsOutlineIcon } from './common/GroupsOutlineIcon';
import { HighestPriorityIcon } from './common/HighestPriorityIcon';
import { NetworkFillIcon } from './common/NetworkFillIcon';
import { StarFillIcon } from './common/StarFillIcon';
import { FitLineIcon } from './pmi/FitLineIcon';
import { PMIIcon } from './pmi/PMIIcon';
import { IIconProps } from './types/IIconProps';

const PMI = {
  // Logo Icons
  FitLine: (props: IIconProps) => (
    <FitLineIcon
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  PM: (props: IIconProps) => (
    <PMIIcon
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),

  // Concept Icons
  CheckAssurance: (props: IIconProps) => (
    <CheckPaymentFillIcon
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Qualification: (props: IIconProps) => (
    <FlagOutlineIcon
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  PreviousQualification: (props: IIconProps) => (
    <CalenderBackIcon
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  NextQualification: (props: IIconProps) => (
    <ArrowRightIcon
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Rank: (props: IIconProps) => (
    <HighestPriorityIcon
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  PersonalSales: (props: IIconProps) => (
    <IoPerson
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  GroupSales: (props: IIconProps) => (
    <GroupsFillIcon
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  SixLevelSales: (props: IIconProps) => (
    <BsFill6CircleFill
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  StructureSales: (props: IIconProps) => (
    <NetworkFillIcon
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Registrations: (props: IIconProps) => (
    <IoPersonAddOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Payouts: (props: IIconProps) => (
    <CoinsOutlineIcon
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Autoships: (props: IIconProps) => (
    <PiPackageLight
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  TeamQualifications: (props: IIconProps) => (
    <GroupsOutlineIcon
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  CarBonus: (props: IIconProps) => (
    <IoCarSport
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Firstline: (props: IIconProps) => (
    <StarFillIcon
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  )
};

const Office = {
  RouteAccount: (props: IIconProps) => (
    <IoPersonOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  // PM Office Features
  PrivacyGuard: (props: IIconProps) => (
    <BsFileEarmarkLock2
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  )
};

const Common = {
  Bell: (props: IIconProps) => (
    <IoNotificationsSharp
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  ShoppingCart: (props: IIconProps) => (
    <IoCartOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  ShoppingBag: (props: IIconProps) => (
    <IoBagHandle
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Share: (props: IIconProps) => (
    <IoShareOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Search: (props: IIconProps) => (
    <IoSearchOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  TrashCan: (props: IIconProps) => (
    <IoTrashOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Checkmark: (props: IIconProps) => (
    <IoCheckmark
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Video: (props: IIconProps) => (
    <IoVideocamOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Megaphone: (props: IIconProps) => (
    <IoMegaphoneOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  ChevronLeft: (props: IIconProps) => (
    <IoChevronBack
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  ChevronRight: (props: IIconProps) => (
    <IoChevronForward
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  ChevronUp: (props: IIconProps) => (
    <IoChevronUp
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  ChevronDown: (props: IIconProps) => (
    <IoChevronDown
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  MinusSign: (props: IIconProps) => (
    <IoRemove
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  PlusSign: (props: IIconProps) => (
    <IoAdd
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Info: (props: IIconProps) => (
    <IoInformationCircleOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Gear: (props: IIconProps) => (
    <IoSettingsOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Key: (props: IIconProps) => (
    <IoKeyOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Link: (props: IIconProps) => (
    <IoLink
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Language: (props: IIconProps) => (
    <IoLanguageOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  MoneyTransfer: (props: IIconProps) => (
    <IoWalletOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  EyeShow: (props: IIconProps) => (
    <IoEyeOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  EyeHide: (props: IIconProps) => (
    <IoEyeOffOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Copy: (props: IIconProps) => (
    <IoCopyOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  CreditCard: (props: IIconProps) => (
    <IoCardOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Documents: (props: IIconProps) => (
    <IoDocumentsOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Exit: (props: IIconProps) => (
    <IoExitOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  ShoppingCartWithPlusSign: (props: IIconProps) => (
    <BsCartPlus
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Person: (props: IIconProps) => (
    <IoPerson
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Warning: (props: IIconProps) => (
    <IoWarningOutline
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  QRCode: (props: IIconProps) => (
    <IoQrCode
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Cancel: (props: IIconProps) => (
    <MdCancel
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  ),
  Success: (props: IIconProps) => (
    <IoCheckmarkCircleSharp
      {...props}
      className={`${props.className} flex-grow-0 flex-shrink-0`}
    />
  )

  // // Migrate to react-icons
  // Add: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // ArrowDown: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // ArrowRight: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // ArrowUp: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Badge: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Ban: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // BarChart: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Box: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Briefcase: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // CalendarBack: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Call: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Car: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // CheckPayment: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Clock: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Close: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Coach: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Coins: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Cross: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Email: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // FitLine: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Flag: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Groups: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // HighPriority: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Location: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Menu: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Network: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // ParentAndChild: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Percentage: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // PM: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Seat: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // SixCircle: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Star: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Ticket: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Shield: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Scan: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Filter: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // Sort: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // SimpleArrowUp: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />,
  // SimpleArrowDown: (props: IIconProps) => <Icon2 {...props} className={`${props.className} flex-grow-0 flex-shrink-0`} />
};

export const Icons = {
  PMI,
  Office,
  Common
};
