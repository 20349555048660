import { PrimaryButton } from '@pmi.web/react-common';
import { UserHeader } from 'components/headers';
import { Pagination } from 'components/pagination';
import { LoadingSpinner } from 'components/spinners';
import { useUsersSearch } from 'features/analytics/hooks/useUsersSearch';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { IUserSearchParamsV2 } from 'services';

const DEFAULT_PAGE_SIZE = 5;
const DEBOUNCE_WAIT = 500;
const EMPTY_SEARCH_VALUE = '   ';

export function SelectTeamparter() {
  const defaultUserSearchParams: UserSearchParamsState = {
    pageNumber: 1,
    search: EMPTY_SEARCH_VALUE,
    byDepthLevelFrom: 1,
    byDepthLevelTo: 3,
    endCustomers: false
  };

  const [userSearchParams, updateUserSearchParams] = useReducer(
    reducer,
    defaultUserSearchParams
  );

  const { t } = useTranslation();
  const { basketOwnerId, isBasketOwnerTeampartner, dispatchStepEvent } =
    useNewBasketContext();

  const { register, handleSubmit, watch, setValue } = useForm<{
    readonly user?: string;
    readonly search?: string;
  }>();

  const { data: searchResults, isLoading } = useUsersSearch(
    userSearchParams.pageNumber,
    DEFAULT_PAGE_SIZE,
    userSearchParams
  );

  const onSearchInputChanges = useMemo(
    () =>
      _.debounce(
        (searchInput?: string) =>
          updateUserSearchParams({ type: 'set-search', value: searchInput }),
        DEBOUNCE_WAIT
      ),
    []
  );

  const searchPlaceholder = useMemo(() => {
    let randomUser;

    if (searchResults && searchResults.data.length > 0) {
      randomUser =
        searchResults.data[
          Math.floor(Math.random() * searchResults.data.length)
        ];
    } else {
      return t('Search by name or Team Partner ID');
    }

    const tpid = randomUser.userId;
    const name = randomUser.name;

    return Math.round(Math.random()) === 0 ? name : tpid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSumbitHandler: SubmitHandler<{ readonly search?: string }> =
    useCallback(
      data => {
        onSearchInputChanges(data.search);
      },
      [onSearchInputChanges]
    );

  useEffect(() => {
    const subscription = watch(data => {
      onSearchInputChanges(data.search);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [onSearchInputChanges, watch]);

  useEffect(() => {
    if (basketOwnerId && isBasketOwnerTeampartner) {
      updateUserSearchParams({ type: 'set-search', value: basketOwnerId });
      setValue('search', basketOwnerId);
    }
  }, []);

  return (
    <>
      <div className="w-full flex">
        <form onSubmit={handleSubmit(onSumbitHandler)} className="flex-1">
          <label className="border border-silver rounded-md flex flex-nowrap items-center">
            <Icon
              name={IconName.Search}
              size={20}
              className="text-silver p-xs"
            />
            <input
              placeholder={searchPlaceholder}
              className="rounded-md flex flex-row items-center italic pr-xs py-xs w-full outline-none bg-white"
              type="search"
              {...register('search')}
            />
          </label>
        </form>
      </div>

      {isLoading && (
        <div className="p-sm flex items-center justify-center w-full min-h-[100px]">
          <LoadingSpinner smallSize />
        </div>
      )}

      {!isLoading &&
        !_.isUndefined(searchResults) &&
        searchResults.data.length > 0 && (
          <>
            <div className="flex w-full justify-end mt-md">
              <Pagination
                currentPage={searchResults.pageNumber}
                onPageChange={p =>
                  updateUserSearchParams({ type: 'change-page', value: p })
                }
                totalPages={searchResults.totalPages}
              />
            </div>

            <form id="comp__user-selection-form" className="mt-xs">
              {searchResults.data.map((searchResult, index) => (
                <label
                  className={`radio-option ${
                    basketOwnerId === searchResult.userId ? 'selected' : ''
                  }`}
                  key={`radio__sr__${searchResult.userId}_${index}`}
                >
                  <input
                    type="radio"
                    {...register('user', {
                      onChange: handleSubmit(data => {
                        if (data.user) {
                          const user = JSON.parse(data.user);

                          dispatchStepEvent({
                            type: 'set-owner-country',
                            value: user.countryCode
                          });
                          dispatchStepEvent({
                            type: 'set-teampartner-id',
                            value: user.userId
                          });
                        }
                      })
                    })}
                    value={JSON.stringify({
                      userId: searchResult.userId,
                      countryCode: searchResult.countryCode
                    })}
                  />
                  <div>
                    <UserHeader
                      user={searchResult}
                      showCountryFlag
                      showBadges
                      showCheckAssurance
                      showQualification
                      showNewRegistration
                      disableNavigation
                    />
                  </div>
                </label>
              ))}
            </form>
            <div className="flex justify-end gap-sm w-full mt-md sticky bg-white bottom-0 py-xs bg-opacity-90 -px-sm">
              <PrimaryButton
                disabled={basketOwnerId === undefined}
                onClick={() => {
                  if (basketOwnerId) {
                    dispatchStepEvent({
                      type: 'confirm-user-id'
                    });
                  }
                }}
              >
                {t('Continue')}
              </PrimaryButton>
            </div>
          </>
        )}

      {!isLoading &&
        !_.isUndefined(searchResults) &&
        searchResults.data.length === 0 && (
          <div className="text-silver flex flex-col items-center gap-xs mt-md">
            <div className="flex items-center justify-center gap-xs">
              <Icon name={IconName.Search} size={24} />
              <p>{t('No results found')}</p>
            </div>
            <p className="text-center">
              {t("Please make sure you don't have any typos.")}
            </p>
          </div>
        )}
    </>
  );
}

interface UserSearchParamsState extends IUserSearchParamsV2 {
  readonly pageNumber: number;
}

type UserSearchParamsStateAction =
  | {
      type: 'set-search';
      value: string | undefined;
    }
  | {
      type: 'change-page';
      value: number;
    };

function reducer(
  state: UserSearchParamsState,
  action: UserSearchParamsStateAction
): UserSearchParamsState {
  if (action.type === 'set-search') {
    return {
      ...state,
      search: action.value ?? EMPTY_SEARCH_VALUE,
      pageNumber: action.value !== state.search ? 1 : state.pageNumber
    };
  }

  if (action.type === 'change-page') {
    return {
      ...state,
      pageNumber: action.value
    };
  }

  return state;
}
