import { SupportedLanguage } from '@pmi.web/localization';

export function mapLanguageToStoryblokLocale(language: string) {
  switch (language) {
    case SupportedLanguage.ChineseSimplified:
      return 'zh-hans';
    default:
      return language.toLowerCase();
  }
}

export function parseStoryblockDate(date: string): string {
  // Date without time is returned by storyblok as 2023-08-22 00:00. Always zero
  // ISO String example 2023-12-11T08:51:07.149Z
  return `${date.split(' ')[0]}T00:00:00.000Z`;
}

export function parseStoryblockDatetime(datetime: string): string {
  // Date with time is returned by storyblok as 2023-08-22 10:00
  // ISO String example 2023-12-11T08:51:07.149Z
  return `${datetime.replace(' ', 'T')}:00.000Z`;
}
