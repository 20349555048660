import { LoadingSpinner } from 'components/spinners';
import { FormattedUnitValue } from 'components/text';
import { QuantityController } from 'features/shared-baskets/components/product-item/QuantityController';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useProduct } from 'features/shared-baskets/hooks/useProduct';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { IoStorefront } from 'react-icons/io5';
import { IBasketPreviewItem } from 'types';

export function ProductsInBasketSummary({
  allowEditing = false,
  small = false
}: {
  readonly allowEditing?: boolean;
  readonly small?: boolean;
}) {
  const {
    basketPreview: { data: basket, isLoading },
    articles
  } = useNewBasketContext();
  const basketItems =
    basket?.basket.items ??
    Array.from(articles.entries()).map(article => ({
      articleNumber: article[0],
      quantity: article[1]
    }));

  if (isLoading) {
    return (
      <div className="w-full flex justify-center p-md">
        <LoadingSpinner smallSize />
      </div>
    );
  }

  return (
    <ul className="w-full grid grid-cols-[56px_1fr_auto] gap-sm">
      {basketItems.map(i => (
        <li key={i.articleNumber} className="contents">
          <ProductSummaryItem
            {...i}
            // We need to ensure that we only allow editing items added by the user
            // and not extra items returned by the API
            allowEditing={allowEditing && articles.has(i.articleNumber)}
            small={small}
          />
        </li>
      ))}
    </ul>
  );
}

interface IProductSummaryItemProps extends Partial<IBasketPreviewItem> {
  readonly allowEditing?: boolean;
  readonly small?: boolean;
  readonly articleNumber: string;
}

function ProductSummaryItem(props: IProductSummaryItemProps) {
  const { activeLanguage } = useTranslation();
  const { shouldShowPoints, shopId } = useNewBasketContext();
  const { addOnItems, articleNumber, details, quantity, allowEditing, small } =
    props;
  const { data: productDetails } = useProduct(
    articleNumber,
    shopId,
    activeLanguage
  );

  const { t } = useTranslation();

  const { name, imgSrc, currency, price, points } = useMemo(() => {
    const currency = details?.currencyCode;
    const price = details?.displayPrice;
    const points = details?.points;

    const name =
      productDetails?.details.attributes?.name?.value ?? articleNumber;
    const imgSrc = productDetails?.details.media.find(
      m => m.type === 'image' && !_.isUndefined(m.url)
    )?.url;

    return {
      name,
      imgSrc,
      currency,
      price,
      points
    };
  }, [
    articleNumber,
    details,
    productDetails?.details.attributes?.name?.value,
    productDetails?.details.media
  ]);

  return (
    <>
      {imgSrc ? (
        <object
          data={imgSrc}
          type="image/png"
          className="bg-lightGray h-[56px] w-[56px] row-span-2"
        >
          <IoStorefront size={24} className="text-silver m-auto h-full" />
        </object>
      ) : (
        <span className="bg-lightGray h-[56px] w-[56px] row-span-2">
          <IoStorefront size={24} className="text-silver m-auto h-full" />
        </span>
      )}
      <div className="flex flex-col items-start justify-between">
        <span className="line-clamp-1">{name}</span>
        <span className="pmi-caption">
          {allowEditing ? null : t('Quantity: {{quantity}}', { quantity })}
        </span>
        {(addOnItems ?? [])?.map(addOnItem => (
          <p className="pmi-caption" key={addOnItem.articleNumber}>
            <span>{`${addOnItem?.details?.attributes?.name?.value}: `}</span>
            <span>{`${addOnItem.quantity * (quantity ?? 0)} x `}</span>
            <FormattedUnitValue
              value={addOnItem.details?.displayPrice}
              unit={addOnItem.details?.currencyCode}
            />
          </p>
        ))}
      </div>
      <div className="flex flex-col items-end">
        {shouldShowPoints &&
          !_.isUndefined(quantity) &&
          !_.isUndefined(points) && (
            <span>
              <FormattedUnitValue value={quantity * points} unit={'points'} />
            </span>
          )}
        {!_.isUndefined(quantity) &&
          !_.isUndefined(price) &&
          !_.isUndefined(currency) && (
            <span className="font-bold">
              <FormattedUnitValue value={quantity * price} unit={currency} />
            </span>
          )}
      </div>
      <div className="col-span-2 flex justify-end -translate-y-xs">
        {allowEditing && (
          <QuantityController articleNumber={articleNumber} small={small} />
        )}
      </div>
    </>
  );
}
