import { Timestamp } from '@pmi.libs/utils-common';
import { IDocument } from 'types';

import { parseAPIDocument } from './DocumentsServiceHelper';
import { getCommonAuthenticatedHeaders } from '../HeaderUtils';
import { execGet } from '../RequestUtils';
import { IDocumentApi } from './types/IDocumentApi';

export async function fetchAllDocuments(
  dateRangeStart: Timestamp,
  dateRangeEnd: Timestamp
): Promise<ReadonlyArray<IDocument>> {
  const searchParams = new URLSearchParams();
  searchParams.set('dateTimeRangeStart', dateRangeStart.toString());
  searchParams.set('dateTimeRangeEnd', dateRangeEnd.toString());

  const documentList = await execGet<IDocumentApi[]>(
    `${process.env.REACT_APP_OFFICE_API}/files/flat?${searchParams.toString()}`,
    { cache: false }
  );

  return documentList.map(parseAPIDocument).sort((doc1, doc2) => {
    return doc2.startRange.getTime() - doc1.startRange.getTime();
  });
}

export function createDocumentDownloadUrl(document: IDocument): string {
  return `${process.env.REACT_APP_OFFICE_API}/files/${document.filename}`;
}

export function downloadDocument(doc: IDocument) {
  return fetch(createDocumentDownloadUrl(doc), {
    headers: getCommonAuthenticatedHeaders('application/pdf'),
    method: 'GET'
  })
    .then(response => {
      if (!response.ok) {
        throw response;
      }

      return response.blob();
    })
    .then(blob => {
      const objectUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = objectUrl;
      downloadLink.download = doc.filename;
      downloadLink.click();
    });
}
