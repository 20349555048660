import { WhatsNewContent } from './components/WhatsNewContent';
import { WhatsNewSubtitle } from './components/WhatsNewSubtitle';
import { WhatsNewTitle } from './components/WhatsNewTitle';

export function WhatsNew() {
  return (
    <>
      <header className="w-full text-center space-y-sm mt-lg">
        <WhatsNewTitle />
        <WhatsNewSubtitle />
      </header>
      <section className="mt-xl m-auto max-w-[900px] w-full">
        <WhatsNewContent />
      </section>
    </>
  );
}
