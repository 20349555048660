import { Timestamp } from '@pmi.libs/utils-common';
import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { LoadingSpinner } from 'components/spinners';
import { AccountBackButton } from 'features/account';
import {
  DocumentsDropdownFilter,
  DocumentsList
} from 'features/document-downloads';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { fetchAllDocuments } from 'services';
import { DocumentCategory, IDocument } from 'types';

export function AccountDocumentsRoute() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeFilter, setActiveFilter] = useState<DocumentCategory>();
  const [documents, setDocuments] = useState<ReadonlyArray<IDocument>>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const categoryOptionFilters = useMemo(() => {
    const filters = [
      DocumentCategory.Bonus,
      DocumentCategory.PerformanceBonus,
      DocumentCategory.Invoices
    ];

    if (
      !_.isUndefined(documents) &&
      !_.isUndefined(
        documents.find(doc => doc.category === DocumentCategory.TaxInvoices)
      )
    ) {
      filters.push(DocumentCategory.TaxInvoices);
    }

    return filters;
  }, [documents]);

  useEffect(() => {
    const filterSearchParam = searchParams.get('filter') ?? undefined;
    if (
      !_.isUndefined(filterSearchParam) &&
      !Object.values(DocumentCategory).includes(
        filterSearchParam as DocumentCategory
      )
    ) {
      searchParams.delete('filter');
      setSearchParams(searchParams);
    } else {
      setActiveFilter(filterSearchParam as DocumentCategory);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    setLoading(true);
    fetchAllDocuments(Timestamp.createOrThrow('1970-01-01'), Timestamp.now())
      .then(setDocuments)
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <PageTitle />
      <section className="space-y-md m-auto">
        <AccountBackButton />

        <div className="bg-white shadow-md rounded-md p-sm relative">
          {isLoading && (
            <div className="absolute inset-0 pmi-glass-white flex items-center justify-center">
              <LoadingSpinner smallSize />
            </div>
          )}

          <DocumentsDropdownFilter categoryOptions={categoryOptionFilters} />
          <div className="mt-md">
            <DocumentsList
              documents={(documents || [])?.filter(
                doc =>
                  _.isUndefined(activeFilter) || doc.category === activeFilter
              )}
            />
          </div>
        </div>
      </section>
    </>
  );
}
