import { FormattedUnitValue } from 'components/text';
import { Icon, IconName } from 'libs/icons';
import { useMemo } from 'react';
import { normalizePercentageValue } from 'utils';

interface ITrendValueProps {
  readonly value: number;
  readonly style?: 'number' | 'text' | 'illustrated' | 'illustration-only';
}

export function TrendValue(props: ITrendValueProps) {
  const style = props.style ?? 'text';
  const normalizedValue = normalizePercentageValue(props.value);

  const illustration = useMemo(() => {
    if (style !== 'illustrated' && style !== 'illustration-only') {
      return null;
    }

    return (
      <Icon
        name={normalizedValue > 0 ? IconName.ArrowUp : IconName.ArrowDown}
        size={16}
      />
    );
  }, [style, normalizedValue]);

  return (
    <span
      className={`
        inline-flex items-center
          ${
            normalizedValue > 0
              ? 'text-success'
              : normalizedValue < 0
              ? 'text-error'
              : 'text-primary'
          }
        `}
    >
      {illustration && normalizedValue !== 0 && (
        <span className="mr-xxs inline-block">{illustration}</span>
      )}
      {style !== 'illustration-only' && (
        <FormattedUnitValue unit="percent" value={normalizedValue} />
      )}
    </span>
  );
}
