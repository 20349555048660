export function SortIcon(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" stroke="currentColor" strokeWidth="1">
        <polyline
          fill="none"
          points="0.5,3.5 3.5,0.5 6.5,3.5 "
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          fill="none"
          points="9.5,12.5 12.5,15.5 15.5,12.5 "
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="3.5"
          x2="3.5"
          y1="12.5"
          y2="0.5"
        />
        <line
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          x1="12.5"
          x2="12.5"
          y1="3.5"
          y2="15.5"
        />
      </g>
    </svg>
  );
}
