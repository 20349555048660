import { ProgressWidget } from 'components/progress/progress-loop/ProgressWidget';
import { FeatureIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import { IProgress } from 'types';

import { WidgetFirstQualificationDetails } from './WidgetFirstQualificationDetails';
import { Widget } from '../Widget';

interface IWidgetFirstQualificationProps {
  readonly percent: number;
  readonly options: ReadonlyArray<IProgress>;
}

export function WidgetFirstQualification(
  props: IWidgetFirstQualificationProps
) {
  const { t } = useTranslation();

  return (
    <Widget
      traceId="FirstQualification"
      title={t('First Qualification')}
      detailedViewButtonLabel={t('Details')}
      iconName={FeatureIconName.Qualification}
      detailedView={
        props.options.length > 0 &&
        props.options[0].details.length > 0 && (
          <WidgetFirstQualificationDetails {...props} />
        )
      }
      content={
        <div className="flex flex-row items-center justify-center">
          <ProgressWidget percentage={props.percent} />
        </div>
      }
    />
  );
}
