import { useTranslation } from 'libs/translations';
import { ICommitment } from 'types';

import { CommimentCard } from './CommimentCard';

export function UnfulfilledCommitmentCard(commitment: ICommitment) {
  const { t } = useTranslation();

  const { totalCommitedQuantity, totalFulfilledQuantity } =
    commitment.progress.reduce(
      (acc, value) => {
        return {
          totalCommitedQuantity:
            acc.totalCommitedQuantity + value.commitedQuantity,
          totalFulfilledQuantity:
            acc.totalFulfilledQuantity + value.fulfilledQuantity
        };
      },
      { totalCommitedQuantity: 0, totalFulfilledQuantity: 0 }
    );

  return (
    <CommimentCard status={commitment.status}>
      <p>
        {t(
          'You sold {{totalFulfilledQuantity}} of {{totalCommitedQuantity}} products.',
          {
            totalFulfilledQuantity,
            totalCommitedQuantity
          }
        )}
      </p>
    </CommimentCard>
  );
}
