import { PMLogo } from '@pmi.libs/react-common';
import { BottomNavigationBar } from 'components/layouts/bottom-nav-bar/BottomNavigationBar';
import { PageContent } from 'components/layouts/page-content/PageContent';
import { Portal } from 'components/modals/Portal';
import { RootPageHeader } from 'components/RootPageHeader';
import { NavBar } from 'components/RootPageNavBar';
import { IS_PWA } from 'contants';
import { useGlobalConfigsContext, useScreenEvents } from 'contexts';
import { useBoolean } from 'hooks';
import { Icon, IconName } from 'libs/icons';
import { useRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { IoMenu } from 'react-icons/io5';

import { ILayoutProps } from './ILayoutProps';
import { PageHeader } from './page-header/PageHeader';

export function DefaultLayout(props: ILayoutProps) {
  const {
    app: {
      office: { enableAnimations, collapseNavBar }
    }
  } = useGlobalConfigsContext();

  const divRef = useRef<HTMLDivElement>(null);

  const [isMenuOpen, { setFalse: closeMenu, setTrue: openMenu }] =
    useBoolean(false);
  const { md } = useScreenEvents();

  return (
    <>
      <PageHeader>
        {(!md || IS_PWA) && (
          <div>
            <button
              type="button"
              title="Menu"
              className="p-sm"
              onClick={openMenu}
            >
              <IoMenu size={20} />
            </button>
            <Portal>
              <div
                ref={divRef}
                className={`${
                  isMenuOpen ? 'visible opacity-1' : 'invisible opacity-0'
                } ${
                  enableAnimations
                    ? 'transition-all duration-300 ease-linear'
                    : 'pmi-force-transition-none'
                } fixed inset-0 w-full h-full flex pmi-glass-black z-10`}
                onClick={closeMenu}
              >
                <div
                  style={{
                    transition: 'transform 0.150s ease-in-out',
                    minWidth: '50%'
                  }}
                  className={`${
                    enableAnimations
                      ? isMenuOpen
                        ? 'translate-x-0'
                        : '-translate-x-full'
                      : ''
                  } min-w-fit w-4/5 md:w-1/2 h-full flex flex-col overflow-y-auto bg-white shadow-xl`}
                >
                  <div className="flex flex-row items-center justify-between mt-xs pmi-safe-area-inset-l">
                    <div className="w-fit flex flex-row items-center">
                      <PMLogo className="h-[48px] pointer-events-none" />
                      <h1 className="ml-xs text-ellipsis overflow-hidden whitespace-nowrap">
                        Office
                      </h1>
                    </div>
                    <div className="mr-sm">
                      <button
                        className="flex p-xs -m-xs text-silver"
                        title="Close"
                        onClick={closeMenu}
                      >
                        <Icon name={IconName.Cross} size={16} />
                      </button>
                    </div>
                  </div>
                  <NavBar expanded />
                </div>
              </div>
            </Portal>
          </div>
        )}
        <RootPageHeader />
      </PageHeader>

      {!IS_PWA && md ? (
        <>
          <aside
            className={`bg-white fixed top-[56px] md:top-[72px] left-0 bottom-0 w-full ${
              collapseNavBar ? 'max-w-[48px]' : 'max-w-[240px]'
            }`}
          >
            <NavBar />
          </aside>
          <div
            className={
              collapseNavBar ? 'ml-0 md:ml-[48px]' : 'ml-0 md:ml-[240px]'
            }
          >
            <PageContent>{props.children}</PageContent>
          </div>
        </>
      ) : (
        <PageContent>{props.children}</PageContent>
      )}

      {IS_PWA && <BottomNavigationBar />}
    </>
  );
}
