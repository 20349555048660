import { Modal } from 'components/dialogs';
import { useState } from 'react';
import AuthenticationService from 'services/AuthenticationService';

export function ImpersonatorBadge() {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <>
      <button
        title="Impersonator"
        onClick={() => setOpen(open => !open)}
        className="text-[#8253D7]"
      >
        <ImpersonatorIcon size={20} />
      </button>
      <Modal
        noPadding
        noBg
        hideCloseBtn
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <div className="bg-[#0E2431] shadow-md rounded-md text-[#8253D7] px-md py-sm">
          <div className="flex flex-row gap-xs items-center">
            <h1 className="font-bold flex flex-row items-center">
              <ImpersonatorIcon size={32} />
              <span className="ml-sm">
                You are impersonating user{' '}
                <strong>{AuthenticationService.user.userId}</strong>
              </span>
            </h1>
          </div>
          <p className="mt-sm">
            Certain features are not available to protect this user's account:
          </p>
          <ul className="list-disc list-inside">
            <li>Changes to preferences are not persisted.</li>
            <li>Interactions with messages are not persisted.</li>
            <li>Password change is not available.</li>
          </ul>
        </div>

        <div className="flex flex-row justify-center mt-sm">
          <button
            onClick={() => {
              setOpen(false);
            }}
            className="rounded-full shadow-md text-[#0E2431] bg-[#8253D7] font-bold px-md py-xs"
          >
            OK
          </button>
        </div>
      </Modal>
    </>
  );
}

function ImpersonatorIcon(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <path
          d="M23.558,17.511l-1.412-4.237a2.986,2.986,0,0,0-1.046-1.45L18.461,9.846A9.38,9.38,0,0,0,19,7c0-2.552-4.449-7-7-7S5,4.448,5,7a9.38,9.38,0,0,0,.539,2.846L2.9,11.824a2.984,2.984,0,0,0-1.046,1.451L.442,17.512a2.983,2.983,0,0,0,.725,3.069l1.126,1.126A1,1,0,0,0,3,22H4V15a3,3,0,0,1,3-3H8.769A8.013,8.013,0,0,1,7.183,8.7,2,2,0,0,1,8.4,6.438L11.257,5.3a2,2,0,0,1,1.486,0L15.6,6.438A2,2,0,0,1,16.817,8.7,8.013,8.013,0,0,1,15.231,12H17a3,3,0,0,1,3,3v7h1a1,1,0,0,0,.707-.293l1.126-1.126A2.985,2.985,0,0,0,23.558,17.511Z"
          fill="currentColor"
        />
        <path d="M17,14H7a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V15A1,1,0,0,0,17,14Zm-5,6.6a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,20.6Z" />
      </g>
    </svg>
  );
}
