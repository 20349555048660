import { useMutation, useQueryClient } from '@tanstack/react-query';
import { flushCommiment } from 'services';

import { COMMITMENTS_QUERY_KEY } from './useCommitments';
import { INVENTORY_QUERY_KEY } from './useCommitmentsInventory';

export function useFlushCommitment() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: flushCommiment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COMMITMENTS_QUERY_KEY] });
      // Invalid the inventory to, so the list gets updated
      queryClient.invalidateQueries({ queryKey: [INVENTORY_QUERY_KEY] });
    }
  });

  return {
    flushCommiment: mutation.mutate,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    error: mutation.error ?? undefined,
    data: mutation.data
  };
}
