import { Icon, IconName } from 'libs/icons';

import './WidgetDetailsButton.scss';

interface IWidgetDetailsButtonProps {
  readonly onClick: () => void;
  readonly label: string;
}

export function WidgetDetailsButton(props: IWidgetDetailsButtonProps) {
  return (
    <button
      type="button"
      onClick={props.onClick}
      className="component__widget-details-button pmi-glass-white"
    >
      <h4>{props.label}</h4>
      <Icon name={IconName.ChevronRight} size={12} />
    </button>
  );
}
