import { FormattedUnitValue } from 'components/text';
import _ from 'lodash';
import { IUserProfile } from 'types';

interface IFirstlineTableColumnValueProps {
  readonly userProfile: IUserProfile;
}

export function StructureSalesColumn(props: IFirstlineTableColumnValueProps) {
  return (
    <FirstlineTableSalesColumn
      value={props.userProfile.businessDataSummary?.structureVolume.current}
    />
  );
}

export function PersonalSalesColumn(props: {
  readonly userProfile: IUserProfile;
}) {
  return (
    <FirstlineTableSalesColumn
      value={props.userProfile.businessDataSummary?.ownVolume}
    />
  );
}

export function SixLevelSalesColumn(props: {
  readonly userProfile: IUserProfile;
}) {
  return (
    <FirstlineTableSalesColumn
      value={props.userProfile.businessDataSummary?.sixLevelVolume}
    />
  );
}

function FirstlineTableSalesColumn(props: { readonly value?: number }) {
  if (_.isUndefined(props.value)) {
    return <span></span>;
  }

  return <FormattedUnitValue unit="points" value={props.value} />;
}
