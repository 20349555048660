import { FormattedUnitValue } from 'components/text';
import { Icons } from 'libs/icons';
import _ from 'lodash';
import { useState } from 'react';
import { IUserProfile } from 'types';

import {
  AutoshipDetailsModal,
  IAutoshipDetails
} from '../../autoships/AutoshipDetailsModal';
import { AutoshipStatus } from '../../autoships/AutoshipStatus';

interface IAutoshipsColumnProps {
  readonly userProfile: IUserProfile;
}

export function AutoshipsColumn(props: IAutoshipsColumnProps) {
  const [autoshipDetails, setAutoshipDetails] = useState<IAutoshipDetails>();

  if (_.isUndefined(props.userProfile.businessDataSummary)) {
    return null;
  }

  return (
    <>
      {props.userProfile.businessDataSummary.ownAutoships.length > 0 && (
        <ul>
          {props.userProfile.businessDataSummary.ownAutoships.map(
            (autoship, index) => (
              <li key={`autoship_${index}`}>
                <button
                  title={undefined}
                  type="button"
                  className="space-x-xs inline-flex items-center"
                  onClick={() => {
                    setAutoshipDetails({
                      ...autoship,
                      owner: props.userProfile
                    });
                  }}
                >
                  <FormattedUnitValue unit="points" value={autoship.points} />
                  <AutoshipStatus status={autoship.status} />
                </button>
              </li>
            )
          )}
        </ul>
      )}
      {props.userProfile.businessDataSummary.ownAutoships.length === 0 && (
        <Icons.Common.Cancel size={20} className="text-error inline-block" />
      )}
      {autoshipDetails && (
        <AutoshipDetailsModal
          {...autoshipDetails}
          onDismiss={() => setAutoshipDetails(undefined)}
        />
      )}
    </>
  );
}
