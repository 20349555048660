import { SupportedLanguage } from '@pmi.web/localization';
import { execGet } from 'services/RequestUtils';

const API_URL = `${process.env.REACT_APP_SHOPS_API}`;

export async function getProductDetails(
  articleNumber: string,
  shopId: string,
  language: SupportedLanguage
): Promise<IProduct> {
  const response = await execGet<{ readonly product: IProduct }>(
    `${API_URL}/${shopId}/products/${articleNumber}?locale=${language}`
  );

  return response.product;
}

export interface IProduct {
  readonly articleNumber: string;
  readonly details: {
    readonly localeCode: string;
    readonly media: ReadonlyArray<{
      readonly id: string;
      readonly type: string;
      readonly url: string;
    }>;
    readonly attributes: {
      readonly [k: string | 'name' | 'description']: {
        readonly label: string;
        readonly value: string;
        readonly type: string;
      };
    };
  };
}
