import { LogoSplashScreen } from 'components/splash-screen/LogoSplashScreen';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Location } from 'react-router-dom';
import AuthenticationService from 'services/AuthenticationService';

/**
 * Authentication redirect route
 */
export function CallbackRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    AuthenticationService.finishSignIn().then(state => {
      if (!AuthenticationService.isAllowedToUseThisOffice) {
        navigate('/401', { replace: true });
        return;
      }

      let redirectLocation: Location = {
        pathname: '/dashboard',
        search: '',
        hash: '',
        state: null,
        key: 'default'
      };

      if (state) {
        // Restore the satate provided when the signin process was started.
        // Please ensure the parsing of the state is in sync with the state
        // set in the AuthenticatedRoute component.
        redirectLocation = JSON.parse(state) as Location;
      }

      navigate(redirectLocation, { replace: true });
    });
  }, [navigate]);

  return <LogoSplashScreen />;
}
