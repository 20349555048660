import { IS_PROD_BUILD } from 'contants';
// eslint-disable-next-line no-restricted-imports
import { type ISbStoriesParams } from 'storyblok-js-client';

import { mapLanguageToStoryblokLocale } from '../StoryblockUtils';
import { STORYBLOK_CLIENT } from '../Storyblok';
import { FAQSlug } from './FAQSlug';
import { IFAQItem, IFAQs } from './IFAQs';

export async function fetchFAQsStory(
  slug: FAQSlug,
  language: string
): Promise<ReadonlyArray<IFAQItem>> {
  const storyblokParams: ISbStoriesParams = {
    version: IS_PROD_BUILD ? 'published' : 'draft',
    resolve_relations: ['featured-posts.posts', 'selected-posts.posts'],
    language: mapLanguageToStoryblokLocale(language),
    token: `${process.env.REACT_APP_STORYBLOK_OFFICE_ACCESS_TOKEN}`
  };

  const response = (
    await STORYBLOK_CLIENT.get(`cdn/stories/${slug}`, storyblokParams)
  )?.data?.story as IFAQs;

  return response.content.questions;
}
