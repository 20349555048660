import {
  Chart as ChartJS,
  registerables as ChartJSRegisterables
} from 'chart.js';
import { usePII } from 'features/pii';
import { useTranslation } from 'libs/translations';
import { useCallback, useEffect, useRef, useState } from 'react';
import { formatNumberLocalized } from 'utils';

import { IDevelopmentChartDataPoint } from './types';
import {
  computeDevelopmentChartDataSet,
  computeDevelopmentChartLabels
} from './utils';

ChartJS.register(...ChartJSRegisterables);

interface IDevelopmentChartProps {
  readonly xAxesLabel?: string;
  readonly yAxesLabel?: string;
  readonly negativeDataPoints: IDevelopmentChartDataPoint[];
  readonly positiveDataPoints: IDevelopmentChartDataPoint[];
}

export function DevelopmentChart(props: IDevelopmentChartProps) {
  const { activeLanguage } = useTranslation();
  const { isPrivacyGuardEnabled } = usePII();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chartRef, setChartRef] = useState<ChartJS | null>(null);

  const renderChart = useCallback(() => {
    if (!canvasRef.current) {
      return;
    }

    const chart = new ChartJS(canvasRef.current, {
      type: 'line',
      data: {
        labels: computeDevelopmentChartLabels(props.positiveDataPoints),
        datasets: computeDevelopmentChartDataSet(
          props.negativeDataPoints,
          props.positiveDataPoints
        )
      },
      options: {
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: props.xAxesLabel
            }
          },
          y: {
            beginAtZero: true,
            grace: '5%',
            type: 'linear',
            display: isPrivacyGuardEnabled ? false : true,
            title: {
              display: true,
              text: props.yAxesLabel
            },
            ticks: {
              callback: value => {
                return formatNumberLocalized(activeLanguage, value as number);
              }
            }
          }
        },
        elements: {
          point: {
            radius: isPrivacyGuardEnabled ? 0 : 3,
            hoverRadius: isPrivacyGuardEnabled ? 0 : 3
          }
        },
        plugins: {
          tooltip: {
            enabled: isPrivacyGuardEnabled ? false : true,
            callbacks: {
              label: ({ formattedValue }) => {
                const numberValue = parseFloat(formattedValue);

                if (numberValue < 0) {
                  return formattedValue.slice(1);
                }

                return formattedValue;
              }
            }
          },
          legend: {
            display: false
          }
        }
      }
    });

    setChartRef(chart);
  }, [
    activeLanguage,
    isPrivacyGuardEnabled,
    props.negativeDataPoints,
    props.positiveDataPoints,
    props.xAxesLabel,
    props.yAxesLabel
  ]);

  const destroyChart = () => {
    if (chartRef) {
      chartRef.destroy();
      setChartRef(null);
    }
  };

  useEffect(() => {
    renderChart();

    return () => destroyChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!chartRef) {
      return;
    }

    chartRef.data.labels = computeDevelopmentChartLabels(
      props.positiveDataPoints
    );
    chartRef.data.datasets = computeDevelopmentChartDataSet(
      props.negativeDataPoints,
      props.positiveDataPoints
    );
    chartRef.update();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.negativeDataPoints, props.positiveDataPoints]);

  return <canvas ref={canvasRef} role="img"></canvas>;
}
