import { Timestamp } from '@pmi.libs/utils-common';

export enum NotificationType {
  PopCard,
  Notice
}
export interface INotification {
  readonly id: string;
  readonly type?: NotificationType;
  readonly timestamp: Timestamp;
  readonly dateFrom?: Timestamp;
  readonly dateTo?: Timestamp;
  readonly thumbnail?: string;
  readonly title: string;
  readonly description?: string;
  readonly ctaTitle?: string;
  readonly internalHref?: string;
  readonly popcardFireworks?: boolean;
}

export interface INotificationObject extends INotification {
  readonly read: boolean;
}

export interface INotificationSerializedObject {
  readonly id: string;
  readonly timestamp: number;
  readonly dateFrom?: number;
  readonly dateTo?: number;
  readonly thumbnail?: string;
  readonly title: string;
  readonly description?: string;
  readonly internalHref?: string;
  readonly read: boolean;
}
