import { useTranslation } from 'libs/translations';

import { WizardStepId } from '../contexts/WizardStepId';

export function useStepI18n() {
  const { t } = useTranslation();

  const customT = (step: WizardStepId, completed?: boolean) => {
    switch (step) {
      case 'owner':
        return completed ? t('Selected Recipient') : t('Select Recipient');
      case 'content':
        return completed ? t('Selected Products') : t('Select Products');
      case 'summary':
        return t('Basket Summary');
      case 'done':
        return t('Done');
    }
  };

  return customT;
}
