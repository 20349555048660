import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { useGlobalConfigsContext } from 'contexts';
import { PromoShopBanner } from 'features/promo-shop';
import { VirtualWarehouseHelpModal } from 'features/virtual-warehouse/components/VirtualWarehouseHelpModal';
import { FAQButton, FAQSlug } from 'libs/cms';
import { Navigate, Outlet } from 'react-router-dom';

export function VirtualWarehouseRoute() {
  const { promoShop } = useGlobalConfigsContext();

  if (!promoShop || !promoShop.isVirtualWarehouseEnabled) {
    return <Navigate to="/" replace />;
  }

  return (
    <section className="space-y-md">
      <div className="flex justify-between">
        <PageTitle />
        <VirtualWarehouseHelpModal />
      </div>
      <PromoShopBanner />
      <FAQButton slug={FAQSlug.VirtualWarehouse} />
      <Outlet />
    </section>
  );
}
