export enum QualificationLevel {
  Teampartner = 'TP',
  Teampartner1 = 'TP I',
  Teampartner2 = 'TP II',
  MinusManager = '-M',
  Manager = 'M',
  SalesManager = 'SM',
  MarketingManager = 'MM',
  InternationalMarketingManager = 'IMM',
  VicePresident = 'VP',
  ExecutiveVicePresident = 'EVP',
  PresidentsTeam = 'P',
  SilverPresidentsTeam = 'SP',
  GoldPresidentsTeam = 'GP',
  PlatinumPresidentsTeam = 'PP',
  ChampionsLeague = 'CL',
  SilverChampionsLeague = 'SCL',
  GoldChampionsLeague = 'GCL',
  PlatinumChampionsLeague = 'PCL',
  Champion = 'C'
}
