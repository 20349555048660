import { PrimaryButton } from '@pmi.web/react-common';
import { BottomSheetContainer } from 'components/modals';
import { PII } from 'features/pii';
import { useBoolean } from 'hooks';
import { SocialShare } from 'libs/social-share';
import { triggerSuccessToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { IoCopyOutline, IoShareOutline } from 'react-icons/io5';

interface IBasketLinkShareProps {
  readonly href: string;
  readonly onClick: (
    copyClick: boolean,
    socialShareAppCllick: string | undefined
  ) => void;
}

export function BasketLinkShare(props: IBasketLinkShareProps) {
  const { t } = useTranslation();
  const [
    showAppSharePopup,
    { setFalse: closeAppSharePopup, setTrue: openAppSharePopup }
  ] = useBoolean(false);

  return (
    <>
      <section>
        <div className="border border-lightGray rounded-md w-full relative flex flex-row justify-between items-center gap-sm">
          <p className="text-center w-full line-clamp-1">
            <PII value={props.href} type="link" />
          </p>
          <button
            title={t('Copy')}
            className="p-sm rounded text-active hover:bg-secondary transition-all"
            onClick={() => {
              navigator.clipboard.writeText(props.href);
              triggerSuccessToast(
                t('The URL was copied to your clipboard successfully!')
              );
            }}
          >
            <IoCopyOutline size={20} />
          </button>
        </div>
        <div className="flex gap-xs justify-center mt-sm">
          <PrimaryButton
            onClick={() => {
              if ('share' in navigator) {
                try {
                  navigator.share({
                    url: props.href
                  });
                } catch (err) {
                  // If user cancels, an error is thrown
                }
              } else {
                openAppSharePopup();
              }
            }}
          >
            <IoShareOutline size={20} />
            {t('Share')}
          </PrimaryButton>
        </div>
      </section>
      <BottomSheetContainer
        open={showAppSharePopup}
        onDismiss={closeAppSharePopup}
      >
        <h1 className="text-center">
          {t('Share the link below with the basket owner!')}
        </h1>
        <div className="border border-lightGray rounded-md w-full relative p-xs flex flex-row justify-between items-center gap-sm mt-md">
          <p className="line-clamp-1 text-ellipsis">
            <PII value={props.href} type="link" />
          </p>
          <button
            className="pmi-caption uppercase border border-primary rounded-full px-sm py-xs text-primary font-bold hover:bg-secondary hover:border-active hover:text-active transition-all"
            title={undefined}
            onClick={() => {
              navigator.clipboard.writeText(props.href);
              triggerSuccessToast(
                t('The URL was copied to your clipboard successfully!')
              );
            }}
          >
            {t('Copy')}
          </button>
        </div>
        <div className="flex justify-center mt-md">
          <SocialShare
            url={props.href}
            telegram
            whatsapp
            email
            onClick={appName => {
              props.onClick(false, appName);
            }}
          />
        </div>
      </BottomSheetContainer>
    </>
  );
}
