import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createPromocode } from 'services';

import { PROMOCODE_QUERY_KEY } from './PromocodeQueryConfig';

export function useCreatePromocode() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: createPromocode,
    onSuccess: response => {
      queryClient.setQueryData([PROMOCODE_QUERY_KEY], response);
    }
  });

  return {
    createPromocode: mutation.mutate,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    error: mutation.error ?? undefined,
    data: mutation.data
  };
}
