import { Icons } from 'libs/icons';
import { trackEvent } from 'libs/telemetry';
import { useEffect } from 'react';

import './NotificationsBell.scss';

interface INotificationsBellProps {
  readonly onClick: () => void;
  readonly hasUnreadNotifications: boolean;
}

export function NotificationsBell(props: INotificationsBellProps) {
  useEffect(() => {
    if (props.hasUnreadNotifications) {
      trackEvent({ name: 'NotificationsCenter_HasUnreadNotifications' });
    }
  }, [props.hasUnreadNotifications]);

  return (
    <button
      onClick={props.onClick}
      title="Notifications"
      className={`component__notifications-bell ${
        props.hasUnreadNotifications ? 'bell-on' : ''
      }`}
    >
      <Icons.Common.Bell size={20} />
    </button>
  );
}
