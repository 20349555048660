import { Dialog, Transition } from '@headlessui/react';
import { UserHeader } from 'components/headers';
import { LoadingSpinner } from 'components/spinners';
import { Disclaimer } from 'components/text';
import { useGlobalConfigsContext } from 'contexts';
import { usePII } from 'features/pii';
import { useBoolean } from 'hooks';
import { Icons } from 'libs/icons';
import { trackEvent } from 'libs/telemetry';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { searchUsers } from 'services';
import AuthenticationService from 'services/AuthenticationService';
import { IUserProfile, IUserSearchResults } from 'types';

import './SearchButton.scss';

const PAGE_NUMBER = 1;
const PAGE_SIZE = 5;
const DEBOUNCE_WAIT = 500;
const MIN_INPUT_SIZE = 2;

const MAX_SEARCH_HISTORY_LENGTH = 5;
const SEARCH_STORAGE_KEY = 'pmi.web.office-globalsearch';

export function SearchButton() {
  const { t } = useTranslation();
  const { pii } = usePII();
  const {
    app: {
      office: { enableAnimations }
    }
  } = useGlobalConfigsContext();

  const [searchResults, setSearchResults] = useState<IUserSearchResults>();
  const [error, setError] = useState<unknown>();
  const [isOpen, { setFalse: closeModal, setTrue: openModal }] = useBoolean();
  const [isLoading, { setFalse: stopLoading, setTrue: startLoading }] =
    useBoolean();

  const recentSearchs: IUserProfile[] =
    JSON.parse(localStorage.getItem(SEARCH_STORAGE_KEY) || '{}')[
      AuthenticationService.user.userId
    ] ?? undefined;

  const updateSearchHistory = (recentSearch: IUserProfile) => {
    const searchHistory: { [userId: string]: IUserProfile[] } = JSON.parse(
      localStorage.getItem(SEARCH_STORAGE_KEY) || '{}'
    );

    const loggedInUserSearchHistory =
      searchHistory[AuthenticationService.user.userId] ?? [];

    // If the recentSearch is already known, delete it from the array.
    // It will be added again in the beggining of the array
    const loggedInUserSearchHistoryDuplicateItemIndex =
      loggedInUserSearchHistory.findIndex(
        recentSearchUser => recentSearchUser.userId === recentSearch.userId
      );

    if (loggedInUserSearchHistoryDuplicateItemIndex !== -1) {
      loggedInUserSearchHistory.splice(
        loggedInUserSearchHistoryDuplicateItemIndex,
        1
      );
    }

    // Add new search result at the beggining of the array
    loggedInUserSearchHistory.unshift(recentSearch);

    // If we passed the max length, drop the last element in the array
    if (loggedInUserSearchHistory.length > MAX_SEARCH_HISTORY_LENGTH) {
      loggedInUserSearchHistory.splice(MAX_SEARCH_HISTORY_LENGTH);
    }

    localStorage.setItem(
      SEARCH_STORAGE_KEY,
      JSON.stringify({
        ...searchHistory,
        [AuthenticationService.user.userId]: loggedInUserSearchHistory
      })
    );
  };

  const debouncedSearch = _.debounce((searchInput: string) => {
    trackEvent({
      name: 'GlobalSearch_Searching',
      properties: {
        inputSize: searchInput.length
      }
    });

    startLoading();
    searchUsers(
      {
        search: searchInput
      },
      PAGE_NUMBER,
      PAGE_SIZE
    )
      .then(result => {
        setSearchResults(result);
      })
      .catch(setError)
      .finally(() => {
        stopLoading();
      });
  }, DEBOUNCE_WAIT);

  const search = (searchInput?: string) => {
    if (!searchInput || searchInput.length < MIN_INPUT_SIZE) {
      debouncedSearch.cancel();
      setSearchResults(undefined);
      stopLoading();
    } else {
      debouncedSearch(searchInput);
    }
  };

  const closeModalAndClearState = () => {
    closeModal();
    setSearchResults(undefined);
    setError(undefined);
    stopLoading();
    debouncedSearch.cancel();
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const modalContent = (
    <div className="bg-white rounded-md shadow-md p-xs flex flex-col">
      <fieldset className="flex flex-row items-center flex-nowrap">
        <Icons.Common.Search size={24} className="text-silver" />
        <input
          placeholder={t('Search by name or Team Partner ID')}
          type="text"
          className="p-xs w-full"
          onChange={event => search(event.target.value)}
        />
      </fieldset>

      {!_.isUndefined(error) && (
        <div className="w-full h-[100px] flex items-center justify-center border-t border-t-lightGray mt-xs">
          <Disclaimer type="error" message={t('Something went wrong.')} />
        </div>
      )}

      {!error &&
        !isLoading &&
        !searchResults &&
        recentSearchs &&
        recentSearchs.length > 0 && (
          <div className="w-full min-h-[100px] border-t border-t-lightGray p-sm space-y-sm">
            <h3 className="text-primary">{t('Recently viewed')}</h3>
            <ul className="space-y-sm">
              {recentSearchs.map(recentSearchItem => {
                return (
                  <li key={recentSearchItem.userId}>
                    <NavLink
                      onClick={() => {
                        closeModalAndClearState();
                      }}
                      to={`/downline/pathTo?userId=${recentSearchItem.userId}`}
                      title={pii(recentSearchItem.name, 'name')}
                    >
                      <UserHeader
                        user={recentSearchItem}
                        showBadges
                        showCheckAssurance
                        showQualification
                        showNewRegistration
                        disableNavigation
                        showCountryFlag
                      />
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

      {isLoading && (
        <div className="w-full h-[100px] flex items-center justify-center border-t border-t-lightGray mt-xs">
          <LoadingSpinner smallSize />
        </div>
      )}

      {!error &&
        !isLoading &&
        !_.isUndefined(searchResults) &&
        searchResults.data.length === 0 && (
          <div className="w-full h-[100px] flex items-center justify-center border-t border-t-lightGray mt-xs">
            {t('No results found.')}
          </div>
        )}

      {!error &&
        !isLoading &&
        !_.isUndefined(searchResults) &&
        searchResults.data.length > 0 && (
          <ul className="w-full min-h-[100px] space-y-sm border-t border-t-lightGray mt-xs p-sm">
            {searchResults.data.map(user => (
              <li key={user.userId}>
                <NavLink
                  onClick={() => {
                    updateSearchHistory(user);
                    closeModalAndClearState();
                  }}
                  to={`/downline/pathTo?userId=${user.userId}`}
                  title={pii(user.name, 'name')}
                >
                  <UserHeader
                    user={user}
                    showBadges
                    showCheckAssurance
                    showNewRegistration
                    showQualification
                    disableNavigation
                    showCountryFlag
                  />
                </NavLink>
              </li>
            ))}
          </ul>
        )}
    </div>
  );

  return (
    <>
      <button
        onClick={() => {
          trackEvent({
            name: 'GlobalSearch_Open'
          });
          openModal();
        }}
        className="component__search-button"
        title="Search"
      >
        <Icons.Office.RouteAnalytics size={20} />
      </button>

      {enableAnimations ? (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={closeModalAndClearState}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 pmi-glass-black" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className={`w-full max-w-lg transform text-left align-middle transition-all rounded-md`}
                  >
                    {modalContent}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      ) : (
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeModalAndClearState}
          open={isOpen}
        >
          <div className="fixed inset-0 pmi-glass-black" />

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Dialog.Panel
                className={`w-full max-w-lg transform text-left align-middle transition-all`}
              >
                {modalContent}
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
