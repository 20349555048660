import { PageContent } from 'components/layouts/page-content/PageContent';
import { PageHeader } from 'components/layouts/page-header/PageHeader';
import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useEffect } from 'react';

import { CancelBasketCreationButton } from './CancelBasketCreationButton';
import { OwnerSelectionStep } from './steps/01-owner-selection/OwnerSelectionStep';
import { ProductSelectionStep } from './steps/02-product-selection/ProductSelectionStep';
import { SummaryStep } from './steps/03-summary-step/SummaryStep';
import { Success } from './steps/04-success/Success';

export function NewBasketWizard() {
  const { id: activeStep, steps } = useNewBasketContext();

  const showSuccessPage = activeStep === steps[3];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [activeStep]);

  return (
    <>
      {!showSuccessPage && (
        <PageHeader sticky={false}>
          <section className="m-auto max-w-4xl w-full flex flex-row justify-between items-center">
            <PageTitle />
            <CancelBasketCreationButton />
          </section>
        </PageHeader>
      )}
      <PageContent>
        <section className="m-auto max-w-4xl space-y-sm">
          {showSuccessPage ? (
            <Success />
          ) : (
            <>
              <OwnerSelectionStep />
              <ProductSelectionStep />
              <SummaryStep />
            </>
          )}
        </section>
      </PageContent>
    </>
  );
}
