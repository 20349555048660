import { PMLogo } from '@pmi.libs/react-common';
import { useTranslation } from 'libs/translations';

export function InstallationInstructions() {
  const { t } = useTranslation();

  const isIos =
    typeof navigator !== 'undefined' &&
    navigator.userAgent.match(/iPhone|iPad|iPod/i);
  const isAndroid =
    typeof navigator !== 'undefined' && navigator.userAgent.match(/Android/i);

  return (
    <>
      <div className="flex flex-col items-center justify-center bg-primary py-sm">
        <div className="bg-white rounded-xl shadow-md">
          <PMLogo className="h-[64px]" />
        </div>
        <h1 className="text-white font-bold mt-xs">
          {t('PM Office Installation')}
        </h1>
      </div>
      <div className="p-sm">
        <div>
          {isIos && (
            <ol className="list-decimal ml-md">
              <li>{t('Click the share button in your browser')}</li>
              <li>{t('Click on "Add to Home Screen"')}</li>
            </ol>
          )}
          {isAndroid && (
            <ol className="list-decimal ml-md">
              <li>{t("Open your browser's menu")}</li>
              <li>{t('Click on "Add to Home screen" or "Install app"')}</li>
            </ol>
          )}
          {!isIos && !isAndroid && (
            <div>
              <ol className="list-decimal ml-md">
                <li>{t('Click on the settings button in your browser')}</li>
                <li>{t('Select "Apps"')}</li>
                <li>{t('Click on "Install PM Office"')}</li>
              </ol>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
