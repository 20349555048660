import { Badge } from 'components/badges';
import { IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';

interface IAutoshipStatusProps {
  readonly status: 'processed' | 'notProcessed' | 'cancelled' | 'paused';
  readonly showLabel?: boolean;
  readonly hideDetails?: boolean;
}

export function AutoshipStatus(props: IAutoshipStatusProps) {
  const { t } = useTranslation();

  if (props.status === 'processed') {
    return (
      <Badge
        icon={IconName.Box}
        title={props.showLabel ? t('Processed') : undefined}
        backgroundColorClass="#0f9a65"
        textColorClass="#ffffff"
        tooltipDetailsContent={<AutoshipStatusHelper />}
        hideDetails={props.hideDetails}
      />
    );
  }

  if (props.status === 'notProcessed') {
    return (
      <Badge
        icon={IconName.Box}
        title={props.showLabel ? t('Scheduled') : undefined}
        backgroundColorClass="#fcdf82"
        textColorClass="#7c6002"
        tooltipDetailsContent={<AutoshipStatusHelper />}
        hideDetails={props.hideDetails}
      />
    );
  }

  if (props.status === 'cancelled') {
    return (
      <Badge
        icon={IconName.Box}
        title={props.showLabel ? t('Canceled') : undefined}
        backgroundColorClass="#e59999"
        textColorClass="#651919"
        tooltipDetailsContent={<AutoshipStatusHelper />}
        hideDetails={props.hideDetails}
      />
    );
  }

  if (props.status === 'paused') {
    return (
      <Badge
        icon={IconName.Box}
        title={props.showLabel ? t('Paused') : undefined}
        backgroundColorClass="#f9af85"
        textColorClass="#793005"
        tooltipDetailsContent={<AutoshipStatusHelper />}
        hideDetails={props.hideDetails}
      />
    );
  }

  return null;
}

function AutoshipStatusHelper() {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t('Autoship status')}</h1>
      <ul className="space-y-sm">
        <li>
          <AutoshipStatus hideDetails status="processed" />
          {'\u00A0'}
          <AutoshipStatus hideDetails showLabel status="processed" />
          <p>
            {t('We processed your autoship and credited the points to you.')}
          </p>
        </li>
        <li>
          <AutoshipStatus hideDetails status="notProcessed" />
          {'\u00A0'}
          <AutoshipStatus hideDetails showLabel status="notProcessed" />
          <p>
            {t(
              'We will process your autoship and credit the points to you later this month.'
            )}
          </p>
        </li>
        <li>
          <AutoshipStatus hideDetails status="paused" />
          {'\u00A0'}
          <AutoshipStatus hideDetails showLabel status="paused" />
          <p>
            {t('You paused your autoship. We will not process it this month.')}
          </p>
        </li>
        <li>
          <AutoshipStatus hideDetails status="cancelled" />
          {'\u00A0'}
          <AutoshipStatus hideDetails showLabel status="cancelled" />
          <p>{t('You canceled your autoship.')}</p>
        </li>
      </ul>
    </>
  );
}
