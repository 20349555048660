import { ReactNode, createContext, useContext } from 'react';

import { useAnalyticsFilters } from '../hooks/useAnalyticsFilters';
import { IFilterFormValues } from '../types/IFilterFormValues';
import { ISearchFormValues } from '../types/ISearchFormValues';
import { ISortFormValues } from '../types/ISortFormValues';

interface IAnalyticsContextValues {
  readonly searchParams: ISearchFormValues;
  readonly filterParams: IFilterFormValues;
  readonly sortParams: ISortFormValues;
  readonly hasFiltersApplied: boolean;
  readonly hasSortByApplied: boolean;
  readonly setFiltersValues: (values: IFilterFormValues) => void;
  readonly setSortByValue: (value: ISortFormValues) => void;
  readonly setSearchValues: (values: ISearchFormValues) => void;
}

const AnalyticsContext = createContext<IAnalyticsContextValues | null>(null);

interface IAnalyticsProviderProps {
  readonly children: ReactNode;
}

export function AnalyticsProvider(props: IAnalyticsProviderProps) {
  const {
    filterParams,
    searchParams,
    sortParams,
    hasFiltersApplied,
    hasSortByApplied,
    updateParams
  } = useAnalyticsFilters();

  return (
    <AnalyticsContext.Provider
      value={{
        searchParams,
        filterParams,
        sortParams,
        hasFiltersApplied,
        hasSortByApplied,
        setFiltersValues: filters =>
          updateParams(searchParams, filters, sortParams),
        setSortByValue: sortby =>
          updateParams(searchParams, filterParams, sortby),
        setSearchValues: search =>
          updateParams(search, filterParams, sortParams)
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalyticsContext() {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error(
      'useAnalyticsContext must be used within AnalyticsProvider'
    );
  }

  return context;
}
