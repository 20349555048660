import { ErrorHandler } from 'components/errors/ErrorHandler';
import { trackException } from 'libs/telemetry';
import { triggerWarningToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import { useEffect } from 'react';

export function DashboardError(props: {
  readonly error: unknown;
  readonly userId: string;
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.error && props.error instanceof Error) {
      triggerWarningToast(
        t(
          'Something went wrong. Your business dashboard is currently unavailable. Please try again later or contact the support team.'
        )
      );
      trackException(
        props.error,
        'dashboard.tsx',
        "Failed to fetch signin user's dashboard"
      );
      console.error(
        `Failed to fetch business data of "${props.userId}"`,
        props.error
      );
    }
  }, [props, t]);

  return (
    <div className="w-full flex justify-center">
      <ErrorHandler err={props.error} />
    </div>
  );
}
