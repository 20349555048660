import { PrimaryLink } from '@pmi.web/react-common';
import { useGlobalConfigsContext } from 'contexts';
import { trackEvent } from 'libs/telemetry';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { FaCirclePlus } from 'react-icons/fa6';

export function AddProductsButton() {
  const { t } = useTranslation();
  const { promoShop } = useGlobalConfigsContext();

  if (
    !promoShop ||
    !promoShop.shopUrl ||
    !promoShop.isVirtualWarehouseEnabled ||
    !promoShop.canCreateCommitments
  ) {
    return null;
  }

  return (
    <PrimaryLink
      target="_blank"
      rel="noreferrer"
      href={promoShop.shopUrl}
      onClick={() => {
        trackEvent({
          name: 'ExternalLinkClick_VWShop',
          properties: {
            shopUrl: promoShop.shopUrl
          }
        });
      }}
    >
      <FaCirclePlus size={16} />
      {t('Add Products')}
    </PrimaryLink>
  );
}

export function AddProductsNowLink() {
  const { t } = useTranslation();
  const { promoShop } = useGlobalConfigsContext();

  if (
    !promoShop ||
    !promoShop.shopUrl ||
    !promoShop.isVirtualWarehouseEnabled ||
    !promoShop.canCreateCommitments
  ) {
    return null;
  }

  return (
    <a
      href={promoShop.shopUrl}
      target="_blank"
      rel="noreferrer"
      className="text-primary border-2 border-primary px-sm py-xxs rounded-full font-bold text-center line-clamp-2 text-ellipsis"
      onClick={() => {
        trackEvent({
          name: 'ExternalLinkClick_VWShop',
          properties: {
            shopUrl: promoShop.shopUrl
          }
        });
      }}
    >
      {t('Add Products')}
    </a>
  );
}
