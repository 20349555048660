import { localizedCountryName } from '@pmi.web/countries';
import { CountryFlag } from '@pmi.web/react-country-flags';
import { QUALIFICATIONS } from 'consts';
import { Icons } from 'libs/icons';
import _ from 'lodash';
import { GoLinkExternal } from 'react-icons/go';
import { PiSealCheckFill } from 'react-icons/pi';

import { AccountProfilePageProps } from './IProps';

export const AccountProfilePage = (props: AccountProfilePageProps) => {
  const {
    t,
    activeLanguage,
    userData,
    profileEditLink,
    RankBadge,
    piiComponent: PII,
    piiFun: pii
  } = props;

  return (
    <section className="grid grid-cols-1 lg:grid-cols-2 gap-md">
      <div>
        <div className="flex justify-between gap-3 items-center">
          <h2 className="text-primary">{t('Personal information')}</h2>
          <a
            href={profileEditLink}
            target="_blank"
            rel="noreferrer"
            className="flex flex-row flex-nowrap gap-xs items-center w-fit text-primary hover:text-active"
          >
            <span>{t('Edit')}</span>
            <GoLinkExternal size={20} />
          </a>
        </div>
        <div className="bg-white p-sm shadow-md rounded-md mt-sm">
          <div className="grid grid-cols-[140px_minmax(100px,_1fr)] gap-sm">
            <h3 className="text-silver overflow-hidden text-ellipsis">
              {t('Name')}
            </h3>
            <p>
              <PII value={userData?.name} type="name" />
            </p>
            <h3 className="text-silver overflow-hidden text-ellipsis">
              {t('Birth Date')}
            </h3>
            <p>
              {!_.isUndefined(userData) &&
                !_.isUndefined(userData.birthDate) && (
                  <PII
                    value={userData.birthDate.toLocaleString(
                      activeLanguage,
                      'long'
                    )}
                    type="fixed-date"
                  />
                )}
            </p>
            <h3 className="text-silver overflow-hidden text-ellipsis">
              {t('Phone number')}
            </h3>
            <p>
              <PII value={userData?.phoneNumber} type="phone" />
            </p>
            <h3 className="text-silver overflow-hidden text-ellipsis">
              {t('Email')}
            </h3>
            <p className="text-ellipsis overflow-hidden">
              <PII value={userData?.email} type="email" />
            </p>
            <h3 className="text-silver overflow-hidden text-ellipsis">
              {t('Address')}
            </h3>
            <div>
              {!_.isUndefined(userData) && !_.isUndefined(userData.address) && (
                <>
                  {!_.isUndefined(userData.address.street) && (
                    <p>
                      <PII value={userData.address.street} type="address" />
                    </p>
                  )}
                  {!_.isUndefined(userData.address.zipcode) && (
                    <p>
                      <PII value={userData.address.zipcode} type="address" />
                    </p>
                  )}
                  {!_.isUndefined(userData.address.city) && (
                    <p>
                      <PII value={userData.address.city} type="address" />
                    </p>
                  )}
                  {!_.isUndefined(userData.address.countryCode) && (
                    <p className="text-ellipsis overflow-hidden">
                      {localizedCountryName(
                        pii(userData.address.countryCode, 'country'),
                        activeLanguage
                      )}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-primary">{t('Team Partner')}</h2>
        <div className="bg-white p-sm shadow-md rounded-md mt-sm">
          <div className="grid grid-cols-[140px_minmax(100px,_1fr)] gap-sm">
            <h3 className="text-silver overflow-hidden text-ellipsis">
              {t('ID')}
            </h3>
            <p>
              <PII value={userData?.userId} type="tpid" />
            </p>
            <h3 className="text-silver overflow-hidden text-ellipsis">
              {t('Rank')}
            </h3>
            <div className="flex flex-wrap items-center">
              {!_.isUndefined(userData) && !_.isUndefined(userData.rank) && (
                <span>
                  <RankBadge rank={userData.rank} />
                  <span className="ml-xs">
                    {QUALIFICATIONS[userData.rank].name}
                  </span>
                </span>
              )}
            </div>
            <h3 className="text-silver overflow-hidden text-ellipsis">
              {t('Registration Date')}
            </h3>
            <p>
              {!_.isUndefined(userData) &&
                !_.isUndefined(userData.registrationDate) && (
                  <PII
                    value={userData.registrationDate.toLocaleString(
                      activeLanguage,
                      'long'
                    )}
                    type="fixed-date"
                  />
                )}
            </p>
            <h3 className="text-silver overflow-hidden text-ellipsis">
              {t('Country')}
            </h3>
            <p>
              {!_.isUndefined(userData) &&
                !_.isUndefined(userData.countryCode) && (
                  <CountryFlag
                    countryCode={pii(userData.countryCode, 'country')}
                    withCountryName
                  />
                )}
            </p>
            <h3 className="text-silver overflow-hidden text-ellipsis">
              {t('FitLine Club member')}
            </h3>
            <div className="flex flex-wrap items-center">
              {userData?.isFitlineClubMember ? (
                <PiSealCheckFill size={20} className="text-success" />
              ) : (
                <Icons.Common.Cancel size={20} className="text-error" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-primary">{t('Sponsor information')}</h2>
        <div className="bg-white p-sm shadow-md rounded-md mt-sm">
          <div className="grid grid-cols-[140px_minmax(100px,_1fr)] gap-sm">
            <h3 className="text-silver overflow-hidden text-ellipsis">
              {t('Sponsor Name')}
            </h3>
            <p>
              <PII value={userData?.sponsor.name} type="name" />
            </p>
            <h3 className="text-silver overflow-hidden text-ellipsis">
              {t('Sponsor ID')}
            </h3>
            <p>
              <PII value={userData?.sponsor.userId} type="tpid" />
            </p>
          </div>
        </div>
      </div>
      {!_.isUndefined(userData) && !_.isUndefined(userData.coach) && (
        <div>
          <h2 className="text-primary">{t('Coach information')}</h2>
          <div className="bg-white p-sm shadow-md rounded-md mt-sm">
            <div className="grid grid-cols-[140px_minmax(100px,_1fr)] gap-sm">
              <h3 className="text-silver overflow-hidden text-ellipsis">
                {t('Coach Name')}
              </h3>
              <p>
                <PII value={userData.coach.name} type="name" />
              </p>
              <h3 className="text-silver overflow-hidden text-ellipsis">
                {t('Coach ID')}
              </h3>
              <p>
                <PII value={userData.coach.userId} type="tpid" />
              </p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
