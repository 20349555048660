import { Modal } from 'components/dialogs';

import { IPopCard } from './IPopCard';
import { PopCard } from './PopCard';
import { usePopCardContext } from './PopCardContext';

interface IPopCardContainerProps {
  readonly popcard: IPopCard;
}

export function PopCardContainer(props: IPopCardContainerProps) {
  const { closePopCard } = usePopCardContext();

  return (
    <Modal
      noPadding
      open
      onClose={() => {
        // Delay the update to ensure the closing animation is not affected
        setTimeout(() => closePopCard(props.popcard.id), 200);
        props.popcard.ctaAction();
      }}
      closeButtonLabel={props.popcard.cta}
    >
      <PopCard {...props.popcard} />
    </Modal>
  );
}
