import { useEffect, useState } from 'react';

import { INotificationObject } from '../schemas/INotificationItem';
import NotificationsService from '../services/NotificationsService';

export function useNotifications() {
  const [notifications, setNotifications] = useState<
    ReadonlyArray<INotificationObject>
  >([]);

  const hasUnreadNotifications =
    notifications.find(n => n.read === false) !== undefined;

  useEffect(() => {
    const subscription = NotificationsService.subscribe(() => {
      NotificationsService.getNotifications().then(setNotifications);
    });

    NotificationsService.getNotifications().then(setNotifications);

    return () => subscription.unsubscribe();
  }, []);

  return {
    hasUnreadNotifications,
    notifications
  };
}
