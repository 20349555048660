import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

interface IListItemProps {
  readonly title: string;
  readonly active?: boolean;
  readonly href?: string;
  readonly children: ReactNode;
}

export function ListItem(props: IListItemProps) {
  if (!props.href) {
    return (
      <div
        title={props.title}
        className={`border border-transparent py-sm p-xs border-b-lightGray last-of-type:border-b-transparent`}
      >
        {props.children}
      </div>
    );
  }

  return (
    <NavLink
      title={props.title}
      className={`border border-transparent py-sm p-xs block ${
        props.active
          ? 'bg-secondary border border-active rounded-md'
          : 'hover:bg-secondary hover:rounded-md border-b-lightGray last-of-type:border-b-transparent'
      }`}
      to={props.href}
    >
      {props.children}
    </NavLink>
  );
}
