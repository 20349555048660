import { ErrorHandler } from 'components/errors/ErrorHandler';
import { LoadingSpinner } from 'components/spinners';

import { CreatePromocode, PromocodeTicket } from './components';
import { usePromocode } from './hooks';

export function Promocode() {
  const {
    promocode,
    error,
    isLoading,
    createPromocodeSucceess,
    createPromocode
  } = usePromocode();

  if (error) {
    return <ErrorHandler err={error} />;
  }

  if (isLoading) {
    return (
      <div className="w-full flex justify-center p-md">
        <LoadingSpinner smallSize />
      </div>
    );
  }

  return (
    <>
      {promocode && (
        <div className={`${createPromocodeSucceess ? 'animate-zoom-in' : ''}`}>
          <PromocodeTicket {...promocode} />
        </div>
      )}

      {!promocode && <CreatePromocode onCreate={createPromocode} />}
    </>
  );
}
