import { SupportedLanguage } from '@pmi.web/localization';
import { ICommitmentList, ICommitment } from 'types';

import {
  parseAPICommitment,
  parseAPICommitmentList,
  parseCommitmentItem
} from './CommitmentsServiceHelper';
import { execGet, execPut } from '../RequestUtils';
import {
  ICommitmentApi,
  ICommitmentItemApi,
  ICommitmentListApi
} from './types/ICommitmentApi';

const API_URL = `${process.env.REACT_APP_SHOPS_API}`;

export async function getCommitment(
  commitmentId: string,
  language: SupportedLanguage
): Promise<ICommitment> {
  const commitments = await execGet<ICommitmentApi>(
    `${API_URL}/commitments/${commitmentId}?locale=${language}`
  );
  return parseAPICommitment(commitments);
}

export async function getCommitments(
  page: number,
  perPage: number,
  language: SupportedLanguage
): Promise<ICommitmentList> {
  const commitments = await execGet<ICommitmentListApi>(
    `${API_URL}/commitments?locale=${language}&page=${page}&perPage=${perPage}`
  );
  return parseAPICommitmentList(commitments);
}

export async function getCommitmentsInventory(language: SupportedLanguage) {
  const r = await execGet<ReadonlyArray<ICommitmentItemApi>>(
    `${API_URL}/commitments/products?locale=${language}`
  );
  return r.map(parseCommitmentItem);
}

export function flushCommiment(commitmentId: string): Promise<void> {
  return execPut<void>(`${API_URL}/commitments/${commitmentId}/flush`);
}
