import { Icon, IconName } from 'libs/icons';
import { trackEvent } from 'libs/telemetry';
import { useTranslation } from 'libs/translations';
import AuthenticationService from 'services/AuthenticationService';

import './SignoutButton.scss';

export function SignoutButton() {
  const { t } = useTranslation();

  return (
    <button
      className="signout-button"
      onClick={() => {
        trackEvent({
          name: 'SignoutButton_ConfirmationActionClick'
        });
        AuthenticationService.signout();
      }}
    >
      <Icon name={IconName.Exit} size={16} />
      {t('Sign out')}
    </button>
  );
}
