import { PrimaryLink } from '@pmi.web/react-common';
import { CountdownRenderer } from 'components/CountdownRenderer';
import { trackEvent } from 'libs/telemetry';
import { useTranslation } from 'libs/translations';
import Countdown from 'react-countdown';
import { CommitmentStatus, ICommitment } from 'types';

import { CommitmentStatusBadge } from './CommitmentStatusBadge';

export function CommitmentHeader(commitment: ICommitment) {
  const { t } = useTranslation();

  return (
    <div
      className={`${
        commitment.status === CommitmentStatus.Fulfilled
          ? 'bg-success'
          : commitment.status === CommitmentStatus.PaymentRequired
          ? 'bg-warn'
          : commitment.status === CommitmentStatus.Unfulfilled
          ? 'bg-lightGray'
          : 'bg-active'
      } px-sm md:px-md py-md md:py-lg flex flex-col items-center`}
    >
      <CommitmentStatusBadge status={commitment.status} />

      {commitment.status === CommitmentStatus.Active && (
        <Countdown
          date={commitment.expiresOn.getTime()}
          renderer={p => (
            <div className="text-white font-bold text-center mt-xxs">
              <CountdownRenderer {...p} />
            </div>
          )}
        />
      )}

      {commitment.status === CommitmentStatus.PaymentRequired &&
        commitment.fulfillmentBasketId && (
          <div className="flex flex-col items-center text-center gap-xs mt-sm">
            <p className="text-[#fcd9c0] font-bold">
              {t(
                'To fulfill your commitment and dispatch the products to you, the associated order must be paid.'
              )}
            </p>
            <PrimaryLink
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_MINI_SHOP_BASE_URL}/vcart/${commitment.fulfillmentBasketId}`}
              onClick={() => {
                trackEvent({
                  name: 'VirtualWarehouse_CommitmentPayNow'
                });
              }}
            >
              {t('Pay now')}
            </PrimaryLink>
          </div>
        )}
    </div>
  );
}
