import { useMemo } from 'react';
import AuthenticationService from 'services/AuthenticationService';

const DEFAULT_MAX_RANGE = 10000;
const DEFAULT_MAX_STEP_SIZE = 1000;
const DEFAULT_MIN_STEP_SIZE = 10;

export function useStructureSalesFilterLimits() {
  const { max, step } = useMemo(() => {
    const currentStructureSales =
      AuthenticationService.user.businessDataSummary?.structureVolume.current ??
      0;
    const lastMonthStructureSales =
      AuthenticationService.user.businessDataSummary?.structureVolume
        .lastMonth ?? 0;

    const max =
      Math.ceil(
        Math.max(
          currentStructureSales,
          lastMonthStructureSales,
          DEFAULT_MAX_RANGE
        ) / DEFAULT_MAX_RANGE
      ) * DEFAULT_MAX_RANGE;

    const step =
      max > DEFAULT_MAX_RANGE ? DEFAULT_MAX_STEP_SIZE : DEFAULT_MIN_STEP_SIZE;

    return { max, step };
  }, []);

  return {
    min: 0,
    max,
    step
  };
}

// 6930617.69
