import { useQuery } from '@tanstack/react-query';
import { getCountryShopMappings } from 'services';

const QUERY_KEY = 'pmi.web.office-country-shops';
const TTL = 900000; // 15 minutes in milliseconds

export function useCountryShops() {
  const query = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => getCountryShopMappings(),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
