import { HelperModal } from 'components/dialogs';
import { useBoolean } from 'hooks';
import { Icon, IconName, Icons } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import { IUserProfile } from 'types';

export function CheckAssuranceColumn(props: {
  readonly userProfile: IUserProfile;
}) {
  if (props.userProfile.businessDataSummary?.hasCheckAssurance) {
    return <CheckAssuranceAchieved />;
  }

  return <CheckAssuranceMissing />;
}

function CheckAssuranceMissing() {
  const { t } = useTranslation();

  const [isOpen, { setFalse: close, setTrue: open }] = useBoolean(false);

  return (
    <>
      <button
        className="cursor-help inline-block"
        title={t('Check Assurance is missing.')}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          open();
        }}
      >
        <Icons.Common.Cancel size={20} className="text-error" />
      </button>
      {isOpen && (
        <HelperModal onDismiss={close}>
          <div className="flex flex-row items-center justify-center flex-wrap gap-xs">
            <Icons.Common.Cancel size={24} className="text-error" />
            <p>{t('Check Assurance is missing.')}</p>{' '}
          </div>
        </HelperModal>
      )}
    </>
  );
}

function CheckAssuranceAchieved() {
  const { t } = useTranslation();

  const [isOpen, { setFalse: close, setTrue: open }] = useBoolean(false);

  return (
    <>
      <button
        className="cursor-help inline-block"
        title={t('Achieved the Check Assurance!')}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          open();
        }}
      >
        <Icon name={IconName.Shield} size={16} className="text-success" />
      </button>
      {isOpen && (
        <HelperModal onDismiss={close}>
          <div className="flex flex-row items-center justify-center flex-wrap gap-xs">
            <Icon name={IconName.Shield} size={24} className="text-success" />
            <p>{t('Achieved the Check Assurance!')}</p>
          </div>
        </HelperModal>
      )}
    </>
  );
}
