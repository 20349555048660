export function Cheque(props: { readonly size: number }) {
  return (
    <svg
      width={props.size}
      height={props.size * (48 / 51)} // To maintain aspect ratio
      viewBox="0 0 51 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.1257 8.19873H49V39.895H1.40625L3.87481 35.8466L1.40625 31.8969L3.87481 27.9472L1.40625 23.9975L3.87481 20.0478L1.40625 16.0981L3.87481 12.1484L1.40625 8.19873H38.1257Z"
        stroke="#A6A9AC"
        strokeWidth="2.1065"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5004 16.2216C15.5004 16.2216 14.3164 15.1296 12.4915 15.6793C10.8157 16.1841 10.3995 18.477 11.5383 19.3701C12.1922 19.883 13.1761 20.3046 14.502 20.7733C17.4697 21.8224 16.3726 26.0076 13.3538 26.0276M13.3538 26.0276C12.1757 26.0354 11.625 25.9607 10.5901 25.2837M13.3538 26.0276L13.3541 27.1573M13.3541 15.5288V14.5183M24.4132 18.468H42.5818M24.4132 24.7875H32.3126M10.1943 33.4768H16.5138M28.3629 33.4768L29.235 32.6047C30.0943 31.7454 31.4549 31.6487 32.4271 32.3779C32.8499 32.6949 33.364 32.8663 33.8924 32.8663C34.4208 32.8663 34.935 32.6949 35.3577 32.3779C36.33 31.6487 37.6905 31.7454 38.5498 32.6047L38.7066 32.7615C38.9334 32.9883 39.2027 33.1682 39.499 33.2909C39.7953 33.4137 40.1128 33.4768 40.4335 33.4768H43.3717"
        stroke="#A6A9AC"
        strokeWidth="2.1065"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
