import { InvoiceProductList } from 'features/invoices';
import _ from 'lodash';
import { ICommitment } from 'types';

export function CommitmentProducts(props: ICommitment) {
  return (
    <InvoiceProductList
      showPoints
      items={props.items.map(i => {
        const imgSrc = i.details.media.find(
          m => m.type === 'image' && !_.isUndefined(m.url)
        );

        return {
          articleNumber: i.articleNumber,
          currencyCode: i.cost.currencyCode,
          itemPrice: i.cost.displayPrice,
          points: i.points,
          quantity: i.quantity,
          name: i.details.attributes.name?.value,
          imgSrc: imgSrc?.url
        };
      })}
    />
  );
}
