import { useScreenEvents } from 'contexts';
import { FC } from 'react';

import './FirstlineTable.scss';

export const FirstlineTableSkeleton: FC = () => {
  const { sm, lg } = useScreenEvents();

  return (
    <table className="firstline-table">
      <thead>
        <tr>
          <th>
            <div className="bg-lightGray h-[24px] w-[120px]"></div>
          </th>
          <th>
            <div className="w-full flex justify-end">
              <div className="bg-lightGray h-[24px] w-[120px]"></div>
            </div>
          </th>
          {sm && (
            <th>
              <div className="w-full flex justify-end">
                <div className="bg-lightGray h-[24px] w-[120px]"></div>
              </div>
            </th>
          )}
          {lg && (
            <th>
              <div className="w-full flex justify-end">
                <div className="bg-lightGray h-[24px] w-[120px]"></div>
              </div>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        <tr className="h-[24px]">
          <td></td>
          <td></td>
          {sm && <td></td>}
          {lg && <td></td>}
        </tr>
        <tr className="h-[24px]">
          <td></td>
          <td></td>
          {sm && <td></td>}
          {lg && <td></td>}
        </tr>
        <tr className="h-[24px]">
          <td></td>
          <td></td>
          {sm && <td></td>}
          {lg && <td></td>}
        </tr>
        <tr className="h-[24px]">
          <td></td>
          <td></td>
          {sm && <td></td>}
          {lg && <td></td>}
        </tr>
        <tr className="h-[24px]">
          <td></td>
          <td></td>
          {sm && <td></td>}
          {lg && <td></td>}
        </tr>
      </tbody>
    </table>
  );
};
