import { ReactNode } from 'react';

interface IPillBadgeProps {
  readonly title: string | ReactNode;
  readonly icon?: ReactNode;
  readonly small?: boolean;
  readonly className?: string;
}

export function PillBadge(props: IPillBadgeProps) {
  return (
    <div
      className={`flex flex-row w-fit flex-nowrap items-center gap-xs rounded-full px-xs py-xxs text-sm h-fit select-none ${
        props.className ?? ''
      }`}
    >
      {props.icon && <span>{props.icon}</span>}
      {!props.small &&
        (typeof props.title === 'string' ? (
          <span className="whitespace-nowrap">{props.title}</span>
        ) : (
          props.title
        ))}
    </div>
  );
}
