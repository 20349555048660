import { LineChart } from 'components/charts/line/LineChart';
import { FormattedUnitValue, LabelledText, TrendValue } from 'components/text';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo, useRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { FaAnglesDown } from 'react-icons/fa6';
import { IUserStructureAutoshipsData } from 'types';
import { computePercentageTrend } from 'utils';

import { WidgetAutoshipDevelopment } from './WidgetAutoshipDevelopment';

export function WidgetAutoshipsDetails(props: IUserStructureAutoshipsData) {
  const { t, activeLanguage } = useTranslation();

  const autoshipDevelopmentRef = useRef<HTMLDivElement>(null);

  const tabularData = useMemo(() => {
    const firstlineHistoricalDataDescending = [
      ...props.autoshipsInFirstline.history
    ].sort((a, b) => {
      // Sort in descending order
      return b.date.getTime() - a.date.getTime();
    });

    const structureHistoricalDataDescending = [
      ...props.autoshipsInStructure.history
    ].sort((a, b) => {
      // Sort in descending order
      return b.date.getTime() - a.date.getTime();
    });

    return firstlineHistoricalDataDescending.map((f, i) => {
      const firstlineMonthlyTrend =
        i < firstlineHistoricalDataDescending.length - 1
          ? computePercentageTrend(
              f.volume,
              firstlineHistoricalDataDescending[i + 1].volume
            )
          : undefined;

      const structureMonthlyTrend =
        i < structureHistoricalDataDescending.length - 1
          ? computePercentageTrend(
              structureHistoricalDataDescending[i].volume,
              structureHistoricalDataDescending[i + 1].volume
            )
          : undefined;

      return {
        firstline: f.volume,
        firstlineMonthlyTrend: firstlineMonthlyTrend,
        structure: structureHistoricalDataDescending[i].volume,
        structureMonthlyTrend: structureMonthlyTrend,
        date: f.date
      };
    });
  }, [props.autoshipsInFirstline.history, props.autoshipsInStructure.history]);

  const { datasets, labels } = useMemo(() => {
    const firstline = [...props.autoshipsInFirstline.history].sort((a, b) => {
      // Sort in ascending order
      return a.date.getTime() - b.date.getTime();
    });

    const structure = [...props.autoshipsInStructure.history].sort((a, b) => {
      // Sort in ascending order
      return a.date.getTime() - b.date.getTime();
    });

    return {
      datasets: [
        // Firstline
        {
          data: firstline.map(h => h.volume),
          label: t('Firstline'),
          cssRgbColor: '#F675A8'
        },
        // Structure
        {
          data: structure.map(h => h.volume),
          label: t('Structure'),
          cssRgbColor: '#554994'
        }
      ],
      // Both firstline and structure are expected to have the same dates
      labels: firstline.map(d => d.date.toLocaleString(activeLanguage))
    };
  }, [
    activeLanguage,
    props.autoshipsInFirstline.history,
    props.autoshipsInStructure.history,
    t
  ]);

  return (
    <>
      <div className="flex flex-row justify-center text-center">
        <div className="mx-sm">
          <LabelledText
            label={t('Firstline Autoships')}
            value={
              <p className="font-bold">
                <FormattedUnitValue
                  unit="count"
                  value={props.autoshipsInFirstline.current ?? 0}
                />
              </p>
            }
          />
        </div>
        <div className="mx-sm">
          <LabelledText
            label={t('Structure Autoships')}
            value={
              <p className="font-bold">
                <FormattedUnitValue
                  unit="count"
                  value={props.autoshipsInStructure.current ?? 0}
                />
              </p>
            }
          />
        </div>
        <div
          className="mx-sm cursor-pointer"
          onClick={() =>
            autoshipDevelopmentRef.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            })
          }
        >
          <LabelledText
            label={t('Structure development')}
            value={
              <div className="flex justify-center mt-xxs animate-bounce">
                <FaAnglesDown />
              </div>
            }
          />
        </div>
      </div>
      <div className="mt-lg">
        <LineChart
          yAxesLabel={t('Number of active autoships')}
          labels={labels}
          datasets={datasets}
        />
      </div>
      <table className="mt-sm m-auto w-full">
        <thead>
          <tr>
            <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
              {t('Month')}
            </th>
            <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
              {t('Firstline')}
            </th>
            <th className="border-b border-silver"></th>
            <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
              {t('Structure')}
            </th>
            <th className="border-b border-silver"></th>
          </tr>
        </thead>
        <tbody>
          {tabularData.map(d => {
            return (
              <tr key={d.date.toString()}>
                <td className="py-xxs text-center">
                  {d.date.toLocaleString(activeLanguage)}
                </td>
                <td className="py-xxs text-center">
                  <FormattedUnitValue unit="count" value={d.firstline} />
                </td>
                <td className="py-xxs text-center">
                  {!_.isUndefined(d.firstlineMonthlyTrend) && (
                    <TrendValue
                      style="number"
                      value={d.firstlineMonthlyTrend}
                    />
                  )}
                </td>
                <td className="py-xxs text-center">
                  <FormattedUnitValue unit="count" value={d.structure} />
                </td>
                <td className="py-xxs text-center">
                  {!_.isUndefined(d.structureMonthlyTrend) && (
                    <TrendValue
                      style="number"
                      value={d.structureMonthlyTrend}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="mt-lg" ref={autoshipDevelopmentRef}>
        <WidgetAutoshipDevelopment
          lostAutoshipsInStructure={props.lostAutoshipsInStructure}
          gainedAutoshipsInStructure={props.gainedAutoshipsInStructure}
        />
      </div>
    </>
  );
}
