import { FC } from 'react';

export const SixCircleIcon: FC<{ readonly size: number }> = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" fill="none">
        <path
          d="M12,1 C18.0723907,1.00661361 22.9933864,5.92760931 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M11.912,6.368 C9.752,6.368 7.976,7.68 7.976,10.224 L7.976,14.4 C7.976,16.64 9.608,18.192 11.944,18.192 C14.264,18.192 15.912,16.608 15.912,14.288 C15.912,11.936 14.44,10.64 12.152,10.64 C11.112,10.64 10.472,10.912 9.864,11.376 L9.864,10.144 C9.864,8.784 10.792,8.128 11.928,8.128 C12.776,8.128 13.656,8.416 14.328,8.912 C14.44,9.008 14.552,8.992 14.648,8.864 L15.368,7.84 C15.448,7.728 15.448,7.6 15.336,7.504 C14.376,6.768 13.112,6.368 11.912,6.368 Z M12.024,12.336 C13.384,12.336 14.008,13.04 14.008,14.288 C14.008,15.616 13.224,16.4 11.96,16.4 C10.68,16.4 9.864,15.616 9.864,14.4 L9.864,14.256 C9.864,12.896 10.824,12.336 12.024,12.336 Z"
          id="Combined-Shape"
          fill="currentColor"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};
