import { OrderChannel, OrderSource } from 'types/orders';

import { IOrderSubscriptionInfoApi } from '../types';

const onlineValues = [OrderChannel.Online, OrderChannel.Webshop];

export const mapOrderSource = (
  channel: OrderChannel,
  subscriptionInfo?: IOrderSubscriptionInfoApi
): OrderSource => {
  if (subscriptionInfo) {
    return OrderSource.Autoship;
  }

  if (channel === OrderChannel.Events) {
    return OrderSource.Events;
  }

  if (onlineValues.includes(channel)) {
    return OrderSource.Online;
  }

  return OrderSource.Offline;
};
