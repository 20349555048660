import { HelperModal } from 'components/dialogs';
import { useBoolean } from 'hooks';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';

interface ITicketTypeProps {
  readonly ticketType: 'online' | 'real';
}

export function TicketType(props: ITicketTypeProps) {
  const { t } = useTranslation();
  const [isOpen, { setFalse: close, setTrue: open }] = useBoolean(false);

  const btnTitle =
    props.ticketType === 'online' ? t('Online ticket') : t('Live ticket');
  const iconName =
    props.ticketType === 'online' ? IconName.Video : IconName.QRCode;
  const description =
    props.ticketType === 'online'
      ? t('This is a ticket for an online event.')
      : t('This is a ticket for a live event.');

  return (
    <>
      <button
        className="cursor-help flex flex-row items-center text-left gap-xs"
        onClick={open}
      >
        <Icon
          name={iconName}
          size={16}
          className="bg-primary text-white p-xs rounded-full"
        />
        <span className="hidden lg:inline-block">{btnTitle}</span>
      </button>
      {isOpen && (
        <HelperModal onDismiss={close}>
          <div className="flex flex-row items-center justify-center flex-wrap gap-xs">
            <Icon name={iconName} size={24} />
            {description}
          </div>
        </HelperModal>
      )}
    </>
  );
}
