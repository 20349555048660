import { PillBadge } from 'components/badges';
import { CountdownRenderer } from 'components/CountdownRenderer';
import { useTranslation } from 'libs/translations';
import Countdown from 'react-countdown';
// eslint-disable-next-line no-restricted-imports
import {
  TbProgress,
  TbProgressAlert,
  TbProgressCheck,
  TbProgressX
} from 'react-icons/tb';
import { BasketStatus, IBasket } from 'types';

export function BasketStatusBadge(props: IBasket) {
  const { t } = useTranslation();

  if (props.basket.status === BasketStatus.Canceled) {
    return (
      <PillBadge
        title={t('Canceled')}
        className="text-silver bg-lightGray font-bold flex-none w-fit"
        icon={<TbProgressX size={20} />}
      />
    );
  }

  if (props.basket.status === BasketStatus.PaymentAuthorized) {
    return (
      <PillBadge
        title={t('Sold')}
        className="text-white bg-success font-bold flex-none w-fit"
        icon={<TbProgressCheck size={20} />}
      />
    );
  }

  if (props.basket.status === BasketStatus.PaymentPending) {
    return (
      <PillBadge
        title={t('Awaiting Payment')}
        className="text-white bg-warn font-bold flex-none w-fit"
        icon={<TbProgressAlert size={20} />}
      />
    );
  }

  return (
    <PillBadge
      title={
        <Countdown
          date={props.basket.autoDeleteOn.getTime()}
          renderer={p => <CountdownRenderer {...p} />}
        />
      }
      className="text-white bg-active font-bold flex-none w-fit"
      icon={<TbProgress size={20} />}
    />
  );
}
