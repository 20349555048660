import { localizedCountryName } from '@pmi.web/countries';
import { PaymentInfo } from 'components/payment-icons';
import { InvoiceItem } from 'features/invoices';
import { PII, usePII } from 'features/pii';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { IoCashOutline, IoLocationOutline } from 'react-icons/io5';
import { IOrderBilling, IOrderPayment } from 'types/orders';

interface OrderPaymentDetailsProps {
  readonly payment: IOrderPayment;
  readonly billing?: IOrderBilling;
}

export function OrderPaymentDetails(props: OrderPaymentDetailsProps) {
  const { payment, billing } = props;

  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();

  return (
    <>
      {billing && (
        <InvoiceItem
          icon={<IoLocationOutline size={16} />}
          title={t('Billing address')}
          value={
            <>
              {!_.isUndefined(billing.address.addressLine1) && (
                <p>
                  <PII type="address" value={billing.address.addressLine1} />
                </p>
              )}
              {!_.isUndefined(billing.address.addressLine2) && (
                <p>
                  <PII type="address" value={billing.address.addressLine2} />
                </p>
              )}
              {!_.isUndefined(billing.address.streetPob) && (
                <p>
                  <PII type="address" value={billing.address.streetPob} />
                </p>
              )}
              {!_.isUndefined(billing.address.city) && (
                <p>
                  <span>
                    <PII type="address" value={billing.address.city} />
                  </span>
                  {!_.isUndefined(billing.address.zipCode) && (
                    <span>
                      ,&nbsp;
                      <PII type="address" value={billing.address.zipCode} />
                    </span>
                  )}
                </p>
              )}
              {!_.isUndefined(billing.address.county) && (
                <p>
                  <span>
                    <PII type="address" value={billing.address.county} />
                  </span>
                  {!_.isUndefined(billing.address.district) && (
                    <span>
                      ,&nbsp;
                      <PII type="address" value={billing.address.district} />
                    </span>
                  )}
                </p>
              )}
              {!_.isUndefined(billing.address.countryCode) && (
                <p>
                  {localizedCountryName(
                    pii(billing.address.countryCode, 'country'),
                    activeLanguage
                  )}
                </p>
              )}
            </>
          }
        />
      )}
      {payment.instrumentReference && (
        <InvoiceItem
          icon={<IoCashOutline size={16} />}
          title={t('Payment Instrument')}
          value={<PaymentInfo {...payment.instrumentReference} />}
        />
      )}
    </>
  );
}
