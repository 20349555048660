import type { FC } from 'react';

export const EmailOutlineIcon: FC<{ readonly size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className="flex-shrink-0 flex-grow-0"
    width={size}
    height={size}
  >
    <g fill="currentColor" stroke="currentColor" strokeWidth="1">
      <circle
        cx="8"
        cy="8"
        fill="none"
        r="3.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11,14.876A7.5,7.5,0,1,1,15.5,8V9.5a2,2,0,0,1-4,0v-5"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
