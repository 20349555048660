import { usePageTitle } from 'components/layouts/page-title/usePageTitle';
import { AccountBackButton } from 'features/account';
import { WalletBalance } from 'features/wallet/components/WalletBalance';
import { WalletTransactionsList } from 'features/wallet/components/WalletTransactionsList';
import { useWalletAccount } from 'features/wallet/hooks/useWalletAccount';
import { Navigate } from 'react-router-dom';
import AuthenticationService from 'services/AuthenticationService';

export function AccountWalletRoute() {
  const { isLoading, isError } = useWalletAccount(
    AuthenticationService.user.userId
  );
  const { title } = usePageTitle();

  if (!isLoading && isError) {
    return <Navigate to="/" />;
  }

  return (
    <section className="max-w-[860px] m-auto">
      <h1>{title}</h1>
      <AccountBackButton />
      <br />
      <section className="space-y-md">
        <WalletBalance />
        <WalletTransactionsList />
      </section>
    </section>
  );
}
