import { useQuery } from '@tanstack/react-query';
import { getPromocode } from 'services';

import { PROMOCODE_QUERY_KEY, TTL } from './PromocodeQueryConfig';

export function useFetchPromocode() {
  const query = useQuery({
    queryKey: [PROMOCODE_QUERY_KEY],
    queryFn: () => getPromocode(),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
