import { useScreenEvents } from 'contexts';
import { FC } from 'react';

export const WidgetSkeleton: FC = () => {
  const { sm } = useScreenEvents();

  return (
    <div
      className={`${
        sm ? 'p-sm' : 'p-xs'
      } bg-white h-full w-full shadow-md rounded-md`}
    >
      <div className="h-[12px] w-full bg-lightGray"></div>

      <div className="mt-xs mb-xs">
        <div className="h-[64px] w-full bg-lightGray mt-sm"></div>
      </div>
    </div>
  );
};
