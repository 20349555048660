import { FixedDate } from '@pmi.libs/utils-common';
import { DocumentType, DocumentCategory, IDocument } from 'types';

import { IDocumentApi } from './types/IDocumentApi';

export function parseAPIDocument(doc: IDocumentApi): IDocument {
  return {
    filename: doc.fileName,
    docType: parseDocType(doc.docType),
    startRange: FixedDate.createOrThrow(doc.startRange),
    endRange: FixedDate.createOrThrow(doc.endRange),
    category: determineDocumentCategory(doc)
  };
}

function determineDocumentCategory(
  doc: IDocumentApi
): DocumentCategory | undefined {
  switch (doc.docType) {
    case DocumentType.Bonus:
    case DocumentType.BonusJp:
      return DocumentCategory.Bonus;
    case DocumentType.BonusPerformance:
    case DocumentType.BonusPool:
      return DocumentCategory.PerformanceBonus;
    case DocumentType.ProvisionItaly:
      return DocumentCategory.TaxInvoices;
    case DocumentType.InvoiceMpm:
      return DocumentCategory.Invoices;
    default:
      return undefined;
  }
}

function parseDocType(docType: string): DocumentType {
  if (Object.values(DocumentType).includes(docType as DocumentType)) {
    return docType as DocumentType;
  }

  // Ensure that all new types introduced will always be displayed correctly
  // under "All documents" section until we decide to categorize something.
  return DocumentType.Unknown;
}
