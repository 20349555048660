import { Badge } from 'components/badges';
import { useTranslation } from 'libs/translations';
import { OrderStatus } from 'types/orders';

interface IOrderStatusBadgeProps {
  readonly status: OrderStatus;
}

export function OrderStatusBadge({ status }: IOrderStatusBadgeProps) {
  const { t } = useTranslation();

  const badgeInfo = {
    [OrderStatus.Created]: {
      title: t('Created').toUpperCase(),
      bgColor: '#0a84ff'
    },
    [OrderStatus.Canceled]: {
      title: t('Canceled').toUpperCase(),
      bgColor: '#bf6203'
    },
    [OrderStatus.Delivered]: {
      title: t('Delivered').toUpperCase(),
      bgColor: '#a7a9ac'
    },
    [OrderStatus.Shipped]: {
      title: t('Shipped').toUpperCase(),
      bgColor: '#a7a9ac'
    },
    [OrderStatus.Paid]: {
      title: t('Paid').toUpperCase(),
      bgColor: '#0f9a65'
    }
  }[status];

  return (
    <Badge
      hideDetails
      title={badgeInfo.title}
      textColorClass="#ffffff"
      backgroundColorClass={badgeInfo.bgColor}
    />
  );
}
