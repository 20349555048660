import { useLocalStorage } from 'hooks';
import { useCallback } from 'react';

const PRIVACY_GUARD_PI_STORAGE_KEY = 'pmi.web.office-hide-pi';
const PRIVACY_GUARD_BI_STORAGE_KEY = 'pmi.web.office-hide-bi';

export function useDemoMode() {
  const [enableDemoPersonalData, setDemoPersonalData] = useLocalStorage(
    PRIVACY_GUARD_PI_STORAGE_KEY,
    '0'
  );

  const [enableDemoBusinessData, setDemoBusinessData] = useLocalStorage(
    PRIVACY_GUARD_BI_STORAGE_KEY,
    '0'
  );

  const setDemoPersonalDataWrapper = useCallback(
    (enable: boolean) => {
      return setDemoPersonalData(enable ? '1' : '0');
    },
    [setDemoPersonalData]
  );

  const setDemoBusinessDataWrapper = useCallback(
    (enable: boolean) => {
      return setDemoBusinessData(enable ? '1' : '0');
    },
    [setDemoBusinessData]
  );

  return {
    enableDemoPersonalData: enableDemoPersonalData === '1',
    enableDemoBusinessData: enableDemoBusinessData === '1',

    setDemoPersonalData: setDemoPersonalDataWrapper,
    setDemoBusinessData: setDemoBusinessDataWrapper
  };
}
