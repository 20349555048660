import { Invoice, InvoiceSection } from 'features/invoices';
import { IOrder, OrderStatus } from 'types';

import { OrderDeliveryDetails } from './OrderDeliveryDetails';
import { OrderDetails } from './OrderDetails';
import { OrderInformation } from './OrderInformation';
import { OrderPaymentDetails } from './OrderPaymentDetails';

export function Order(props: IOrder) {
  return (
    <div className="bg-white shadow-md rounded-md p-sm">
      <Invoice>
        <OrderInformation {...props} />

        <InvoiceSection>
          <OrderDetails {...props} />

          {props.status !== OrderStatus.Canceled && (
            <>
              <OrderDeliveryDetails {...props} />
              <OrderPaymentDetails
                payment={props.payment}
                billing={props.billing}
              />
            </>
          )}
        </InvoiceSection>
      </Invoice>
    </div>
  );
}
