import { useTranslation } from 'libs/translations';
import { ICommitment } from 'types';

import { CommimentCard } from './CommimentCard';

export function ExpiredCommitmentCard(commitment: ICommitment) {
  const { t } = useTranslation();

  return (
    <CommimentCard status={commitment.status}>
      <p>
        {t(
          'This commitment has expired. We will process it shortly. This means that we will charge you for any remaining products and send them to the address you provided.'
        )}
      </p>
    </CommimentCard>
  );
}
