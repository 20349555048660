import { ApiError } from 'errors';

import { BadRequestError } from './BadRequestError';
import { InternalError } from './InternalError';
import { NotFoundError } from './NotFoundError';
import { SomethingWentWrong } from '../general-errors/SomethingWentWrong';

interface IHttpErrorProps {
  readonly err: ApiError;
}

export function HttpError(props: IHttpErrorProps) {
  // Client error responses
  if (props.err.status >= 400 && props.err.status <= 499) {
    switch (props.err.status) {
      case 404:
        return <NotFoundError err={props.err} />;
      case 400:
      default:
        return <BadRequestError err={props.err} />;
    }
  }

  // Server error responses
  if (props.err.status >= 500 && props.err.status <= 599) {
    return <InternalError err={props.err} />;
  }

  return <SomethingWentWrong err={props.err} />;
}
