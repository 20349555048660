import { useCallback } from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon
} from 'react-share';

interface ISocialShareProps {
  readonly facebook?: boolean;
  readonly whatsapp?: boolean;
  readonly telegram?: boolean;
  readonly email?: boolean;
  readonly x?: boolean;

  readonly onClick?: (platform: string) => void;
  readonly url: string;
}

export function SocialShare(props: ISocialShareProps) {
  const onClick = useCallback(
    (shareOn: 'facebook' | 'whatsapp' | 'telegram' | 'email' | 'x') => {
      if (props.onClick) {
        props.onClick(shareOn);
      }
    },
    [props]
  );

  return (
    <div className="space-x-sm w-fit">
      {props.facebook && (
        <ShareOnFacebook url={props.url} onClick={() => onClick('facebook')} />
      )}
      {props.whatsapp && (
        <ShareOnWhatsApp url={props.url} onClick={() => onClick('whatsapp')} />
      )}
      {props.telegram && (
        <ShareOnTelegram url={props.url} onClick={() => onClick('telegram')} />
      )}
      {props.email && (
        <ShareOnEmail url={props.url} onClick={() => onClick('email')} />
      )}
      {props.x && <ShareOnX url={props.url} onClick={() => onClick('x')} />}
    </div>
  );
}

interface IShareOnProps {
  readonly onClick?: () => void;
  readonly url: string;
}

function ShareOnFacebook(props: IShareOnProps) {
  return (
    <FacebookShareButton url={props.url} onClick={props.onClick}>
      <FacebookIcon round size={48} />
    </FacebookShareButton>
  );
}

function ShareOnWhatsApp(props: IShareOnProps) {
  return (
    <WhatsappShareButton url={props.url} onClick={props.onClick}>
      <WhatsappIcon round size={48} />
    </WhatsappShareButton>
  );
}

function ShareOnTelegram(props: IShareOnProps) {
  return (
    <TelegramShareButton url={props.url} onClick={props.onClick}>
      <TelegramIcon round size={48} />
    </TelegramShareButton>
  );
}

function ShareOnEmail(props: IShareOnProps) {
  return (
    <EmailShareButton url={props.url} onClick={props.onClick}>
      <EmailIcon round size={48} />
    </EmailShareButton>
  );
}

function ShareOnX(props: IShareOnProps) {
  return (
    <TwitterShareButton url={props.url} onClick={props.onClick}>
      <XIcon round size={48} />
    </TwitterShareButton>
  );
}
