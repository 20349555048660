import { RouteIcon, RouteIconName } from 'libs/icons';
import { NavLink } from 'react-router-dom';

import { usePageTitle } from '../page-title/usePageTitle';

export function BottomNavigationBar() {
  const { getRouteTitle } = usePageTitle();

  return (
    <>
      <div className="h-[100px] w-full"></div>
      <footer className="fixed left-0 right-0 bottom-0 bg-white border-t border-t-lightGray grid grid-cols-5 gap-2 text-center pmi-safe-area-inset-b px-sm">
        <CustomNavLink
          to="/dashboard"
          title={getRouteTitle('/dashboard') ?? ''}
          icon={RouteIconName.Dashboard}
        />
        <CustomNavLink
          to="/downline"
          title={getRouteTitle('/downline') ?? ''}
          icon={RouteIconName.MyTeam}
        />
        <CustomNavLink
          to="/analytics"
          title={getRouteTitle('/analytics') ?? ''}
          icon={RouteIconName.Analytics}
        />
        <CustomNavLink
          to="/events"
          title={getRouteTitle('/events') ?? ''}
          icon={RouteIconName.Events}
        />
        <CustomNavLink
          to="/account"
          title={getRouteTitle('/account') ?? ''}
          icon={RouteIconName.Account}
        />
      </footer>
    </>
  );
}

function CustomNavLink(props: {
  readonly to: string;
  readonly title: string;
  readonly icon: RouteIconName;
}) {
  return (
    <NavLink to={props.to} title={props.title}>
      {({ isActive }) => {
        return (
          <ButtonContent
            icon={props.icon}
            isActive={isActive}
            title={props.title}
          />
        );
      }}
    </NavLink>
  );
}

function ButtonContent(props: {
  readonly title: string;
  readonly icon: RouteIconName;
  readonly isActive: boolean;
}) {
  return (
    <div className={`relative ${props.isActive ? 'text-active' : ''}`}>
      {props.isActive && (
        <div className="absolute inset-0 h-[2px] bg-active rounded-full"></div>
      )}
      <div className="p-xs" title={props.title}>
        <RouteIcon name={props.icon} size={16} className="inline-block" />
        <p className="pmi-caption text-ellipsis overflow-hidden whitespace-nowrap">
          {props.title}
        </p>
      </div>
    </div>
  );
}
