import { useBoolean } from 'hooks';

import { NotificationsPanel } from './components/notifcations-panel/NotificationsPanel';
import { NotificationsBell } from './components/notifications-bell/NotificationsBell';
import { FakeNotificationsGenerator } from './FakeNotificationsGenerator';
import { useNotifications } from './hooks/useNotifications';

export function NotificationsCenter() {
  const { hasUnreadNotifications, notifications } = useNotifications();

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  return (
    <div className="flex">
      <FakeNotificationsGenerator />
      <NotificationsBell
        onClick={openPanel}
        hasUnreadNotifications={hasUnreadNotifications}
      />
      <NotificationsPanel
        isOpen={isOpen}
        notifications={notifications}
        onDismiss={dismissPanel}
      />
    </div>
  );
}
