import { IChangelog } from 'libs/cms';
import { useTranslation } from 'libs/translations';
import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import './WhatsNewPost.scss';

export function WhatsNewPost(props: IChangelog) {
  const { hash } = useLocation();
  const { activeLanguage } = useTranslation();

  useEffect(() => {
    if (hash === `#${props._uid}`) {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (!element) {
          return;
        }

        element.scrollIntoView();
      }, 0);
    }
  }, [hash, props._uid]);

  return (
    <article className="component__whats-new-post" id={props._uid}>
      <header>
        <img src={props.previewUrl} alt="" />
        <h2>{props.title}</h2>
        <span>{props.date.toLocaleDateString(activeLanguage)}</span>
        {props.tags.length > 0 && (
          <ul>
            {props.tags.map(tag => (
              <li key={tag}>{tag}</li>
            ))}
          </ul>
        )}
      </header>
      <div>
        <img src={props.previewUrl} alt="" />
        {props.description && <p>{props.description}</p>}
        {props.features.length > 0 && (
          <ul>
            {props.features.map(feature => (
              <li key={feature._uid}>
                {feature.target ? (
                  <NavLink to={feature.target}>{feature.description}</NavLink>
                ) : (
                  feature.description
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </article>
  );
}
