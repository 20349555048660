export enum PaymentInstrumentType {
  Amex = 1,
  ApplePay,
  Bancontact,
  Diners,
  DirectDebit,
  Discover,
  Elo,
  GooglePay,
  iDeal,
  Jcb,
  Maestro,
  MasterCard,
  PayPal,
  Transbank,
  UnionPay,
  UPC,
  Visa,
  PMSubsidiary,
  ECommerceConnect,
  SEPA,
  NuveiTwint,
  TwoCTwoP,
  GenericPayment
}
