import type { FC } from 'react';

export const MenuIcon: FC<{ readonly size: number }> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
    className="flex-shrink-0 flex-grow-0"
  >
    <g
      strokeLinecap="round"
      strokeWidth="2"
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
    >
      <line x1="1" y1="8" x2="15" y2="8"></line>
      <line x1="1" y1="3" x2="15" y2="3"></line>
      <line x1="1" y1="13" x2="15" y2="13"></line>
    </g>
  </svg>
);
