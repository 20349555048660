import { Badge } from 'components/badges';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';

interface ICoachBadgeProps {
  readonly coachName: string;
}

export function CoachBadge(props: ICoachBadgeProps) {
  const { t } = useTranslation();

  return (
    <Badge
      icon={IconName.Coach}
      title="C"
      tooltipDetailsContent={
        <p className="flex flex-row gap-xs items-baseline">
          <span>
            <Icon name={IconName.Coach} size={16} />
          </span>
          {t('Coached by {{coachName}}', { coachName: props.coachName })}
        </p>
      }
    />
  );
}
