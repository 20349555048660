import { useQuery } from '@tanstack/react-query';
import { getOrder, getOrders } from 'services';

const ORDERS_QUERY_KEY = 'pmi.web.office__orders';
const TTL = 900000; // 15 minutes in milliseconds

export function useOrder(id: string) {
  const query = useQuery({
    queryKey: [ORDERS_QUERY_KEY, id],
    queryFn: () => getOrder(id),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}

export function useOrders(page: number, perPage: number) {
  const query = useQuery({
    queryKey: [ORDERS_QUERY_KEY, page, perPage],
    queryFn: () => getOrders(page, perPage),
    staleTime: TTL,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}
