import { IS_PROD_BUILD } from 'contants';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { type ISbStoriesParams } from 'storyblok-js-client';

import { IEventStory } from './IEventStory';
import {
  STORYBLOK_CLIENT,
  computeStoryblokOptimisedImgSrc
} from '../Storyblok';

export async function fetchEventStory(
  eventId: string,
  slug: string,
  eventLanguage: string
): Promise<IEventStory> {
  const storyblokParams: ISbStoriesParams = {
    version: IS_PROD_BUILD ? 'published' : 'draft',
    resolve_relations: ['featured-posts.posts', 'selected-posts.posts'],
    language: eventLanguage,
    token: `${process.env.REACT_APP_STORYBLOK_EVENTS_ACCESS_TOKEN}`
  };

  return parseEventStory(
    eventId,
    (await STORYBLOK_CLIENT.get(`cdn/stories/${slug}`, storyblokParams))?.data
      ?.story as IStoryblokEventStory
  );
}

interface IStoryblokEventStory {
  id: number;
  name: string;
  content: {
    address?: string;
    dates?: string;
    description?: string;
    image?: {
      filename?: string;
    };
    title?: string;
    venue?: string;
    videoFile?: {
      filename?: string;
    };
    videoUrl?: string;
  };
}

function parseEventStory(
  eventId: string,
  story: IStoryblokEventStory
): IEventStory {
  return {
    eventId: eventId,
    title: stringOrUndefined(story.content.title),
    description: stringOrUndefined(story.content.description),
    dates: stringOrUndefined(story.content.dates),
    address: stringOrUndefined(story.content.address),
    venue: stringOrUndefined(story.content.venue),
    imgSrc: computeStoryblokOptimisedImgSrc(story.content.image?.filename),
    videoSrc:
      stringOrUndefined(story.content.videoUrl) ??
      stringOrUndefined(story.content.videoFile?.filename) ??
      undefined
  };
}

function stringOrUndefined(
  value: string | null | undefined
): string | undefined {
  if (_.isUndefined(value) || _.isNull(value) || value === '') {
    return undefined;
  }

  return value;
}
