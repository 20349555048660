import { CreateNewBasketButton } from 'features/shared-baskets/components/create-basket-button/CreateNewBasketButton';
import { RouteIcon, RouteIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';

export function BasketListEmpty() {
  const { t } = useTranslation();

  return (
    <div className="p-md flex flex-col items-center justify-center gap-sm rounded-md bg-white shadow-md">
      <RouteIcon name={RouteIconName.Sales} size={62} />

      <p className="text-center max-w-[500px] space-y-xs">
        <span className="block">
          {t(
            'Here you can create and share custom baskets for Team Partners, customers, or new customers, and track them all in one place.'
          )}
        </span>
        <span className="block">
          {t('Click "Create Basket" to get started.')}
        </span>
      </p>

      <CreateNewBasketButton />
    </div>
  );
}
