interface IInvoiceSectionDividerProps {
  readonly thick?: boolean;
}

export function InvoiceSectionDivider(props: IInvoiceSectionDividerProps) {
  if (props.thick) {
    return <div className="col-span-2 border-t-2 border-silver my-xs"></div>;
  }

  return <div className="col-span-2 border-t border-lightGray my-sm"></div>;
}
