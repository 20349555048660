import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { Trans } from 'react-i18next';

import './WhatsNewTitle.scss';

export function WhatsNewTitle() {
  const { t } = useTranslation();

  return (
    <h1 className="component__whats-new-title">
      <Trans i18nKey={'WhatsNewTitle'} t={t}>
        What's <span>New</span>
      </Trans>
    </h1>
  );
}
