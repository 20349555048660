import { useContext } from 'react';

import { NewBasketContext } from './NewBasketContext';

export function useNewBasketContext() {
  const context = useContext(NewBasketContext);

  if (context === undefined) {
    throw new Error(
      'useNewBasketContext must be used within NewBasketContextProvider'
    );
  }

  return context;
}
