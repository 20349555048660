import { ReactNode } from 'react';

interface IInvoiceCostItem {
  readonly label: string;
  readonly value: ReactNode;
}

export function InvoiceCostItem(props: IInvoiceCostItem) {
  return (
    <>
      <p className="pmi-caption text-silver truncate">{props.label}</p>
      <p className="text-right truncate pmi-caption">{props.value}</p>
    </>
  );
}
