import { Icon, IconName } from 'libs/icons';

import { Toast, ToastContent } from './Toast';

interface IErrorToastProps {
  readonly description: string;
}

export function ErrorToast(props: IErrorToastProps) {
  return (
    <Toast>
      <Icon name={IconName.Warning} className="text-error" size={16} />
      <ToastContent>
        <p className={'whitespace-pre-wrap text-error'}>{props.description}</p>
      </ToastContent>
    </Toast>
  );
}
