import { HelperModal } from 'components/dialogs';
import { useBoolean } from 'hooks';
import { Icons } from 'libs/icons';
import { useTranslation } from 'libs/translations';

export function NoTicket() {
  const { t } = useTranslation();

  const [isOpen, { setFalse: close, setTrue: open }] = useBoolean(false);

  return (
    <>
      <button className="cursor-help" title={undefined} onClick={open}>
        <Icons.Common.Cancel size={20} className="text-silver" />
      </button>
      {isOpen && (
        <HelperModal onDismiss={close}>
          <div className="flex flex-row items-center justify-center flex-wrap gap-xs">
            <Icons.Common.Cancel
              size={20}
              className="text-silver inline-block"
            />
            {t("This user doesn't have a ticket for this event.")}
          </div>
        </HelperModal>
      )}
    </>
  );
}
