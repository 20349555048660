import { useTranslation } from 'libs/translations';
import { useFormContext } from 'react-hook-form';

export function SearchInput() {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <fieldset className="analytics-filters-fieldset">
      <input
        placeholder={t('Search by name or Team Partner ID')}
        type="text"
        {...register('search')}
      />
    </fieldset>
  );
}
