import { PrimaryButton } from '@pmi.web/react-common';
import { SwitchButton } from 'components/buttons';
import { LoadingSpinner } from 'components/spinners';
import { FormattedUnitValue } from 'components/text';
import { FIVE_PERCENT_DISCOUNT_SETTINGS } from 'contants';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useCreateBasket } from 'features/shared-baskets/hooks/useCreateBasket';
import { replaceStringWithComponent } from 'libs/components';
import { triggerErrorToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { Fragment, useEffect, useMemo } from 'react';

export function BasketSummary() {
  const { t } = useTranslation();
  const {
    articles,
    isDiscountActive,
    basketOwnerId,
    shopId,
    isAffiliateDiscountEnabled,
    toogleDiscount,
    dispatchStepEvent
  } = useNewBasketContext();

  const { createBasket, isPending, data, isError, error } = useCreateBasket();
  const basketItems = useMemo(() => {
    const basketItems = Array.from(articles).map(a => ({
      articleNumber: a[0] as string,
      quantity: a[1] as number
    }));

    return basketItems;
  }, [articles]);

  const { discounts } = useMemo(() => {
    return {
      discounts: isDiscountActive ? [FIVE_PERCENT_DISCOUNT_SETTINGS] : undefined
    };
  }, [isDiscountActive]);

  const handleConfirm = () => {
    if (basketOwnerId && shopId) {
      createBasket({
        shopId: shopId,
        customerId: basketOwnerId,
        items: basketItems,
        affiliateDiscount: discounts
      });
    }
  };

  useEffect(() => {
    if (data && data.checkoutUrl) {
      dispatchStepEvent({
        type: 'set-basket-id',
        value: data.checkoutUrl
      });
    }
  }, [data, dispatchStepEvent]);

  useEffect(() => {
    // TODO: improve
    if (isError) {
      triggerErrorToast(error?.message ?? '');
    }
  }, [error?.message, isError]);

  if (basketItems.length === 0) {
    return null;
  }

  return (
    <>
      {basketOwnerId && basketItems.length > 0 && (
        <section className="divide-y divide-solid divide-primary">
          {isAffiliateDiscountEnabled && (
            <div className="flex justify-between items-center py-sm">
              <p>
                {replaceStringWithComponent(
                  t('Offer {{discountFormattedValue}} discount'),
                  '{{discountFormattedValue}}',
                  () => (
                    <FormattedUnitValue
                      unit={FIVE_PERCENT_DISCOUNT_SETTINGS.type}
                      value={FIVE_PERCENT_DISCOUNT_SETTINGS.value}
                    />
                  )
                ).map((elem, index) => (
                  <Fragment key={index}>{elem}</Fragment>
                ))}
              </p>
              <SwitchButton isOn={isDiscountActive} onClick={toogleDiscount} />
            </div>
          )}
          <RenderBasketTotal />
        </section>
      )}

      <div className="flex justify-end">
        <PrimaryButton
          onClick={handleConfirm}
          disabled={isPending || !basketOwnerId || articles.size === 0}
        >
          {t('Create basket')}
          {isPending && <LoadingSpinner tinySize className="text-white" />}
        </PrimaryButton>
      </div>

      {isPending && (
        <>
          <div className="fixed inset-0 z-30 flex items-center justify-center bg-secondary opacity-80"></div>
          <div className="fixed inset-0 z-30 flex items-center justify-center">
            <LoadingSpinner />
          </div>
        </>
      )}
    </>
  );
}

function RenderBasketTotal() {
  const { shouldShowPoints, basketPreview } = useNewBasketContext();
  const { t } = useTranslation();

  const { currency, price, points, basePrice } = useMemo(() => {
    const price = basketPreview.data?.basket.totals.totalGross;
    const basePrice =
      basketPreview.data?.basket.totals.itemsTotalGrossExcludingDiscount;

    return {
      currency: basketPreview.data?.basket.totals.currencyCode,
      points: basketPreview.data?.basket.totals.points,
      price,
      basePrice
    };
  }, [basketPreview]);

  return (
    <div className="flex flex-row gap-sm justify-between py-sm">
      <p>{t('Total')}</p>
      <div className="text-right">
        {basketPreview.isLoading ? (
          <div className="flex justify-center">
            <LoadingSpinner smallSize />
          </div>
        ) : (
          <>
            {!_.isUndefined(points) && shouldShowPoints && (
              <p className="text-xl">
                <FormattedUnitValue unit={'points'} value={points} />
              </p>
            )}
            {!_.isUndefined(currency) &&
              !_.isUndefined(price) &&
              !_.isUndefined(basePrice) && (
                <div>
                  {basePrice !== price && (
                    <span className="text-silver text-sm line-through mr-xxs">
                      <FormattedUnitValue unit={currency} value={basePrice} />
                    </span>
                  )}
                  <p className="text-xl font-bold">
                    <FormattedUnitValue unit={currency} value={price} />
                  </p>
                </div>
              )}
          </>
        )}
        <p className="text-silver pmi-caption">
          {`+ ${t('Shipping if applicable')}`}
        </p>
      </div>
    </div>
  );
}
