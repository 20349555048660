import { PII, usePII } from 'features/pii';
import { triggerSuccessToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { FaCheck } from 'react-icons/fa6';
// eslint-disable-next-line no-restricted-imports
import { GoLinkExternal } from 'react-icons/go';
// eslint-disable-next-line no-restricted-imports
import { IoCopyOutline } from 'react-icons/io5';
import { IOrderDeliveryPackage } from 'types/orders/IOrderDelivery';

import './OrderTrackingNumber.scss';

export function OrderTrackingNumber({ id, url }: IOrderDeliveryPackage) {
  const { pii } = usePII();
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(pii(id, 'ids'));
    triggerSuccessToast(
      t('Tracking number was copied to your clipboard successfully!')
    );
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <div className="flex flex-row items-center w-fit max-w-full gap-xs">
      <span className="whitespace-nowrap overflow-hidden text-ellipsis">
        <PII type="ids" value={id} />
      </span>
      <div className="flex gap-0.5">
        <button
          type="button"
          className="transition-all p-xs text-active hover:text-active hover:bg-secondary hover:rounded"
          onClick={handleCopy}
        >
          {copied ? (
            <FaCheck size={20} aria-hidden="true" />
          ) : (
            <IoCopyOutline size={20} aria-hidden="true" />
          )}
        </button>

        {url && (
          <a
            href={pii(url, 'link')}
            rel="noreferrer"
            target="_blank"
            type="button"
            className="transition-all text-active p-xs hover:text-active hover:bg-secondary hover:rounded"
          >
            <GoLinkExternal size={20} aria-hidden="true" />
          </a>
        )}
      </div>
    </div>
  );
}
