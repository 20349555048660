import { RANDOM_FEMALE_NAMES } from './data/FemaleNames';
import { RANDOM_MALE_NAMES } from './data/MaleNames';
import { RANDOM_SURNAMES } from './data/Surnames';
import { mapStringToIndex } from './random';

export function randomName(seed: string): string {
  const useFemaleName = mapStringToIndex(seed, 2) === 0;

  const firstNameOptions = useFemaleName
    ? RANDOM_FEMALE_NAMES
    : RANDOM_MALE_NAMES;
  const indexFirstName = mapStringToIndex(seed, firstNameOptions.length);
  const indexSurname = mapStringToIndex(seed, firstNameOptions.length);

  return `${firstNameOptions[indexFirstName]} ${RANDOM_SURNAMES[indexSurname]}`;

  // const useFemaleName = Math.random() < 0.5;

  // const firstname = pickRandomItem(
  //   useFemaleName ? RANDOM_FEMALE_NAMES : RANDOM_MALE_NAMES
  // );
  // const surname = pickRandomItem(RANDOM_SURNAMES);

  // return `${firstname} ${surname}`;
}
