import { InvoiceItem } from 'features/invoices';
import { PII } from 'features/pii';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { IoBarcodeSharp, IoCalendarOutline } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { IOrder } from 'types';

import { useOrderCommitments } from './hooks/useOrderCommitments';

export function OrderDetails(props: IOrder) {
  const { t, activeLanguage } = useTranslation();
  const commitments = useOrderCommitments(props);

  return (
    <>
      {!_.isUndefined(props.createdOn) && (
        <InvoiceItem
          icon={<IoCalendarOutline size={16} />}
          title={t('Created on')}
          value={
            <PII
              value={props.createdOn.toLocaleDateTimeString(
                activeLanguage,
                'medium'
              )}
              type={'fixed-date'}
            />
          }
        />
      )}

      <InvoiceItem
        icon={<IoBarcodeSharp size={16} />}
        title={t('Order Reference')}
        value={<PII value={props.reference} type="ids" />}
      />

      {commitments.length > 0 && (
        <InvoiceItem
          icon={<IoBarcodeSharp size={16} />}
          title={t('Virtual Warehouse Reference')}
          value={
            <ul>
              {commitments.map(commitmentId => (
                <li key={commitmentId}>
                  <NavLink
                    to={`/virtual-warehouse/commitments/${commitmentId}`}
                  >
                    {commitmentId}
                  </NavLink>
                </li>
              ))}
            </ul>
          }
        />
      )}
    </>
  );
}
