import { NavLink } from 'react-router-dom';

interface ICommitmentOrdersProps {
  readonly orderIds: ReadonlyArray<string>;
}
export function CommitmentOrders(props: ICommitmentOrdersProps) {
  return (
    <ul>
      {props.orderIds.map(orderId => (
        <li key={orderId}>
          <NavLink to={`/account/orders/${orderId}`}>{orderId}</NavLink>
        </li>
      ))}
    </ul>
  );
}
