import { HelperModal } from 'components/dialogs';
import { useBoolean } from 'hooks';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';

export function TicketScanned() {
  const { t } = useTranslation();

  const [isOpen, { setFalse: close, setTrue: open }] = useBoolean(false);

  return (
    <>
      <button
        className="cursor-help flex flex-row gap-xs items-center text-left"
        title={t('Scanned')}
        onClick={open}
      >
        <Icon name={IconName.Scan} size={16} />
        <span className="hidden lg:inline-block">{t('Scanned')}</span>
      </button>
      {isOpen && (
        <HelperModal onDismiss={close}>
          <div className="flex flex-row items-center justify-center flex-wrap gap-xs">
            <Icon name={IconName.Scan} size={24} />
            {t('This ticket was already scanned at the event venue.')}
          </div>
        </HelperModal>
      )}
    </>
  );
}
