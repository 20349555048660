import type { ReactNode } from 'react';

interface ILabelledTextProps {
  readonly label: string | ReactNode;
  readonly value: string | ReactNode;
  readonly inline?: boolean;
}

export function LabelledText(props: ILabelledTextProps) {
  return (
    <div className={props.inline ? 'flex flex-row items-center gap-xs' : ''}>
      <h4 className="uppercase text-silver">{props.label}</h4>
      {typeof props.value === 'string' ? (
        <p>{props.value}</p>
      ) : (
        <>{props.value}</>
      )}
    </div>
  );
}
