import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useTranslation } from 'libs/translations';
// eslint-disable-next-line no-restricted-imports
import { IoAddOutline } from 'react-icons/io5';

import { ProductsInBasketSummary } from './ProductsInBasketSummary';

export function ProductSelectionStepSummary({
  editInDetails
}: {
  readonly editInDetails: boolean;
}) {
  const { t } = useTranslation();
  const { dispatchStepEvent } = useNewBasketContext();

  return (
    <>
      <ProductsInBasketSummary allowEditing={editInDetails} small />
      {editInDetails && (
        <div className="flex justify-end mt-sm">
          <button
            className="hover:bg-lightGray rounded-full px-sm py-xs flex flex-row items-center gap-xs w-fit text-primary"
            onClick={() =>
              dispatchStepEvent({ type: 'edit-step', value: 'content' })
            }
          >
            <IoAddOutline size={16} />
            {t('Add more products')}
          </button>
        </div>
      )}
    </>
  );
}
