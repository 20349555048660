import Slider from 'rc-slider';
import { useRef } from 'react';

import './RangeSlider.scss';

interface IRangeInputProps {
  readonly min: number;
  readonly max: number;
  readonly onChange: (range: number[]) => void;
  readonly defaultMin?: number;
  readonly defaultMax?: number;
  readonly step?: number;
}

export function RangeInput(props: IRangeInputProps) {
  const min = useRef<number>(props.defaultMin ?? props.min);
  const max = useRef<number>(props.defaultMax ?? props.max);

  const internalOnChange = (range: number | number[]) => {
    if (Array.isArray(range)) {
      props.onChange(range);
      min.current = range[0];
      max.current = range[1];
    }
  };

  return (
    <Slider
      className="range-input"
      allowCross={false}
      range={true}
      min={props.min}
      max={props.max}
      step={props.step}
      defaultValue={[min.current, max.current]}
      onChange={internalOnChange}
    />
  );
}
