import { Modal } from 'components/dialogs';
import { usePopCardContext } from 'components/popcards/PopCardContext';
import { LoadingSpinner } from 'components/spinners';
import { useGlobalConfigsContext } from 'contexts';
import { trackEvent, trackException } from 'libs/telemetry';
import { triggerErrorToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import AuthenticationService from 'services/AuthenticationService';
import { BonusPayoutMethod, DirectCashPayoutMethod } from 'types';

import { usePayoutsContext } from '../../contexts/PayoutsContext';
import { PayquickerDirectCashLegalAgreement } from '../payquicker/PayquickerDirectCashLegalAgreement';

export function PMDirectCashSignupMarketingBanner() {
  const { t } = useTranslation();
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();
  const { queuePopCard } = usePopCardContext();
  const { payoutDetails, updatePayouts } = usePayoutsContext();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [openPayquickerLegalAgreement, setOpenPayquickerLegalAgreement] =
    useState<boolean>(false);

  const autoselectMethod = useMemo(() => {
    if (
      _.isUndefined(payoutDetails) ||
      payoutDetails.directCashState.active ||
      payoutDetails.isDirectCashAvailable === false
    ) {
      return undefined;
    }

    if (
      payoutDetails.availableDirectCashPayoutMethods
        .map(method => method.directCashPayoutMethod)
        .includes(DirectCashPayoutMethod.PayQuicker)
    ) {
      return DirectCashPayoutMethod.PayQuicker;
    }

    if (payoutDetails.availableDirectCashPayoutMethods.length > 0) {
      return payoutDetails.availableDirectCashPayoutMethods[0]
        .directCashPayoutMethod;
    }

    return undefined;
  }, [payoutDetails]);

  const onSignupClick = async () => {
    if (_.isUndefined(autoselectMethod)) {
      return;
    }

    const alreadyAcceptedPayquickerAgreement =
      payoutDetails?.selectedBonusPayoutMethod === BonusPayoutMethod.PayQuicker;

    if (
      autoselectMethod === DirectCashPayoutMethod.PayQuicker &&
      !alreadyAcceptedPayquickerAgreement
    ) {
      setOpenPayquickerLegalAgreement(true);
      trackEvent({
        name: 'DirectCashSignup_OpenedPayQuickerAgreement'
      });
      return;
    }

    try {
      setLoading(true);
      await updatePayouts({
        activeDirectCashState: true,
        directCashMethod: autoselectMethod
      });
      trackEvent({
        name: 'DirectCashSignup_OptionChanged',
        properties: {
          selectMethod: autoselectMethod
        }
      });
    } catch (err) {
      console.error('Failed to automatically signup for PM DirectCash', err);
      trackException(
        err,
        'DirectCashSignup',
        `Failed to show the direct cash legal agreement.`
      );
      triggerErrorToast(t('Something went wrong.'));
    } finally {
      setLoading(false);
    }
  };

  const refusePQAgreement = () => {
    setLoading(false);
    setOpenPayquickerLegalAgreement(false);
    trackEvent({
      name: 'DirectCashSignup_RefusedPayQuickerAgreement'
    });
  };

  const acceptPQAgreement = async () => {
    setOpenPayquickerLegalAgreement(false);

    trackEvent({
      name: 'DirectCashSignup_AcceptedPayQuickerAgreement'
    });

    try {
      setLoading(true);
      await updatePayouts({
        activeDirectCashState: true,
        directCashMethod: DirectCashPayoutMethod.PayQuicker
      });
      trackEvent({
        name: 'DirectCashSignup_OptionChanged',
        properties: {
          selectMethod: DirectCashPayoutMethod.PayQuicker
        }
      });

      if (
        !_.isUndefined(AuthenticationService.user) &&
        !_.isUndefined(AuthenticationService.user.email)
      ) {
        queuePopCard({
          title: AuthenticationService.user.email,
          description: t(
            "You successfully upgraded to PM Pay+! However, your registration still needs to be completed. Along with your next payout, you will receive an email with further instructions on how to register. Don't forget to complete your registration so you can access your future payouts."
          ),
          id: _.uniqueId(),
          imgUri: '/imgs/promo/Email.svg',
          cta: t('Close'),
          ctaAction: _.noop
        });
      }
    } catch (err) {
      console.error('Failed to update PM DdirectCash payout method', err);
      trackException(
        err,
        'DirectCashSignup',
        'Failed to active direct cash with "Payquicker"'
      );
      triggerErrorToast(t('Something went wrong.'));
    } finally {
      setLoading(false);
    }
  };

  if (_.isUndefined(autoselectMethod)) {
    return null;
  }

  return (
    <div className="bg-white shadow-md rounded-md flex flex-col-reverse sm:flex-row justify-between relative">
      {isLoading && (
        <div className="absolute inset-0 pmi-glass-white flex items-center justify-center">
          <LoadingSpinner smallSize />
        </div>
      )}

      <div className="p-md sm:p-lg space-y-sm">
        <h2 className="text-primary">{t('PM DirectCash')}</h2>
        <p>{t('Earning cash has never been easier!')}</p>
        <p>
          {t(
            'Sign up for PM DirectCash and receive cash immediately after a customer places an order through your online store.'
          )}
        </p>
        <button
          className="rounded-full px-md py-sm bg-primary text-white"
          onClick={onSignupClick}
        >
          {t('Sign up')}
        </button>
      </div>
      <div
        className="min-h-[250px] min-w-[180px] sm:max-w-[350px] grow bg-center bg-no-repeat bg-cover"
        style={{
          backgroundImage:
            'url("/imgs/promo/payquicker/getDirectCash-min.jpeg")'
        }}
      ></div>

      <Modal
        hideCloseBtn
        open={openPayquickerLegalAgreement}
        onClose={() => setOpenPayquickerLegalAgreement(false)}
      >
        <PayquickerDirectCashLegalAgreement origin="directcash" />
        <div className="flex flex-row flex-wrap justify-evenly mt-md">
          <button
            className="rounded-full px-md py-sm bg-primary text-white"
            onClick={acceptPQAgreement}
          >
            {t('I accept')}
          </button>
          <button
            className={`text-primary ${
              supportsHover ? 'hover:text-active' : 'text-primary'
            }`}
            onClick={refusePQAgreement}
          >
            {t('Go back')}
          </button>
        </div>
      </Modal>
    </div>
  );
}
