/* eslint-disable @miovision/disallow-date/no-static-date */
/* eslint-disable @miovision/disallow-date/no-new-date */
const START_DATE = Date.now();
const END_DATE = Date.now();

export function randomDate(): string {
  const date = new Date(START_DATE + Math.random() * (END_DATE - START_DATE));
  date.setUTCHours(0);
  return date.toISOString();
}
