import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import { useNavigate } from 'react-router-dom';

import './EventsBackButton.scss';

export function EventsBackButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <button
      title={t('Events')}
      className="events-back-button"
      onClick={() => navigate('/events')}
    >
      <Icon name={IconName.ChevronLeft} size={16} />
      <span className="ml-xs">{t('Events')}</span>
    </button>
  );
}
