import { Timestamp } from '@pmi.libs/utils-common';
import { IEvent, IEventTicket } from 'features/events';
import { fetchEventStory, IEventStory } from 'libs/cms';
import _ from 'lodash';
import { IFirstlineTicketPage, IEventBenefit } from 'types';
import { parseQualificationLevel } from 'utils';

import {
  parseEvent,
  parseEventBenefits,
  parseEventOrders,
  parseEvents
} from './EventsServiceHelper';
import AuthenticationService from '../AuthenticationService';
import { execGet } from '../RequestUtils';
import { IEventApi } from './types/IEventApi';
import { IEventBenefitApi } from './types/IEventBenefitApi';
import { IEventOrderApi } from './types/IEventOrderApi';

export async function getEvent(eventId: string): Promise<IEvent> {
  const event = await execGet<IEventApi>(
    `${process.env.REACT_APP_EVENTS_API}/${eventId}`
  );

  return parseEvent(event);
}

export async function getPromotedEvents(
  country: string
): Promise<ReadonlyArray<IEvent>> {
  const apiEvents = await execGet<ReadonlyArray<IEventApi>>(
    `${
      process.env.REACT_APP_EVENTS_API
    }/promotion?country=${country.toLowerCase()}`
  );

  return parseEvents(apiEvents);
}

export function getEventStory(
  eventId: string,
  slug: string,
  slugLanguage: string
): Promise<IEventStory> {
  return fetchEventStory(eventId, slug, slugLanguage);
}

export function getEventPageExternalURL(
  eventId: string,
  eventLanguage: string
): string {
  return `${process.env.REACT_APP_EVENTS_PAGE}/${eventLanguage}/${eventId}`;
}

export function getEventRegistrationExternalURL(
  eventId: string,
  eventLanguage: string
) {
  return `${process.env.REACT_APP_EVENTS_REGISTRATION}/${eventLanguage}/${eventId}`;
}

/**
 *
 * @param eventId
 * @returns The ticket of the signed in user for the specified event and his/her
 * guests.
 */
export function getTicketsForEvent(eventId: string) {
  return execGet<ReadonlyArray<IEventTicket>>(
    `${process.env.REACT_APP_EVENTS_API}/${eventId}/tickets`
  );
}

export function computeEventPreferedLanguage(
  eventLanguages: { code: string }[],
  uiLanguage: string
): string | undefined {
  if (eventLanguages.length === 0) {
    return undefined;
  }

  // Return the language from the event that matches the current UI language
  const eventUILanguage = eventLanguages.find(
    l => l.code === uiLanguage || uiLanguage.startsWith(l.code)
  )?.code;

  if (!_.isUndefined(eventUILanguage)) {
    return eventUILanguage;
  }

  // Fallback to english if it exists
  const eventEnglishLanguage = eventLanguages.find(l =>
    l.code.startsWith('en')
  )?.code;

  if (!_.isUndefined(eventEnglishLanguage)) {
    return eventEnglishLanguage;
  }

  // Fallback to the first language in the array
  return eventLanguages[0].code;
}

export async function getFirstlineTickets(
  eventId: string,
  userId: string,
  options?: { pageSize?: number; pageNumber?: number }
) {
  const searchParams = new URLSearchParams();
  searchParams.append('page', '' + (options?.pageNumber ?? 1));
  searchParams.append('perPage', '' + (options?.pageSize ?? 20));
  const results = await execGet<IFirstlineTicketPage>(
    `${
      process.env.REACT_APP_EVENTS_API
    }/${eventId}/attendees/${userId}/firstline?${searchParams.toString()}`
  );

  const firstlineTicketsPage: IFirstlineTicketPage = {
    ...results,
    results: (results.results ?? []).map(r => {
      return {
        ...r,
        ticket: r.ticket
          ? {
              ...r.ticket,
              purchaseDate: r.ticket?.purchaseDate
                ? Timestamp.create(r.ticket?.purchaseDate as unknown as string)
                : undefined
            }
          : undefined,
        customer: {
          ...r.customer,
          qualification: r.customer.qualification
            ? parseQualificationLevel(r.customer.qualification)
            : undefined,
          rank: r.customer.rank
            ? parseQualificationLevel(r.customer.rank)
            : undefined
        }
      };
    })
  };

  return firstlineTicketsPage;
}

/**
 *
 * @param eventId
 * @returns All tickets bought by the signed in user for a specific event
 */
export async function getEventOrders(eventId: string) {
  const orders = await execGet<ReadonlyArray<IEventOrderApi>>(
    `${process.env.REACT_APP_EVENTS_API}/${eventId}/orders`
  );

  return parseEventOrders(orders);
}

export async function getAllEventBenefits(): Promise<
  ReadonlyArray<IEventBenefit>
> {
  const loggedInUserId = AuthenticationService.user.userId;
  const benefits = await execGet<ReadonlyArray<IEventBenefitApi>>(
    `${process.env.REACT_APP_EVENTS_API}/events/tickets/benefits/?customerId=${loggedInUserId}`
  );

  return parseEventBenefits(benefits);
}
