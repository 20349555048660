import { PII } from 'features/pii';
import { useTranslation } from 'libs/translations';
import { useMemo } from 'react';
import { IPaymentIntrument, PaymentModule } from 'types';

import { PaymentIcon } from './PaymentIcon';

export function PaymentInfo(props: IPaymentIntrument) {
  const { t } = useTranslation();

  const info = useMemo(() => {
    if (props.methodType === PaymentModule.PaymentAtSubsidiary) {
      return t('Payment at your Subsidiary');
    }

    if (
      props.methodType === PaymentModule.BraintreeSepa ||
      props.methodType === PaymentModule.Sepa
    ) {
      return t('Direct debit {{ibanLast4Digits}}', {
        ibanLast4Digits: props.info
      });
    }

    return props.info;
  }, [props.info, props.methodType, t]);

  return (
    <div className="flex flex-row gap-xs items-center w-fit rounded-full">
      {props.instrumentBrand.map(p => (
        <PaymentIcon key={p} brand={p} />
      ))}
      <PII type="payment-instrument" value={info} />
    </div>
  );
}
