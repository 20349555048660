import { FormattedUnitValue } from 'components/text';
import { useBoolean } from 'hooks';
import { useTranslation } from 'libs/translations';
import { useFormContext } from 'react-hook-form';

import { useStructureSalesFilterLimits } from './useStructureSalesFilterLimits';
import { FilterSection } from '../../filter-sections/FilterSection';
import { FilterSectionName } from '../../filter-sections/FilterSectionName';
import { RangeInput } from '../../range-slider/RangeInput';

export function StructureSalesFilter() {
  const { t } = useTranslation();
  const { register, watch, setValue } = useFormContext();
  const { max, min, step } = useStructureSalesFilterLimits();

  const [manualIputEnabled, { toggle: toggleManualInput }] = useBoolean(false);

  const byStructureVolumeFrom = watch('byStructureVolumeFrom') || 0;
  const byStructureVolumeTo = watch('byStructureVolumeTo') || Infinity;

  const onRangeChange = (range: number[]) => {
    const newMinValue = range[0] ?? 0;
    const newMaxValue = range[1] === max ? Infinity : range[1];
    setValue('byStructureVolumeFrom', newMinValue);
    setValue('byStructureVolumeTo', newMaxValue);
  };

  return (
    <FilterSection name={FilterSectionName.StructureSales}>
      <button
        className="text-active text-xs py-xs"
        onClick={event => {
          toggleManualInput();
          event.preventDefault();
        }}
      >
        {manualIputEnabled ? t('Hide inputs') : t('Show inputs')}
      </button>
      <div className="space-y-xs" hidden={manualIputEnabled}>
        <p className="space-x-xs">
          <FormattedUnitValue unit={'points'} value={byStructureVolumeFrom} />
          <span>-</span>
          <FormattedUnitValue unit={'points'} value={byStructureVolumeTo} />
        </p>
        <RangeInput
          min={min}
          max={max}
          step={step}
          onChange={onRangeChange}
          defaultMin={byStructureVolumeFrom}
          defaultMax={byStructureVolumeTo}
        />
      </div>
      <fieldset
        className="analytics-filters-fieldset"
        hidden={!manualIputEnabled}
      >
        <label>
          {t('Minimum Structure Sales')}
          <div data-type="number">
            <input
              min={0}
              hidden={!manualIputEnabled}
              type="number"
              inputMode="numeric"
              {...register('byStructureVolumeFrom', {
                valueAsNumber: true,
                min: 0,
                onChange: event => {
                  const value = Number.parseInt(event.target.value, 10);
                  if (Number.isNaN(value) || value < 0) {
                    setValue('byStructureVolumeFrom', 0);
                  }
                }
              })}
            />
          </div>
        </label>
        <label>
          {t('Maximum Structure Sales')}
          <div data-type="number">
            <input
              min={0}
              hidden={!manualIputEnabled}
              type="number"
              inputMode="numeric"
              {...register('byStructureVolumeTo', {
                valueAsNumber: true,
                min: 0,
                onChange: event => {
                  const value = Number.parseInt(event.target.value, 10);
                  if (Number.isNaN(value) || value < 0) {
                    setValue('byStructureVolumeTo', undefined);
                  }
                }
              })}
            />
          </div>
        </label>
      </fieldset>
    </FilterSection>
  );
}
