import { Disclaimer } from 'components/text';
import { useGlobalConfigsContext } from 'contexts';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { downloadDocument } from 'services';
import { DocumentType, DocumentCategory, IDocument } from 'types';

import { DocDate } from './DocDate';
import { DocFormatIcon } from './DocFormatIcon';
import './DocumentsList.scss';
import { useDocumentI18n } from './hooks/useDocumentI18n';

interface IDocumentsListProps {
  readonly documents: ReadonlyArray<IDocument>;
}

export function DocumentsList(props: IDocumentsListProps) {
  const { t } = useTranslation();
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();
  const { translateDocumentType } = useDocumentI18n();

  const showTaxDocumentSection = useMemo(() => {
    return !_.isUndefined(
      props.documents.find(doc => doc.category === DocumentCategory.TaxInvoices)
    );
  }, [props.documents]);

  const shouldShowItalyTaxWarning = useMemo(() => {
    if (!showTaxDocumentSection) {
      return false;
    }

    return !_.isUndefined(
      props.documents.find(doc => doc.docType === DocumentType.ProvisionItaly)
    );
  }, [showTaxDocumentSection, props.documents]);

  return (
    <>
      {shouldShowItalyTaxWarning && (
        <div className="my-lg">
          <Disclaimer
            message={t(
              `As required by Law 173/2005, PM-International issues receipts and invoices for the commissions accrued by the Home-Based Distributor (IVD) business on your behalf. You are obliged to print and keep these receipts and dispose of them if necessary within 10 days of their issue. It is your responsibility to check the overcoming of accumulated commissions in the calendar year received by PM or other companies treating as an IVD exceeding a gross amount of € 6,410.- and to communicate it promptly to distributors@pm-international.it!`
            )}
          />
        </div>
      )}

      <table className="documents-table">
        <thead>
          <tr>
            <th className="text-left">
              <p>{t('Document')}</p>
            </th>
            <th className="text-right">
              <p>{t('Date')}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.documents.map(doc => {
            return (
              <tr key={`doc_${doc.filename}`}>
                <td className="text-left">
                  <button
                    className={`${
                      supportsHover
                        ? 'hover:text-active'
                        : 'text-primary underline'
                    } flex flex-row items-center`}
                    onClick={() => downloadDocument(doc)}
                  >
                    <DocFormatIcon {...doc} />
                    <span className="ml-xs">{translateDocumentType(doc)}</span>
                  </button>
                </td>
                <td className="text-right">
                  <DocDate {...doc} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {props.documents.length === 0 && (
        <div className="w-full flex flex-col items-center mt-lg">
          <Icon name={IconName.Documents} size={48} className="text-silver" />
          <p className="text-silver mt-sm">{t('No documents available.')}</p>
        </div>
      )}
    </>
  );
}
