import type { FC } from 'react';

export const FitLineIcon: FC<{ readonly size: number }> = ({ size }) => (
  <svg
    width={size}
    className="flex-shrink-0 flex-grow-0"
    height={size}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-4.000000, 0.000000)"
        fill="#CE0538"
        fillRule="nonzero"
      >
        <g transform="translate(4.009484, 0.000000)">
          <path
            d="M6.07543801,14.020131 C7.94353897,10.8209552 14.7694553,2.26482295 15.129287,0.826705735 C15.2369341,0.395512473 14.6980937,0 14.2669005,0 C13.4764803,0 12.578413,1.40183167 12.1472197,1.97695761 L8.23140431,7.33270449 C3.41752162,14.1997444 1.33352165,18.1542644 0.148193745,21.7489527 C-0.354965853,23.2233555 0.506815911,24.1571036 1.47684956,23.9780949 C2.69846301,23.7621958 3.27358894,23.5112208 3.99204279,23.1870699 C5.35698416,22.5762632 12.6739649,21.9648517 14.1828389,21.9648517 C14.4344187,21.9648517 16.2305534,22.4686161 16.2305534,21.0661796 C16.2305534,19.7357095 9.5249841,19.448449 2.44688321,21.5693392 C3.2373034,18.8727183 4.6742111,16.4288866 6.07543801,14.020131"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
