import _ from 'lodash';

export function getInitials(name: string): string {
  const names = name.split(' ');
  const firstNameFirstChar = (names[0] ?? '').charAt(0).toUpperCase();
  const lastNameFirstChar = (names[names.length - 1] ?? '')
    .charAt(0)
    .toUpperCase();
  return `${firstNameFirstChar}${lastNameFirstChar}`;
}

const SINGLE_ZERO_STRING = '0';

export function padWithZeros(
  value: number | string | undefined,
  minSize: number,
  side: 'left' | 'right' = 'left'
): string {
  let valueToPad: string;

  if (_.isUndefined(value)) {
    valueToPad = '';
  } else if (typeof value === 'number') {
    valueToPad = value.toString();
  } else {
    valueToPad = value;
  }

  if (side === 'left') {
    return valueToPad.padStart(minSize, SINGLE_ZERO_STRING);
  }

  return valueToPad.padEnd(minSize, SINGLE_ZERO_STRING);
}
