import { LogoSplashScreen } from 'components/splash-screen/LogoSplashScreen';
import { usePromoShop } from 'features/promo-shop';
import {
  useSupportsHover,
  useDemoMode,
  useSuperUserConfigs,
  useUserServerState
} from 'hooks';
import _ from 'lodash';
import { ReactNode, createContext, useContext } from 'react';
import { IPromoShop, IUserServerState } from 'types';

interface IGlobalConfigsContextValues {
  readonly device: {
    readonly supportsHover: boolean;
  };
  readonly app: IUserServerState;
  readonly promoShop?: IPromoShop;
  readonly pii: {
    readonly setDemoPersonalData: (enable: boolean) => void;
    readonly setDemoBusinessData: (enable: boolean) => void;
    readonly enableDemoBusinessData?: boolean;
    readonly enableDemoPersonalData?: boolean;
  };
  readonly previewMode: {
    readonly setPreviewMode: (enable: boolean) => void;
    readonly isPreviewEnabled: boolean;
    readonly isImpersonator: boolean;
    readonly isPreviewUser: boolean;
  };
}

const GlobalConfigsContext = createContext<IGlobalConfigsContextValues>({
  device: {
    supportsHover: false
  },
  app: { me: {}, office: {} },
  promoShop: undefined,
  pii: {
    setDemoPersonalData: _.noop,
    setDemoBusinessData: _.noop
  },
  previewMode: {
    isPreviewEnabled: false,
    isImpersonator: false,
    isPreviewUser: false,
    setPreviewMode: _.noop
  }
});

interface IGlobalConfigsProviderProps {
  readonly children: ReactNode;
}

export function GlobalConfigsProvider(props: IGlobalConfigsProviderProps) {
  const deviceHoverSupport = useSupportsHover();

  const {
    enableDemoPersonalData,
    enableDemoBusinessData,
    setDemoPersonalData,
    setDemoBusinessData
  } = useDemoMode();

  const { isImpersonator, isPreviewUser, isPreviewEnabled, setPreviewMode } =
    useSuperUserConfigs();

  const app = useUserServerState();

  const {
    data: promoShopConfig,
    isSuccess,
    isError
  } = usePromoShop(app?.me.preferedLanguage);

  // App is ready to render iff: (1) preferences were fetched, and, if applicable (2) VW config was fetch.
  const isReadyToRender = !_.isUndefined(app) && (isSuccess || isError);

  if (!isReadyToRender) {
    return <LogoSplashScreen loading />;
  }

  return (
    <GlobalConfigsContext.Provider
      value={{
        device: {
          supportsHover: deviceHoverSupport
        },
        app,
        promoShop: promoShopConfig ?? undefined,
        pii: {
          setDemoPersonalData,
          setDemoBusinessData,
          enableDemoPersonalData,
          enableDemoBusinessData
        },
        previewMode: {
          setPreviewMode,
          isPreviewEnabled,
          isImpersonator,
          isPreviewUser
        }
      }}
    >
      {props.children}
    </GlobalConfigsContext.Provider>
  );
}

export function useGlobalConfigsContext() {
  const context = useContext(GlobalConfigsContext);

  if (context === undefined) {
    throw new Error(
      'useGlobalConfigsContext must be used within GlobalConfigsProvider'
    );
  }

  return context;
}
