import { Dialog } from '@headlessui/react';
import { INotificationObject } from 'features/notifications/schemas/INotificationItem';
import NotificationsService from 'features/notifications/services/NotificationsService';
import { usePatchOfficeServerState } from 'hooks';
import { trackEvent } from 'libs/telemetry';
import { useTranslation } from 'libs/translations';
import { useCallback, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { IoCloseSharp } from 'react-icons/io5';

import './NotificationsPanel.scss';
import { NoNotifications } from '../no-notifications/NoNotifications';
import { NotificationItem } from '../notification-item/NotificationItem';

interface INotificationsPanelProps {
  readonly isOpen: boolean;
  readonly notifications: ReadonlyArray<INotificationObject>;
  readonly onDismiss: () => void;
}

export function NotificationsPanel(props: INotificationsPanelProps) {
  const { t } = useTranslation();
  const { patchPreferences } = usePatchOfficeServerState();

  const markAllNotificationsAsRead = useCallback(() => {
    const unreadNotifications = props.notifications.filter(
      notification => notification.read === false
    );

    trackEvent({
      name: 'NotificationsCenter_MarkAllAsRead',
      properties: {
        unreadNotifications: unreadNotifications.length,
        notifications: unreadNotifications.map(unreadNotifications => ({
          date: unreadNotifications.timestamp,
          id: unreadNotifications.id,
          type: unreadNotifications.type,
          internalHref: unreadNotifications.internalHref
        }))
      }
    });

    unreadNotifications.forEach(unreadNotification =>
      NotificationsService.markNotificationAsRead(unreadNotification.id)
    );
    patchPreferences({ notificationsSeen: unreadNotifications.map(n => n.id) });
  }, [patchPreferences, props.notifications]);

  useEffect(() => {
    if (props.isOpen) {
      trackEvent({
        name: 'NotificationsCenter_OpenPanel'
      });
    }
  }, [props.isOpen]);

  return (
    <>
      <Dialog
        as="div"
        className="component__notifications-panel"
        onClose={props.onDismiss}
        open={props.isOpen}
      >
        <div className="overlay pmi-glass-black" />
        <div className="panel">
          <Dialog.Panel>
            <header>
              <h2>{t('Notifications Center')}</h2>
              <button
                className="close-btn"
                type="button"
                title="Close"
                onClick={props.onDismiss}
              >
                <IoCloseSharp size={20} />
              </button>
            </header>
            {props.notifications && (
              <button
                type="button"
                className="mark-all-as-read-btn"
                onClick={markAllNotificationsAsRead}
              >
                {t('Mark all as read')}
              </button>
            )}
            {props.notifications.length > 0 && (
              <ul>
                {props.notifications.map(n => {
                  return (
                    <li key={n.id}>
                      <NotificationItem
                        {...n}
                        onOpen={() => {
                          NotificationsService.markNotificationAsRead(n.id);
                          patchPreferences({
                            notificationsSeen: [n.id]
                          });
                          trackEvent({
                            name: 'NotificationsCenter_NotificationClick',
                            properties: {
                              date: n.timestamp,
                              id: n.id,
                              internalHref: n.internalHref
                            }
                          });
                        }}
                        onClose={() => {
                          props.onDismiss();
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
            {props.notifications.length === 0 && <NoNotifications />}
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
