import { AccountProfilePage } from '@pmi.libs/react-common';
import { RankBadge } from 'components/badges/qualification/RankBadge';
import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { AccountBackButton } from 'features/account';
import { PII, usePII } from 'features/pii';
import { useTranslation } from 'libs/translations';
import AuthenticationService from 'services/AuthenticationService';

export function AccountProfileRoute() {
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();

  const authenticatedUserProfile = AuthenticationService.user;

  return (
    <>
      <PageTitle />
      <section className="space-y-md m-auto">
        <AccountBackButton />
        <AccountProfilePage
          t={t}
          activeLanguage={activeLanguage}
          userData={authenticatedUserProfile}
          profileEditLink={process.env.REACT_APP_LINK_EDIT_PROFILE || ''}
          RankBadge={RankBadge}
          piiComponent={PII}
          piiFun={pii}
        />
      </section>
    </>
  );
}
