import { PrimaryButton } from '@pmi.web/react-common';
import { HelperModalContainer } from 'components/modals';
import {
  INotificationObject,
  NotificationType
} from 'features/notifications/schemas/INotificationItem';
import { useBoolean } from 'hooks';
import { fireConfettiGun } from 'libs/effects';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';

import './NotificationItem.scss';

interface INotificationItemProps extends INotificationObject {
  readonly onOpen: () => void;
  readonly onClose: () => void;
}

export function NotificationItem(props: INotificationItemProps) {
  const { t, activeLanguage } = useTranslation();

  const [isPopCardOpen, { setFalse: closePopCard, setTrue: openPopCard }] =
    useBoolean(false);

  const content = (
    <div className="component__notification-item" data-is-read={props.read}>
      <div className="notification-image">
        <img alt="" src={props.thumbnail ?? '/imgs/Notification.png'} />
      </div>
      <div>
        <p className="notification-title">{props.title}</p>
        {props.description && (
          <p className="notification-description">{props.description}</p>
        )}
        <p className="notification-timestamp">
          {props.timestamp.toLocaleDateString(activeLanguage)}
        </p>
      </div>
    </div>
  );

  if (_.isUndefined(props.type) || props.type === NotificationType.Notice) {
    return _.isUndefined(props.internalHref) ? (
      content
    ) : (
      <NavLink
        to={props.internalHref}
        onClick={() => {
          props.onOpen();
          props.onClose();
        }}
      >
        {content}
      </NavLink>
    );
  }

  return (
    <>
      <button
        className="text-left"
        onClick={() => {
          openPopCard();
          props.onOpen();

          if (props.popcardFireworks && !props.read) {
            _.times(5, () => fireConfettiGun('blue'));
          }
        }}
      >
        {content}
      </button>
      <HelperModalContainer
        maxWidth={450}
        open={isPopCardOpen}
        onDismiss={() => {
          closePopCard();
        }}
      >
        <div className="flex flex-col items-center justify-center gap-md text-center">
          <div className="rounded-lg shadow-md bg-lightGray flex-shrink-0 bg-cover bg-no-repeat bg-center w-32 h-32">
            <img
              alt=""
              src={props.thumbnail ?? '/imgs/Notification.png'}
              className="rounded-lg w-full h-full"
            />
          </div>
          <h1>{props.title}</h1>
          {props.description && <p>{props.description}</p>}
          {props.internalHref && (
            <PrimaryButton>
              <NavLink
                to={props.internalHref}
                onClick={() => {
                  closePopCard();
                  props.onClose();
                }}
              >
                {props.ctaTitle ?? t('Open')}
              </NavLink>
            </PrimaryButton>
          )}
        </div>
      </HelperModalContainer>
    </>
  );
}
