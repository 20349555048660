import { PMLogo } from '@pmi.libs/react-common';
import { Modal } from 'components/dialogs';
import { LoadingSpinner } from 'components/spinners';
import { Disclaimer } from 'components/text';
import { useGlobalConfigsContext } from 'contexts';
import { trackEvent, trackException } from 'libs/telemetry';
import { triggerErrorToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import { useState } from 'react';
import { BonusPayoutMethod, DirectCashPayoutMethod } from 'types';

import { PayquickerDirectCashLegalAgreement } from './PayquickerDirectCashLegalAgreement';
import { usePayoutsContext } from '../../contexts/PayoutsContext';

export function PayquickerForceSetupButton(props: {
  readonly both: boolean;
  readonly onlyBonus: boolean;
}) {
  const { t } = useTranslation();
  const { updatePayouts } = usePayoutsContext();
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();

  const [openLegalAgreement, setOpenLegalAgreement] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const onCloseAgreement = () => {
    setOpenLegalAgreement(false);
    trackEvent({
      name: 'PayquickerSignUp_RefusedPayQuickerAgreement'
    });
  };

  const onConfirmAgreement = async () => {
    try {
      if (props.onlyBonus) {
        await updatePayouts({ bonusMethod: BonusPayoutMethod.PayQuicker });
        trackEvent({
          name: 'PayquickerSignUp_SetBonusMethod',
          properties: {
            selectMethod: BonusPayoutMethod.PayQuicker
          }
        });
      } else if (props.both) {
        await updatePayouts({
          bonusMethod: BonusPayoutMethod.PayQuicker,
          activeDirectCashState: true,
          directCashMethod: DirectCashPayoutMethod.PayQuicker
        });
        trackEvent({
          name: 'PayquickerSignUp_SetBonusMethod',
          properties: {
            selectMethod: BonusPayoutMethod.PayQuicker
          }
        });
        trackEvent({
          name: 'PayquickerSignUp_SetDirectCashMethod',
          properties: {
            selectMethod: BonusPayoutMethod.PayQuicker
          }
        });
      }
    } catch (err) {
      console.error('Failed to automatically signup for PayQuicker', err);
      trackException(
        err,
        'PayquickerSignUp',
        props.onlyBonus
          ? 'Failed to automatically set bonus method to PayQuicker'
          : props.both
          ? 'Failed to automatically set bonus and direct cash to PayQuicker'
          : ''
      );
      triggerErrorToast(t('Something went wrong.'));
    } finally {
      setOpenLegalAgreement(false);
    }
  };

  return (
    <>
      <button
        className="rounded-full px-md py-sm bg-primary text-white"
        onClick={() => {
          setOpenDialog(true);
          trackEvent({
            name: 'PayquickerSignUp_SignUpButtonClick'
          });
        }}
      >
        {t('Sign up for PM Pay+')}
      </button>
      <Modal
        hideCloseBtn
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
      >
        <div className="space-y-sm p-sm">
          <div className="flex flex-row items-center justify-center text-primary">
            <PMLogo className="h-[60px]" />
            <h1>Pay+</h1>
          </div>
          {props.onlyBonus && (
            <>
              <p>
                {t(
                  'PM Pay+ is a world of enhanced convenience where bonus payouts happen instantly! Sign up now and enjoy all the benefits!'
                )}
              </p>
              <Disclaimer
                message={t(
                  'By signing up, bonus payouts via PM Pay+ will be automatically activated!'
                )}
                type="error"
              />
            </>
          )}
          {props.both && (
            <>
              <p className="text-center">
                {t(
                  "Great news! We're introducing the PM Pay+, our latest payment method for receiving bonus payouts and seamlessly connecting to our PM DirectCash program. Simply click the button below and enjoy the benefit of swift and secure transactions."
                )}
              </p>
              <Disclaimer
                message={t(
                  'By signing up, DirectCash and bonus payouts via PM Pay+ will be automatically activated!'
                )}
                type="error"
              />
            </>
          )}

          <div className="flex flex-row flex-wrap justify-evenly mt-md">
            <button
              className="rounded-full px-md py-sm bg-primary text-white"
              onClick={() => {
                setOpenDialog(false);
                setOpenLegalAgreement(true);
              }}
            >
              {t('Sign up for PM Pay+')}
            </button>
            <button
              className={`text-primary ${
                supportsHover ? 'hover:text-active' : 'text-primary'
              }`}
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              {t('Close')}
            </button>
          </div>
        </div>
      </Modal>
      {openLegalAgreement && (
        <LegalAgreement
          onClose={onCloseAgreement}
          onConfirm={onConfirmAgreement}
        />
      )}
    </>
  );
}

function LegalAgreement(props: {
  readonly onClose: () => void;
  readonly onConfirm: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();

  const [open, setOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const refusePQAgreement = () => {
    setOpen(false);
    trackEvent({
      name: 'PayquickerForceAction_RefusedPayQuickerAgreement'
    });
    props.onClose();
  };

  const acceptPQAgreement = async () => {
    setLoading(true);
    trackEvent({
      name: 'PayquickerForceAction_AcceptedPayQuickerAgreement'
    });
    props.onConfirm().then(() => {
      setLoading(false);
    });
  };

  return (
    <Modal hideCloseBtn open={open} onClose={() => props.onClose()}>
      {loading && (
        <div className="absolute inset-0 pmi-glass-white flex items-center justify-center">
          <LoadingSpinner smallSize />
        </div>
      )}
      <PayquickerDirectCashLegalAgreement origin="bonus" />
      <div className="flex flex-row flex-wrap justify-evenly mt-md">
        <button
          className="rounded-full px-md py-sm bg-primary text-white"
          onClick={acceptPQAgreement}
        >
          {t('I accept')}
        </button>
        <button
          className={`text-primary ${
            supportsHover ? 'hover:text-active' : 'text-primary'
          }`}
          onClick={refusePQAgreement}
        >
          {t('Go back')}
        </button>
      </div>
    </Modal>
  );
}
