export function CoachIcon(props: { readonly size: number }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <circle cx="4" cy="3" fill="currentColor" r="3" />
        <path
          d="M16,9V7H3A2,2,0,0,0,1,9V24H3V17H5v7H7V9Z"
          fill="currentColor"
        />
        <path d="M23,2H9V4H22V15H9v2h7.341l2.74,6.394A1,1,0,0,0,20,24a.979.979,0,0,0,.394-.081,1,1,0,0,0,.525-1.313L18.516,17H23a1,1,0,0,0,1-1V3A1,1,0,0,0,23,2Z" />
      </g>
    </svg>
  );
}
