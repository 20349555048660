import { localizedCountryName } from '@pmi.web/countries';
import { InvoiceItem } from 'features/invoices';
import { PII, usePII } from 'features/pii';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { IoLocationOutline } from 'react-icons/io5';
import { ICommitment } from 'types';

export function CommitmentBillingAddress(props: ICommitment) {
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();

  if (_.isUndefined(props.billing)) {
    return null;
  }

  return (
    <InvoiceItem
      icon={<IoLocationOutline size={16} />}
      title={t('Billing address')}
      value={
        <>
          {!_.isUndefined(props.billing.address.addressLine1) && (
            <p>
              <PII type="address" value={props.billing.address.addressLine1} />
            </p>
          )}
          {!_.isUndefined(props.billing.address.addressLine2) && (
            <p>
              <PII type="address" value={props.billing.address.addressLine2} />
            </p>
          )}
          {!_.isUndefined(props.billing.address.streetPob) && (
            <p>
              <PII type="address" value={props.billing.address.streetPob} />
            </p>
          )}
          {!_.isUndefined(props.billing.address.city) && (
            <p>
              <span>
                <PII type="address" value={props.billing.address.city} />
              </span>
              {!_.isUndefined(props.billing.address.zipCode) && (
                <span>
                  ,&nbsp;
                  <PII type="address" value={props.billing.address.zipCode} />
                </span>
              )}
            </p>
          )}
          {!_.isUndefined(props.billing.address.county) && (
            <p>
              <span>
                <PII type="address" value={props.billing.address.county} />
              </span>
              {!_.isUndefined(props.billing.address.district) && (
                <span>
                  ,&nbsp;
                  <PII type="address" value={props.billing.address.district} />
                </span>
              )}
            </p>
          )}
          {!_.isUndefined(props.billing.address.countryCode) && (
            <p>
              {localizedCountryName(
                pii(props.billing.address.countryCode, 'country'),
                activeLanguage
              )}
            </p>
          )}
        </>
      }
    />
  );
}
