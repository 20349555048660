export function addEventListenerToMediaQuery(
  mediaQuery: MediaQueryList,
  handler: (e: MediaQueryListEvent) => void
) {
  if (
    'addEventListener' in mediaQuery &&
    typeof mediaQuery.addEventListener === 'function'
  ) {
    mediaQuery.addEventListener('change', handler);
  } else if (
    'addListener' in mediaQuery &&
    typeof mediaQuery.addListener === 'function'
  ) {
    mediaQuery.addListener(handler);
  }
}

export function removeEventListenerFromMediaQuery(
  mediaQuery: MediaQueryList,
  handler: (e: MediaQueryListEvent) => void
) {
  if (
    'removeEventListener' in mediaQuery &&
    typeof mediaQuery.removeEventListener === 'function'
  ) {
    mediaQuery.removeEventListener('change', handler);
  } else if (
    'removeListener' in mediaQuery &&
    typeof mediaQuery.removeListener === 'function'
  ) {
    mediaQuery.removeListener(handler);
  }
}
