import { Month } from '@pmi.libs/utils-common';
import { parseCountryCode } from '@pmi.web/countries';
import _ from 'lodash';
import { TUserSearchSorting } from 'services';
import { parseQualificationLevel } from 'utils';

import { IFilterFormValues } from '../types/IFilterFormValues';
import { ISearchFormValues } from '../types/ISearchFormValues';
import { ISortFormValues } from '../types/ISortFormValues';

export function isValidSearchFormValues(
  value: unknown
): value is ISearchFormValues {
  if (!_.isObject(value)) {
    return false;
  }

  const properties: (keyof ISearchFormValues)[] = ['search'];

  const validityStatus = properties.map(property => {
    const propertyValue = _.get(value, property) ?? undefined;

    if (_.isUndefined(propertyValue)) {
      return;
    }

    switch (property) {
      case 'search':
        return typeof propertyValue === 'string';
    }
  });

  return validityStatus.find(status => status === false) === undefined;
}

export function isValidFilterFormValues(
  value: unknown
): value is IFilterFormValues {
  if (!_.isObject(value)) {
    return false;
  }

  const properties: (keyof IFilterFormValues)[] = [
    'byCountry',
    'byQualificationFrom',
    'byQualificationTo',
    'byStructureVolumeFrom',
    'byStructureVolumeTo',
    'fitlineClubMembers',
    'newRegistrations',
    'period',
    'withCheckAssurance',
    'withNewQualification',
    'withSponsorActivity'
  ];

  const validityStatus = properties.map(property => {
    const propertyValue = _.get(value, property) ?? undefined;

    if (_.isUndefined(propertyValue)) {
      return;
    }

    switch (property) {
      case 'byCountry':
        try {
          return (
            typeof propertyValue === 'string' &&
            (propertyValue === '' || parseCountryCode(propertyValue))
          );
        } catch (err) {
          return false;
        }
      case 'byQualificationFrom':
        return (
          typeof propertyValue === 'number' &&
          parseQualificationLevel(propertyValue) !== undefined
        );
      case 'byQualificationTo':
        return (
          typeof propertyValue === 'number' &&
          parseQualificationLevel(propertyValue) !== undefined
        );
      case 'byStructureVolumeFrom':
        return propertyValue || typeof propertyValue === 'number';
      case 'byStructureVolumeTo':
        return propertyValue === undefined || typeof propertyValue === 'number';
      case 'period':
        return (
          typeof propertyValue === 'string' &&
          Month.create(propertyValue) !== undefined
        );
      case 'fitlineClubMembers':
        return (
          typeof propertyValue === 'string' &&
          ['', 'true', 'false'].includes(propertyValue)
        );
      case 'newRegistrations':
        return (
          typeof propertyValue === 'string' &&
          ['', 'true', 'false'].includes(propertyValue)
        );
      case 'withCheckAssurance':
        return (
          typeof propertyValue === 'string' &&
          ['', 'true', 'false'].includes(propertyValue)
        );
      case 'withSponsorActivity':
        return (
          typeof propertyValue === 'string' &&
          ['', 'true', 'false'].includes(propertyValue)
        );
    }
  });

  return validityStatus.find(status => status === false) === undefined;
}

export function isValidSortFormValues(
  value: unknown
): value is ISortFormValues {
  if (!_.isObject(value)) {
    return false;
  }

  const sortingPropertyName: keyof ISortFormValues = 'sorting';
  const propertyValue = _.get(value, sortingPropertyName) ?? undefined;

  return (
    typeof propertyValue === 'string' &&
    Object.values(TUserSearchSorting).includes(
      propertyValue as TUserSearchSorting
    )
  );
}
