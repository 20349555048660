import { RadioGroup } from '@headlessui/react';
import { Modal } from 'components/dialogs';
import { useGlobalConfigsContext } from 'contexts';
import { usePayoutsContext } from 'features/payouts/contexts/PayoutsContext';
import { usePayoutsI18n } from 'features/payouts/hooks/usePayoutsI18n';
import { useBoolean } from 'hooks';
import { PMPayPlus } from 'libs/icons/svg-icons/svgs/pm-pay-plus';
import { trackEvent } from 'libs/telemetry';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { Fragment } from 'react/jsx-runtime';
// eslint-disable-next-line no-restricted-imports
import { PiWarningBold } from 'react-icons/pi';
import { BonusPayoutMethod } from 'types';

import { RequirePayquickerRegistration } from './RequirePayquickerRegistration';
import { PayoutOptionIcon } from '../common/PayoutOptionIcon';
import { PayoutOptionStatusIcon } from '../common/PayoutOptionStatusIcon';
import { getPayQuickerExternalLink } from '../payquicker/PayquickerHelper';

interface BonusPayoutOptionProps {
  readonly option: BonusPayoutMethod;
  readonly region?: string;
  readonly locked: boolean;
}

export function BonusPayoutOption({
  option,
  region,
  locked
}: BonusPayoutOptionProps) {
  const { t } = useTranslation();
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();
  const { payoutDetails } = usePayoutsContext();
  const { translatePayoutOptionType, translatePayoutOptionDescription } =
    usePayoutsI18n();

  const [
    payQuickerRegistrationWarning,
    {
      setTrue: openPayQuickerRegistrationWarning,
      setFalse: closePayQuickerRegistrationWarning
    }
  ] = useBoolean();

  const isPayQuickerRegistrationCompleted = useMemo(
    () => payoutDetails?.payQuickerAccount?.registrationStatus === 'Completed',
    [payoutDetails]
  );

  return (
    <RadioGroup.Option
      key={option}
      value={option}
      as={Fragment}
      disabled={locked}
    >
      {({ checked, disabled }) => (
        <div
          className={`w-auto h-56 transition-all list-none px-4 py-5 rounded-md relative ${
            checked ? 'bg-secondary' : 'bg-zinc-100'
          } ${
            disabled
              ? 'cursor-not-allowed text-silver bg-zinc-100'
              : 'cursor-pointer'
          } ${supportsHover && !disabled ? 'hover:bg-secondary' : ''}`}
        >
          <PayoutOptionStatusIcon checked={checked} disabled={disabled} />

          <article className="h-full flex flex-col">
            <div>
              <PayoutOptionIcon method={option} />
            </div>
            <h2 className="mt-md">
              <span className="inline-flex items-center">
                {checked &&
                  !isPayQuickerRegistrationCompleted &&
                  option === BonusPayoutMethod.PayQuicker && (
                    <PiWarningBold className="mr-xxs" />
                  )}
                {translatePayoutOptionType(option)}
              </span>
            </h2>
            <p className="text-sm mt-sm">
              {option === BonusPayoutMethod.BankTransfer &&
              _.isEmpty(payoutDetails?.bankAccount?.iban)
                ? t(
                    'Please provide your bank details to enable this payout method.'
                  )
                : translatePayoutOptionDescription(option)}
            </p>

            {checked &&
              option === BonusPayoutMethod.PayQuicker &&
              (isPayQuickerRegistrationCompleted ? (
                <div className="mt-sm">
                  <a
                    className={`${
                      supportsHover
                        ? 'hover:text-active hover:underline'
                        : 'underline'
                    } mt-xs text-primary text-[12px] leading-[18px]`}
                    target="pmi_web_office__pmpayplus"
                    rel="noreferrer"
                    href={getPayQuickerExternalLink(region)}
                    onClick={() => {
                      trackEvent({
                        name: 'BonusPayouts_OpenExternalPayquicker'
                      });
                      return true;
                    }}
                  >
                    {t('Go to your PM Pay+ account')}
                  </a>
                </div>
              ) : (
                <div className="mt-sm">
                  <button
                    type="button"
                    onClick={openPayQuickerRegistrationWarning}
                    className={`${
                      supportsHover
                        ? 'hover:text-active hover:underline'
                        : 'underline'
                    } mt-xs text-primary text-[12px] leading-[18px]`}
                  >
                    {t('More Information')}
                  </button>
                </div>
              ))}
          </article>

          <Modal
            hideCloseBtn
            open={payQuickerRegistrationWarning}
            onClose={() => closePayQuickerRegistrationWarning()}
          >
            <PMPayPlus size={92} />
            <div className="mt-sm">
              <RequirePayquickerRegistration />
            </div>
            <div className="flex flex-row flex-wrap justify-evenly mt-md">
              <button
                className="rounded-full px-md py-sm bg-primary text-white"
                onClick={closePayQuickerRegistrationWarning}
              >
                {t('Got it')}
              </button>
            </div>
          </Modal>
        </div>
      )}
    </RadioGroup.Option>
  );
}
