import { QUALIFICATIONS } from '@pmi.libs/react-common';
import { QualificationBadge } from 'components/badges';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { IUserQualificationHistory } from 'types';

import './QualificationHistory.scss';

interface IQualificationHistoryProps {
  readonly history: ReadonlyArray<IUserQualificationHistory>;
}

export function QualificationHistory(props: IQualificationHistoryProps) {
  const { t, activeLanguage } = useTranslation();

  const historicalData = useMemo(() => {
    return [...props.history].sort((a, b) => {
      // Sort in descending order
      return b.date.getTime() - a.date.getTime();
    });
  }, [props.history]);

  return (
    <>
      <h4 className="uppercase text-silver">{t('Qualification history')}</h4>
      <div className="timeline-container">
        <ul className="timeline">
          {historicalData.map((item, index) => (
            <li
              key={`${item.date.toString()}_${item.qualification}_${index}`}
              className="relative mb-sm last:mb-0"
            >
              <div className="timeline--item-container">
                <div className="w-fit flex items-center">
                  {!_.isUndefined(item.qualification) ? (
                    <>
                      <QualificationBadge qualification={item.qualification} />
                      <p className="ml-xxs">
                        {QUALIFICATIONS[item.qualification].name}
                      </p>
                    </>
                  ) : (
                    <p></p>
                  )}
                </div>
                <p className="pmi-caption text-silver">
                  {item.date.toLocaleString(activeLanguage)}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
