import { ReactNode } from 'react';

interface IPageHeaderProps {
  readonly sticky?: boolean;
  readonly children: ReactNode;
}

export function PageHeader({ children, sticky = true }: IPageHeaderProps) {
  return (
    <header
      className={`${
        sticky ? 'sticky top-0' : ''
      } left-0 right-0 z-1 shadow-lg h-[56px] sm:h-[72px] flex transition-all bg-white w-full px-xs md:px-sm`}
    >
      <div className="flex flex-row items-center w-full pmi-safe-area-inset-l pmi-safe-area-inset-r">
        {children}
      </div>
    </header>
  );
}
