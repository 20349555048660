import { useEffect } from 'react';

const KEYDOWN_EVENT_NAME = 'keydown';

export function useKeydownWindowEvent(
  eventHandler: (event: KeyboardEvent) => void
) {
  useEffect(() => {
    window.addEventListener(KEYDOWN_EVENT_NAME, eventHandler);

    return () => {
      window.removeEventListener(KEYDOWN_EVENT_NAME, eventHandler);
    };
  }, [eventHandler]);
}
