export function ShieldFillIcon(props: { readonly size: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.size}
      width={props.size}
      viewBox="0 0 16 16"
    >
      <g fill="currentColor">
        <path
          d="M14.165,1.014l-6-1a1.015,1.015,0,0,0-.33,0l-6,1A1,1,0,0,0,1,2V9c0,5,7,7,7,7s7-2,7-7V2A1,1,0,0,0,14.165,1.014ZM12.274,5.133l-4.5,5.5A1,1,0,0,1,7.05,11H7a1,1,0,0,1-.707-.293l-2.5-2.5A1,1,0,1,1,5.207,6.793L6.926,8.512l3.8-4.645a1,1,0,0,1,1.548,1.266Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
