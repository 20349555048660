import _ from 'lodash';
import { RefObject, useEffect, useRef, useState } from 'react';

import { AutoshipsColumn } from './columns/AutoshipsColumns';
import { CheckAssuranceColumn } from './columns/CheckAssuranceColumn';
import { FitlineClubMemberColumn } from './columns/FitlineClubMemberColumn';
import {
  CurrentQualificationColumn,
  HighesthQualificationColumn,
  PreviousMonthQualificationColumn
} from './columns/QualificationColumns';
import {
  PersonalSalesColumn,
  SixLevelSalesColumn,
  StructureSalesColumn
} from './columns/SalesColumns';
import { TeampartnerColumn } from './columns/TeamparterColumn';
import { IFirstlineDataViewerProps } from './FirstlineDataViewer';
import { AutoshipsHeader } from './headers/AutoshipHeaders';
import { CheckAssuranceHeader } from './headers/CheckAssuranceHeader';
import { FitlineClubMemberHeader } from './headers/FitlineClubMemberHeader';
import {
  CurrentQualificationHeader,
  HighestQualificationHeader,
  PreviousQualificationHeader
} from './headers/QualificationHeaders';
import {
  PersonalSalesHeader,
  SixLevelSalesHeader,
  StructureSalesHeader
} from './headers/SalesHeaders';

export function useContainerDimensions(myRef: RefObject<HTMLElement>) {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    scrolling: false
  });

  useEffect(() => {
    const getDimensions = () => {
      if (_.isNull(myRef.current)) {
        return;
      }

      return {
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight,
        scrolling: _.isNull(myRef.current.offsetParent)
          ? false
          : (myRef.current.offsetParent as HTMLElement).offsetWidth <
            myRef.current.offsetWidth
      };
    };

    const handleResize = () => {
      const newDimensions = getDimensions();

      if (!_.isUndefined(newDimensions)) {
        setDimensions(newDimensions);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return dimensions;
}

export function FirstlineDataViewerDesktop(props: IFirstlineDataViewerProps) {
  const componentRef = useRef<HTMLTableElement>(null);
  const { width, scrolling } = useContainerDimensions(componentRef);
  const iconOnly = scrolling || width < 1200;

  return (
    <table className="table-auto w-full" ref={componentRef}>
      <thead>
        <tr className="text-silver">
          <th></th>
          <th className="px-xs whitespace-nowrap font-normal text-center">
            <CheckAssuranceHeader iconOnly={iconOnly} />
          </th>
          <th className="px-xs whitespace-nowrap font-normal text-center">
            <CurrentQualificationHeader iconOnly={iconOnly} />
          </th>
          <th className="px-xs whitespace-nowrap font-normal text-center">
            <PreviousQualificationHeader iconOnly={iconOnly} />
          </th>
          <th className="px-xs whitespace-nowrap font-normal text-center">
            <HighestQualificationHeader iconOnly={iconOnly} />
          </th>
          <th className="px-xs whitespace-nowrap font-normal text-center">
            <FitlineClubMemberHeader iconOnly={iconOnly} />
          </th>
          <th className="px-xs whitespace-nowrap font-normal text-center">
            <AutoshipsHeader />
          </th>
          <th className="px-xs whitespace-nowrap font-normal text-right">
            <PersonalSalesHeader />
          </th>
          <th className="px-xs whitespace-nowrap font-normal text-right">
            <SixLevelSalesHeader />
          </th>
          <th className="px-xs whitespace-nowrap font-normal text-right">
            <StructureSalesHeader />
          </th>
        </tr>
      </thead>
      <tbody className="overflow-scroll">
        {props.data.map(obj => {
          return (
            <tr
              key={`fstl__${obj.userId}`}
              className="even:bg-[#f6f6f7] border-t first-of-type:border-0 border-lightGray"
            >
              <td className="p-xs">
                <TeampartnerColumn userProfile={obj} />
              </td>
              <td className="text-center p-xs">
                <CheckAssuranceColumn userProfile={obj} />
              </td>
              <td className="text-center p-xs">
                <CurrentQualificationColumn userProfile={obj} />
              </td>
              <td className="text-center p-xs">
                <PreviousMonthQualificationColumn userProfile={obj} />
              </td>
              <td className="text-center p-xs">
                <HighesthQualificationColumn userProfile={obj} />
              </td>
              <td className="text-center p-xs">
                <FitlineClubMemberColumn userProfile={obj} />
              </td>
              <td className="text-center p-xs">
                <AutoshipsColumn userProfile={obj} />
              </td>
              <td className="text-right p-xs">
                <PersonalSalesColumn userProfile={obj} />
              </td>
              <td className="text-right p-xs">
                <SixLevelSalesColumn userProfile={obj} />
              </td>
              <td className="text-right p-xs">
                <StructureSalesColumn userProfile={obj} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
