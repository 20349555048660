import { isISOCountryCode } from '@pmi.web/countries';
import { FIVE_PERCENT_DISCOUNT_SETTINGS } from 'contants';
import { useBoolean, useUser } from 'hooks';
import _ from 'lodash';
import {
  Dispatch,
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { IBasketPreview } from 'types';

import { DefaultStep, StepSequence } from './contextDefaults';
import { NewBasketState } from './NewBasketState';
import { NewBasketStateAction } from './NewBasketStateAction';
import { newBasketStateReducer } from './newBasketStateReducer';
import { WizardStepId } from './WizardStepId';
import { useBasketPreviewer } from '../hooks/useBasketPreviewer';
import { useCountryShops } from '../hooks/useCountryShops';

interface INewBasketContextValues extends NewBasketState {
  readonly dispatchStepEvent: Dispatch<NewBasketStateAction>;
  readonly steps: ReadonlyArray<WizardStepId>;
  readonly shouldShowPoints: boolean;
  readonly isDiscountActive: boolean;
  readonly toogleDiscount: () => void;
  readonly basketPreview: {
    readonly isLoading: boolean;
    readonly isError: boolean;
    readonly data?: IBasketPreview;
  };
}

export const NewBasketContext = createContext<INewBasketContextValues>({
  ...DefaultStep,
  steps: StepSequence,
  dispatchStepEvent: _.noop,
  shouldShowPoints: false,
  isDiscountActive: false,
  toogleDiscount: _.noop,
  basketPreview: {
    isError: false,
    isLoading: false
  }
});

interface INewBasketContextProviderProps {
  readonly children: ReactNode;
}

export function NewBasketContextProvider(
  props: INewBasketContextProviderProps
) {
  const [searchParams] = useSearchParams();
  const [step, dispatchStepEvent] = useReducer(
    newBasketStateReducer,
    DefaultStep
  );

  const { data: basketOwner } = useUser(step.basketOwnerId);
  const shouldShowPoints = useMemo(
    () => basketOwner?.type === 'BusinessBuilder',
    [basketOwner]
  );

  const [isDiscountActive, { toggle: toogleDiscount }] = useBoolean(false);

  const basketItems = useMemo(() => {
    const basketItems = Array.from(step.articles).map(a => ({
      articleNumber: a[0],
      quantity: a[1]
    }));

    return basketItems;
  }, [step.articles]);

  const basketPreview = useBasketPreviewer(
    step.shopId,
    step.basketOwnerId,
    basketItems,
    step.isAffiliateDiscountEnabled && isDiscountActive
      ? [FIVE_PERCENT_DISCOUNT_SETTINGS]
      : undefined
  );

  const { data: countryShops } = useCountryShops();

  const urlProspectId = searchParams.get('prospectId') ?? undefined;
  const { data: prospectData } = useUser(urlProspectId);

  const urlCustomerId = searchParams.get('customerId') ?? undefined;
  const { data: customerData } = useUser(urlCustomerId);

  const urlTeampartnerId = searchParams.get('teampartnerId') ?? undefined;
  const { data: teampartnerData } = useUser(urlTeampartnerId);

  useEffect(() => {
    if (
      prospectData &&
      prospectData.addresses &&
      prospectData.addresses[0] &&
      prospectData.addresses[0].country &&
      isISOCountryCode(prospectData.addresses[0].country)
    ) {
      dispatchStepEvent({
        type: 'set-owner-country',
        value: prospectData.addresses[0].country
      });
      dispatchStepEvent({
        type: 'set-prospect-id',
        value: prospectData.publicId ?? prospectData.id
      });
    }

    if (
      customerData &&
      customerData.addresses &&
      customerData.addresses[0] &&
      customerData.addresses[0].country &&
      isISOCountryCode(customerData.addresses[0].country)
    ) {
      dispatchStepEvent({
        type: 'set-owner-country',
        value: customerData.addresses[0].country
      });
      dispatchStepEvent({
        type: 'set-customer-id',
        value: customerData.publicId ?? customerData.id
      });
      dispatchStepEvent({
        type: 'confirm-user-id'
      });
    }

    if (
      teampartnerData &&
      teampartnerData.addresses &&
      teampartnerData.addresses[0] &&
      teampartnerData.addresses[0].country &&
      isISOCountryCode(teampartnerData.addresses[0].country)
    ) {
      dispatchStepEvent({
        type: 'set-owner-country',
        value: teampartnerData.addresses[0].country
      });
      dispatchStepEvent({
        type: 'set-teampartner-id',
        value: teampartnerData.publicId ?? teampartnerData.id
      });
      dispatchStepEvent({
        type: 'confirm-user-id'
      });
    }
  }, [customerData, prospectData, teampartnerData]);

  useEffect(() => {
    const urlArticles: [string, number][] =
      searchParams
        .get('articles')
        ?.split(',')
        .map(s => {
          const [key, value] = s.split('_');
          return [key, Number(value)];
        }) ?? [];

    if (urlArticles.length > 0) {
      urlArticles.forEach(article => {
        dispatchStepEvent({
          type: 'set-article-quantity',
          value: article
        });
      });

      if (urlTeampartnerId || urlCustomerId || urlProspectId) {
        dispatchStepEvent({ type: 'confirm-articles' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (step.id === 'summary' && step.articles.size === 0) {
      dispatchStepEvent({ type: 'edit-step', value: 'content' });
    }
  }, [step.articles.size, step.id]);

  useEffect(() => {
    if (countryShops) {
      dispatchStepEvent({
        type: 'set-available-country-shops',
        value: countryShops
      });
    }
  }, [countryShops]);

  return (
    <NewBasketContext.Provider
      value={{
        ...step,
        shouldShowPoints,
        isDiscountActive,
        toogleDiscount,
        basketPreview,
        steps: StepSequence,
        dispatchStepEvent
      }}
    >
      {props.children}
    </NewBasketContext.Provider>
  );
}
