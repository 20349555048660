import { useTranslation } from 'libs/translations';

export function NoNotifications() {
  const { t } = useTranslation();

  return (
    <>
      <object
        title=""
        type="image/svg+xml"
        className="m-auto mt-xl"
        data={'/imgs/EmptyNotifications.svg'}
        width={64}
      ></object>
      <p className="mt-sm text-center">{t('No new notifications')}</p>
    </>
  );
}
