import { APP_VERSION } from 'contants';

export function attachVersionToDOM() {
  const headElement = document.getElementsByTagName('head').item(0);

  if (headElement && !isVersionAlreadyAttachedToDOM()) {
    const versionMetaTag = document.createElement('meta');
    versionMetaTag.setAttribute('name', 'version');
    versionMetaTag.setAttribute('content', APP_VERSION);
    headElement.appendChild(versionMetaTag);
  }
}

function isVersionAlreadyAttachedToDOM() {
  return document.querySelector('head > meta[name="version"]');
}
