import { useWalletAccount } from 'features/wallet/hooks/useWalletAccount';
import { useWalletTransactions } from 'features/wallet/hooks/useWalletTransactions';
import { useTranslation } from 'libs/translations';
import { useEffect } from 'react';
import AuthenticationService from 'services/AuthenticationService';
import { formatCurrencyLocalized } from 'utils';

import { INotification, NotificationType } from '../schemas/INotificationItem';
import NotificationsService from '../services/NotificationsService';

export function GenerateFakeWalletNotifications() {
  const { data: wallet } = useWalletAccount(AuthenticationService.user.userId);

  if (wallet) {
    return <GenerateFakeNotifications />;
  }

  return null;
}

function GenerateFakeNotifications() {
  const { t, activeLanguage } = useTranslation();
  const { data: transactions } = useWalletTransactions(
    AuthenticationService.user.userId
  );

  useEffect(() => {
    if (transactions) {
      transactions.pages[0].data
        // Only create nofitications for income transactions
        .filter(d => d.amount > 0 && d.status === 'posted')
        .forEach(d => {
          const notification: INotification = {
            type: NotificationType.Notice,
            timestamp: d.createdAt,
            id: d.transactionId,
            title: t('You received {{amount}} in your PM Credit account', {
              amount: formatCurrencyLocalized(
                activeLanguage,
                d.amount,
                d.currency
              )
            }),
            internalHref: `/account/pmcredit`,
            thumbnail: '/imgs/wallet/income.jpeg'
          };

          NotificationsService.addNotification(notification);
        });
    }
  }, [activeLanguage, t, transactions]);

  return null;
}
