export function pickRandomItem(items: ReadonlyArray<string>): string {
  return items[Math.floor(Math.random() * items.length)];
}

export function simpleHash(input: string): number {
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    hash = (hash << 5) + input.charCodeAt(i);
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash); // Ensure non-negative value
}

export function mapStringToIndex(input: string, arrayLength: number) {
  const hash = simpleHash(input);
  return hash % arrayLength;
}
