import { BasketsList } from 'features/virtual-warehouse';
import { FAQButton, FAQSlug } from 'libs/cms';
import { Outlet } from 'react-router-dom';

export function BasketsRoute() {
  return (
    <>
      <BasketsList />
      <Outlet />
      <FAQButton slug={FAQSlug.SharedBaskets} />
    </>
  );
}
