import {
  ChartDataTrend,
  LineChart,
  ILineChartDataset
} from 'components/charts/line/LineChart';
import { FormattedUnitValue, LabelledText, TrendValue } from 'components/text';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { IRegistration, IRegistrationDetail } from 'types';
import { computePercentageTrend } from 'utils';

export function WidgetRegistrationsDetails(props: IRegistration) {
  const { t, activeLanguage } = useTranslation();

  const sortedFirstline: IRegistrationDetail = useMemo(() => {
    return {
      ...props.firstline,
      history: [...props.firstline.history].sort((a, b) => {
        // Sort in ascending order
        return a.date.getTime() - b.date.getTime();
      })
    };
  }, [props.firstline]);

  const sortedStructure: IRegistrationDetail = useMemo(() => {
    return {
      ...props.structure,
      history: [...props.structure.history].sort((a, b) => {
        // Sort in ascending order
        return a.date.getTime() - b.date.getTime();
      })
    };
  }, [props.structure]);

  const tabularData = useMemo(() => {
    const firstlineHistoricalDataDescending = [...props.firstline.history].sort(
      (a, b) => {
        // Sort in descending order
        return b.date.getTime() - a.date.getTime();
      }
    );

    const structureHistoricalDataDescending = [...props.structure.history].sort(
      (a, b) => {
        // Sort in descending order
        return b.date.getTime() - a.date.getTime();
      }
    );

    return firstlineHistoricalDataDescending.map((f, i) => {
      const firstlineMonthlyTrend =
        i < firstlineHistoricalDataDescending.length - 1
          ? computePercentageTrend(
              f.volume,
              firstlineHistoricalDataDescending[i + 1].volume
            )
          : undefined;

      const structureMonthlyTrend =
        i < structureHistoricalDataDescending.length - 1
          ? computePercentageTrend(
              structureHistoricalDataDescending[i].volume,
              structureHistoricalDataDescending[i + 1].volume
            )
          : undefined;

      return {
        firstline: f.volume,
        firstlineMonthlyTrend: firstlineMonthlyTrend,
        structure: structureHistoricalDataDescending[i].volume,
        structureMonthlyTrend: structureMonthlyTrend,
        date: f.date
      };
    });
  }, [props.firstline, props.structure]);

  function computeDataset(
    regDetails: IRegistrationDetail,
    label: string,
    rgbColor: string
  ): ILineChartDataset {
    const monthlyTrend = !_.isUndefined(regDetails.lastMonth)
      ? computePercentageTrend(regDetails.current, regDetails.lastMonth)
      : undefined;

    const dataTrend = !_.isUndefined(monthlyTrend)
      ? monthlyTrend > 0
        ? ChartDataTrend.Positive
        : monthlyTrend < 0
        ? ChartDataTrend.Negative
        : undefined
      : undefined;

    return {
      data: regDetails.history.map(h => h.volume),
      dataTrend,
      label,
      cssRgbColor: rgbColor
    };
  }

  const datasets = [
    computeDataset(sortedFirstline, t('Firstline'), 'rgb(10, 132, 255)'),
    computeDataset(sortedStructure, t('Structure'), 'rgb(240, 158, 57)')
  ];

  // Both firstline and structure are expected to have the same dates
  const labels = sortedFirstline.history.map(d =>
    d.date.toLocaleString(activeLanguage)
  );

  return (
    <>
      <div className="flex flex-row justify-center text-center">
        <div className="mx-sm">
          <LabelledText
            label={t('Firstline registrations')}
            value={
              <p className="font-bold">
                <FormattedUnitValue
                  unit="count"
                  value={props.firstline.current}
                />
              </p>
            }
          />
        </div>
        <div className="mx-sm">
          <LabelledText
            label={t('Structure regristrations')}
            value={
              <p className="font-bold">
                <FormattedUnitValue
                  unit="count"
                  value={props.structure.current}
                />
              </p>
            }
          />
        </div>
      </div>
      <div className="mt-lg">
        <LineChart
          yAxesLabel={t('Total count')}
          labels={labels}
          datasets={datasets}
        />
      </div>
      <table className="mt-sm m-auto w-full">
        <thead>
          <tr>
            <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
              {t('Month')}
            </th>
            <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
              {t('Firstline')}
            </th>
            <th className="border-b border-silver"></th>
            <th className="border-b border-silver px-sm text-center text-[12px] text-silver uppercase">
              {t('Structure')}
            </th>
            <th className="border-b border-silver"></th>
          </tr>
        </thead>
        <tbody>
          {tabularData.map(d => {
            return (
              <tr key={d.date.toString()}>
                <td className="py-xxs text-center">
                  {d.date.toLocaleString(activeLanguage)}
                </td>
                <td className="py-xxs text-center">{d.firstline}</td>
                <td className="py-xxs text-center">
                  {!_.isUndefined(d.firstlineMonthlyTrend) && (
                    <TrendValue
                      style="number"
                      value={d.firstlineMonthlyTrend}
                    />
                  )}
                </td>
                <td className="py-xxs text-center">{d.structure}</td>
                <td className="py-xxs text-center">
                  {!_.isUndefined(d.structureMonthlyTrend) && (
                    <TrendValue
                      style="number"
                      value={d.structureMonthlyTrend}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
