import { Month } from '@pmi.libs/utils-common';
import { useResize } from 'hooks';
import { FeatureIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useMemo, useRef } from 'react';
import AuthenticationService from 'services/AuthenticationService';
import { IPaginatedResponse, IUserBusinessData, IUserProfile } from 'types';

import { WidgetAutoships } from './widgets/autoships/WidgetAutoships';
import { WidgetCarBonus } from './widgets/car-bonus/WidgetCarBonus';
import { WidgetCheckAssurance } from './widgets/check-assurance/WidgetCheckAssurance';
import { WidgetFirstQualification } from './widgets/first-qualification/WidgetFirstQualification';
import { WidgetFirstline } from './widgets/firstline/WidgetFirstline';
import { WidgetPayouts } from './widgets/payouts/WidgetPayouts';
import { WidgetQualification } from './widgets/qualification/WidgetQualification';
import { WidgetRegistration } from './widgets/registrations/WidgetRegistrations';
import { WidgetTeamQualification } from './widgets/team-qualifications/WidgetTeamQualification';
import { WidgetTurnover } from './widgets/turnover/WidgetTurnover';

interface IDashboardContentProps extends IUserBusinessData {
  readonly firstlineData: IPaginatedResponse<IUserProfile>;
  readonly period: Month;
}

export function DashboardContent(props: IDashboardContentProps) {
  const {
    userId,
    calculationDate,

    currentQualificationLevel,
    nextQualificationLevel,
    nextQualificationProgress,
    qualificationHistory,
    qualificationTeam,

    checkAssuranceProgress,
    firstQualificationProgress,

    turnoverFromSelf,
    turnoverFromGroup,
    turnoverFromSixLevel,
    turnoverFromStructure,

    autoshipsInFirstline,
    autoshipsInStructure,
    autoshipsOpenTurnover,
    autoshipsPersonalList,
    gainedAutoshipsInStructure,
    lostAutoshipsInStructure,

    customerRegistrations,
    teampartnerRegistrations,
    quickstarterRegistrations,

    carBonusCurrency,
    carBonusHistory,
    carBonusTotal,

    payoutCurrency,
    payoutCurrentValue,
    payoutHistory,

    period,
    firstlineData
  } = props;

  const { t, activeLanguage } = useTranslation();
  const dashboardContainerRef = useRef<HTMLDivElement>(null);
  const { width } = useResize(dashboardContainerRef);
  const isSmall = useMemo(() => width <= 750, [width]);

  return (
    <section className="space-y-xs">
      {!_.isUndefined(calculationDate) && (
        <p className="pmi-caption text-silver text-right">
          {t('Updated on {{- timestamp}}', {
            timestamp: calculationDate.toLocaleDateTimeString(
              activeLanguage,
              'medium'
            )
          })}
        </p>
      )}
      <div
        className={`grid gap-2 sm:gap-4 grid-flow-dense`}
        style={{
          gridTemplateColumns: isSmall
            ? `repeat(2, minmax(0, 1fr))`
            : `repeat(4, minmax(0, 1fr))`
        }}
        ref={dashboardContainerRef}
      >
        {!_.isUndefined(checkAssuranceProgress) && (
          <div className="col-span-1 sm:col-span-2">
            <WidgetCheckAssurance
              period={period}
              userId={userId}
              {...checkAssuranceProgress}
            />
          </div>
        )}

        {!_.isUndefined(firstQualificationProgress) && (
          <div className="col-span-1 sm:col-span-2">
            <WidgetFirstQualification {...firstQualificationProgress} />
          </div>
        )}

        <div className="col-span-1 sm:col-span-2">
          <WidgetQualification
            userId={userId}
            currentQualificationLevel={currentQualificationLevel}
            nextQualificationProgress={nextQualificationProgress}
            nextQualificationLevel={nextQualificationLevel}
            qualificationHistory={qualificationHistory}
            canReachCheckAssurance={!_.isUndefined(checkAssuranceProgress)}
          />
        </div>

        <div className="min-h-[120px]">
          <WidgetTurnover
            title={t('Personal Sales')}
            traceId="PersonalSales"
            tooltip={t(
              'Personal Sales include Personal Purchases and Customer-Direct Sales.'
            )}
            icon={FeatureIconName.PersonalSales}
            {...turnoverFromSelf}
          />
        </div>
        <div className="min-h-[120px]">
          <WidgetTurnover
            title={t('Group Sales')}
            traceId="GroupSales"
            tooltip={t(
              'The Group Sales include sales of your Team Partners (without Check Assurance) + your Customer-Direct Sales + your own purchase(s). Sales of Managers and higher are excluded from the Group Sales.'
            )}
            icon={FeatureIconName.GroupSales}
            {...turnoverFromGroup}
          />
        </div>
        <div className="min-h-[120px]">
          <WidgetTurnover
            title={t('6-Level Sales')}
            traceId="6LevelSales"
            tooltip={t(
              'The 6-Level Sales can be the same as the Qualification Volume and is based on the 1st – 6th level. If someone has 5 qualification legs, the Structure Sales count as the Qualification Volume. If not, the 6-Level Sales count as the Qualification Volume.'
            )}
            icon={FeatureIconName.SixLevelSales}
            {...turnoverFromSixLevel}
          />
        </div>
        <div className="min-h-[120px]">
          <WidgetTurnover
            title={t('Structure Sales')}
            traceId="StructureSales"
            tooltip={t(
              'The Structure Sales refers to the sales by the entire organization, that is, the Group Sales or Manager Group.'
            )}
            icon={FeatureIconName.StructureSales}
            {...turnoverFromStructure}
          />
        </div>

        <div className={isSmall ? 'col-span-2' : 'col-span-4'}>
          <WidgetFirstline
            firstline={firstlineData}
            userId={userId}
            period={period}
          />
        </div>

        <div className="col-span-2">
          <WidgetAutoships
            autoshipsPersonalList={autoshipsPersonalList}
            autoshipsInFirstline={autoshipsInFirstline}
            autoshipsInStructure={autoshipsInStructure}
            autoshipsOpenTurnover={autoshipsOpenTurnover}
            gainedAutoshipsInStructure={gainedAutoshipsInStructure}
            lostAutoshipsInStructure={lostAutoshipsInStructure}
          />
        </div>

        {qualificationTeam.length > 0 && (
          <div className={isSmall ? 'col-span-2' : ''}>
            <WidgetTeamQualification qualificationTeam={qualificationTeam} />
          </div>
        )}

        {userId === AuthenticationService.user.userId &&
          !_.isUndefined(payoutCurrency) &&
          !_.isUndefined(payoutCurrentValue) &&
          !_.isUndefined(payoutHistory) && (
            <div className={isSmall ? 'col-span-2' : ''}>
              <WidgetPayouts
                payoutCurrency={payoutCurrency}
                payoutCurrentValue={payoutCurrentValue}
                payoutHistory={payoutHistory}
              />
            </div>
          )}

        <div className={isSmall ? 'col-span-2' : ''}>
          <WidgetRegistration
            traceId="TPRegistrations"
            icon={FeatureIconName.Registrations}
            title={t('Team Partner registrations')}
            firstline={teampartnerRegistrations.firstline}
            structure={teampartnerRegistrations.structure}
          />
        </div>

        <div className={isSmall ? 'col-span-2' : ''}>
          <WidgetRegistration
            traceId="CustomerRegistrations"
            icon={FeatureIconName.Registrations}
            title={t('Customer registrations')}
            firstline={customerRegistrations.firstline}
            structure={customerRegistrations.structure}
          />
        </div>

        <div className={isSmall ? 'col-span-2' : ''}>
          <WidgetRegistration
            traceId="QuickstarterRegistrations"
            icon={FeatureIconName.Registrations}
            title={t('Quickstarter registrations')}
            firstline={quickstarterRegistrations.firstline}
            structure={quickstarterRegistrations.structure}
          />
        </div>

        {!_.isUndefined(carBonusCurrency) &&
          !_.isUndefined(carBonusHistory) &&
          !_.isUndefined(carBonusTotal) && (
            <div>
              <WidgetCarBonus
                carBonusCurrency={carBonusCurrency}
                carBonusHistory={carBonusHistory}
                carBonusTotal={carBonusTotal}
              />
            </div>
          )}
      </div>
    </section>
  );
}
