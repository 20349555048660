import { localizedCountryName } from '@pmi.web/countries';
import { DropdownInput } from '@pmi.web/react-inputs';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { useTranslation } from 'libs/translations';
import { useEffect, useMemo } from 'react';
import AuthenticationService from 'services/AuthenticationService';
import { ICountryShop } from 'types';

export function SelectNewProspectCountry() {
  const {
    dispatchStepEvent,
    availableCountryShops,
    basketOwnerCountry,
    shopId
  } = useNewBasketContext();
  const { t, activeLanguage } = useTranslation();

  const { options, value } = useMemo(() => {
    if (!availableCountryShops) {
      return {
        options: [],
        value: null
      };
    }

    const options = availableCountryShops.map(shop => ({
      label: localizedCountryName(shop.countryCode, activeLanguage),
      value: JSON.stringify(shop)
    }));

    // Logged in user's country
    const loggedInUserCountryShop = availableCountryShops.find(
      shop => shop.countryCode === AuthenticationService.user.countryCode
    );

    // Select prospect country
    const selectProspectCountryShop = availableCountryShops.find(
      shop => shop.countryCode === basketOwnerCountry
    );

    const value = selectProspectCountryShop ?? loggedInUserCountryShop ?? null;

    return { options, value };
  }, [activeLanguage, availableCountryShops, basketOwnerCountry]);

  useEffect(() => {
    if (!shopId && value) {
      dispatchStepEvent({
        type: 'set-shop-id',
        value: value.shopId
      });
      dispatchStepEvent({
        type: 'set-owner-country',
        value: value.countryCode
      });
    }
  }, [availableCountryShops, dispatchStepEvent, shopId, value]);

  if (options.length === 0 || value === null) {
    return null;
  }

  return (
    <DropdownInput
      label={t('Select their country')}
      value={JSON.stringify(value)}
      options={options}
      onChange={event => {
        const value = JSON.parse(event.target.value) as ICountryShop;
        dispatchStepEvent({
          type: 'set-shop-id',
          value: value.shopId
        });
        dispatchStepEvent({
          type: 'set-owner-country',
          value: value.countryCode
        });
      }}
    />
  );
}
