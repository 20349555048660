import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import StoryblokClient from 'storyblok-js-client';

export const STORYBLOK_CLIENT = new StoryblokClient({
  cache: {
    clear: 'auto',
    type: 'memory'
  }
});

const MAX_IMAGE_WIDTH = 1024;
const IMAGE_QUALITY = 80;

export function computeStoryblokOptimisedImgSrc(
  imgFilename: undefined | string
): string | undefined {
  if (_.isUndefined(imgFilename)) {
    return undefined;
  }

  // Image optimization
  // https://www.storyblok.com/docs/image-service#image-service
  return `${imgFilename}/m/${MAX_IMAGE_WIDTH}x0/filters:quality(${IMAGE_QUALITY})`;
}
