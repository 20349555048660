import { QualificationLevel } from 'types';

interface IQualificationLevelDetails {
  readonly numericValue: number;
  readonly abbreviation: string;
  readonly name: string;
}

export const QUALIFICATIONS: {
  [key in QualificationLevel]: IQualificationLevelDetails;
} = {
  [QualificationLevel.Teampartner]: {
    numericValue: 2,
    abbreviation: QualificationLevel.Teampartner,
    name: 'Team Partner'
  },
  [QualificationLevel.Teampartner1]: {
    numericValue: 2,
    abbreviation: QualificationLevel.Teampartner1,
    name: 'Team Partner I'
  },
  [QualificationLevel.Teampartner2]: {
    numericValue: 3,
    abbreviation: QualificationLevel.Teampartner2,
    name: 'Team Partner II'
  },
  [QualificationLevel.MinusManager]: {
    numericValue: 1,
    abbreviation: QualificationLevel.MinusManager,
    name: 'Minus Manager'
  },
  [QualificationLevel.Manager]: {
    numericValue: 4,
    abbreviation: QualificationLevel.Manager,
    name: 'Manager'
  },
  [QualificationLevel.SalesManager]: {
    numericValue: 5,
    abbreviation: QualificationLevel.SalesManager,
    name: 'Sales Manager'
  },
  [QualificationLevel.MarketingManager]: {
    numericValue: 6,
    abbreviation: QualificationLevel.MarketingManager,
    name: 'Marketing Manager'
  },
  [QualificationLevel.InternationalMarketingManager]: {
    numericValue: 7,
    abbreviation: QualificationLevel.InternationalMarketingManager,
    name: 'International Marketing Manager'
  },
  [QualificationLevel.VicePresident]: {
    numericValue: 8,
    abbreviation: QualificationLevel.VicePresident,
    name: 'Vice President'
  },
  [QualificationLevel.ExecutiveVicePresident]: {
    numericValue: 9,
    abbreviation: QualificationLevel.ExecutiveVicePresident,
    name: 'Executive Vice President'
  },
  [QualificationLevel.PresidentsTeam]: {
    numericValue: 10,
    abbreviation: QualificationLevel.PresidentsTeam,
    name: "President's Team"
  },
  [QualificationLevel.SilverPresidentsTeam]: {
    numericValue: 11,
    abbreviation: QualificationLevel.SilverPresidentsTeam,
    name: "Silver President's Team"
  },
  [QualificationLevel.GoldPresidentsTeam]: {
    numericValue: 12,
    abbreviation: QualificationLevel.GoldPresidentsTeam,
    name: "Gold President's Team"
  },
  [QualificationLevel.PlatinumPresidentsTeam]: {
    numericValue: 13,
    abbreviation: QualificationLevel.PlatinumPresidentsTeam,
    name: "Platinum President's Team"
  },
  [QualificationLevel.ChampionsLeague]: {
    numericValue: 14,
    abbreviation: QualificationLevel.ChampionsLeague,
    name: "Champion's League"
  },
  [QualificationLevel.SilverChampionsLeague]: {
    numericValue: 15,
    abbreviation: QualificationLevel.SilverChampionsLeague,
    name: "Silver Champion's League"
  },
  [QualificationLevel.GoldChampionsLeague]: {
    numericValue: 16,
    abbreviation: QualificationLevel.GoldChampionsLeague,
    name: "Gold Champion's League"
  },
  [QualificationLevel.PlatinumChampionsLeague]: {
    numericValue: 17,
    abbreviation: QualificationLevel.PlatinumChampionsLeague,
    name: "Platinum Champion's League"
  },
  [QualificationLevel.Champion]: {
    numericValue: 18,
    abbreviation: QualificationLevel.Champion,
    name: 'Champion'
  }
};
