import { Badge } from 'components/badges';
import { Modal } from 'components/dialogs';
import { UserAvatar } from 'components/icons';
import { EventTicket } from 'features/events';
import { PII, usePII } from 'features/pii';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';

interface IEventTicketThumbnailProps {
  readonly eventName: string;
  readonly ticketName?: string;
  readonly owner: string;
  readonly ownerId: string;
  readonly attendeeType: 'tp' | 'partner';
  readonly qrCodeUri?: string;
  readonly isOnlineTicket?: boolean;
  readonly onlineMeeting?: {
    readonly url: string;
    readonly meetingId?: string;
    readonly password?: string;
  };
}

export function EventTicketThumbnail(props: IEventTicketThumbnailProps) {
  const { t } = useTranslation();
  const { pii } = usePII();

  return (
    <div className="flex flex-row">
      <div className="shadow-md bg-secondary rounded-[12px] p-sm flex flex-col gap-xs">
        {!_.isUndefined(props.ticketName) && (
          <div className="text-primary overflow-hidden">
            <p
              className="overflow-hidden whitespace-nowrap text-ellipsis max-w-[250px]"
              title={props.ticketName}
            >
              {props.ticketName}
            </p>
          </div>
        )}
        <div className="flex flex-row items-center gap-xs overflow-hidden">
          <UserAvatar
            userName={props.owner}
            userType={
              props.attendeeType === 'tp' ? 'teampartner' : 'external-user'
            }
          />
          <p
            className="overflow-hidden whitespace-nowrap text-ellipsis"
            title={pii(props.owner, 'name')}
          >
            <PII value={props.owner} type="name" />
          </p>
          {props.attendeeType === 'partner' && (
            <Badge hideDetails title={t('External guest')} />
          )}
          {props.attendeeType === 'tp' && (
            <Badge hideDetails title={`#${pii(props.ownerId, 'tpid')}`} />
          )}
          {props.isOnlineTicket && <Badge hideDetails title={t('Online')} />}
        </div>
      </div>
      {!_.isUndefined(props.qrCodeUri) && (
        <div className="shadow-md rounded-[12px] p-sm bg-primary text-white flex items-center">
          <Modal
            triggerNode={<Icon name={IconName.QRCode} size={24} />}
            noPadding
            noBg
          >
            <EventTicket
              eventName={props.eventName}
              nameInTheTicket={props.owner}
              ticketName={props.ticketName}
              tpIdInTheTicket={props.ownerId}
              isGuest={props.attendeeType === 'partner'}
              qrCodeUri={props.qrCodeUri}
            />
          </Modal>
        </div>
      )}
      {!_.isUndefined(props.onlineMeeting) && (
        <div className="shadow-md rounded-[12px] p-sm bg-white border border-body flex flex-col items-center">
          {!_.isUndefined(props.onlineMeeting.url) && (
            <a
              href={props.onlineMeeting.url}
              target="pmi_web_office__onlineevent"
              rel="noreferrer"
              className="text-active"
            >
              {t('Join online')}
            </a>
          )}
          {(!_.isUndefined(props.onlineMeeting.meetingId) ||
            !_.isUndefined(props.onlineMeeting.password)) && (
            <div className="mt-xs text-center">
              {!_.isUndefined(props.onlineMeeting.meetingId) && (
                <p className="pmi-caption">
                  {t('Meeting ID: {{meetingId}}', {
                    meetingId: pii(
                      props.onlineMeeting.meetingId,
                      'online-meeting-id'
                    )
                  })}
                </p>
              )}
              {!_.isUndefined(props.onlineMeeting.password) && (
                <p className="pmi-caption">
                  {t('Password: {{password}}', {
                    password: pii(
                      props.onlineMeeting.password,
                      'online-meeting-id'
                    )
                  })}
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
