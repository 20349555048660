import { FiltersButton } from './filters/FiltersButton';
import { FiltersDialog } from './filters/FiltersDialog';
import { SearchForm } from './search/SearchForm';
import { SortByButton } from './sort-by/SortByButton';
import { SortByDialog } from './sort-by/SortByDialog';

export function AnalyticsForm() {
  return (
    <section className="bg-white rounded-md p-xs sm:p-sm shadow-md space-y-sm">
      <SearchForm />
      <div className="flex flex-row gap-sm flex-wrap">
        <FiltersButton />
        <SortByButton />
      </div>
      <FiltersDialog />
      <SortByDialog />
    </section>
  );
}
