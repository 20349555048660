import { Icon, IconName } from 'libs/icons';

interface IDisclaimerProps {
  readonly message: string;
  readonly type?: 'error' | 'warning';
}

export function Disclaimer(props: IDisclaimerProps) {
  return (
    <div
      className={`w-full flex flex-row ${
        props.type === 'error'
          ? 'bg-errorBg text-error'
          : props.type === 'warning'
          ? 'bg-[#fcd9c0] text-[#933f04]'
          : 'bg-secondary text-primary'
      } items-center p-sm rounded-md`}
    >
      <Icon
        name={props.type === undefined ? IconName.Info : IconName.Warning}
        size={16}
      />
      <p className="pmi-caption ml-sm whitespace-pre-line">{props.message}</p>
    </div>
  );
}
